const styles = (theme) => ({
  tourButton: {
    boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.5)',
    borderRadius: 60,
    width: 115,
    height: 40,
    cursor: 'pointer',
    position: 'absolute',
    top: '-50px',
    right: 48,
    border: 'none',
    background: theme.content.activeFontColor,
    color: theme.content.backgroundColor,
    [theme.breakpoints.down('md')]: {
      right: 36
    },
    [theme.breakpoints.down('sm')]: {
      right: 24
    },
    '&:focus': {
      outline: 'none'
    }
  }
});

export default styles;
