import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { hexToRgb } from '../../utils';

const StyledMenuItem = withStyles((theme) => {
  const {
    leftMenu = {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69'
    }
  } = theme;
  const backgroundColorRGB = hexToRgb(leftMenu.inactiveFontColor);
  return {
    root: {
      '&:focus': {
        backgroundColor: `rgba(${backgroundColorRGB[0]}, ${backgroundColorRGB[1]}, ${backgroundColorRGB[2]}, 0.1)`,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: leftMenu.inactiveFontColor
        }
      },
      '&:hover': {
        backgroundColor: `rgba(${backgroundColorRGB[0]}, ${backgroundColorRGB[1]}, ${backgroundColorRGB[2]}, 0.1)`,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: leftMenu.inactiveFontColor
        }
      }
    }
  };
})(MenuItem);

export default StyledMenuItem;
