import React from 'react';
import { shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import themeStyles from '../../../styles';
import {
  bathroomIcon,
  bedroomIcon,
  parkingIcon,
  sqmIcon
} from '../../../config/assets';

const FloorplanSpecs = ({ classes, floorplan, area }) => (
  <>
    <Grid container className={classes.detailsWithIcons}>
      {floorplan.bedrooms > 0 && (
        <Grid className={classes.floorplanSpecification}>
          <img
            src={bedroomIcon.src}
            alt={bedroomIcon.alt}
            className={classes.specificationIcon}
          />
          {floorplan.bedrooms}
        </Grid>
      )}
      {floorplan.bathrooms > 0 && (
        <Grid className={classes.floorplanSpecification}>
          <img
            src={bathroomIcon.src}
            alt={bathroomIcon.alt}
            className={classes.specificationIcon}
          />
          {floorplan.bathrooms}
        </Grid>
      )}
      {floorplan.parking > 0 && (
        <Grid className={classes.floorplanSpecification}>
          <img
            src={parkingIcon.src}
            alt={parkingIcon.alt}
            className={classes.specificationIcon}
          />
          {floorplan.parking}
        </Grid>
      )}
      <Grid className={classes.floorplanSpecification}>
        <img
          src={sqmIcon.src}
          alt={sqmIcon.alt}
          className={classes.specificationIcon}
        />
        {area}
      </Grid>
    </Grid>
    {floorplan.features && (
      <Grid container>
        <ul className={classes.featureList}>
          {floorplan.features.map((feature) => (
            <li key={feature.text}>{feature.text}</li>
          ))}
        </ul>
      </Grid>
    )}
  </>
);

FloorplanSpecs.propTypes = {
  classes: shape({}).isRequired,
  floorplan: shape({}).isRequired,
  area: string
};

FloorplanSpecs.defaultProps = {
  area: ''
};

export default withStyles(themeStyles)(FloorplanSpecs);
