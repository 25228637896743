import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const bathroomIcon =
  'https://developers-portal-test.s3-us-west-2.amazonaws.com/fa36e8d5-1e0c-4ec8-ab56-6f3a481b29fb/58/icons/bathroomIcon-Offices.svg';

const CustomInputBathroomOffice = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 20
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 12
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      background: `url(${bathroomIcon}) no-repeat #ededed`,
      backgroundPosition: '15% 50%',
      border: 'none',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: '#ec6b69 0 0 0 0.2rem'
      },
      marginTop: 5
    }
  })
)(InputBase);

export default CustomInputBathroomOffice;
