import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, string, arrayOf, shape, bool } from 'prop-types';
import ImageMenuItem from '../ImageMenuItem';
import { getFinishScenes, getSelectedFinish } from '../../../../selectors/Menu';
import ThreeSixtyAction from '../../../../stores/threeSixty/actions';
import SocketActions from '../../../../stores/sockets/actions';
import {
  socketIdSelector,
  emitingSelector,
  logSelector,
  publisherSelector
} from '../../../../selectors/Socket';

class MobileFinishMenu extends Component {
  componentDidMount() {
    const { socket, publisher } = this.props;
    if (socket !== null && !publisher) {
      socket.on('CHANGE-FINISH', (data) => {
        const { type } = data;
        switch (type) {
          case 'CHANGE-FINISH':
            this.applyFinish(data.finishType);
            break;

          default:
            break;
        }
      });
    }
  }

  changeFinish = async (e, finish) => {
    const { socket, typeOfLog, isEmiting, socketId: id } = this.props;

    const finishType = finish === 'default' ? null : finish;
    this.applyFinish(finishType);

    if (isEmiting) {
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'CHANGE-FINISH',
        data: {
          type: 'CHANGE-FINISH',
          finishType
        }
      });
    }
  };

  applyFinish = async (finishType) => {
    const { dispatch } = this.props;

    await dispatch(ThreeSixtyAction.setSelectedFinish(finishType));

    await dispatch(ThreeSixtyAction.updateFinishes());
  };

  render() {
    const { scenes, selectedFinish, mode, closeMenu } = this.props;
    return (
      <>
        <div className="mobile-submenu-title">FINISHES</div>
        <div
          id="finish-mobile-menu"
          className="mobile-submenu finish-mobile-menu sub-mobile-menu d-flex justify-content-start align-items-center"
        >
          {scenes.map((scene, index) => {
            const { key, name, modes } = scene;
            return (
              <ImageMenuItem
                key={key}
                keyName={key}
                name={name}
                index={index}
                onClick={this.changeFinish}
                img={modes[mode]}
                selected={selectedFinish}
                closeMenu={closeMenu}
              />
            );
          })}
        </div>
      </>
    );
  }
}

MobileFinishMenu.propTypes = {
  scenes: arrayOf(shape({})).isRequired,
  closeMenu: func.isRequired,
  selectedFinish: string.isRequired,
  mode: string.isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired,
  isEmiting: bool.isRequired,
  socketId: string.isRequired,
  typeOfLog: string.isRequired,
  publisher: bool.isRequired
};

const mapStateToProps = (state) => {
  const { mode } = state.threeSixty;
  return {
    scenes: getFinishScenes(state),
    selectedFinish: getSelectedFinish(state),
    mode,
    socketId: socketIdSelector(state),
    isEmiting: emitingSelector(state),
    typeOfLog: logSelector(state),
    publisher: publisherSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileFinishMenu);
