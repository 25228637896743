import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { parkingIcon } from '../../config/assets';

const CustomInputParking = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      marginBottom: 10
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      background: `url(${parkingIcon.src}) no-repeat`,
      backgroundSize: '20px 20px',
      backgroundPosition: '15% 50%',
      border: 'none',
      fontSize: 16,
      width: 'auto',
      padding: '8px 10px',
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: '#ec6b69 0 0 0 0.2rem'
      },
      marginTop: 5
    }
  })
)(InputBase);

export default CustomInputParking;
