const styles = (theme) => ({
  svgMenuContainer: {
    position: 'absolute',
    background: theme.content.backgroundColor,
    boxShadow: '-2px 0px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    width: 230,
    height: 264,
    zIndex: 3,
    padding: '16px 0',
    overflow: 'auto',
    bottom: 8,
    right: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '30%',
      bottom: 0,
      right: 'auto',
      borderRadius: '10px 10px 0 0'
    }
  },
  svgMenuItemContainer: {
    background: theme.content.backgroundColor,
    color: theme.content.inactiveFontColor,
    padding: '0 12px',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
    borderBottom: '0.7px solid rgba(237, 237, 237, 0.3)',
    '&:first-child': {
      borderTop: '0.7px solid rgba(237, 237, 237, 0.3)'
    },
    '&:hover': {
      background: 'rgba(48, 77, 95, 0.1)'
    },
    '&.selected': {
      fontWeight: 'bold'
    },
    cursor: 'pointer'
  },
  svgSubmenuItemContainer: {
    background: theme.content.backgroundColor,
    color: theme.content.inactiveFontColor,
    padding: '0 26px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
    borderBottom: '0.7px solid rgba(237, 237, 237, 0.3)',
    '&:first-child': {
      borderTop: '0.7px solid rgba(237, 237, 237, 0.3)'
    },
    '&:hover': {
      background: 'rgba(48, 77, 95, 0.1)'
    },
    cursor: 'pointer'
  },
  backButton: {
    color: theme.content.inactiveFontColor,
    fontSize: 14
  },
  overlayContainer: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%'
  },
  hidden: {
    display: 'none'
  },
  collapseButtonContainer: {
    position: 'absolute',
    top: 0,
    height: 16,
    width: 230,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  collapseButton: {
    fontSize: 16,
    cursor: 'pointer'
  },
  svgCollapsedMenuContainer: {
    position: 'absolute',
    background: theme.content.backgroundColor,
    boxShadow: '-2px 0px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    width: 230,
    height: 16,
    zIndex: 3,
    overflow: 'auto',
    bottom: 8,
    right: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      bottom: 0,
      right: 'auto',
      borderRadius: '10px 10px 0 0'
    }
  },
  expandButton: {
    fontSize: 16,
    cursor: 'pointer'
  }
});

export default styles;
