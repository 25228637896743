const styles = (theme) => ({
  callButtonsMobileContainer: {
    width: 40
  },
  hiddenCallButtonsMobileContainer: {
    width: 40,
    position: 'absolute',
    bottom: '-72px'
  },
  sessionButtonsContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  callButtonsContainer: {
    width: 'auto',
    paddingRight: 8,
    paddingLeft: 8
  },
  callButtonIcon: {
    marginTop: 20,
    height: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: 15
    }
  }
});

export default styles;
