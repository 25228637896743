import React, { Component } from 'react';
import { string, shape, bool, arrayOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import VimeoPlayer from './VimeoPlayer';
import styles from './styles';
import { socketEmit } from '../../services/socket';

class VideoPlayer extends Component {
  constructor() {
    super();
    this.state = {
      playing: false,
      muted: true
    };
    this.video = null;
  }

  componentDidMount() {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('PLAY-VIDEO', (data) => {
        const { type, socketPlay } = data;
        switch (type) {
          case 'PLAY-VIDEO':
            this.setPlayingState(socketPlay);
            break;
          default:
            break;
        }
      });
      socket.on('MUTE-VIDEO', (data) => {
        const { type, mute } = data;
        switch (type) {
          case 'MUTE-VIDEO':
            this.setMutedState(mute);
            break;

          default:
            break;
        }
      });
    }
  }

  componentWillUnmount = () => {
    const { socket } = this.props;
    socket.off('PLAY-VIDEO', () => {});
  };

  setPlayingState = (playing) => {
    this.setState({
      playing
    });
  };

  setMutedState = (muted) => {
    this.setState({
      muted
    });
  };

  onPlay = () => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'PLAY-VIDEO',
        data: {
          type: 'PLAY-VIDEO',
          socketPlay: true
        }
      });
    }
  };

  onPause = () => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'PLAY-VIDEO',
        data: {
          type: 'PLAY-VIDEO',
          socketPlay: false
        }
      });
    }
  };

  onMute = () => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'MUTE-VIDEO',
        data: {
          type: 'MUTE-VIDEO',
          mute: true
        }
      });
    }
  };

  onUnmute = () => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'MUTE-VIDEO',
        data: {
          type: 'MUTE-VIDEO',
          mute: false
        }
      });
    }
  };

  render() {
    const { content } = this.props;
    const { playing, muted } = this.state;
    return (
      <Grid
        container
        justify="center"
        style={{ height: '100%', width: '100%' }}
      >
        <VimeoPlayer
          src={content[0].src}
          playing={playing}
          muted={muted}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onMute={this.onMute}
          onUnmute={this.onUnmute}
        />
      </Grid>
    );
  }
}

VideoPlayer.propTypes = {
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired
};

export default withStyles(styles)(VideoPlayer);
