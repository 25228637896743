import { createElement } from 'react';
import { shape, bool, oneOfType, arrayOf, string, number } from 'prop-types';
import Pagination from '../Pagination';
import Paragraph from '../Paragraph';
import Logos from '../Logos';
import Image from '../Image';
import Title from '../Title';
import Carousel from '../Carousel';
import VideoPlayer from '../VideoPlayer';
import Layouts from '../Layouts';
import MapComponent from '../Maps';
import Table from '../Table';
import List from '../List';
import ListWithIcon from '../ListWithIcon';
import Tour from '../Tour';
import VirtualTour from '../VirtualTour';
import Finishes from '../Finishes';
import Youtube from '../VideoPlayer/Youtube';
import Vimeo from '../VideoPlayer/Vimeo';
import InteractiveSVG from '../InteractiveSVG';
import DrawableSVG from '../DrawableSVG';
import InteractiveFloorplan from '../InteractiveFloorplan';
import LiveTour from '../LiveTour';
import Link from '../Link';
import GallerySVG from '../GallerySVG';
import Calculator from '../Calculators';

const ContentTypeContainer = ({
  content,
  type,
  socket,
  height,
  publisher,
  meetingId,
  columns,
  color,
  ordered,
  currentPage,
  selectedTab,
  selectedPage,
  selectedMenuItem,
  callOut,
  privatePanelVisible,
  videoCallOpen,
  tabs,
  isCustomer,
  sessionID,
  hasPagination,
  isCover
}) => {
  const component = {
    pagination: Pagination,
    paragraph: Paragraph,
    logos: Logos,
    image: Image,
    title: Title,
    gallery: Carousel,
    video: VideoPlayer,
    layouts: Layouts,
    map: MapComponent,
    table: Table,
    listWithIcon: ListWithIcon,
    list: List,
    tour: Tour,
    virtualTour: VirtualTour,
    finishes: Finishes,
    youtube: Youtube,
    vimeo: Vimeo,
    interactiveImage: InteractiveSVG,
    drawableImage: DrawableSVG,
    gallerySVG: GallerySVG,
    liveTour: LiveTour,
    link: Link,
    interactiveFloorPlan: InteractiveFloorplan,
    priceCalculator: Calculator
  };
  if (type === 'table') {
    const componentSection = createElement(component[type], {
      socket,
      publisher,
      meetingId,
      content,
      currentPage,
      selectedTab,
      selectedPage,
      selectedMenuItem,
      height,
      isCustomer,
      sessionID,
      isCover
    });
    return componentSection;
  }
  if (type === 'list' || type === 'listWithIcon') {
    const componentSection = createElement(component[type], {
      socket,
      publisher,
      meetingId,
      content,
      columns,
      color,
      ordered,
      callOut,
      isCustomer,
      sessionID,
      selectedTab,
      selectedPage,
      selectedMenuItem,
      isCover
    });
    return componentSection;
  }
  if (type === 'interactiveImage') {
    const componentSection = createElement(component[type], {
      socket,
      publisher,
      meetingId,
      content,
      currentPage,
      selectedTab,
      selectedPage,
      selectedMenuItem,
      callOut,
      privatePanelVisible,
      videoCallOpen,
      tabs,
      isCustomer,
      sessionID,
      isCover
    });
    return componentSection;
  }
  if (type === 'gallerySVG') {
    const componentSection = createElement(component[type], {
      socket,
      publisher,
      meetingId,
      content,
      currentPage,
      selectedTab,
      selectedPage,
      selectedMenuItem,
      callOut,
      privatePanelVisible,
      videoCallOpen,
      tabs,
      isCustomer,
      sessionID,
      hasPagination,
      isCover,
      height
    });
    return componentSection;
  }
  if (component[type]) {
    const componentSection = createElement(component[type], {
      socket,
      publisher,
      meetingId,
      content,
      currentPage,
      selectedTab,
      selectedPage,
      selectedMenuItem,
      callOut,
      privatePanelVisible,
      videoCallOpen,
      tabs,
      isCustomer,
      sessionID,
      hasPagination,
      isCover
    });
    return componentSection;
  }
  return null;
};

ContentTypeContainer.propTypes = {
  content: oneOfType([shape({}), arrayOf(shape({}))]).isRequired,
  type: string.isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  columns: number,
  color: string,
  ordered: bool,
  currentPage: number,
  selectedTab: number,
  height: string,
  callOut: bool,
  privatePanelVisible: bool.isRequired,
  videoCallOpen: bool.isRequired,
  tabs: bool,
  isCustomer: bool,
  sessionID: string,
  hasPagination: bool,
  selectedPage: number,
  selectedMenuItem: number,
  isCover: bool
};

ContentTypeContainer.defaultProps = {
  columns: 1,
  color: '#4a4a4a',
  ordered: false,
  currentPage: 1,
  selectedTab: 0,
  height: '90%',
  callOut: false,
  selectedPage: null,
  selectedMenuItem: null,
  isCover: false,
  isCustomer: false,
  sessionID: '',
  hasPagination: false,
  tabs: false
};

export default ContentTypeContainer;
