const styles = (theme) => ({
  floorplansContainer: {
    overflow: 'auto',
    paddingBottom: 40,
    height: 'calc(100% - 8px)',
    '& h3': {
      fontWeight: 'bold',
      fontSize: 16,
      color: theme.content.inactiveFontColor,
      padding: '10px 48px 16px 48px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 36px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 24px'
      }
    }
  },
  floorplanContainer: {
    background: 'rgba(200, 200, 200, 0.2)',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    height: 370,
    width: '50%',
    margin: 10,
    padding: '10px 20px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '95%'
    },
    '& p': {
      width: '100%',
      margin: 0,
      color: theme.content.inactiveFontColor,
      fontSize: 21,
      fontWeight: 'bold'
    }
  },
  floorplanContainerNoAction: {
    background: 'rgba(200, 200, 200, 0.2)',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    height: 320,
    width: '50%',
    margin: 10,
    padding: '10px 20px',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '95%'
    },
    '& p': {
      width: '100%',
      margin: 0,
      color: theme.content.inactiveFontColor,
      fontSize: 21,
      fontWeight: 'bold'
    }
  },
  minimap: {
    maxWidth: '100%',
    height: 220,
    objectFit: 'contain'
  },
  floorplanSpecification: {
    marginRight: 48,
    [theme.breakpoints.down('lg')]: {
      marginRight: 36
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 24
    }
  },
  floorplanAreaSpecification: {},
  specificationIcon: {
    marginRight: 10,
    filter: theme.content.svg.inactive
  },
  filterSelect: {
    width: 'auto',
    marginRight: 24
  },
  layoutTitle: {
    color: `${theme.content.activeFontColor} !important`
  },
  layoutRibbon: {
    color: theme.content.activeFontColor,
    position: 'absolute',
    top: 0,
    right: 0,
    width: 0,
    height: 0,
    borderLeft: '80px solid transparent',
    borderRight: `80px solid ${theme.content.activeFontColor}`,
    borderBottom: '80px solid transparent'
  },
  videoLayoutRibbonContent: {
    color: theme.content.backgroundColor,
    position: 'absolute',
    top: 10,
    fontSize: 12,
    textTransform: 'uppercase',
    right: '-37px',
    [theme.breakpoints.down('sm')]: {
      right: '-50px'
    }
  },
  threeSixtyLayoutRibbonContent: {
    color: theme.content.backgroundColor,
    position: 'absolute',
    top: 10,
    fontSize: 12,
    textTransform: 'uppercase',
    right: '-45px',
    [theme.breakpoints.down('sm')]: {
      right: '-55px'
    }
  },
  badgeIcon: {
    width: 12,
    height: 12,
    objectFit: 'contain',
    filter: theme.content.svg.background
  }
});

export default styles;
