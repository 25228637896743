import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf, string, bool, number, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, FormControl } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import CustomSearchInput from '../../CustomSearchInput';
import CustomPriceSelectInput from '../../CustomPriceSelectInput';
import CustomInputBedroom from '../../CustomInputBedroom';
import CustomInputBathroom from '../../CustomInputBathroom';
import CustomInputParking from '../../CustomInputParking';
import UpdatePricesModal from '../../Modals/UpdatePricesModal';
import themeStyles from '../../../styles';
import ProjectAction from '../../../stores/project/actions';
import { bathroomIcon, bedroomIcon, parkingIcon } from '../../../config/assets';
import { inputPlaceholder } from '../../../config/messages';
import FilteredFloorplans from './FilteredFloorplans';
import EditablePricesMenu from './EditablePricesMenu';
import { sortOptions } from './utils';

class PrivatePanelPrices extends Component {
  constructor() {
    super();
    this.state = {
      searchValue: '',
      bedrooms: '',
      bathrooms: '',
      parking: '',
      floorplans: {},
      isEditing: false,
      items: [],
      pricesUpdated: false,
      pricesUpdateConfirmationModal: false
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  handlePriceInputChange = (event, currency) => {
    const { target } = event;
    const { id, value } = target;
    const newValue = value.replace(/[,$]/g, '');
    if (newValue.match(/^\d*(\.\d{0,2})*$/)) {
      const { items } = this.state;
      if (items.length > 0) {
        const found = items.some((el) => el.id === id);
        if (found) {
          const newItems = items.map((el) => {
            if (el.id === id) {
              return {
                id,
                prices: [
                  {
                    price: newValue,
                    currency
                  }
                ]
              };
            }
            return el;
          });
          this.setState({ items: newItems });
        } else {
          this.addItem(id, newValue, currency);
        }
      } else {
        this.addItem(id, newValue, currency);
      }
      this.setState({ [id]: newValue });
    }
  };

  addItem = (id, price, currency) => {
    const { items } = this.state;
    items.push({
      id,
      prices: [
        {
          price,
          currency
        }
      ]
    });
    this.setState({ items });
  };

  updatePrices = async () => {
    const { items } = this.state;
    const { selectedProject, dispatch } = this.props;
    const actionResponse = await dispatch(
      ProjectAction.updatePrices(selectedProject, {
        items
      })
    );
    const { isError } = actionResponse;
    if (!isError) {
      this.pricesUpdated();
    }
  };

  undo = (id) => {
    const { items } = this.state;
    const newItems = items.filter((item) => item.id !== id);
    this.setState({ items: newItems, [id]: undefined });
  };

  pricesUpdated = () => {
    this.setState({ pricesUpdated: true });
  };

  editPrices = () => {
    this.setState({ isEditing: true, pricesUpdated: false });
  };

  closePriceEdition = () => {
    this.setState({
      isEditing: false,
      pricesUpdateConfirmationModal: false
    });
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
  };

  toggleFloorplanDetails = (id) => {
    const { floorplans } = this.state;
    const newFloorplans = floorplans;
    if (floorplans[id]) {
      newFloorplans[id] = !newFloorplans[id];
    } else {
      newFloorplans[id] = true;
    }
    this.setState({ floorplans: newFloorplans });
  };

  showPricesUpdateConfirmationModal = () => {
    this.setState({ pricesUpdateConfirmationModal: true });
  };

  closePricesUpdateConfirmationModal = () => {
    this.setState({ pricesUpdateConfirmationModal: false });
  };

  formatNumber = (n) =>
    n
      .toString()
      .replace(/[^0-9.]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  render() {
    const {
      classes,
      prices,
      language,
      meetingIsActive,
      canUpdatePrices
    } = this.props;
    const {
      searchValue,
      bedrooms: bedroomsFilter,
      bathrooms: bathroomsFilter,
      parking: parkingFilter,
      isEditing,
      pricesUpdated,
      pricesUpdateConfirmationModal,
      items
    } = this.state;
    const bedroomOptions = sortOptions(prices, 'bedrooms');
    const bathroomOptions = sortOptions(prices, 'bathrooms');
    const parkingOptions = sortOptions(prices, 'parking');
    const endAdornment = (
      <InputAdornment position="end" className={classes.endAdornment}>
        <IconButton aria-label="toggle password visibility">
          <Search className={classes.pricePanelIcon} />
        </IconButton>
      </InputAdornment>
    );
    return (
      <>
        <UpdatePricesModal
          pricesUpdateConfirmationModal={pricesUpdateConfirmationModal}
          pricesUpdated={pricesUpdated}
          closePriceEdition={this.closePriceEdition}
          updatePrices={this.updatePrices}
          closePricesUpdateConfirmationModal={
            this.closePricesUpdateConfirmationModal
          }
        />
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="column"
          className={
            meetingIsActive
              ? classes.reducedPricesPrivatePanelContainer
              : classes.pricesPrivatePanelContainer
          }
        >
          <EditablePricesMenu
            canUpdatePrices={canUpdatePrices}
            isEditing={isEditing}
            items={items}
            closePriceEdition={this.closePriceEdition}
            showPricesUpdateConfirmationModal={
              this.showPricesUpdateConfirmationModal
            }
            editPrices={this.editPrices}
          />
          <Grid container alignItems="center">
            <FormControl className={classes.searchFormControl}>
              <CustomSearchInput
                id="searchValue"
                type="text"
                value={searchValue}
                placeholder={inputPlaceholder[language]}
                onChange={this.handleInputChange}
                endAdornment={endAdornment}
              />
            </FormControl>
          </Grid>
          <Grid className={classes.filtersAndSorts}>
            {bedroomOptions.length > 1 && (
              <Select
                id="bedrooms"
                value={bedroomsFilter}
                onChange={(event) => this.handleChange(event, 'bedrooms')}
                input={
                  bedroomsFilter === '' ? (
                    <CustomInputBedroom />
                  ) : (
                    <CustomPriceSelectInput />
                  )
                }
                className={classes.filterSelect}
              >
                <MenuItem value="">
                  <img src={bedroomIcon.src} alt={bedroomIcon.alt} />
                </MenuItem>
                {bedroomOptions.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            )}
            {bathroomOptions.length > 1 && (
              <Select
                id="bathrooms"
                value={bathroomsFilter}
                onChange={(event) => this.handleChange(event, 'bathrooms')}
                input={
                  bathroomsFilter === '' ? (
                    <CustomInputBathroom />
                  ) : (
                    <CustomPriceSelectInput />
                  )
                }
                className={classes.filterSelect}
              >
                <MenuItem value="">
                  <img src={bathroomIcon.src} alt={bathroomIcon.alt} />
                </MenuItem>
                {bathroomOptions.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            )}
            {parkingOptions.length > 1 && (
              <Select
                id="parking"
                value={parkingFilter}
                onChange={(event) => this.handleChange(event, 'parking')}
                input={
                  parkingFilter === '' ? (
                    <CustomInputParking />
                  ) : (
                    <CustomPriceSelectInput />
                  )
                }
                className={classes.filterSelect}
              >
                <MenuItem value="">
                  <img src={parkingIcon.src} alt={parkingIcon.alt} />
                </MenuItem>
                {parkingOptions.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <FilteredFloorplans
            state={this.state}
            toggleFloorplanDetails={this.toggleFloorplanDetails}
            undo={this.undo}
            handlePriceInputChange={this.handlePriceInputChange}
          />
        </Grid>
      </>
    );
  }
}

PrivatePanelPrices.propTypes = {
  classes: shape({}).isRequired,
  prices: arrayOf(shape({})).isRequired,
  language: string.isRequired,
  canUpdatePrices: bool.isRequired,
  selectedProject: number.isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

const mapStateToProps = (state) => {
  const { prices, selectedProject } = state.project;
  const { language } = state.language;
  const { meetingIsActive } = state.meeting;
  const { user = { roles: [] }, token } = state.session;
  const { roles = [] } = user;
  const canUpdatePrices =
    roles.filter((role) =>
      role.modules.filter(
        ({ module, permissions }) =>
          module === 'Prices' && permissions.includes('update')
      )
    ).length > 0;
  return {
    prices,
    language,
    meetingIsActive,
    canUpdatePrices,
    token,
    selectedProject
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(themeStyles)(PrivatePanelPrices));
