import { withStyles, createStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

const CustomDatePicker = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24
      },
      '& div': {
        '& input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: '#ededed',
          border: 'none',
          fontSize: 16,
          width: 318,
          padding: '10px 12px',
          [theme.breakpoints.down('xs')]: {
            width: '100%'
          }
        }
      }
    }
  })
)(DatePicker);

export default CustomDatePicker;
