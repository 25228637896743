/* eslint-disable class-methods-use-this */
import MeetingAction from '../actions';
import BaseReducer from '../../../utilities/BaseReducer';

export default class MeetingReducer extends BaseReducer {
  initialState = {
    loading: false,
    meetings: [],
    createdMeeting: {},
    activeMeeting: {},
    meetingIsActive: false,
    meetingInfo: {},
    twilioToken: '',
    identity: '',
    twilioRoom: '',
    onboarding: {},
    meetingId: '',
    emailSent: false,
    customer: {},
    customerMeeting: {},
    error: '',
    publisher: false,
    isCustomer: false,
    showTable: false
  };

  [MeetingAction.CREATE_MEETING_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      createdMeeting: action.payload.meeting
    };
  }

  [MeetingAction.LIST_MEETINGS_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      meetings: action.payload.meetings
    };
  }

  [MeetingAction.START_MEETING_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      activeMeeting: action.payload.meeting,
      meetingIsActive: true
    };
  }

  [MeetingAction.JOIN_MEETING_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      activeMeeting: action.payload.meeting,
      meetingIsActive: true
    };
  }

  [MeetingAction.GET_TWILIO_TOKEN_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      twilioToken: action.payload.twilioToken,
      identity: action.payload.identity,
      twilioRoom: action.payload.twilioRoom
    };
  }

  [MeetingAction.FINISH_MEETING_REQUEST_FINISHED](state) {
    return {
      ...state,
      activeMeeting: {},
      twilioToken: '',
      meetingIsActive: false
    };
  }

  [MeetingAction.CHECK_MEETING_STATUS_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      meetingInfo: action.payload.meeting
    };
  }

  [MeetingAction.LEAVE_MEETING_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      meetingInfo: action.payload.meeting
    };
  }

  [MeetingAction.REFRESH_CREATED_MEETING](state) {
    return {
      ...state,
      createdMeeting: {}
    };
  }

  [MeetingAction.DISCONNECT_MEETING](state) {
    return {
      ...state,
      activeMeeting: {},
      meetingIsActive: false,
      twilioToken: '',
      twilioRoom: '',
      identity: ''
    };
  }

  [MeetingAction.ONBOARDING_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      onboarding: action.payload.onboarding,
      meetingId: action.payload.meetingId
    };
  }

  [MeetingAction.SEND_EMAIL_REQUEST_FINISHED](state) {
    return {
      ...state,
      emailSent: true
    };
  }

  [MeetingAction.GET_CUSTOMER_MEETING_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      customer: action.payload.customer,
      customerMeeting: action.payload.meeting,
      isCustomer: true
    };
  }

  [MeetingAction.CLIENT_LOG_REQUEST_FINISHED](state) {
    return {
      ...state
    };
  }

  [MeetingAction.SET_PUBLISHER](state, action) {
    return {
      ...state,
      publisher: action.payload.publisher
    };
  }

  [MeetingAction.SET_SHOW_TABLE_FINISHED](state, action) {
    return {
      ...state,
      showTable: action.payload
    };
  }
}
