import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.content.activeTabColor
  }
}))(Tabs);

export default CustomTabs;
