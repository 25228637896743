import React from 'react';
import { shape, arrayOf, func, string } from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import themeStyles from '../../../styles';
import CustomSecondaryButton from '../../CustomSecondaryButton';
import CustomButton from '../../CustomButton';
import { plusIcon, playIcon, linkIcon } from '../../../config/assets';
import {
  activeSessionWith,
  newSession,
  startSession,
  recentSessions
} from '../../../config/messages';
import { orderMeetingsByDate } from './utils';
import { isMobile } from '../../../utils';

const ActiveMeetings = ({
  meetings,
  classes,
  openScheduleMeetingForm,
  handleCopyText,
  startMeeting,
  toggleMobileMenu,
  language
}) => {
  moment.locale(language);
  const activeMeetings = meetings.filter(
    (meeting) => meeting.status === 'active'
  );
  const scheduledMeetings = meetings.filter(
    (meeting) => meeting.status === 'scheduled'
  );
  const createSessionButton = (
    <Grid container className={classes.buttonContainer}>
      <CustomButton onClick={openScheduleMeetingForm}>
        <img
          src={plusIcon.src}
          alt={plusIcon.alt}
          className={classes.buttonIcon}
        />
        {newSession[language]}
      </CustomButton>
    </Grid>
  );
  if (activeMeetings.length > 0) {
    const orderedMeetings = orderMeetingsByDate(activeMeetings);
    const uniqueDays = new Set(
      activeMeetings.map((meeting) => moment(meeting.dateTime).format('LL'))
    );
    const meetingDays = [...uniqueDays];
    return (
      <>
        <Grid className={classes.nextMeetingContainer}>
          <h4>
            {`${activeSessionWith[language]} ${orderedMeetings[0].buyer.firstName} ${orderedMeetings[0].buyer.lastName}`}
          </h4>
          <CustomSecondaryButton
            onClick={() => {
              startMeeting(orderedMeetings[0].id);
              if (isMobile()) {
                toggleMobileMenu();
              }
            }}
          >
            <PlayCircleOutline className={classes.playIcon} />
            <span className={classes.startSessionButtonText}>
              {startSession[language]}
            </span>
          </CustomSecondaryButton>
        </Grid>
        <Grid className={classes.meetingsListContainer}>
          <h3>{recentSessions[language]}</h3>
          {meetingDays.map((meetingDay) => {
            const filteredMeetings = orderedMeetings.filter(
              (meeting) => moment(meeting.dateTime).format('LL') === meetingDay
            );
            return (
              <Grid key={meetingDay} className={classes.dayMeetingsContainer}>
                <p>{meetingDay}</p>
                {filteredMeetings.map((meeting) => (
                  <Grid key={meeting.id} className={classes.dayMeetingsDetail}>
                    {`${moment(meeting.dateTime).format('LT')} - ${
                      meeting.buyer.firstName
                    } ${meeting.buyer.lastName}`}
                    <Grid className={classes.meetingIconsContainer}>
                      <img
                        src={playIcon.src}
                        alt={playIcon.alt}
                        onClick={() => {
                          startMeeting(meeting.id);
                          if (isMobile()) {
                            toggleMobileMenu();
                          }
                        }}
                      />
                      <img
                        src={linkIcon.src}
                        alt={linkIcon.alt}
                        onClick={() => handleCopyText(meeting.id)}
                      />
                      <div className={classes.emptyIcon} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            );
          })}
        </Grid>
        {scheduledMeetings.length === 0 && createSessionButton}
      </>
    );
  }
  return null;
};

ActiveMeetings.propTypes = {
  meetings: arrayOf(shape({})).isRequired,
  classes: shape({}).isRequired,
  openScheduleMeetingForm: func.isRequired,
  handleCopyText: func.isRequired,
  startMeeting: func.isRequired,
  toggleMobileMenu: func.isRequired,
  language: string.isRequired
};

export default withStyles(themeStyles)(ActiveMeetings);
