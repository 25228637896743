import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, func, shape } from 'prop-types';
import PanoramaAction from '../../stores/panorama/actions';
import { errorSelector } from '../../selectors/Error';
import { menuOptionSelector } from '../../selectors/Menu';
import './Viewer.scss';

class Viewer extends Component {
  constructor() {
    super();
    this.atHUMViewer = null;
  }

  async componentDidMount() {
    const { dispatch, socket } = this.props;

    await dispatch(PanoramaAction.setContainer(this.atHUMViewer));

    const panoramaInfo = await dispatch(PanoramaAction.createPanoramaInfo());

    if (!panoramaInfo.isError) {
      dispatch(PanoramaAction.setPanorama(socket));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(PanoramaAction.reset());
  }

  render() {
    const { error, selectedMenuOption } = this.props;
    return (
      <div
        id="viewer"
        ref={(ref) => {
          this.atHUMViewer = ref;
        }}
        className={`${
          error || selectedMenuOption === 'mini-map' ? 'blur' : ''
        }`}
      />
    );
  }
}

Viewer.propTypes = {
  error: string.isRequired,
  selectedMenuOption: string.isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired
};

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  selectedMenuOption: menuOptionSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
