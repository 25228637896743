import React from 'react';
import { connect } from 'react-redux';
import { shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { athumLogoSVG, background } from '../config/assets';
import styles from '../styles';

const ClientErrorPage = ({ classes, error }) => (
  <Grid
    container
    direction="column"
    justify="flex-start"
    alignItems="flex-start"
    wrap="nowrap"
    className={classes.container}
  >
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      style={{
        backgroundImage: `url(${background.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 90%'
      }}
      className={classes.header}
    >
      <img src={athumLogoSVG.src} alt={athumLogoSVG.alt} />
    </Grid>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.contentContainer}
    >
      <h2>{error}</h2>
    </Grid>
  </Grid>
);

ClientErrorPage.propTypes = {
  classes: shape({}).isRequired,
  error: string.isRequired
};

const mapStateToProps = (state) => {
  const { message: error } = state.error;
  return {
    error
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ClientErrorPage));
