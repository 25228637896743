import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomButton = withStyles({
  root: {
    width: '100%',
    height: 40,
    boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.5)',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: 'none',
    borderRadius: 60,
    lineHeight: 1.5,
    backgroundColor: '#000000',
    color: '#ffffff',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#000000'
    },
    '&:active': {
      backgroundColor: '#000000',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: '#000000',
      outline: 'none'
    }
  }
})(Button);

export default CustomButton;
