/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  withStyles,
  Grid
} from '@material-ui/core';
import {
  calculatorsSelector,
  deferredPercentageSelector,
  deferredMonthsSelector,
  showAvailableOnSelector,
  showStatusSelector,
  showPriceSelector,
  showDiscountSelector,
  showPriceWithDiscountSelector,
  showDownPaymentPercentageSelector,
  showDownPaymentSelector,
  showDeferredAmountMonthlyPaymentSelector,
  showDeedAmountSelector,
  showNotaryFeesAmountSelector
} from '../../../selectors/Calculator';
import styles from '../styles';
import StyledTableCell from './StyledTableCell';
import { unitLabel } from '../../../config/messages';
import StyledTableRow from './StyledTableRow';
import { numberWithCommas, emptyForDash } from '../../../utils';
import {
  bathroomIcon,
  bedroomIcon,
  parkingIcon,
  sqmIcon
} from '../../../config/assets';

const MxSalesTable = ({
  calculators,
  language,
  deferredPercentage,
  deferredMonths,
  showAvailableOn,
  showStatus,
  showPrice,
  showDiscount,
  showPriceWithDiscount,
  showDownPaymentPercentage,
  showDownPayment,
  showDeferredAmountMonthlyPayment,
  showDeedAmount,
  showNotaryFeesAmount,
  classes,
  sections
}) => {
  console.log('showAvailable', showAvailableOn);
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <StyledTableCell>{unitLabel[language]}</StyledTableCell>
          {calculators.map((calculator, index) => (
            <StyledTableCell key={`unitName-th-${index.toString()}`}>
              {emptyForDash(calculator.unitName)}
            </StyledTableCell>
          ))}
        </TableHead>
        <TableBody>
          {showAvailableOn && (
            <StyledTableRow>
              <StyledTableCell>Fecha Disponible</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`availableOn-${index.toString()}`}>
                  {emptyForDash(calculator.availableOn)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showStatus && (
            <StyledTableRow>
              <StyledTableCell>Fase</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`status-${index.toString()}`}>
                  {emptyForDash(calculator.status)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          <StyledTableRow>
            <StyledTableCell>Area</StyledTableCell>
            {calculators.map((calculator, index) => (
              <StyledTableCell key={`description-${index.toString()}`}>
                <Grid container justify="flex-start" alignItems="center">
                  <img
                    src={sqmIcon.src}
                    alt={sqmIcon.src}
                    className={classes.icons}
                  />
                  <Grid className={classes.iconText}>
                    {emptyForDash(calculator.area)}
                  </Grid>
                </Grid>
              </StyledTableCell>
            ))}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Descripción</StyledTableCell>
            {calculators.map((calculator, index) => (
              <StyledTableCell key={`description-${index.toString()}`}>
                <Grid container justify="flex-start" alignItems="center">
                  <img
                    src={bedroomIcon.src}
                    alt={bedroomIcon.src}
                    className={classes.icons}
                  />
                  <Grid className={classes.iconText}>
                    {emptyForDash(calculator.bedrooms)}
                  </Grid>
                  <img
                    src={bathroomIcon.src}
                    alt={bathroomIcon.src}
                    className={classes.icons}
                  />
                  <Grid className={classes.iconText}>
                    {emptyForDash(calculator.bathrooms)}
                  </Grid>
                  <img
                    src={parkingIcon.src}
                    alt={parkingIcon.src}
                    className={classes.icons}
                  />
                  <Grid className={classes.iconText}>
                    {emptyForDash(calculator.parking)}
                  </Grid>
                </Grid>
              </StyledTableCell>
            ))}
          </StyledTableRow>
          {showPrice && (
            <StyledTableRow className={classes.boldRow}>
              <StyledTableCell>Precio</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`price-${index.toString()}`}>
                  ${emptyForDash(numberWithCommas(calculator.price))}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showDiscount && (
            <StyledTableRow>
              <StyledTableCell>Descuento</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`discount-${index.toString()}`}>
                  {calculator.typeDiscount === 'money' && '$'}
                  {emptyForDash(numberWithCommas(calculator.discount))}
                  {calculator.typeDiscount === 'percentage' && '%'}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showPriceWithDiscount && (
            <StyledTableRow>
              <StyledTableCell>Precio con descuento</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`priceWithDiscount-${index.toString()}`}>
                  $
                  {emptyForDash(numberWithCommas(calculator.priceWithDiscount))}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showDownPaymentPercentage && (
            <StyledTableRow>
              <StyledTableCell>Enganche</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell
                  key={`downPaymentPercentage-${index.toString()}`}
                >
                  {emptyForDash(calculator.downPaymentPercentage)} %
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showDownPayment && (
            <StyledTableRow>
              <StyledTableCell>Monto de apartado</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`downPayment-${index.toString()}`}>
                  ${emptyForDash(numberWithCommas(calculator.downPayment))}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showDeferredAmountMonthlyPayment && (
            <StyledTableRow>
              <StyledTableCell>
                Monto diferido ({deferredPercentage}% a {deferredMonths} meses)
              </StyledTableCell>
              {calculators.map((calculator) => (
                <StyledTableCell>
                  $
                  {emptyForDash(
                    numberWithCommas(calculator.deferredAmountMonthlyPayment)
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showDeedAmount && (
            <StyledTableRow>
              <StyledTableCell>Saldo al momento de la entrega</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`deedsAmount-${index.toString()}`}>
                  ${emptyForDash(numberWithCommas(calculator.deedsAmount))}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
          {showNotaryFeesAmount && sections.notaryFees && (
            <StyledTableRow>
              <StyledTableCell>Gastos Notariales</StyledTableCell>
              {calculators.map((calculator, index) => (
                <StyledTableCell key={`notaryFeesAmount-${index.toString()}`}>
                  ${emptyForDash(numberWithCommas(calculator.notaryFeesAmount))}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  calculators: calculatorsSelector(state),
  deferredPercentage: deferredPercentageSelector(state),
  deferredMonths: deferredMonthsSelector(state),
  showAvailableOn: showAvailableOnSelector(state),
  showStatus: showStatusSelector(state),
  showPrice: showPriceSelector(state),
  showDiscount: showDiscountSelector(state),
  showPriceWithDiscount: showPriceWithDiscountSelector(state),
  showDownPaymentPercentage: showDownPaymentPercentageSelector(state),
  showDownPayment: showDownPaymentSelector(state),
  showDeferredAmountMonthlyPayment: showDeferredAmountMonthlyPaymentSelector(
    state
  ),
  showDeedAmount: showDeedAmountSelector(state),
  showNotaryFeesAmount: showNotaryFeesAmountSelector(state),
  language: state.language.language
});

MxSalesTable.propTypes = {
  classes: shape({}).isRequired,
  calculators: arrayOf(shape({})).isRequired,
  language: string.isRequired,
  deferredPercentage: string.isRequired,
  deferredMonths: string.isRequired,
  showAvailableOn: bool.isRequired,
  showStatus: bool.isRequired,
  showPrice: bool.isRequired,
  showDiscount: bool.isRequired,
  showPriceWithDiscount: bool.isRequired,
  showDownPaymentPercentage: bool.isRequired,
  showDownPayment: bool.isRequired,
  showDeferredAmountMonthlyPayment: bool.isRequired,
  showDeedAmount: bool.isRequired,
  showNotaryFeesAmount: bool.isRequired,
  sections: shape({}).isRequired
};

export default withStyles(styles)(connect(mapStateToProps)(MxSalesTable));
