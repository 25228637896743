import React, { Component } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styles from './styles';
import Loader from './Loader';
import MxSalesTable from './Table/MxSalesTable';
import ProjectAction from '../../stores/project/actions';

class Watcher extends Component {
  constructor() {
    super();
    this.state = {
      start: false
    };
  }

  componentDidMount() {
    this.handleSocketMessage();
  }

  componentWillUnmount() {
    const { socket } = this.props;
    socket.off('CALCULATOR', this.onMessage);
  }

  handleSocketMessage = () => {
    const { socket } = this.props;
    socket.on('CALCULATOR', this.onMessage);
  };

  onMessage = (data) => {
    const { dispatch } = this.props;
    const { type, start, calculators } = data;
    console.log(type, calculators);
    switch (type) {
      case 'CALCULATOR':
        dispatch(ProjectAction.setCalculators(calculators));
        this.setState({ start });
        break;
      default:
        break;
    }
  };

  render() {
    const { start } = this.state;
    const { classes, calculators } = this.props;
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={`${classes.calculatorTable} ${classes.calculator}`}
      >
        {start && calculators.length > 0 && <MxSalesTable />}
        {(!start || calculators.length === 0) && <Loader />}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  calculators: state.project.calculators
});

Watcher.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  dispatch: func.isRequired,
  calculators: arrayOf(shape({})).isRequired
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Watcher)
);
