import React from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, func, bool } from 'prop-types';
import { Grid, MenuList, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/icons/Menu';
import themeStyles from '../../styles';

const ClientMenu = ({
  classes,
  selectedMenuItem,
  token,
  general,
  sections,
  customer,
  handleSelectMenuItem,
  openMenu,
  toggleMenu
}) => {
  const logo = general.logoContratedUri;
  const clientNavigation = JSON.stringify(customer) !== '{}';
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.brandContainer}
    >
      <Menu className={classes.toggleMenuIcon} onClick={toggleMenu} />
      {logo && <img src={logo} alt="Logo" />}
      <MenuList className={classes.menuItemsContainer}>
        {sections.map(({ icon, name, order }) => (
          <MenuItem
            key={order}
            className={
              order === selectedMenuItem
                ? classes.menuItemActive
                : classes.menuItem
            }
            onClick={
              token || clientNavigation
                ? () => handleSelectMenuItem(order)
                : () => {}
            }
          >
            <img
              src={icon}
              alt={name}
              className={
                order === selectedMenuItem
                  ? classes.menuIconActive
                  : classes.menuIcon
              }
            />
            {openMenu && name}
          </MenuItem>
        ))}
      </MenuList>
    </Grid>
  );
};

ClientMenu.propTypes = {
  selectedMenuItem: number.isRequired,
  classes: shape({}).isRequired,
  token: string.isRequired,
  sections: arrayOf(shape({})).isRequired,
  general: shape({}).isRequired,
  customer: shape({}).isRequired,
  handleSelectMenuItem: func.isRequired,
  openMenu: bool.isRequired,
  toggleMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { selectedMenuItem } = state.menu;
  const { customer } = state.meeting;
  const { sections, general } = state.project;
  return {
    selectedMenuItem,
    token,
    sections,
    general,
    customer
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(themeStyles)(ClientMenu));
