import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomPriceSelectInput = withStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 10
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      // backgroundColor: '#ededed',
      border: 'none',
      fontSize: 16,
      padding: '8px 10px',
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: '#ec6b69 0 0 0 0.2rem'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })
)(InputBase);

export default CustomPriceSelectInput;
