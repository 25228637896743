import React from 'react';
import { shape, bool, arrayOf, oneOfType } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import styles from './styles';

const Loader = ({ classes, children, load }) => (
  <Grid
    className={classes.container}
    container
    justify="center"
    alignItems="center"
  >
    {!load && <CircularProgress className={classes.loader} />}
    {children}
  </Grid>
);

Loader.propTypes = {
  classes: shape({}).isRequired,
  children: arrayOf(oneOfType([shape({}), bool])),
  load: bool.isRequired
};

Loader.defaultProps = {
  children: null
};

export default withStyles(styles)(Loader);
