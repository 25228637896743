import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomSearchInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      marginBottom: 5
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#ededed',
      border: 'none',
      fontSize: 16,
      width: 318,
      padding: '10px 12px',
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: `${theme.rightMenu.activeFontColor} 0 0 0 0.2rem`
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '&#clientNameFM': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 412
        }
      },
      '&#clientEmailFM': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 412
        }
      }
    }
  })
)(InputBase);

export default CustomSearchInput;
