import {
  VIRTUAL_SALES_API,
  VIRTUAL_SALES_API_KEY
} from '../../../config/endpoints';
import HttpUtility from '../../../utilities/HttpUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import LanguageModel from '../models';

export default class LanguageEffect {
  static async setLanguage(language) {
    return language;
  }

  static async getPropertyLanguage(propertyId) {
    const endpoint = `${VIRTUAL_SALES_API}/languages/${propertyId}`;

    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new LanguageModel(response.data);
    return model.language;
  }
}
