import { hexToRgb } from '../../utils';

const styles = (theme) => {
  const tabBackgroundRGB = hexToRgb(theme.leftMenu.backgroundColor);
  return {
    indexAsPageContainer: {
      position: 'absolute',
      height: '100%'
    },
    indexContainer: {
      position: 'absolute',
      height: '100%',
      padding: '0 32px',
      zIndex: 4
    },
    maskIndexContainer: {
      position: 'absolute',
      height: '100%',
      padding: '0 32px',
      zIndex: 4,
      background: `rgba(${tabBackgroundRGB[0]}, ${tabBackgroundRGB[1]}, ${tabBackgroundRGB[2]}, 0.9)`
    },
    indexElement: {
      color: theme.leftMenu.inactiveFontColor,
      fontSize: 16,
      cursor: 'pointer',
      fontWeight: 300,
      marginBottom: 10,
      [theme.breakpoints.down('md')]: {
        marginBottom: 5
      },
      '&.active': {
        fontWeight: 'bold',
        cursor: 'initial'
      }
    }
  };
};

export default styles;
