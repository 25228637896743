import ActionUtility from '../../../utilities/ActionUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import ProjectEffect from '../effects';
import { returnProjectTheme } from '../../../utils';
import MXCalculatorModel from '../../../models/MXCalculatorModel';

export default class ProjectAction {
  static GET_SECTIONS_REQUEST = 'GET_SECTIONS_REQUEST';

  static GET_SECTIONS_REQUEST_FINISHED = 'GET_SECTIONS_REQUEST_FINISHED';

  static SET_SECTIONS = 'SET_SECTIONS';

  static GET_CLIENT_SECTIONS_REQUEST = 'GET_CLIENT_SECTIONS_REQUEST';

  static GET_CLIENT_SECTIONS_REQUEST_FINISHED =
    'GET_CLIENT_SECTIONS_REQUEST_FINISHED';

  static GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';

  static GET_PROJECTS_REQUEST_FINISHED = 'GET_PROJECTS_REQUEST_FINISHED';

  static GET_BUILDER_INFO_REQUEST = 'GET_BUILDER_INFO_REQUEST';

  static GET_BUILDER_INFO_REQUEST_FINISHED =
    'GET_BUILDER_INFO_REQUEST_FINISHED';

  static SELECT_LAYOUT = 'SELECT_LAYOUT';

  static GET_LAYOUT_INFO_REQUEST = 'GET_LAYOUT_INFO_REQUEST';

  static GET_LAYOUT_INFO_REQUEST_FINISHED = 'GET_LAYOUT_INFO_REQUEST_FINISHED';

  static SELECT_TAB = 'SELECT_TAB';

  static SELECT_PAGE = 'SELECT_PAGE';

  static GET_PRICES_REQUEST = 'GET_PRICES_REQUEST';

  static GET_PRICES_REQUEST_FINISHED = 'GET_PRICES_REQUEST_FINISHED';

  static UPDATE_PRICES_REQUEST = 'UPDATE_PRICES_REQUEST';

  static UPDATE_PRICES_REQUEST_FINISHED = 'UPDATE_PRICES_REQUEST_FINISHED';

  static SELECT_PROJECT_REQUEST_FINISHED = 'SELECT_PROJECT_REQUEST_FINISHED';

  static STORE_INTERACTIVE_MAP_SUBSECTION_AND_PAGE =
    'STORE_INTERACTIVE_MAP_SUBSECTION_AND_PAGE';

  static SET_UNIT_INFO = 'SET_UNIT_INFO';

  static RESET_PROJECT = 'RESET_PROJECT';

  static CHANGE_CALCULATOR_PAGE_FINISHED = 'CHANGE_CALCULATOR_PAGE_FINISHED';

  static UPDATE_CALCULATOR_REQUEST_FINISHED =
    'UPDATE_CALCULATOR_REQUEST_FINISHED';

  static getSections(propertyId, propertiesSections = {}, projects = []) {
    if (propertyId in propertiesSections) {
      const foundedProject = projects.find(
        (project) => project.id === propertyId
      );
      return ActionUtility.createAction(ProjectAction.SET_SECTIONS, {
        sections: propertiesSections[propertyId],
        selectedProject: propertyId,
        theme: returnProjectTheme(foundedProject)
      });
    }
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_SECTIONS_REQUEST,
        ProjectEffect.getSections,
        true,
        language,
        token,
        propertyId,
        propertiesSections
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getSectionsByProjectIdForClient(propertyId, propertiesSections = {}) {
    if (propertyId in propertiesSections) {
      return ActionUtility.createAction(ProjectAction.SET_SECTIONS, {
        sections: propertiesSections[propertyId],
        selectedProject: propertyId
      });
    }
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_SECTIONS_REQUEST,
        ProjectEffect.getSectionsByProjectIdForClient,
        true,
        language,
        propertyId,
        propertiesSections
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getSectionsForClient(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_CLIENT_SECTIONS_REQUEST,
        ProjectEffect.getSectionsForClient,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getClientProjectSections(propertyId, propertiesSections = {}) {
    if (propertyId in propertiesSections) {
      return ActionUtility.createAction(ProjectAction.SET_SECTIONS, {
        sections: propertiesSections[propertyId],
        selectedProject: propertyId
      });
    }
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_CLIENT_SECTIONS_REQUEST,
        ProjectEffect.getClientProjectSections,
        true,
        language,
        propertyId,
        propertiesSections
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getSectionsForClientNavigation(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_CLIENT_SECTIONS_REQUEST,
        ProjectEffect.getSectionsForClientNavigation,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getProjects() {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_PROJECTS_REQUEST,
        ProjectEffect.getProjects,
        true,
        language,
        token
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getClientProjects(builderId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_PROJECTS_REQUEST,
        ProjectEffect.getClientProjects,
        true,
        language,
        builderId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getBuilderInfo(builder) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_BUILDER_INFO_REQUEST,
        ProjectEffect.getBuilderInfo,
        true,
        language,
        builder
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static selectLayout(selectedLayout, selectedPage = 1, selectedTab = 0) {
    return ActionUtility.createAction(ProjectAction.SELECT_LAYOUT, {
      selectedLayout,
      selectedPage,
      selectedTab
    });
  }

  static getLayoutInfo(layoutId, selectedPage = 1, selectedTab = 0) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_LAYOUT_INFO_REQUEST,
        ProjectEffect.getLayoutInfo,
        true,
        language,
        token,
        layoutId,
        selectedPage,
        selectedTab
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static selectTab(selectedTab, selectedPage) {
    return ActionUtility.createAction(ProjectAction.SELECT_TAB, {
      selectedTab,
      selectedPage
    });
  }

  static selectPage(selectedPage) {
    return ActionUtility.createAction(ProjectAction.SELECT_PAGE, {
      selectedPage
    });
  }

  static getPrices(projectId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.GET_PRICES_REQUEST,
        ProjectEffect.getPrices,
        true,
        language,
        token,
        projectId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static updatePrices(projectId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.UPDATE_PRICES_REQUEST,
        ProjectEffect.updatePrices,
        true,
        language,
        token,
        projectId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static storeInteractiveMapLocation(interactiveMapLocation) {
    return ActionUtility.createAction(
      ProjectAction.STORE_INTERACTIVE_MAP_SUBSECTION_AND_PAGE,
      interactiveMapLocation
    );
  }

  static setUnitInfo(selectedLayout, selectedPage, selectedTab) {
    return ActionUtility.createAction(ProjectAction.SET_UNIT_INFO, {
      selectedLayout,
      selectedPage,
      selectedTab
    });
  }

  static reset() {
    return ActionUtility.createAction(ProjectAction.RESET_PROJECT, '');
  }

  static changeCalculatorPage(page) {
    return ActionUtility.createAction(
      ProjectAction.CHANGE_CALCULATOR_PAGE_FINISHED,
      page
    );
  }

  static createNewCalculator(calculators, currentCalculator) {
    const newCalculators = calculators;
    if (newCalculators.length < 10) {
      newCalculators[currentCalculator] = new MXCalculatorModel({
        layoutId: 0,
        unitName: '',
        price: '',
        availableOn: '',
        status: '',
        typeDiscount: 'money',
        discount: '',
        priceWithDiscount: '',
        downPaymentPercentage: '',
        downPayment: '',
        holdingFee: '',
        deferredAmountPercentage: '',
        deferredAmountMonths: '',
        deferredAmountMonthlyPayment: '',
        deedsAmount: '',
        notaryFeesPercentage: '',
        notaryFeesAmount: '',
        loanType: '',
        loanTerm: '',
        interestRate: '',
        estimatedMonthlyPayment: '',
        area: '',
        bathrooms: '',
        bedrooms: '',
        parking: ''
      });

      return ActionUtility.createAction(
        ProjectAction.UPDATE_CALCULATOR_REQUEST_FINISHED,
        newCalculators
      );
    }

    return null;
  }

  static updateCalculator(calculator, currentCalculator, calculators) {
    const newCalculators = calculators;
    newCalculators[currentCalculator] = calculator;
    return ActionUtility.createAction(
      ProjectAction.UPDATE_CALCULATOR_REQUEST_FINISHED,
      newCalculators
    );
  }

  static setCalculators(calculators) {
    return ActionUtility.createAction(
      ProjectAction.UPDATE_CALCULATOR_REQUEST_FINISHED,
      calculators
    );
  }

  static deleteCalculator(currentCalculator, calculators) {
    const newCalculators = calculators.filter(
      (item) => item !== calculators[currentCalculator]
    );
    return ActionUtility.createAction(
      ProjectAction.UPDATE_CALCULATOR_REQUEST_FINISHED,
      newCalculators
    );
  }
}
