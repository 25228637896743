import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, NativeSelect, InputLabel, IconButton } from '@material-ui/core';
import TwilioAction from '../../stores/twilio/actions';
import CustomButton from '../CustomButton';
import CustomSelectInput from '../CustomSelectInput';
import { isMobile } from '../../utils';
import { camIcon } from '../../config/assets';
import styles from './styles';
import {
  liveTourBroadcaster,
  pickCamera,
  startSharing
} from '../../config/messages';
import { socketEmit } from '../../services/socket';

class Broadcaster extends Component {
  constructor(props) {
    super(props);
    this.video = null;
    this.state = {
      stream: null,
      selectedCamera: '',
      cameras: [],
      peerConnections: {},
      config: {
        iceServers: [
          {
            urls: 'stun:stun.services.mozilla.com'
          },
          {
            urls: 'stun:stun.l.google.com:19302'
          }
        ]
      },
      start: false,
      videoStatus: false
    };
  }

  componentDidMount() {
    const { twilio, video, dispatch } = this.props;
    this.setState({
      videoStatus: video
    });
    if (video) {
      dispatch(TwilioAction.toggleVideo(!video));
      twilio.muteVideo();
    }
    this.handleSocketMessage();
    this.getDevices();
  }

  componentWillUnmount() {
    this.stop();
  }

  stop = () => {
    const { socket, twilio, dispatch } = this.props;
    const { stream, videoStatus } = this.state;
    socket.off('BROADCASTER', this.onMessage);
    socket.off('ANSWER', this.onMessage);
    socket.off('WATCHER', this.onMessage);
    socket.off('CANDIDATE', this.onMessage);
    socket.off('DISCONNECTPEER', this.onMessage);
    if (stream !== null) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.video.srcObject = null;
    if (videoStatus) {
      dispatch(TwilioAction.toggleVideo(videoStatus));
      twilio.muteVideo();
    }
    this.setState({
      start: false
    });
  };

  handleSocketMessage = () => {
    const { socket } = this.props;
    socket.on('ANSWER', this.onMessage);
    socket.on('WATCHER', this.onMessage);
    socket.on('CANDIDATE', this.onMessage);
    socket.on('DISCONNECTPEER', this.onMessage);
  };

  onMessage = (data) => {
    const { type, id, description, candidate } = data;
    switch (type) {
      case 'ANSWER':
        this.handleAnswer(id, description);
        break;
      case 'WATCHER':
        this.handleWatcher(id);
        break;
      case 'CANDIDATE':
        this.handleCandidate(id, candidate);
        break;
      case 'DISCONNECTPEER':
        this.handleDisconnectPeer(id, candidate);
        break;
      default:
        break;
    }
  };

  handleAnswer = (id, description) => {
    const { peerConnections } = this.state;
    peerConnections[id].setRemoteDescription(description);
    this.setState({ peerConnections });
  };

  handleWatcher = (id) => {
    const { socket, meetingId } = this.props;
    const { config, peerConnections } = this.state;
    const peerConnection = new RTCPeerConnection(config);
    peerConnections[id] = peerConnection;

    const stream = this.video.srcObject;
    stream
      .getTracks()
      .forEach((track) => peerConnection.addTrack(track, stream));

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketEmit(socket, 'message-meeting', {
          id: meetingId,
          event: 'CANDIDATE',
          data: {
            type: 'CANDIDATE',
            id,
            candidate: event.candidate
          }
        });
      }
    };

    peerConnection
      .createOffer()
      .then((sdp) => peerConnection.setLocalDescription(sdp))
      .then(() => {
        socketEmit(socket, 'message-meeting', {
          id: meetingId,
          event: 'OFFER',
          data: {
            type: 'OFFER',
            id,
            description: peerConnection.localDescription
          }
        });
      });

    this.setState({ peerConnections });
  };

  handleCandidate = (id, candidate) => {
    const { peerConnections } = this.state;
    peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
    this.setState({ peerConnections });
  };

  handleDisconnectPeer = (id) => {
    const { peerConnections } = this.state;
    peerConnections[id].close();
    delete peerConnections[id];
    this.setState({ peerConnections });
  };

  getDevices = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        navigator.mediaDevices
          .enumerateDevices()
          .then(this.gotDevices)
          .then(this.getStream);
      });
  };

  gotDevices = (deviceInfo) => {
    const cameras = [];
    deviceInfo.forEach((item, index) => {
      if (item.kind === 'videoinput') {
        cameras.push({
          value: item.deviceId,
          label: item.label || `Camera ${index}`
        });
      }
    });
    this.setState({
      cameras,
      selectedCamera: cameras[0].value
    });
  };

  getStream = (value) => {
    const { stream, selectedCamera } = this.state;
    if (stream !== null) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const constraints = {
      video: {
        deviceId: value ? { exact: value } : selectedCamera
      }
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this.gotStream)
      .catch((error) => console.error(error));
  };

  gotStream = (stream) => {
    const { cameras } = this.state;
    const selectedCamera = cameras.find(
      (item) => item.label === stream.getVideoTracks()[0].label
    );
    this.setState({
      stream,
      selectedCamera: selectedCamera.value
    });
    this.video.srcObject = stream;
  };

  handleVideoSelect = (event) => {
    this.getStream(event.target.value);
  };

  flipCamera = () => {
    const { cameras, selectedCamera } = this.state;
    const camera = cameras.find((item) => item.value !== selectedCamera);
    this.getStream(camera.value);
  };

  broadcast = () => {
    const { socket, meetingId } = this.props;
    socketEmit(socket, 'message-meeting', {
      id: meetingId,
      event: 'BROADCASTER',
      data: {
        type: 'BROADCASTER'
      }
    });
    this.setState({ start: true });
  };

  render() {
    const { classes, language } = this.props;
    const { selectedCamera, cameras, start } = this.state;
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.videoContainer}
      >
        {!start && (
          <Grid item>
            <p className={classes.paragraph}>{liveTourBroadcaster[language]}</p>

            {!isMobile() && (
              <>
                <InputLabel htmlFor="camera-select">
                  {pickCamera[language]}
                </InputLabel>
                <NativeSelect
                  labelId="camera-selection"
                  id="camera-select"
                  value={selectedCamera}
                  onChange={this.handleVideoSelect}
                  className={classes.cameraSelect}
                  input={<CustomSelectInput />}
                >
                  {cameras.map((camera, index) => (
                    <option
                      key={`camera-${index.toString()}`}
                      value={camera.value}
                    >
                      {camera.label}
                    </option>
                  ))}
                </NativeSelect>
              </>
            )}
          </Grid>
        )}
        <Grid
          item
          className={
            !start ? classes.webcamContainer : classes.webcamContainerWatcher
          }
        >
          <video
            ref={(ref) => {
              this.video = ref;
            }}
            playsInline
            autoPlay
            muted
          />
          {isMobile() && (
            <IconButton
              className={classes.flipButton}
              aria-label="add to shopping cart"
              onClick={this.flipCamera}
            >
              <img src={camIcon.icons} alt={camIcon.alt} />
            </IconButton>
          )}
        </Grid>
        {!start && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.sharingButtonContiner}
          >
            <Grid container className={classes.sharingButton}>
              <CustomButton
                onClick={() => {
                  this.broadcast();
                }}
              >
                {startSharing[language]}
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

Broadcaster.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  twilio: shape({}).isRequired,
  video: bool.isRequired,
  language: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { twilio, video } = state.twilio;
  const { language } = state.language;
  return {
    twilio,
    video,
    language
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Broadcaster));
