import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { noFilled, okFilled } from '../../config/assets';
import styles from './styles';

const icons = {
  incorrect: noFilled,
  correct: okFilled
};

const Tootltip = ({ message, icon, classes, style, messageStyle, show }) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    className={`${classes.tooltip} ${show ? 'added' : 'hide'}`}
    style={style}
  >
    <img className="icon" src={icons[icon].src} alt={icons[icon].alt} />
    <span className="tooltip-message" style={messageStyle}>
      {message}
    </span>
  </Grid>
);

Tootltip.propTypes = {
  classes: shape({}).isRequired,
  icon: string.isRequired,
  message: string.isRequired,
  style: shape({}).isRequired,
  show: bool.isRequired
};

export default withStyles(styles)(Tootltip);
