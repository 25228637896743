import React from 'react';
import { arrayOf, shape, number, func, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import styles from './styles';

const Index = ({
  classes,
  content,
  currentPage,
  changePage,
  background,
  overlay
}) => (
  <Grid
    container
    justify="center"
    align="flex-start"
    className={classes.indexAsPageContainer}
    style={
      background
        ? { backgroundImage: `url(${background})`, backgroundSize: 'cover' }
        : {}
    }
  >
    <Grid
      container
      justify="center"
      align="flex-start"
      direction="column"
      wrap="nowrap"
      className={
        overlay || background
          ? classes.maskIndexContainer
          : classes.indexContainer
      }
    >
      {content.map((el) => (
        <p
          key={`${el.order}. ${el.name}`}
          className={classnames(
            classes.indexElement,
            currentPage === el.order ? 'active' : ''
          )}
          onClick={() => {
            if (currentPage !== el.order) {
              changePage(el.order);
            }
          }}
        >
          {`${el.order}. ${el.name}`}
        </p>
      ))}
    </Grid>
  </Grid>
);

Index.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  currentPage: number.isRequired,
  changePage: func.isRequired,
  background: string,
  overlay: bool
};

Index.defaultProps = {
  background: '',
  overlay: true
};

export default withStyles(styles)(Index);
