/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { Button, Grid, withStyles } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  unitsSelector,
  typeSelector,
  regionSelector,
  calculatorsSelector,
  showTableSelector,
  canShareSelector
} from '../../selectors/Calculator';
import styles from './styles';
import MXCalculator from './MXCalculator';
import ProjectAction from '../../stores/project/actions';
import {
  addAnotherUnitLabel,
  edit,
  pleaseSelect,
  shareWithClientLabel,
  unitAdded,
  unitBudgetComparison,
  unitLabel
} from '../../config/messages';
import { shareIcon, deleteIcon, plusIcon } from '../../config/assets';
import Tooltip from '../Tooltips';
import MxSalesTable from './Table/MxSalesTable';
import MeetingAction from '../../stores/meeting/actions';
import { socketEmit } from '../../services/socket';
import { meetingIdSelector } from '../../selectors/Meeting';
import DeleteCalcModal from '../Modals/DeleteCalcModal';
import { isMobile } from '../../utils';

class Pagination extends Component {
  constructor() {
    super();
    this.state = {
      currentCalculator: 0,
      canAdd: false,
      added: false,
      addedError: false,
      deleteCalc: false
    };
  }

  componentDidMount() {
    const { calculators, meetingId, socket, showTable } = this.props;
    if (calculators.length === 0) {
      this.createNewCalculator();
    } else {
      this.setState({ currentCalculator: calculators.length - 1 });
    }

    if (meetingId !== undefined) {
      socketEmit(socket, 'message-meeting', {
        id: meetingId,
        event: 'CALCULATOR',
        data: {
          type: 'CALCULATOR',
          start: showTable,
          calculators
        }
      });
    }
  }

  createNewCalculator = () => {
    const { currentCalculator } = this.state;
    const { dispatch, calculators } = this.props;
    if (calculators.length < 10) {
      dispatch(
        ProjectAction.createNewCalculator(calculators, currentCalculator)
      );
    }
  };

  getUnitIndex = (calculators, currentCalculator) =>
    calculators.findIndex(
      (calculator) => calculator === calculators[currentCalculator]
    );

  addAnotherUnit = async () => {
    const { canAdd, currentCalculator } = this.state;
    const { dispatch, calculators } = this.props;
    if (canAdd) {
      if (calculators.length < 10) {
        await dispatch(
          ProjectAction.createNewCalculator(calculators, currentCalculator + 1)
        );
        this.nextCalculator();
        this.setState({ added: true, canAdd: false });
        setTimeout(() => {
          this.setState({ added: false });
        }, 3000);
      }
    } else {
      this.setState({ addedError: true });
      setTimeout(() => {
        this.setState({ addedError: false });
      }, 3000);
    }
  };

  prevCalculator = () => {
    const { currentCalculator } = this.state;
    if (currentCalculator !== 0) {
      this.setState({ currentCalculator: currentCalculator - 1 });
    }
  };

  nextCalculator = () => {
    const { currentCalculator } = this.state;
    const { calculators } = this.props;
    if (currentCalculator + 1 < calculators.length) {
      this.setState({ currentCalculator: currentCalculator + 1 });
    }
  };

  handleTable = () => {
    const { canAdd } = this.state;
    const {
      dispatch,
      socket,
      meetingId,
      calculators,
      showTable,
      canShare
    } = this.props;
    if (canAdd) {
      if (!showTable && meetingId !== undefined) {
        dispatch(MeetingAction.saveMxCalculatorData());
      }

      if (meetingId !== undefined) {
        socketEmit(socket, 'message-meeting', {
          id: meetingId,
          event: 'CALCULATOR',
          data: {
            type: 'CALCULATOR',
            start: !showTable,
            calculators
          }
        });
      }
    }
    if (canShare) {
      dispatch(MeetingAction.setShowTable(!showTable));
    }
  };

  enableAddUnit = (canAdd) => {
    this.setState({ canAdd });
  };

  deleteCalculatorModal = () => {
    this.setState((prevState) => ({
      deleteCalc: !prevState.deleteCalc
    }));
  };

  deleteCalculator = () => {
    const { currentCalculator } = this.state;
    const { dispatch, calculators } = this.props;
    if (currentCalculator > 0) {
      dispatch(ProjectAction.deleteCalculator(currentCalculator, calculators));
      this.prevCalculator();
      this.deleteCalculatorModal();
    }
  };

  render() {
    const { currentCalculator, added, addedError, deleteCalc } = this.state;
    const {
      classes,
      type,
      region,
      units,
      language,
      calculators,
      showTable,
      sections
    } = this.props;
    const component = {
      sale: {
        mx: MXCalculator
      },
      rent: {}
    };
    const CalculatorComponent = component[type][region];
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.calculatorContainer}
      >
        {showTable && (
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.tableHeaderContainer}
          >
            <Grid item className="title-table">
              {unitBudgetComparison[language]}
            </Grid>
            <Button
              type="button"
              variant="contained"
              onClick={this.handleTable}
              className={classes.mediumButton}
            >
              <ChevronLeft />
              {edit[language]}
            </Button>
          </Grid>
        )}
        {showTable && <MxSalesTable sections={sections} />}
        {!showTable && (
          <>
            <Grid
              container
              item
              direction="row"
              justify="space-between"
              alignItems="center"
              className={`${classes.header} bottom-border`}
            >
              <Grid item className={classes.title}>
                {unitLabel[language]} {currentCalculator + 1}
              </Grid>
              {currentCalculator > 0 && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  onClick={this.deleteCalculatorModal}
                  className={classes.delete}
                >
                  <img src={deleteIcon.src} alt={deleteIcon.alt} />
                </Grid>
              )}
            </Grid>
            <CalculatorComponent
              key={`calc-${currentCalculator}`}
              units={units}
              currentCalculator={currentCalculator}
              enableAddUnit={this.enableAddUnit}
              notaryFees={sections.notaryFees}
            />
          </>
        )}
        {!showTable && (
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className={classes.footer}
          >
            <Button
              type="button"
              variant="contained"
              onClick={this.prevCalculator}
              className={`${classes.smallButton} ${
                currentCalculator === 0 ? 'grayed' : ''
              }`}
            >
              <ChevronLeft
                className={`${currentCalculator === 0 ? 'grayed' : ''}`}
              />
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={this.nextCalculator}
              className={`${classes.smallButton}`}
            >
              <ChevronRight
                className={`${
                  currentCalculator + 1 === calculators.length ||
                  calculators.length === 0
                    ? 'grayed'
                    : ''
                }`}
              />
            </Button>
            {!isMobile() && (
              <Button
                type="button"
                variant="contained"
                onClick={this.addAnotherUnit}
                className={classes.largeButton}
              >
                {addAnotherUnitLabel[language]}
              </Button>
            )}
            {isMobile() && (
              <Button
                type="button"
                variant="contained"
                onClick={this.addAnotherUnit}
                className={classes.smallButton}
              >
                <img className="plus" src={plusIcon.src} alt={plusIcon.alt} />
              </Button>
            )}
            <Button
              type="button"
              variant="contained"
              onClick={this.handleTable}
              className={`${classes.largeButton} filled`}
            >
              {shareWithClientLabel[language]}
              <img src={shareIcon.src} alt={shareIcon.alt} />
            </Button>
          </Grid>
        )}
        <Tooltip
          message={unitAdded[language]}
          icon="correct"
          style={{ width: language === 'es' ? 150 : 125, height: 36 }}
          messageStyle={{ width: 'auto' }}
          show={added}
        />
        <Tooltip
          message={pleaseSelect[language]}
          icon="incorrect"
          style={{ width: 308, height: 48 }}
          messageStyle={{ width: 249 }}
          show={addedError}
        />
        <DeleteCalcModal
          deleteCalc={deleteCalc}
          hideDeleteModal={this.deleteCalculatorModal}
          deleteCalcFunc={this.deleteCalculator}
        />
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  units: unitsSelector(ownProps),
  type: typeSelector(ownProps),
  region: regionSelector(ownProps),
  meetingId: meetingIdSelector(state),
  calculators: calculatorsSelector(state),
  showTable: showTableSelector(state),
  canShare: canShareSelector(state),
  language: state.language.language
});

Pagination.propTypes = {
  socket: shape({}).isRequired,
  units: arrayOf(shape({})).isRequired,
  classes: shape({}).isRequired,
  type: string.isRequired,
  region: string.isRequired,
  dispatch: func.isRequired,
  calculators: arrayOf(shape({})).isRequired,
  language: string.isRequired,
  meetingId: string.isRequired,
  showTable: bool.isRequired,
  canShare: bool.isRequired,
  sections: shape({}).isRequired
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Pagination)
);
