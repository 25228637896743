const styles = {
  pageContainer: {
    height: '100%'
  },
  pageContentContainer: {
    paddingBottom: 40,
    height: '100%',
    width: '100%'
  }
};

export default styles;
