import React, { Component } from 'react';
import { arrayOf, shape, bool, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class Paragraph extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: '' };
  }

  componentDidMount = () => {
    const { socket } = this.props;
    socket.on('PARAGRAPH-SELECTED', this.onSelectedEvent);
  };

  onSelectedEvent = (data) => {
    const { selected } = data;
    this.setState({ selected });
    this.resetSelected();
  };

  onSelected = (selected) => {
    const { socket, publisher, meetingId } = this.props;
    if (publisher) {
      this.setState({ selected });
      socket.emit('message-meeting', {
        id: meetingId,
        event: 'PARAGRAPH-SELECTED',
        data: {
          type: 'PARAGRAPH-SELECTED',
          selected
        }
      });
      this.resetSelected();
    }
  };

  resetSelected = () => {
    setTimeout(() => {
      this.setState({ selected: '' });
    }, 2000);
  };

  render() {
    const { classes, content, callOut, publisher, isCover } = this.props;
    const { selected } = this.state;
    let paragraphClass = classes.paragraph;
    let selectedParagraphClass = classes.selected;
    if (isCover) {
      paragraphClass = classes.coverParagraph;
      selectedParagraphClass = classes.coverSelected;
    } else if (callOut) {
      paragraphClass = classes.cardParagraph;
    }
    return (
      <p className={paragraphClass}>
        {content.map(({ fontWeight, text, order }, index) => (
          <span
            key={`p-${index * 1}`}
            onClick={() => {
              this.onSelected(`p-${index * 1}-${order}`);
            }}
            style={fontWeight !== 'normal' ? { fontWeight } : {}}
            className={`${
              selected === `p-${index * 1}-${order}`
                ? selectedParagraphClass
                : ''
            } ${publisher ? classes.pointer : ''}`}
          >
            {`${text} `}
          </span>
        ))}
      </p>
    );
  }
}
Paragraph.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: bool.isRequired,
  callOut: bool,
  isCover: bool
};

Paragraph.defaultProps = {
  callOut: false,
  isCover: false
};

export default withStyles(styles)(Paragraph);
