import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomPriceInput = withStyles((theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#ededed',
      border: 'none',
      fontSize: 16,
      width: 140,
      padding: '10px 12px',
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: `${theme.rightMenu.activeFontColor} 0 0 0 0.2rem`
      }
    }
  })
)(InputBase);

export default CustomPriceInput;
