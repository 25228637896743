import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { string, shape, number, bool, func } from 'prop-types';
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_NONE } from 'react-svg-pan-zoom';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { ReactSvgPanZoomLoader } from 'react-svg-pan-zoom-loader';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import CallOut from './CallOut';
import SVGMenu from './SVGMenu';
import ProjectAction from '../../stores/project/actions';
import MenuAction from '../../stores/menu/actions';
import styles from './styles';
import { socketEmit } from '../../services/socket';
import {
  cursorIcon,
  panIcon,
  zoomInIcon,
  zoomOutExtents,
  zoomOutIcon
} from '../../config/assets';

class InteractiveSVG extends PureComponent {
  constructor() {
    super();
    this.state = {
      tool: TOOL_NONE,
      value: INITIAL_VALUE,
      actionableItems: [],
      activeMenu: null,
      activeSubmenu: null,
      callOut: null,
      openCallOut: false,
      menus: [],
      currentTime: moment().format('x')
    };
    this.viewer = null;
  }

  componentDidMount() {
    this.handleSocketMessage();
    const { content } = this.props;
    const { menus } = content;
    this.setState({ menus });
  }

  async componentDidUpdate(prevProps) {
    const { content: prevContent } = prevProps;
    const { content } = this.props;
    if (JSON.stringify(content) !== JSON.stringify(prevContent)) {
      const { menus } = content;
      this.updateMenu(menus);
    }
  }

  componentWillUnmount() {
    const { socket } = this.props;
    socket.off('MENU', () => {});
    socket.off('ACTIVE-FLOORPLAN', () => {});
    socket.off('ZOOM', () => {});
    socket.off('PAN', () => {});
    socket.off('SVG-ACTION', () => {});
    socket.off('SVG-MENU', () => {});
  }

  updateMenu = async (menus) => {
    await this.setState({ menus: [], actionableItems: [] });
    this.setState({
      menus,
      activeMenu: null,
      activeSubmenu: null,
      callOut: null
    });
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('MENU', (data) => {
        const { type, selectedMenuItem } = data;
        switch (type) {
          case 'MENU':
            this.handleSelectMenuItem(selectedMenuItem);
            break;
          default:
            break;
        }
      });
      socket.on('ACTIVE-FLOORPLAN', (data) => {
        const { type, selectedLayout, selectedTab, currentPage } = data;
        switch (type) {
          case 'ACTIVE-FLOORPLAN':
            this.selectLayout(selectedLayout, selectedTab, currentPage);
            break;
          default:
            break;
        }
      });
      socket.on('ZOOM', (data) => {
        const { type, zoom } = data;
        switch (type) {
          case 'ZOOM':
            this.onZoom(zoom);
            break;
          default:
            break;
        }
      });
      socket.on('PAN', (data) => {
        const { type, pan } = data;
        switch (type) {
          case 'PAN':
            this.onZoom(pan);
            break;
          default:
            break;
        }
      });
      socket.on('SVG-ACTION', (data) => {
        const { type } = data;
        switch (type) {
          case 'SVG-ACTION':
            {
              const { actionType, value, itemId } = data;
              const item = document.getElementById(itemId);
              item.setAttribute(actionType, value);
            }
            break;
          case 'SVG-MENU':
            {
              const { activeMenu, activeSubmenu, callOut } = data;
              this.setState({ activeMenu, activeSubmenu, callOut });
            }
            break;
          default:
            break;
        }
      });
    }
  };

  handleSelectMenuItem = (selectedMenuItem) => {
    const {
      socket,
      activeMeeting,
      meetingIsActive,
      publisher,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'MENU',
        data: {
          type: 'MENU',
          selectedMenuItem
        }
      });
    }
    dispatch(MenuAction.setSelectedMenuItem(selectedMenuItem));
  };

  selectLayout = (selectedLayout, selectedTab, currentPage) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    dispatch(
      ProjectAction.selectLayout(selectedLayout, currentPage, selectedTab)
    );
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout,
          selectedTab,
          currentPage
        }
      });
    }
  };

  selectLayoutByLayoutId = async (
    layoutId,
    section,
    selectedTab = 0,
    selectedPage = 1
  ) => {
    const { activeMeeting, meetingIsActive, socket, dispatch } = this.props;
    const actionResponse = await dispatch(
      ProjectAction.getLayoutInfo(layoutId, selectedPage, selectedTab)
    );
    const { isError } = actionResponse;
    if (!isError) {
      if (meetingIsActive) {
        socketEmit(socket, 'message-meeting', {
          id: activeMeeting.id,
          event: 'ACTIVE-FLOORPLAN',
          data: {
            type: 'ACTIVE-FLOORPLAN',
            layoutId,
            selectedTab,
            selectedPage
          }
        });
      }
      this.handleSelectMenuItem(Number(section));
    }
  };

  onMouseMove = () => {
    const { content } = this.props;
    const { actionableItems } = this.state;
    const newActionableItems = [];
    if (actionableItems.length === 0) {
      const { svgActions } = content;
      svgActions.forEach(
        ({
          id,
          onClick = null,
          onMouseEnter = null,
          onMouseLeave = null,
          hoverClass = null,
          hoverId = null,
          goToSubmenu = null,
          submenu = null,
          menu = null,
          primaryMenuInteraction = null
        }) => {
          const item = document.getElementById(id);
          if (item) {
            newActionableItems.push(item);
            if (onClick) {
              if (onClick === 'callOutById') {
                item.addEventListener('click', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    e.stopPropagation();
                    this.openCallOut(hoverId);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onClick: onClick2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null,
                      goToSubmenu: goToSubmenu2 = null,
                      menu: menu2 = null,
                      onMouseEnter: onMouseEnter2 = null
                    } = primaryMenuInteraction;
                    if (onClick2) {
                      if (onClick2 === 'callOutById') {
                        e.stopPropagation();
                        this.openCallOut(hoverId2);
                      } else if (onClick2 === 'callOutByClass') {
                        e.stopPropagation();
                        this.openCallOut(hoverClass2);
                      } else if (onClick2 === 'openSubmenu') {
                        e.stopPropagation();
                        this.openSubmenu(menu2, goToSubmenu2);
                        if (onMouseEnter2) {
                          if (onMouseEnter2 === 'showById') {
                            const item2 = document.getElementById(hoverId2);
                            item2.setAttribute('opacity', '0');
                          } else if (onMouseEnter2 === 'showByClass') {
                            const items = document.getElementsByClass(hoverId2);
                            items.forEach((el) => {
                              el.setAttribute('opacity', '0');
                            });
                          }
                        }
                      }
                    }
                  }
                });
              } else if (onClick === 'callOutByClass') {
                item.addEventListener('click', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    e.stopPropagation();
                    this.openCallOut(hoverClass);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onClick: onClick2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null,
                      goToSubmenu: goToSubmenu2 = null,
                      menu: menu2 = null
                    } = primaryMenuInteraction;
                    if (onClick2) {
                      if (onClick2 === 'callOutById') {
                        e.stopPropagation();
                        this.openCallOut(hoverId2);
                      } else if (onClick2 === 'callOutByClass') {
                        e.stopPropagation();
                        this.openCallOut(hoverClass2);
                      } else if (onClick2 === 'openSubmenu') {
                        e.stopPropagation();
                        this.openSubmenu(menu2, goToSubmenu2);
                      }
                    }
                  }
                });
              } else if (onClick === 'openSubmenu') {
                item.addEventListener('click', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    e.stopPropagation();
                    this.openSubmenu(menu, goToSubmenu);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onClick: onClick2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null,
                      goToSubmenu: goToSubmenu2 = null,
                      menu: menu2 = null
                    } = primaryMenuInteraction;
                    if (onClick2) {
                      if (onClick2 === 'callOutById') {
                        e.stopPropagation();
                        this.openCallOut(hoverId2);
                      } else if (onClick2 === 'callOutByClass') {
                        e.stopPropagation();
                        this.openCallOut(hoverClass2);
                      } else if (onClick2 === 'openSubmenu') {
                        e.stopPropagation();
                        this.openSubmenu(menu2, goToSubmenu2);
                      }
                    }
                  }
                });
              }
            }
            if (onMouseEnter) {
              if (onMouseEnter === 'showByClass') {
                item.addEventListener('mouseenter', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    this.showByClass(hoverClass);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onMouseEnter: onMouseEnter2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null
                    } = primaryMenuInteraction;
                    if (onMouseEnter2) {
                      if (onMouseEnter2 === 'showByClass') {
                        e.stopPropagation();
                        this.showByClass(hoverClass2);
                      } else if (onMouseEnter2 === 'showById') {
                        e.stopPropagation();
                        this.showById(hoverId2);
                      }
                    }
                  }
                });
              } else if (onMouseEnter === 'showById') {
                item.addEventListener('mouseenter', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    this.showById(hoverId);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onMouseEnter: onMouseEnter2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null
                    } = primaryMenuInteraction;
                    if (onMouseEnter2) {
                      if (onMouseEnter2 === 'showByClass') {
                        e.stopPropagation();
                        this.showByClass(hoverClass2);
                      } else if (onMouseEnter2 === 'showById') {
                        e.stopPropagation();
                        this.showById(hoverId2);
                      }
                    }
                  }
                });
              }
            }
            if (onMouseLeave) {
              if (onMouseLeave === 'hideByClass') {
                item.addEventListener('mouseleave', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    this.hideByClass(hoverClass);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onMouseLeave: onMouseLeave2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null
                    } = primaryMenuInteraction;
                    if (onMouseLeave2) {
                      if (onMouseLeave2 === 'hideByClass') {
                        e.stopPropagation();
                        this.hideByClass(hoverClass2);
                      } else if (onMouseLeave2 === 'hideById') {
                        e.stopPropagation();
                        this.hideById(hoverId2);
                      }
                    }
                  }
                });
              } else if (onMouseLeave === 'hideById') {
                item.addEventListener('mouseleave', (e) => {
                  const { activeSubmenu } = this.state;
                  if (submenu && activeSubmenu && activeSubmenu === submenu) {
                    this.hideById(hoverId);
                  } else if (
                    (!activeSubmenu || activeSubmenu !== submenu) &&
                    primaryMenuInteraction
                  ) {
                    const {
                      onMouseLeave: onMouseLeave2 = null,
                      hoverClass: hoverClass2 = null,
                      hoverId: hoverId2 = null
                    } = primaryMenuInteraction;
                    if (onMouseLeave2) {
                      if (onMouseLeave2 === 'hideByClass') {
                        e.stopPropagation();
                        this.hideByClass(hoverClass2);
                      } else if (onMouseLeave2 === 'hideById') {
                        e.stopPropagation();
                        this.hideById(hoverId2);
                      }
                    }
                  }
                });
              }
            }
          }
        }
      );
      this.setState({ actionableItems: newActionableItems });
    }
  };

  showByClass = (id) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const affectedItems = document.getElementsByClassName(id);
    const affectedMenuItem = document.getElementById(`${id}Menu`);
    if (affectedMenuItem) {
      affectedMenuItem.classList.add('menu-hover');
    }
    if (affectedItems.length > 0) {
      for (let i = 0; i < affectedItems.length; i += 1) {
        affectedItems[i].setAttribute('opacity', '0.7');
        affectedItems[i].setAttribute('display', 'block');
        if ((publisher && meetingIsActive) || isCustomer) {
          const type = isCustomer ? 'customer-log' : 'message-meeting';
          const socketId = isCustomer ? sessionID : activeMeeting.id;
          socketEmit(socket, type, {
            id: socketId,
            event: 'SVG-ACTION',
            data: {
              type: 'SVG-ACTION',
              actionType: 'opacity',
              value: '0.7',
              itemId: affectedItems[i].id
            }
          });
        }
      }
    }
  };

  showById = (id) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const affectedItem = document.getElementById(id);
    if (affectedItem) {
      affectedItem.setAttribute('opacity', '0.7');
      affectedItem.setAttribute('display', 'block');
      affectedItem.classList.remove('st5');
      const affectedMenuItem = document.getElementById(`${id}Menu`);
      if (affectedMenuItem) {
        affectedMenuItem.classList.add('menu-hover');
      }
      if ((publisher && meetingIsActive) || isCustomer) {
        const type = isCustomer ? 'customer-log' : 'message-meeting';
        const socketId = isCustomer ? sessionID : activeMeeting.id;
        socketEmit(socket, type, {
          id: socketId,
          event: 'SVG-ACTION',
          data: {
            type: 'SVG-ACTION',
            actionType: 'opacity',
            value: '0.7',
            itemId: affectedItem.id
          }
        });
      }
    }
  };

  hideByClass = (id) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;

    const affectedItems = document.getElementsByClassName(id);
    const affectedMenuItem = document.getElementById(`${id}Menu`);
    if (affectedMenuItem) {
      affectedMenuItem.classList.remove('menu-hover');
    }
    if (affectedItems.length > 0) {
      for (let i = 0; i < affectedItems.length; i += 1) {
        affectedItems[i].setAttribute('opacity', '0');
        if ((publisher && meetingIsActive) || isCustomer) {
          const type = isCustomer ? 'customer-log' : 'message-meeting';
          const socketId = isCustomer ? sessionID : activeMeeting.id;
          socketEmit(socket, type, {
            id: socketId,
            event: 'SVG-ACTION',
            data: {
              type: 'SVG-ACTION',
              actionType: 'opacity',
              value: '0',
              itemId: affectedItems[i].id
            }
          });
        }
      }
    }
  };

  hideById = (id) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const affectedItem = document.getElementById(id);
    if (affectedItem) {
      affectedItem.setAttribute('opacity', '0');
      const affectedMenuItem = document.getElementById(`${id}Menu`);
      if (affectedMenuItem) {
        affectedMenuItem.classList.remove('menu-hover');
      }
      if ((publisher && meetingIsActive) || isCustomer) {
        const type = isCustomer ? 'customer-log' : 'message-meeting';
        const socketId = isCustomer ? sessionID : activeMeeting.id;
        socketEmit(socket, type, {
          id: socketId,
          event: 'SVG-ACTION',
          data: {
            type: 'SVG-ACTION',
            actionType: 'opacity',
            value: '0',
            itemId: affectedItem.id
          }
        });
      }
    }
  };

  openMenu = (menuId) => {
    this.setState({ activeMenu: menuId, activeSubmenu: null, callOut: null });
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-MENU',
          activeMenu: menuId,
          activeSubmenu: null,
          callOut: null
        }
      });
    }
  };

  openSubmenu = (menuId, submenu) => {
    this.setState({
      activeMenu: menuId,
      activeSubmenu: submenu,
      callOut: null
    });
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-MENU',
          activeMenu: menuId,
          activeSubmenu: submenu,
          callOut: null
        }
      });
    }
  };

  clearSubmenu = () => {
    this.setState({ activeSubmenu: null, callOut: null });
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const { activeMenu } = this.state;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-MENU',
          activeMenu,
          activeSubmenu: null,
          callOut: null
        }
      });
    }
  };

  clearMenu = () => {
    this.setState({ activeMenu: null, activeSubmenu: null, callOut: null });
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-MENU',
          activeMenu: null,
          activeSubmenu: null,
          callOut: null
        }
      });
    }
  };

  openCallOut = (id) => {
    console.log(id);
    const { content } = this.props;
    const { callOuts } = content;
    this.setState({ callOut: callOuts[id], openCallOut: true });
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const { activeMenu, activeSubmenu } = this.state;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const socketId = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id: socketId,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-MENU',
          activeMenu,
          activeSubmenu,
          callOut: callOuts[id]
        }
      });
    }
  };

  openCallOutUp = () => {
    console.log('UP');
    this.setState({ openCallOut: false });
  };

  clearCallOut = () => {
    const { openCallOut } = this.state;
    console.log(openCallOut);
    if (!openCallOut) {
      this.setState({ callOut: null });
    }
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const { activeMenu, activeSubmenu } = this.state;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-MENU',
          activeMenu,
          activeSubmenu,
          callOut: null
        }
      });
    }
  };

  storeInteractiveMapLocation = (
    selectedMenuItem,
    selectedTab,
    selectedPage
  ) => {
    const { dispatch } = this.props;
    dispatch(MenuAction.storeInteractiveMapLocation({ selectedMenuItem }));
    dispatch(
      ProjectAction.storeInteractiveMapLocation({ selectedTab, selectedPage })
    );
  };

  callOutAction = async (callOut) => {
    const { selectLayout, redirect } = callOut;
    console.log('selectLayout', selectLayout);
    console.log('redirect', redirect);
    const { selectedMenuItem, currentTab, selectedPage } = this.props;
    if (selectLayout) {
      const { layoutId } = selectLayout;
      await this.selectLayoutByLayoutId(
        layoutId,
        selectedMenuItem,
        currentTab,
        selectedPage
      );
    }
    if (redirect) {
      const { section, subSection, page } = redirect;
      if (section && section !== selectedMenuItem) {
        await this.handleSelectMenuItem(section);
      }
      if (subSection || page) {
        await this.selectLayout(null, subSection, page);
      }
      this.storeInteractiveMapLocation(
        selectedMenuItem,
        currentTab,
        selectedPage
      );
    }
  };

  onZoom = (e) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    this.setState({ value: e });
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'ZOOM',
        data: {
          type: 'ZOOM',
          zoom: e
        }
      });
    }
  };

  onPan = (e) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    this.setState({ value: e });
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'PAN',
        data: {
          type: 'PAN',
          pan: e
        }
      });
    }
  };

  changeTool(nextTool) {
    this.setState({ tool: nextTool });
  }

  changeValue(nextValue) {
    this.setState({ value: nextValue });
  }

  render() {
    const {
      content,
      classes,
      socket,
      activeMeeting,
      publisher,
      currentPage,
      selectedTab,
      theme
    } = this.props;
    const {
      tool,
      value,
      activeSubmenu,
      callOut,
      menus,
      currentTime
    } = this.state;
    const { svgImage } = content;
    console.log('CallOut', callOut);
    return (
      <div className={classes.svgContainer}>
        {callOut && (
          <CallOut
            callOut={callOut}
            socket={socket}
            publisher={publisher}
            meetingId={activeMeeting.id || ''}
            currentPage={currentPage}
            selectedTab={selectedTab}
            callOutAction={this.callOutAction}
          />
        )}
        {menus.length > 0 && (
          <SVGMenu
            menu={menus[0]}
            activeSubmenu={activeSubmenu}
            clearSubmenu={this.clearSubmenu}
            openCallOut={this.openCallOut}
            openCallOutUp={this.openCallOutUp}
            openSubmenu={this.openSubmenu}
            showById={this.showById}
            hideById={this.hideById}
            showByClass={this.showByClass}
            hideByClass={this.hideByClass}
          />
        )}
        <Grid
          className={classes.zoomButtons}
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <img
            src={cursorIcon.src}
            alt={cursorIcon.alt}
            className={tool === 'none' ? 'selectedTool' : ''}
            onClick={() => this.changeTool('none')}
          />
          <img
            src={panIcon.src}
            alt={panIcon.alt}
            className={tool === 'pan' ? 'selectedTool' : ''}
            onClick={() => this.changeTool('pan')}
          />
          <img
            src={zoomInIcon.src}
            alt={zoomInIcon.alt}
            className={tool === 'zoom-in' ? 'selectedTool' : ''}
            onClick={() => this.changeTool('zoom-in')}
          />
          <img
            src={zoomOutIcon.src}
            alt={zoomOutIcon.alt}
            className={tool === 'zoom-out' ? 'selectedTool' : ''}
            onClick={() => this.changeTool('zoom-out')}
          />
          <img
            src={zoomOutExtents.src}
            alt={zoomOutExtents.alt}
            onClick={() => {
              this.viewer.reset();
            }}
          />
        </Grid>
        <AutoSizer>
          {({ width, height }) => {
            const size = width > height ? height : width;
            return width === 0 || height === 0 ? null : (
              <ReactSvgPanZoomLoader
                src={`${svgImage}?time=${currentTime}`}
                render={(svgContent) => (
                  <ReactSVGPanZoom
                    width={size}
                    height={size}
                    ref={(viewer) => {
                      this.viewer = viewer;
                    }}
                    tool={tool}
                    onChangeTool={(nextTool) => this.changeTool(nextTool)}
                    value={value}
                    onChangeValue={(nextValue) => this.changeValue(nextValue)}
                    onZoom={this.onZoom}
                    onPan={this.onPan}
                    onClick={this.clearCallOut()}
                    onMouseMove={this.onMouseMove}
                    miniatureProps={{ position: 'none' }}
                    toolbarProps={{ position: 'none' }}
                    detectAutoPan={false}
                    preventPanOutside={false}
                    background={theme.content.backgroundColor}
                    SVGBackground={theme.content.backgroundColor}
                    scaleFactorMin={1}
                  >
                    <svg width={size} height={size}>
                      {svgContent}
                    </svg>
                  </ReactSVGPanZoom>
                )}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

InteractiveSVG.propTypes = {
  classes: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  socket: shape({}).isRequired,
  content: shape({}).isRequired,
  currentPage: number.isRequired,
  selectedTab: number.isRequired,
  selectedMenuItem: number.isRequired,
  publisher: bool.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired,
  currentTab: number.isRequired,
  selectedPage: number.isRequired,
  theme: shape({}).isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { token, sessionID } = state.session;
  const { activeMeeting, isCustomer, meetingIsActive } = state.meeting;
  const { selectedMenuItem } = state.menu;
  const { selectedTab: currentTab, selectedPage, theme } = state.project;
  return {
    language,
    activeMeeting,
    token,
    selectedMenuItem,
    sessionID,
    isCustomer,
    currentTab,
    selectedPage,
    theme,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InteractiveSVG));
