import React, { useState } from 'react';
import { connect } from 'react-redux';
import { shape, string, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControl } from '@material-ui/core';
import MeetingAction from '../stores/meeting/actions';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';
import { athumLogoSVG, background, iconFailed } from '../config/assets';
import {
  meetingRoomFullMessage,
  firstFullMessage,
  emailSentMessage,
  emailSentMessageSecond,
  sendToAgent,
  emailLabel,
  clientNameLabel
} from '../config/messages';
import styles from '../styles';

const ErrorPage = ({ classes, language, meetingId, emailSent, dispatch }) => {
  const [clientName, setClientName] = useState('');
  const [email, setEmail] = useState('');
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      wrap="nowrap"
      className={classes.errorContainer}
    >
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{
          backgroundImage: `url(${background.src})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 90%'
        }}
        className={classes.header}
      >
        <img src={athumLogoSVG.src} alt={athumLogoSVG.alt} />
      </Grid>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        className={classes.contentFullContainer}
      >
        {!emailSent && (
          <img className="failed" src={iconFailed.src} alt={iconFailed.src} />
        )}
        <h2>
          {!emailSent ? firstFullMessage[language] : emailSentMessage[language]}
        </h2>
        <p>
          {!emailSent
            ? meetingRoomFullMessage[language]
            : emailSentMessageSecond[language]}
        </p>
      </Grid>
      {!emailSent && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(
              MeetingAction.sendEmail(meetingId, {
                name: clientName,
                email
              })
            );
          }}
          className={classes.errorFormContainer}
        >
          <FormControl className={classes.fullMeetingForm}>
            <CustomLabel htmlFor="clientNameFM">
              {clientNameLabel[language]}
            </CustomLabel>
            <CustomInput
              id="clientNameFM"
              className="meeting-full"
              type="text"
              value={clientName}
              onChange={(event) => {
                const { target } = event;
                const { value } = target;
                setClientName(value);
              }}
            />
          </FormControl>
          <FormControl className={classes.fullMeetingForm}>
            <CustomLabel htmlFor="clientEmailFM">
              {emailLabel[language]}
            </CustomLabel>
            <CustomInput
              id="clientEmailFM"
              className="meeting-full"
              type="email"
              value={email}
              onChange={(event) => {
                const { target } = event;
                const { value } = target;
                setEmail(value);
              }}
            />
          </FormControl>
          <CustomButton className="full-meeting" type="submit">
            {sendToAgent[language]}
          </CustomButton>
        </form>
      )}
    </Grid>
  );
};

ErrorPage.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  meetingId: string.isRequired,
  emailSent: bool.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { meetingId, emailSent } = state.meeting;
  return {
    language,
    meetingId,
    emailSent
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ErrorPage));
