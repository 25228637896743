import React from 'react';
import { shape, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Undo from '@material-ui/icons/Undo';
import CustomPriceInput from '../../CustomPriceInput';
import themeStyles from '../../../styles';
import { formatNumber } from './utils';

const EditablePrice = ({
  classes,
  rawCurrency,
  rawPrice,
  state,
  unit,
  undo,
  handlePriceInputChange
}) => (
  <Grid>
    <Undo
      onClick={() => {
        if (state[unit.id]) {
          undo(`${unit.id}`);
        }
      }}
      className={
        state[unit.id] !== undefined
          ? classes.undoIcon
          : classes.disabledUndoIcon
      }
    />
    <CustomPriceInput
      id={`${unit.id}`}
      type="text"
      value={`$${
        state[unit.id] !== undefined
          ? formatNumber(state[unit.id])
          : formatNumber(rawPrice.toFixed(2))
      }`}
      onChange={(e) => {
        handlePriceInputChange(e, rawCurrency);
      }}
    />
  </Grid>
);

EditablePrice.propTypes = {
  classes: shape({}).isRequired,
  state: shape({}).isRequired,
  undo: func.isRequired,
  handlePriceInputChange: func.isRequired,
  rawCurrency: string,
  rawPrice: string,
  unit: shape({}).isRequired
};

EditablePrice.defaultProps = {
  rawCurrency: '',
  rawPrice: ''
};

export default withStyles(themeStyles)(EditablePrice);
