const styles = (theme) => ({
  finishesContainer: {
    backgroundColor: theme.content.backgroundColor,
    overflow: 'auto',
    paddingBottom: 40,
    marginTop: 20,
    height: 'calc(100% - 8px)'
  },
  finishContainer: {
    background: 'rgba(200, 200, 200, 0.2)',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    height: 222,
    width: '46%',
    cursor: 'pointer',
    overflow: 'hidden',
    margin: 10,
    '& p': {
      width: '100%',
      padding: 10,
      height: 64,
      margin: 0,
      color: theme.content.inactiveFontColor,
      fontSize: 16,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center'
    }
  },
  finishContainerNoAction: {
    background: 'rgba(200, 200, 200, 0.2)',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    height: 222,
    width: '46%',
    overflow: 'hidden',
    margin: 10,
    '& p': {
      width: '100%',
      padding: 10,
      height: 64,
      margin: 0,
      color: theme.content.inactiveFontColor,
      fontSize: 16,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center'
    }
  },
  finishImage: {
    width: '100%',
    height: 158,
    objectFit: 'cover'
  },
  icon: {
    fontSize: 30,
    cursor: 'pointer'
  }
});

export default styles;
