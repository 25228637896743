import React, { createElement, Component } from 'react';
import { shape, number, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import styles from './styles';
import ColorName from './MenuTypes/ColorName';
import IconColorName from './MenuTypes/IconColorName';
import IconName from './MenuTypes/IconName';
import NumberColorName from './MenuTypes/NumberColorName';
import NumberName from './MenuTypes/NumberName';
import { isMobile } from '../../../utils';

class SVGMenu extends Component {
  constructor() {
    super();
    this.state = {
      menuIsOpen: !isMobile()
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ menuIsOpen: !prevState.menuIsOpen }));
  };

  render() {
    const {
      menu,
      activeSubmenu,
      classes,
      clearSubmenu,
      openCallOut,
      openCallOutUp,
      openSubmenu,
      showById,
      hideById,
      showByClass,
      hideByClass
    } = this.props;
    const { menuIsOpen } = this.state;
    const component = {
      'number-color-name': NumberColorName,
      'number-name': NumberName,
      'color-name': ColorName,
      'icon-name': IconName,
      'icon-color-name': IconColorName
    };
    if (!menuIsOpen) {
      return (
        <div
          className={classes.svgCollapsedMenuContainer}
          onClick={this.toggleMenu}
        >
          <ArrowDropUp className={classes.expandButton} />
        </div>
      );
    }
    return (
      <>
        <div
          id={menu.id}
          className={activeSubmenu ? classes.svgMenuContainer : classes.hidden}
        >
          <div
            className={classes.collapseButtonContainer}
            onClick={this.toggleMenu}
          >
            <ArrowDropDown className={classes.collapseButton} />
          </div>
          {menu.options.map((option) => {
            if (activeSubmenu === option.order) {
              return (
                <div key={option.name} id={option.name}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      clearSubmenu();
                    }}
                    className={classnames(
                      classes.svgMenuItemContainer,
                      'selected'
                    )}
                  >
                    <ChevronLeft className={classes.backButton} />
                    {component[option.type] &&
                      createElement(component[option.type], {
                        option
                      })}
                    {option.name}
                  </div>
                  {option.subOptions.map((subOption) => (
                    <div
                      key={subOption.name}
                      id={`${subOption.id}Menu`}
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log('HEY');
                        openCallOut(subOption.id);
                      }}
                      onMouseUp={() => {
                        openCallOutUp();
                      }}
                      onMouseEnter={() => {
                        if (subOption.menuInteraction === 'id') {
                          showById(subOption.id);
                        } else {
                          showByClass(subOption.id);
                        }
                      }}
                      onMouseLeave={() => {
                        if (subOption.menuInteraction === 'id') {
                          hideById(subOption.id);
                        } else {
                          hideByClass(subOption.id);
                        }
                      }}
                      className={classes.svgSubmenuItemContainer}
                    >
                      {component[subOption.type] &&
                        createElement(component[subOption.type], {
                          subOption
                        })}
                      {subOption.name}
                    </div>
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>
        <div
          id={menu.id}
          className={!activeSubmenu ? classes.svgMenuContainer : classes.hidden}
        >
          <div
            className={classes.collapseButtonContainer}
            onClick={this.toggleMenu}
          >
            <ArrowDropDown className={classes.collapseButton} />
          </div>
          {menu.options.map((option) => (
            <div
              key={option.id}
              id={`${option.id}Menu`}
              onClick={(e) => {
                e.stopPropagation();
                openSubmenu(menu.id, option.order);
              }}
              className={classes.svgMenuItemContainer}
              onMouseEnter={() => {
                if (option.menuInteraction === 'id') {
                  showById(option.id);
                } else {
                  showByClass(option.id);
                }
              }}
              onMouseLeave={() => {
                if (option.menuInteraction === 'id') {
                  hideById(option.id);
                } else {
                  hideByClass(option.id);
                }
              }}
            >
              {component[option.type] &&
                createElement(component[option.type], {
                  option
                })}
              {option.name}
            </div>
          ))}
        </div>
      </>
    );
  }
}

SVGMenu.propTypes = {
  menu: shape({}).isRequired,
  activeSubmenu: number,
  classes: shape({}).isRequired,
  clearSubmenu: func.isRequired,
  openCallOut: func.isRequired,
  openSubmenu: func.isRequired,
  showById: func.isRequired,
  hideById: func.isRequired,
  showByClass: func.isRequired,
  hideByClass: func.isRequired,
  openCallOutUp: func.isRequired
};

SVGMenu.defaultProps = {
  activeSubmenu: null
};

export default withStyles(styles)(SVGMenu);
