import ActionUtility from '../../../utilities/ActionUtility';

export default class TwilioAction {
  static CREATE_TWILIO_OBJECT = 'CREATE_TWILIO_OBJECT';

  static TOGGLE_VIDEO = 'TOGGLE_VIDEO';

  static createTwilioObject() {
    return ActionUtility.createAction(TwilioAction.CREATE_TWILIO_OBJECT, '');
  }

  static toggleVideo(video) {
    return ActionUtility.createAction(TwilioAction.TOGGLE_VIDEO, {
      video
    });
  }
}
