import React, { Component } from 'react';
import { shape, string, bool, func, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import SessionAction from '../../stores/session/actions';
import ProjectAction from '../../stores/project/actions';
import PrivatePanelMeetings from './Meetings';
import PrivatePanelPrices from './Prices';
import MobilePrivatePanel from './MobilePrivatePanel';
import DesktopPrivatePanel from './DesktopPrivatePanel';
import ChatComponent from '../Chat';
import { isMobile } from '../../utils';

class PrivatePanel extends Component {
  constructor() {
    super();
    this.state = {
      currentTab: 0,
      mobileMenuIsOpen: false,
      messagessRead: [],
      pendingMessages: false
    };
  }

  changeTab = (currentTab) => {
    this.setState({ currentTab });
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuIsOpen: !prevState.mobileMenuIsOpen
    }));
  };

  readMessages = (messagessRead) => {
    this.setState({ messagessRead, pendingMessages: false });
  };

  pendingMessagesToRead = () => {
    this.setState({ pendingMessages: true });
  };

  handleLogout = () => {
    const { cookies, dispatch } = this.props;
    dispatch(SessionAction.logout(cookies));
    dispatch(ProjectAction.reset());
  };

  render() {
    const {
      language,
      privatePanelVisible,
      togglePrivatePanel,
      twilioToken,
      identity,
      twilioRoom,
      videoCallOpen,
      socket,
      meetings,
      openScheduleMeetingForm,
      handleCopyText,
      startMeeting,
      showDeleteModal,
      toggleVideoCall,
      history,
      theme,
      meetingIsActive,
      cookies
    } = this.props;
    const {
      currentTab,
      mobileMenuIsOpen,
      messagessRead,
      pendingMessages
    } = this.state;
    const twilioChat = twilioToken && (
      <ChatComponent
        twilioToken={twilioToken}
        twilioRoom={twilioRoom}
        identity={identity}
        messagesRead={messagessRead}
        readMessages={this.readMessages}
        mobileMenuIsOpen={mobileMenuIsOpen}
        pendingMessagesToRead={this.pendingMessagesToRead}
        currentTab={currentTab}
        privatePanelVisible={privatePanelVisible}
      />
    );
    const tabs = [
      {
        en: 'Session',
        es: 'Sesión',
        badge: pendingMessages,
        component: !meetingIsActive ? (
          <PrivatePanelMeetings
            openScheduleMeetingForm={openScheduleMeetingForm}
            meetings={meetings}
            handleCopyText={handleCopyText}
            startMeeting={startMeeting}
            deleteMeeting={showDeleteModal}
            toggleMobileMenu={this.toggleMobileMenu}
            language={language}
            theme={theme}
          />
        ) : (
          twilioChat
        )
      },
      {
        en: 'Notes',
        es: 'Notas',
        component: null
      },
      {
        en: 'Prices',
        es: 'Precios',
        component: <PrivatePanelPrices />
      }
    ];
    const buyerTabs = [
      {
        en: 'Session',
        es: 'Sesión',
        component: twilioChat
      }
    ];
    return (
      <>
        {isMobile() ? (
          <MobilePrivatePanel
            videoCallOpen={videoCallOpen && meetingIsActive}
            socket={socket}
            toggleVideoCall={toggleVideoCall}
            history={history}
            cookies={cookies}
            tabs={tabs}
            buyerTabs={buyerTabs}
            pendingMessages={pendingMessages}
            mobileMenuIsOpen={mobileMenuIsOpen}
            toggleMobileMenu={this.toggleMobileMenu}
            currentTab={currentTab}
            changeTab={this.changeTab}
          />
        ) : (
          <DesktopPrivatePanel
            videoCallOpen={videoCallOpen && meetingIsActive}
            socket={socket}
            toggleVideoCall={toggleVideoCall}
            togglePrivatePanel={togglePrivatePanel}
            privatePanelVisible={privatePanelVisible}
            history={history}
            tabs={tabs}
            buyerTabs={buyerTabs}
            pendingMessages={pendingMessages}
            currentTab={currentTab}
            changeTab={this.changeTab}
          />
        )}
      </>
    );
  }
}

PrivatePanel.propTypes = {
  language: string.isRequired,
  privatePanelVisible: bool.isRequired,
  togglePrivatePanel: func.isRequired,
  meetings: arrayOf(shape({})).isRequired,
  twilioToken: string.isRequired,
  identity: string.isRequired,
  twilioRoom: string.isRequired,
  videoCallOpen: bool.isRequired,
  socket: shape({}).isRequired,
  openScheduleMeetingForm: func.isRequired,
  handleCopyText: func.isRequired,
  startMeeting: func.isRequired,
  showDeleteModal: func.isRequired,
  toggleVideoCall: func.isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  theme: shape({}).isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { theme } = state.project;
  const {
    meetings,
    twilioToken,
    identity,
    twilioRoom,
    meetingIsActive
  } = state.meeting;
  return {
    language,
    meetings,
    twilioToken,
    identity,
    twilioRoom,
    theme,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivatePanel);
