import { InputLabel } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomLabel = withStyles((theme) =>
  createStyles({
    root: {
      transform: 'none',
      color: '#353535',
      '&.Mui-focused': {
        color: theme.rightMenu.activeFontColor
      }
    }
  })
)(InputLabel);

export default CustomLabel;
