import React from 'react';
import { shape, string, bool, func } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { closeIcon } from '../../config/assets';
import styles from '../../styles';

const ChangePropertyModal = ({
  classes,
  open,
  close,
  header,
  message,
  yesClick,
  backButtonText,
  yesButtonText,
  style
}) => (
  <Modal open={open} onClose={close}>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.changePropertyModalContainer}
    >
      <img
        src={closeIcon.src}
        alt={closeIcon.alt}
        className={classes.closeIcon}
        onClick={close}
      />
      <h2>{header}</h2>
      <h3>{message}</h3>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.buttonsContainer}
        style={style}
      >
        <button
          type="button"
          className={classes.changePropertyCancelButton}
          onClick={close}
        >
          {backButtonText}
        </button>
        <button
          type="button"
          className={classes.changePropertyDeleteButton}
          onClick={yesClick}
        >
          {yesButtonText}
        </button>
      </Grid>
    </Grid>
  </Modal>
);

ChangePropertyModal.propTypes = {
  classes: shape({}).isRequired,
  open: bool.isRequired,
  close: func.isRequired,
  header: string.isRequired,
  message: string.isRequired,
  yesClick: func.isRequired,
  backButtonText: string.isRequired,
  yesButtonText: string.isRequired,
  style: shape({})
};

ChangePropertyModal.defaultProps = {
  style: {}
};

export default withStyles(styles)(ChangePropertyModal);
