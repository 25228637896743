import React, { Component } from 'react';
import { shape, arrayOf, bool, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class Title extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: '' };
  }

  componentDidMount = () => {
    const { socket } = this.props;
    socket.on('TITLE-SELECTED', this.onSelectedEvent);
  };

  onSelectedEvent = (data) => {
    const { selected } = data;
    this.setState({ selected });
    this.resetSelected();
  };

  onSelected = (selected) => {
    const { socket, publisher, meetingId } = this.props;
    if (publisher) {
      this.setState({ selected });
      socket.emit('message-meeting', {
        id: meetingId,
        event: 'TITLE-SELECTED',
        data: {
          type: 'TITLE-SELECTED',
          selected
        }
      });
      this.resetSelected();
    }
  };

  resetSelected = () => {
    setTimeout(() => {
      this.setState({ selected: '' });
    }, 2000);
  };

  render() {
    const { classes, content, callOut, publisher, isCover } = this.props;
    const { selected } = this.state;
    let titleClass = classes.title;
    let selectedClass = classes.selected;
    if (isCover) {
      titleClass = classes.coverTitle;
      selectedClass = classes.coverSelected;
    } else if (callOut) {
      titleClass = classes.cardTitle;
    }
    return (
      <h1 className={titleClass}>
        {content.map(({ fontWeight, text, order }, index) => (
          <span
            key={`p-${index * 1}`}
            onClick={() => {
              this.onSelected(`p-${index * 1}-${order}`);
            }}
            style={{ fontWeight }}
            className={`${
              selected === `p-${index * 1}-${order}` ? selectedClass : ''
            } ${publisher ? classes.pointer : ''}`}
          >
            {text}
          </span>
        ))}
      </h1>
    );
  }
}

Title.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: bool,
  callOut: bool,
  isCover: bool
};

Title.defaultProps = {
  callOut: false,
  isCover: false,
  publisher: false
};

export default withStyles(styles)(Title);
