import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { shape, string, bool, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MeetingAction from '../stores/meeting/actions';
import LanguageAction from '../stores/language/actions';
import ProjectAction from '../stores/project/actions';
import SessionAction from '../stores/session/actions';
import styles from '../styles';
import SharedSession from '../components/SharedSession';
import FullPageLoader from '../components/Loader/FullPageLoader';
import { getBrowser, typeOfDevice, getOperatingSystem } from '../utils';

class ClientPage extends Component {
  async componentDidMount() {
    const { match, dispatch } = this.props;
    const { params } = match;
    const { meetingId, customerId } = params;
    const actionResponse = await dispatch(
      MeetingAction.getCustomerMeeting(meetingId, customerId)
    );
    const { isError } = actionResponse;
    if (!isError) {
      const { model } = actionResponse;
      this.getPropertyLanguage(model.meeting.projectId);
      this.getPropertySections();
      this.getProperties(model.meeting.builderId);
    }

    this.getLocation();
  }

  getLocation = () => navigator.geolocation.getCurrentPosition(this.getLatLon);

  getLatLon = async (position) => {
    const { dispatch, match } = this.props;
    const { params } = match;
    const { customerId } = params;
    const { latitude, longitude } = position.coords;
    const log = {
      userAgent: getBrowser(),
      device: typeOfDevice(),
      so: getOperatingSystem(),
      latitude,
      longitude
    };
    dispatch(SessionAction.setCustomerSession(log, customerId));
    return { latitude, longitude };
  };

  getPropertySections = () => {
    const { dispatch, match } = this.props;
    const { params } = match;
    const { meetingId } = params;
    dispatch(ProjectAction.getSectionsForClientNavigation(meetingId));
  };

  getPropertyLanguage = (projectId) => {
    const { dispatch } = this.props;
    dispatch(LanguageAction.getPropertyLanguage(projectId));
  };

  getProperties = (builderId) => {
    const { dispatch } = this.props;
    dispatch(ProjectAction.getClientProjects(builderId));
  };

  render() {
    const { classes, socket, loading, customer, error } = this.props;
    if (error) {
      return <Redirect to="/client/error" />;
    }
    if (JSON.stringify(customer) !== '{}') {
      return (
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="flex-start"
          className={classes.mainContainer}
        >
          <FullPageLoader loading={loading} />
          <SharedSession
            socket={socket}
            videoCallOpen={false}
            privatePanelVisible={false}
          />
        </Grid>
      );
    }
    return null;
  }
}

ClientPage.propTypes = {
  match: shape({}).isRequired,
  socket: shape({}).isRequired,
  classes: shape({}).isRequired,
  loading: bool.isRequired,
  customer: shape({}).isRequired,
  error: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { activeMeeting, meetingInfo, customer } = state.meeting;
  const { messsage: error } = state.error;
  const { language } = state.language;
  const { general } = state.project;
  const { loading } = state.loading;
  return {
    activeMeeting,
    meetingInfo,
    language,
    loading,
    general,
    error,
    customer
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ClientPage));
