import React, { Component } from 'react';
import { shape, string, bool, func, arrayOf, number } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SessionAction from '../../stores/session/actions';
import ProjectAction from '../../stores/project/actions';
import SettingsPage from '../../pages/SettingsPage';
import { settingsIcon, logoutIcon } from '../../config/assets';
import { settings, logout } from '../../config/messages';
import themeStyles from '../../styles';

class SalesMobilePrivatePanel extends Component {
  handleLogout = () => {
    const { cookies, dispatch } = this.props;
    dispatch(SessionAction.logout(cookies));
    dispatch(ProjectAction.reset());
  };

  render() {
    const {
      classes,
      language,
      token,
      history,
      meetingIsActive,
      tabs,
      pendingMessages,
      currentTab,
      mobileMenuIsOpen,
      toggleMobileMenu,
      changeTab
    } = this.props;
    const tabsContent = (
      <>
        {tabs.map((tab, index) => (
          <Grid
            key={`tab-${index.toString()}`}
            container
            className={
              currentTab === index
                ? classes.selectedTabContentContainer
                : classes.noSelectedTabContentContainer
            }
          >
            {tab.component}
          </Grid>
        ))}
      </>
    );
    return (
      <>
        <Grid
          className={
            !mobileMenuIsOpen
              ? classes.mobilePrivatePanelMenuContainer
              : classes.hiddenMobilePrivatePanelMenu
          }
        >
          <Menu
            className={classes.mobilePrivatePanelMenu}
            onClick={toggleMobileMenu}
          />
          {pendingMessages && (
            <Grid container className={classes.chatBadgeIndicator} />
          )}
        </Grid>
        <Grid
          className={
            mobileMenuIsOpen
              ? classes.mobilePrivatePanelContainer
              : classes.hiddenMobilePrivatePanelContainer
          }
        >
          <Grid
            container
            justify="space-around"
            alignItems="center"
            className={classes.tabsContainer}
          >
            {token && !meetingIsActive && (
              <AccountCircle
                className={
                  currentTab !== 99
                    ? classes.sessionPrivatePanelButton
                    : classes.activeSessionPrivatePanelButton
                }
                onClick={() => {
                  changeTab(99);
                }}
              />
            )}
            {tabs.map((tab, index) => (
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                className={
                  currentTab === index ? classes.activeTab : classes.tab
                }
                onClick={() => {
                  changeTab(index);
                }}
              >
                <Grid
                  container
                  className={
                    currentTab === index
                      ? classes.activeTabIndicator
                      : classes.noTabIndicator
                  }
                />
                {tab.badge && (
                  <Grid container className={classes.chatBadgeIndicator} />
                )}
                {tab[language]}
              </Grid>
            ))}
            <Close
              className={classes.privatePanelButton}
              onClick={toggleMobileMenu}
            />
          </Grid>
          {currentTab < 99 && tabsContent}
          {currentTab === 99 && (
            <Grid>
              <Grid
                className={classes.sessionOptions}
                key="Settings"
                onClick={() => {
                  changeTab(100);
                }}
              >
                <img
                  src={settingsIcon.src}
                  alt="Settings"
                  className={classes.sessionOptionsIcon}
                />
                {settings[language]}
              </Grid>
              <Grid
                className={classes.sessionOptions}
                key="Logout"
                onClick={this.handleLogout}
              >
                <img
                  src={logoutIcon.src}
                  alt="Logout"
                  className={classes.sessionOptionsIcon}
                />
                {logout[language]}
              </Grid>
            </Grid>
          )}
          {currentTab === 100 && <SettingsPage hisory={history} />}
        </Grid>
      </>
    );
  }
}

SalesMobilePrivatePanel.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  token: string.isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired,
  tabs: arrayOf(shape({})).isRequired,
  pendingMessages: bool.isRequired,
  currentTab: number.isRequired,
  changeTab: func.isRequired,
  mobileMenuIsOpen: bool.isRequired,
  toggleMobileMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { token } = state.session;
  const { meetings, meetingIsActive } = state.meeting;
  return {
    language,
    meetings,
    token,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(themeStyles)(SalesMobilePrivatePanel));
