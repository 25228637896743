import React, { Component } from 'react';
import { arrayOf, string, shape, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DrawableSVG from '../DrawableSVG';
import Thumbnail from './Thumbnail';
import styles from './styles';
import { isMobile } from '../../utils';

/* eslint-disable camelcase */

class GallerySVG extends Component {
  constructor() {
    super();
    this.state = {
      selectedIndex: 0,
      selectedIndexThumbnailCarousel: 0,
      toolsOpen: false
    };
  }

  componentDidMount = () => {
    this.handleSocketMessage();
  };

  componentWillUnmount = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.off('GALLERY', this.onMessage);
    }
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('GALLERY', this.onMessage);
    }
  };

  onMessage = (data) => {
    const { type, selectedIndex } = data;
    switch (type) {
      case 'GALLERY':
        this.handleGallerySelectedIndex(selectedIndex);
        break;

      default:
        break;
    }
  };

  onThumbnailClick = (selectedIndex) => {
    const { publisher } = this.props;
    if (publisher) {
      this.setState({
        selectedIndex
      });
      this.emit(selectedIndex);
    }
  };

  handleGallerySelectedIndex = (selectedIndex) => {
    this.setState({
      selectedIndex
    });
  };

  onNext = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndex } = this.state;
      const { content: images } = this.props;
      if (selectedIndex !== images.length - 1) {
        this.setState((prevState) => ({
          selectedIndex: prevState.selectedIndex + 1
        }));
        const minImagesIndex = isMobile() ? 2 : 3;
        if (selectedIndex + 1 > minImagesIndex) {
          const selectedIndexThumbnailCarousel = Math.round(
            (selectedIndex + 1) / minImagesIndex
          );
          this.setState({
            selectedIndexThumbnailCarousel
          });
        }
        this.emit(selectedIndex + 1);
      }
    }
  };

  onPrev = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndex } = this.state;
      if (selectedIndex !== 0) {
        this.setState((prevState) => ({
          selectedIndex: prevState.selectedIndex - 1
        }));
        const minImagesIndex = isMobile() ? 2 : 3;
        if (selectedIndex - 1 < minImagesIndex) {
          const selectedIndexThumbnailCarousel = Math.round(
            (selectedIndex - 1) / minImagesIndex
          );
          this.setState({
            selectedIndexThumbnailCarousel
          });
        }
        this.emit(selectedIndex - 1);
      }
    }
  };

  onNextThumbnail = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndexThumbnailCarousel } = this.state;
      const { content: images } = this.props;
      if (selectedIndexThumbnailCarousel !== images.length - 1) {
        this.setState((prevState) => ({
          selectedIndexThumbnailCarousel:
            prevState.selectedIndexThumbnailCarousel + 1
        }));
      }
    }
  };

  onPrevThumbnail = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndexThumbnailCarousel } = this.state;
      if (selectedIndexThumbnailCarousel !== 0) {
        this.setState((prevState) => ({
          selectedIndexThumbnailCarousel:
            prevState.selectedIndexThumbnailCarousel - 1
        }));
      }
    }
  };

  emit = (selectedIndex) => {
    const { socket, publisher, meetingId } = this.props;
    if (publisher && meetingId) {
      socket.emit('message-meeting', {
        id: meetingId,
        event: 'GALLERY',
        data: {
          type: 'GALLERY',
          selectedIndex
        }
      });
    }
  };

  setToolsOpen = (toolsOpen) => {
    this.setState({ toolsOpen });
  };

  render() {
    const {
      classes,
      content: images,
      socket,
      privatePanelVisible,
      videoCallOpen,
      height
    } = this.props;
    const {
      selectedIndex,
      selectedIndexThumbnailCarousel,
      toolsOpen
    } = this.state;
    const minImagesIndex = isMobile() ? 2 : 3;
    const thumbnailWidth = isMobile() ? 110 : 154;
    let thumbnailNavigationCondition = images.length > 4;
    if (isMobile()) {
      thumbnailNavigationCondition = images.length > 3;
    }
    const thumbnailRightChevron = (
      <div
        role="button"
        tabIndex={0}
        className={classnames(classes.button, 'right')}
        onClick={this.onNextThumbnail}
      >
        <ChevronRight className={classes.chevron} />
      </div>
    );
    const heights = {
      '100%': window.innerHeight,
      '90%': window.innerHeight * 0.9,
      '80%': window.innerHeight * 0.8,
      '70%': window.innerHeight * 0.7,
      '60%': window.innerHeight * 0.6,
      '50%': window.innerHeight * 0.5,
      '40%': window.innerHeight * 0.4,
      '30%': window.innerHeight * 0.3
    };
    return (
      <div
        className={classes.carouselContainer}
        style={{ height: toolsOpen && heights[height] < 500 ? 500 : height }}
      >
        {selectedIndexThumbnailCarousel !== 0 && images.length > 1 && (
          <div
            role="button"
            tabIndex={0}
            className={classnames(classes.button, 'left')}
            onClick={this.onPrevThumbnail}
          >
            <ChevronLeft className={classes.chevron} />
          </div>
        )}
        <div
          className={
            images.length > 1
              ? classes.selectedImageContainer
              : classes.selectedImageFullContainer
          }
        >
          <DrawableSVG
            content={{
              svgImage: images[selectedIndex].svgImage,
              defaultHeight: 1,
              height
            }}
            socket={socket}
            privatePanelVisible={privatePanelVisible}
            videoCallOpen={videoCallOpen}
            isGallery
            onPrev={this.onPrev}
            onNext={this.onNext}
            selectedIndex={selectedIndex}
            images={images}
            setToolsOpen={this.setToolsOpen}
          />
        </div>
        <div className={classes.thumbnailsContainer}>
          <div
            className={classes.thumbnailContainer}
            style={{
              width: images.length * thumbnailWidth,
              transform: `translateX(-${
                selectedIndexThumbnailCarousel * thumbnailWidth
              }px)`
            }}
          >
            {images.map((image, index) => (
              <Thumbnail
                key={`thumbnail-${index.toString()}`}
                index={index}
                url={image.svgImage}
                selected={selectedIndex === index}
                onThumbnailClick={this.onThumbnailClick}
              />
            ))}
          </div>
        </div>
        {selectedIndexThumbnailCarousel !== images.length - 1 &&
          thumbnailNavigationCondition &&
          selectedIndexThumbnailCarousel < images.length - 1 - minImagesIndex &&
          thumbnailRightChevron}
        {/* {images[selectedIndex].description && (
          <div className={classes.description}>
            <p>{images[selectedIndex].description}</p>
          </div>
        )} */}
      </div>
    );
  }
}

GallerySVG.propTypes = {
  content: arrayOf(string).isRequired,
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  privatePanelVisible: bool.isRequired,
  videoCallOpen: bool.isRequired,
  height: string
};

GallerySVG.defaultProps = {
  height: '100%'
};

export default withStyles(styles)(GallerySVG);
