import { hexToRgb } from '../../utils';

const styles = (theme) => {
  const primaryColorRGB = hexToRgb(theme.colors.primary || theme.colors.prmary);
  const coverHghlightRGB = hexToRgb(
    theme.leftMenu.activeFontColor || theme.leftMenu.activeFontColor
  );
  return {
    title: {
      margin: 0,
      padding: '28px 48px 12px 48px',
      fontSize: 21,
      fontWeight: 'bold',
      color: theme.content.activeFontColor,
      [theme.breakpoints.down('md')]: {
        padding: '12px 36px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 24px'
      }
    },
    coverTitle: {
      margin: 0,
      padding: '28px 48px 12px 48px',
      fontSize: 21,
      fontWeight: 'bold',
      color: theme.content.coverInactiveFontColor,
      [theme.breakpoints.down('md')]: {
        padding: '12px 36px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 24px'
      }
    },
    cardTitle: {
      margin: 0,
      padding: '5px 10px',
      fontSize: 14,
      fontWeight: 'bold',
      color: theme.content.activeFontColor
    },
    selected: {
      backgroundColor: `rgba(${primaryColorRGB[0]}, ${primaryColorRGB[1]}, ${primaryColorRGB[2]}, 0.2)`,
      color: theme.content.activeFontColor
    },
    coverSelected: {
      backgroundColor: `rgba(${coverHghlightRGB[0]}, ${coverHghlightRGB[1]}, ${coverHghlightRGB[2]}, 0.2)`,
      color: theme.leftMenu.backgroundColor
    },
    pointer: {
      cursor: 'pointer'
    }
  };
};

export default styles;
