import React, { Component } from 'react';
import { shape, bool, func, string } from 'prop-types';
import {
  Grid,
  Modal,
  NativeSelect,
  InputLabel,
  IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CustomButton from '../CustomButton';
import CustomSelectInput from '../CustomSelectInput';
import { isMobile, isTablet } from '../../utils';
import { camIcon } from '../../config/assets';
import styles from '../../styles';
import {
  videoPreview,
  device,
  joinWithoutVideo,
  orJoinWithoutVideo,
  joinWithVideo
} from '../../config/messages';
import { socketEmit } from '../../services/socket';

class PreviewModal extends Component {
  constructor(props) {
    super(props);
    this.previewCamera = null;
    this.state = {
      stream1: null,
      stream2: null,
      cameras: [],
      selectedCamera: ''
    };
  }

  componentDidMount = () => {
    const { publisher, meetingId, socket } = this.props;
    this.getDevices();
    if (publisher) {
      socketEmit(socket, 'message-meeting', {
        id: meetingId,
        event: 'REMOTE-SALES-CONNECTED',
        data: {
          type: 'REMOTE-SALES-CONNECTED'
        }
      });
    }
  };

  stopStream = () => {
    const { stream1, stream2 } = this.state;
    this.previewCamera.pause();
    this.previewCamera.srcObject = null;
    this.previewCamera = null;

    stream1.getTracks().forEach((track) => {
      track.stop();
    });
    stream2.getTracks().forEach((track) => {
      track.stop();
    });
  };

  getDevices = () => {
    const { onError } = this.props;
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
      (stream) => {
        navigator.mediaDevices
          .enumerateDevices()
          .then(this.gotDevices)
          .then(this.getStream);
        this.setState({ stream1: stream });
      },
      (error) => {
        console.error('STREAM1', error);
        onError(error.toString());
      }
    );
  };

  gotDevices = (deviceInfo) => {
    const cameras = [];
    deviceInfo.forEach((item, index) => {
      if (item.kind === 'videoinput') {
        cameras.push({
          value: item.deviceId,
          label: item.label || `Camera ${index}`
        });
      }
    });
    this.setState({
      cameras,
      selectedCamera: cameras[0].value
    });
  };

  getStream = (value) => {
    const { stream2, selectedCamera } = this.state;
    const { onError } = this.props;
    if (stream2 !== null) {
      stream2.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const constraints = {
      video: {
        deviceId: value ? { exact: value } : selectedCamera
      }
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this.gotStream)
      .catch((error) => {
        console.error('STREAM2', error);
        onError(error.toString());
      });
  };

  gotStream = (stream) => {
    const { cameras } = this.state;
    const selectedCamera = cameras.find(
      (item) => item.label === stream.getVideoTracks()[0].label
    );
    this.setState({
      stream2: stream,
      selectedCamera: selectedCamera.value
    });
    this.previewCamera.srcObject = stream;
  };

  handleVideoSelect = (event) => {
    this.getStream(event.target.value);
  };

  flipCamera = () => {
    const { cameras, selectedCamera } = this.state;
    const camera = cameras.find((item) => item.value !== selectedCamera);
    this.getStream(camera.value);
  };

  render() {
    const {
      classes,
      preview,
      setVideoStart,
      settings,
      applyChanges,
      language
    } = this.props;
    const { cameras, selectedCamera } = this.state;
    return (
      <Modal open={preview}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.modalPreviewContainer}
        >
          <h2>{videoPreview[language]}</h2>
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            direction="column"
            id="preview"
            className={classes.previewCameraContainer}
          >
            {!isMobile() && !isTablet() && (
              <>
                <InputLabel htmlFor="camera-select">
                  {device[language]}
                </InputLabel>
                <NativeSelect
                  labelId="camera-selection"
                  id="camera-select"
                  value={selectedCamera}
                  onChange={this.handleVideoSelect}
                  className={classes.cameraSelect}
                  input={<CustomSelectInput />}
                >
                  {cameras.map((camera, index) => (
                    <option
                      key={`camera-${index.toString()}`}
                      value={camera.value}
                    >
                      {camera.label}
                    </option>
                  ))}
                </NativeSelect>
              </>
            )}
            <Grid item className={classes.webcamContainer}>
              <video
                ref={(ref) => {
                  this.previewCamera = ref;
                }}
                playsInline
                autoPlay
                muted
              />
              {isMobile() ||
                (isTablet() && (
                  <IconButton
                    className={classes.flipButton}
                    aria-label="add to shopping cart"
                    onClick={this.flipCamera}
                  >
                    <img src={camIcon.icons} alt={camIcon.alt} />
                  </IconButton>
                ))}
            </Grid>
            <>
              {!settings && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.buttons}
                >
                  <button
                    type="button"
                    onClick={() => {
                      this.stopStream();
                      setVideoStart(false);
                    }}
                    className={classes.buttonAsLinkTop}
                  >
                    {joinWithoutVideo[language]}
                  </button>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.joinVideoButtonContainer}
                  >
                    <CustomButton
                      onClick={() => {
                        this.stopStream();
                        setVideoStart(true, selectedCamera);
                      }}
                    >
                      {joinWithVideo[language]}
                    </CustomButton>
                  </Grid>
                  <button
                    type="button"
                    onClick={() => {
                      this.stopStream();
                      setVideoStart(false);
                    }}
                    className={classes.buttonAsLinkBottom}
                  >
                    {orJoinWithoutVideo[language]}
                  </button>
                </Grid>
              )}
              {settings && (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.cameraSelect}
                >
                  <CustomButton
                    onClick={() => {
                      applyChanges();
                    }}
                  >
                    Apply
                  </CustomButton>
                </Grid>
              )}
            </>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

PreviewModal.propTypes = {
  classes: shape({}).isRequired,
  preview: bool.isRequired,
  setVideoStart: func.isRequired,
  applyChanges: func.isRequired,
  settings: bool.isRequired,
  onError: func.isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  socket: shape({}).isRequired,
  language: string.isRequired
};

export default withStyles(styles)(PreviewModal);
