import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, bool, func } from 'prop-types';
import ProjectAction from '../../stores/project/actions';
import MenuAction from '../../stores/menu/actions';
import { settingsIcon } from '../../config/assets';
import {
  changeProperty,
  yesChangeProperty,
  noGoBack,
  mightTakeAMinute
} from '../../config/messages';
import ChangePropertyModal from '../Modals/ChangePropertyModal';
import LogoLoader from '../Loader/LogoLoader';
import ProjectsMenu from './ProjectsMenu';
import MobileSalesMenu from './MobileSalesMenu';
import { socketEmit } from '../../services/socket';

class SalesMenu extends Component {
  constructor() {
    super();
    this.state = {
      showMobileMenu: false,
      projectModal: false,
      selectedProjectToApply: 0
    };
  }

  componentDidMount() {
    this.handleSocketMessage();
  }

  componentWillUnmount() {
    const { socket } = this.props;
    if (socket) {
      socket.off('MENU', () => {});
    }
  }

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('MENU', (data) => {
        const { type, selectedMenuItem } = data;
        switch (type) {
          case 'MENU':
            this.handleSelectMenuItem(selectedMenuItem);
            break;
          default:
            break;
        }
      });
      socket.on('ACTIVE-FLOORPLAN', (data) => {
        const { type, selectedLayout, selectedPage, selectedTab } = data;
        switch (type) {
          case 'ACTIVE-FLOORPLAN':
            this.selectLayout(selectedLayout, selectedPage, selectedTab);
            break;
          default:
            break;
        }
      });
    }
  };

  handleSelectMenuItem = (selectedMenuItem) => {
    const {
      socket,
      activeMeeting,
      meetingIsActive,
      publisher,
      token,
      history,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'MENU',
        data: {
          type: 'MENU',
          selectedMenuItem
        }
      });
    }
    dispatch(MenuAction.setSelectedMenuItem(selectedMenuItem));
    if (token) {
      if (selectedMenuItem === 'Settings') {
        history.push('/settings');
      } else {
        history.push('/');
      }
      this.selectLayout(null);
    }
  };

  selectLayout = (selectedLayout, selectedPage = 1, selectedTab = 0) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    dispatch(ProjectAction.selectLayout(selectedLayout));
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout,
          selectedPage,
          selectedTab
        }
      });
    }
  };

  showMobileMenu = () => {
    this.setState((prevState) => ({
      showMobileMenu: !prevState.showMobileMenu
    }));
  };

  getPropertySections = async (selectedProperty) => {
    const {
      token,
      propertiesSections,
      isCustomer,
      dispatch,
      projects
    } = this.props;
    if (token) {
      const actionResponse = await dispatch(
        ProjectAction.getSections(
          selectedProperty,
          propertiesSections,
          projects
        )
      );
      const { isError } = actionResponse;
      if (!isError) {
        dispatch(ProjectAction.getPrices(selectedProperty));
      }
    } else if (isCustomer) {
      dispatch(
        ProjectAction.getSections(
          selectedProperty,
          propertiesSections,
          projects
        )
      );
    }
  };

  onPropertySelected = (newProperty) => {
    const {
      socket,
      activeMeeting,
      meetingIsActive,
      publisher,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    const selectedProperty = newProperty;
    dispatch(MenuAction.setSelectedPropertyMenuItem(selectedProperty));
    this.getPropertySections(selectedProperty);
    dispatch(ProjectAction.selectTab(0, 1));
    dispatch(MenuAction.setSelectedMenuItem(1));
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'MENU-PROPERTY',
        data: {
          type: 'MENU-PROPERTY',
          selectedProperty
        }
      });
    }
  };

  handlePropertySelected = (propertyId) => {
    const { propertiesSections, selectedPropertyMenuItem } = this.props;
    if (propertyId !== selectedPropertyMenuItem) {
      if (propertyId in propertiesSections) {
        this.onPropertySelected(propertyId);
      } else {
        this.openProjectAlert();
        this.setState({
          selectedProjectToApply: propertyId
        });
      }
    }
  };

  openProjectAlert = () => {
    this.setState({
      projectModal: true
    });
  };

  closeProjectModal = () => {
    this.setState({
      projectModal: false
    });
  };

  applySelectedProject = () => {
    const { selectedProjectToApply } = this.state;
    this.onPropertySelected(selectedProjectToApply);
    this.closeProjectModal();
  };

  render() {
    const {
      selectedMenuItem,
      sections,
      language,
      loading,
      loadingTheme,
      history,
      cookies,
      openMenu,
      toggleMenu
    } = this.props;
    const { projectModal } = this.state;
    let activeMenuItem = sections.filter(
      ({ order }) => selectedMenuItem === order
    );
    if (selectedMenuItem === sections.length + 1) {
      activeMenuItem = [
        { order: sections.length + 1, name: 'Settings', icon: settingsIcon.src }
      ];
    }
    return (
      <>
        {!loadingTheme && (
          <>
            {activeMenuItem.length === 1 && (
              <MobileSalesMenu
                handlePropertySelected={this.handlePropertySelected}
                handleSelectMenuItem={this.handleSelectMenuItem}
              />
            )}
            <ProjectsMenu
              handlePropertySelected={this.handlePropertySelected}
              closeProjectModal={this.closeProjectModal}
              handleSelectMenuItem={this.handleSelectMenuItem}
              history={history}
              cookies={cookies}
              openMenu={openMenu}
              toggleMenu={toggleMenu}
            />
            <ChangePropertyModal
              open={projectModal}
              close={this.closeProjectModal}
              header={changeProperty[language]}
              message={mightTakeAMinute[language]}
              yesClick={this.applySelectedProject}
              backButtonText={noGoBack[language]}
              yesButtonText={yesChangeProperty[language]}
            />
            {loading && <LogoLoader load={loading} />}
          </>
        )}
      </>
    );
  }
}

SalesMenu.propTypes = {
  selectedMenuItem: number.isRequired,
  history: shape({}).isRequired,
  cookies: shape({}).isRequired,
  socket: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  token: string.isRequired,
  sections: arrayOf(shape({})).isRequired,
  language: string.isRequired,
  projects: arrayOf(shape({})).isRequired,
  propertiesSections: shape({}).isRequired,
  loading: bool.isRequired,
  publisher: bool.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired,
  loadingTheme: bool.isRequired,
  selectedPropertyMenuItem: number.isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired,
  openMenu: bool.isRequired,
  toggleMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { token, sessionID } = state.session;
  const { language } = state.language;
  const { selectedMenuItem, selectedPropertyMenuItem } = state.menu;
  const {
    activeMeeting,
    publisher,
    isCustomer,
    meetingIsActive
  } = state.meeting;
  const { loading } = state.loading;
  const {
    sections,
    projects,
    propertiesSections,
    loadingTheme
  } = state.project;
  return {
    selectedMenuItem,
    activeMeeting,
    token,
    sections,
    language,
    projects,
    propertiesSections,
    loading,
    publisher,
    isCustomer,
    sessionID,
    loadingTheme,
    selectedPropertyMenuItem,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesMenu);
