import React from 'react';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const NumberName = ({ option, subOption, classes }) => {
  if (option) {
    return (
      <span className={classes.numberNameMenuItemContainer}>
        {option.content.number}
      </span>
    );
  }
  if (subOption) {
    return (
      <span className={classes.numberNameSubmenuItemContainer}>
        {subOption.content.number}
      </span>
    );
  }
  return null;
};

NumberName.propTypes = {
  option: shape({}),
  subOption: shape({}),
  classes: shape({}).isRequired
};

NumberName.defaultProps = {
  option: null,
  subOption: null
};

export default withStyles(styles)(NumberName);
