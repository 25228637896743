import { hexToRgb } from '../../utils';

const styles = (theme) => {
  const activeColorRGB = hexToRgb(theme.content.activeFontColor);
  const coverHghlightRGB = hexToRgb(
    theme.leftMenu.activeFontColor || theme.leftMenu.activeFontColor
  );
  return {
    listContainer: {
      margin: 0,
      width: '100%',
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 300,
      color: theme.content.inactiveFontColor,
      padding: '10px 48px 16px 60px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 58px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 46px'
      },
      '& .selected': {
        backgroundColor: `rgba(${activeColorRGB[0]}, ${activeColorRGB[1]}, ${activeColorRGB[2]}, 0.2)`,
        color: theme.content.activeFontColor
      }
    },
    coverListContainer: {
      margin: 0,
      width: '100%',
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 300,
      color: theme.leftMenu.inactiveFontColor,
      padding: '10px 48px 16px 60px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 58px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 46px'
      },
      '& .selected': {
        backgroundColor: `rgba(${coverHghlightRGB[0]}, ${coverHghlightRGB[1]}, ${coverHghlightRGB[2]}, 0.2)`,
        color: theme.leftMenu.backgroundColor
      }
    },
    orderedListContainer: {
      margin: 0,
      width: '100%',
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 300,
      listStyle: 'none',
      padding: '10px 48px 16px 48px',
      color: theme.content.inactiveFontColor,
      [theme.breakpoints.down('md')]: {
        padding: '10px 36px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 24px'
      },
      '& .selected': {
        backgroundColor: `rgba(${activeColorRGB[0]}, ${activeColorRGB[1]}, ${activeColorRGB[2]}, 0.2)`,
        color: theme.content.activeFontColor
      }
    },
    pointer: {
      cursor: 'pointer'
    }
  };
};

export default styles;
