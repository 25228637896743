export default class MXCalculatorModel {
  constructor(
    data = {
      layoutId: 0,
      unitName: '',
      price: '',
      availableOn: '',
      status: '',
      typeDiscount: 'money',
      discount: '',
      priceWithDiscount: '',
      downPaymentPercentage: '',
      downPayment: '',
      holdingFee: '',
      deferredAmountPercentage: '',
      deferredAmountMonths: '',
      deferredAmountMonthlyPayment: '',
      deedsAmount: '',
      notaryFeesPercentage: '',
      notaryFeesAmount: '',
      loanType: '',
      loanTerm: '',
      interestRate: '',
      estimatedMonthlyPayment: '',
      area: '',
      bathrooms: '',
      bedrooms: '',
      parking: ''
    }
  ) {
    this.layoutId = data.layoutId;

    this.unitName = data.unitName;

    this.price = data.price;

    this.availableOn = data.availableOn;

    this.status = data.status;

    this.typeDiscount = data.typeDiscount;

    this.discount = data.discount;

    this.priceWithDiscount = data.priceWithDiscount;

    this.downPaymentPercentage = data.downPaymentPercentage;

    this.downPayment = data.downPayment;

    this.holdingFee = data.holdingFee;

    this.deferredAmountPercentage = data.deferredAmountPercentage;

    this.deferredAmountMonths = data.deferredAmountMonths;

    this.deferredAmountMonthlyPayment = data.deferredAmountMonthlyPayment;

    this.deedsAmount = data.deedsAmount;

    this.notaryFeesPercentage = data.notaryFeesPercentage;

    this.notaryFeesAmount = data.notaryFeesAmount;

    this.area = data.area;

    this.bathrooms = data.bathrooms;

    this.bedrooms = data.bedrooms;

    this.parking = data.parking;

    this.loanType = '';

    this.loanTerm = '';

    this.interestRate = '';

    this.estimatedMonthlyPayment = '';
  }

  layoutId = 0;

  unitName = '';

  price = '';

  availableOn = '';

  status = '';

  typeDiscount = 'money';

  discount = '';

  priceWithDiscount = '';

  downPaymentPercentage = '';

  downPayment = '';

  holdingFee = '';

  deferredAmountPercentage = '';

  deferredAmountMonths = '';

  deferredAmountMonthlyPayment = '';

  deedsAmount = '';

  notaryFeesPercentage = '';

  notaryFeesAmount = '';

  loanType = '';

  loanTerm = '';

  interestRate = '';

  estimatedMonthlyPayment = '';

  area = '';

  bathrooms = '';

  bedrooms = '';

  parking = '';
}
