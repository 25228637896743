import React from 'react';
import { connect } from 'react-redux';
import { shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  athumLogoSVG,
  background,
  desktopErrorImg,
  mobileErrorImg
} from '../config/assets';
import {
  browserErrorMessage,
  browserNotFoundErrorMessage,
  browserErrorMessageSecond,
  browserNotFoundErrorMessageSecond,
  browserDesktopErrorMessageSecond
} from '../config/messages';
import { isMobile, isTablet, getOperatingSystem } from '../utils';
import styles from '../styles';

const ErrorPage = ({ classes, language, match }) => {
  const { params } = match;
  const { error = '' } = params;
  const secondMessage = error.includes('404') ? (
    <p>{browserNotFoundErrorMessageSecond[language]}</p>
  ) : (
    <>
      <p>{browserDesktopErrorMessageSecond[language]}</p>
      <div className={classes.linksContainer}>
        <a href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMI4YH-utOV6gIVRb7ACh2EkgnSEAAYASAAEgJzU_D_BwE&gclsrc=aw.ds">
          Chrome
        </a>
        or
        <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
      </div>
    </>
  );
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
      className={classes.errorContainer}
    >
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{
          backgroundImage: `url(${background.src})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 90%'
        }}
        className={classes.header}
      >
        <img src={athumLogoSVG.src} alt={athumLogoSVG.alt} />
      </Grid>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        className={classes.errorContentContainer}
      >
        {getOperatingSystem() === 'iOS' && (isMobile() || isTablet()) ? (
          <img
            className="failed"
            src={mobileErrorImg.src}
            alt={mobileErrorImg.alt}
          />
        ) : (
          <img
            className="failed"
            src={desktopErrorImg[error].src}
            alt={desktopErrorImg[error].alt}
          />
        )}
        <h2>
          {error.includes('404')
            ? browserNotFoundErrorMessage[language]
            : browserErrorMessage[language]}
        </h2>
        {getOperatingSystem() === 'iOS' && (isMobile() || isTablet()) ? (
          <p>{browserErrorMessageSecond[language]}</p>
        ) : (
          secondMessage
        )}
      </Grid>
    </Grid>
  );
};

ErrorPage.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  match: shape({}).isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  return {
    language
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ErrorPage));
