import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, bool, func } from 'prop-types';
import { Grid, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SessionAction from '../../stores/session/actions';
import ProjectAction from '../../stores/project/actions';
import MenuAction from '../../stores/menu/actions';
import { settingsIcon, logoutIcon, switchIcon } from '../../config/assets';
import styles from '../../styles';
import {
  settings,
  logout,
  switchProperties,
  property
} from '../../config/messages';
import AvatarWithName from '../AvatarWithName';
import StyledMenu from './StyledMenu';
import SectionsMenu from './SectionsMenu';
import StyledMenuItem from './StyledMenuItem';

class SalesMenu extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      anchorProjectEl: null
    };
  }

  handleLogout = () => {
    const { cookies, dispatch } = this.props;
    this.handleAvatarClose();
    dispatch(SessionAction.logout(cookies));
    dispatch(ProjectAction.reset());
    dispatch(MenuAction.setSelectedMenuItem(1));
  };

  goToSettings = () => {
    const { history } = this.props;
    history.push('/settings');
  };

  handleAvatarClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAvatarClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProjectClick = (event) => {
    this.setState({ anchorProjectEl: event.currentTarget });
  };

  handleProjectClose = () => {
    this.setState({ anchorProjectEl: null });
  };

  render() {
    const {
      classes,
      selectedMenuItem,
      sections,
      language,
      projects,
      user,
      selectedProject: currentProject,
      isCustomer,
      theme,
      meetingIsActive,
      handlePropertySelected,
      handleSelectMenuItem,
      openMenu,
      toggleMenu
    } = this.props;
    const { anchorEl, anchorProjectEl } = this.state;
    const defaultMenuItems = (mobileEnabled) => {
      const menuItemClass =
        sections.length + 1 === selectedMenuItem
          ? classes.menuItemActive
          : classes.menuItem;
      const responsiveMenuItemClass = mobileEnabled
        ? classes.menuItem
        : menuItemClass;
      const menuIconClass =
        sections.length + 1 === selectedMenuItem
          ? classes.menuIconActive
          : classes.menuIcon;
      const responsiveMenuIconClass = mobileEnabled
        ? classes.menuIcon
        : menuIconClass;
      return [
        <StyledMenuItem
          key="Settings"
          className={`${responsiveMenuItemClass} sales`}
          onClick={() => {
            handleSelectMenuItem(sections.length + 1);
            this.goToSettings();
            this.handleAvatarClose();
          }}
        >
          <img
            src={settingsIcon.src}
            alt="Settings"
            className={responsiveMenuIconClass}
          />
          {settings[language]}
        </StyledMenuItem>,
        <StyledMenuItem
          key="Logout"
          className={`${responsiveMenuItemClass} sales`}
          onClick={this.handleLogout}
        >
          <img
            src={logoutIcon.src}
            alt="Logout"
            className={responsiveMenuIconClass}
          />
          {logout[language]}
        </StyledMenuItem>
      ];
    };
    const selectedProject = projects.find(
      (project) => project.id === currentProject
    );
    let switchPropertiesLabel =
      projects.length > 1 ? switchProperties[language] : property[language];
    if (!openMenu) {
      switchPropertiesLabel = null;
    }
    return (
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        className={
          openMenu
            ? `${classes.menuContainer} projects`
            : `${classes.menuContainer} collapsed-projects`
        }
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.brandContainer}
        >
          {!isCustomer && (
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className={classes.userAvatarMenu}
            >
              <Grid container>
                <AvatarWithName
                  image={user.avatar}
                  name={user.name}
                  theme={theme}
                  handleAvatarClick={this.handleAvatarClick}
                  toggleMenu={toggleMenu}
                  openMenu={openMenu}
                />
              </Grid>
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleAvatarClose}
              >
                {!meetingIsActive && defaultMenuItems(false).map((el) => el)}
              </StyledMenu>
            </Grid>
          )}
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className={classes.projectsMenu}
          >
            <Grid
              onClick={this.handleProjectClick}
              container
              className={classes.switchContainer}
              style={{
                cursor: projects.length > 1 ? 'pointer' : 'default'
              }}
            >
              {(openMenu || projects.length > 1) && (
                <Grid
                  container
                  className="switch-properties-container"
                  alignItems="center"
                  justify="space-between"
                >
                  {switchPropertiesLabel}
                  {projects.length > 1 && (
                    <img
                      src={switchIcon.src}
                      alt={switchIcon.alt}
                      className={classes.switchIcon}
                    />
                  )}
                </Grid>
              )}
              {selectedProject && (
                <Grid
                  container
                  alignItems="center"
                  justify="flex-start"
                  className="switch-properties-current"
                  style={
                    openMenu || projects.length > 1 ? {} : { marginTop: 13 }
                  }
                >
                  <img
                    src={selectedProject.logoContracted}
                    alt={`logo-${selectedProject.id}`}
                    className={classes.projectLogo}
                  />
                  {openMenu && selectedProject.name}
                </Grid>
              )}
            </Grid>
            {projects.length > 1 && (
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorProjectEl}
                keepMounted
                open={Boolean(anchorProjectEl)}
                onClose={this.handleProjectClose}
              >
                {projects.map((project) => (
                  <StyledMenuItem
                    key={`project-${project.id}`}
                    className={classes.menuProjectItem}
                    onClick={() => {
                      handlePropertySelected(project.id);
                      this.handleProjectClose();
                    }}
                  >
                    <img
                      src={project.logoContracted}
                      alt={`logo-${project.id}`}
                      className={classes.projectLogo}
                    />

                    <ListItemText
                      className={classes.projectName}
                      primary={project.name}
                    />
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            )}
          </Grid>
          <SectionsMenu
            handleSelectMenuItem={handleSelectMenuItem}
            openMenu={openMenu}
          />
        </Grid>
        {openMenu && (
          <Grid
            container
            justify="space-around"
            alignItems="center"
            className={classes.logosContainer}
          >
            {selectedProject && (
              <img
                src={selectedProject.logoContracted}
                alt="logo contracted"
                className="project"
              />
            )}
            {selectedProject && (
              <img
                src={selectedProject.atHumLogo}
                alt="At hum"
                className="athum"
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

SalesMenu.propTypes = {
  selectedMenuItem: number.isRequired,
  cookies: shape({}).isRequired,
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  sections: arrayOf(shape({})).isRequired,
  user: shape({}).isRequired,
  language: string.isRequired,
  projects: arrayOf(shape({})).isRequired,
  selectedProject: number.isRequired,
  theme: shape({}).isRequired,
  isCustomer: bool.isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired,
  handlePropertySelected: func.isRequired,
  handleSelectMenuItem: func.isRequired,
  openMenu: bool.isRequired,
  toggleMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { user } = state.session;
  const { language } = state.language;
  const { selectedMenuItem } = state.menu;
  const { isCustomer, meetingIsActive } = state.meeting;
  const { sections, projects, selectedProject, theme } = state.project;
  return {
    selectedMenuItem,
    sections,
    language,
    projects,
    user,
    selectedProject,
    theme,
    isCustomer,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SalesMenu));
