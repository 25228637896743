import React from 'react';
import { shape, string, func, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import styles from './styles';
import { dropdownIcon } from '../../config/assets';

const AvatarWithName = ({
  name,
  classes,
  handleAvatarClick,
  toggleMenu,
  openMenu
}) => (
  <Grid
    container
    justify="space-between"
    alignItems="center"
    className={classes.avatarContainer}
  >
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.avatarBadgeWithName}
    >
      <Menu className={classes.menuIcon} onClick={toggleMenu} />
      {openMenu && <h5 onClick={handleAvatarClick}>{name}</h5>}
    </Grid>
    {openMenu && (
      <img
        src={dropdownIcon.src}
        alt={dropdownIcon.alt}
        className={classes.dropdownIcon}
        onClick={handleAvatarClick}
      />
    )}
  </Grid>
);

AvatarWithName.propTypes = {
  classes: shape({}).isRequired,
  name: string.isRequired,
  handleAvatarClick: func.isRequired,
  toggleMenu: func.isRequired,
  openMenu: bool.isRequired
};

export default withStyles(styles)(AvatarWithName);
