import React from 'react';
import { connect } from 'react-redux';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const VirtualTour = ({ classes, content }) => {
  const {
    enabled,
    externalLink,
    layoutName,
    // backgroundColor,
    // color,
    text,
    showTour
  } = content;
  if (!enabled) {
    return null;
  }
  return (
    <button
      type="button"
      // style={{ backgroundColor, color }}
      onClick={() => showTour(externalLink, layoutName)}
      className={classes.tourButton}
    >
      {text}
    </button>
  );
};

VirtualTour.propTypes = {
  classes: shape({}).isRequired,
  content: shape({}).isRequired
};

const mapStateToProps = (state) => {
  const { selectedLayout, selectedPage } = state.project;
  return {
    selectedLayout,
    selectedPage
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(VirtualTour));
