import React from 'react';
import { connect } from 'react-redux';
import { shape, number, bool, arrayOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import SharedWithTabs from './SharedWithTabs';
import styles from '../../styles';

const SharedSession = ({
  classes,
  selectedMenuItem,
  meetingIsActive,
  videoCallOpen,
  sections,
  socket,
  selectedLayout,
  privatePanelVisible
}) => {
  let sharedSessionContent = null;
  if (sections.length > 0) {
    const selectedSection = sections.filter(
      (section) => section.order === selectedMenuItem
    );
    const { subsections } = selectedSection[0];
    sharedSessionContent = (
      <SharedWithTabs
        socket={socket}
        subSections={subsections}
        privatePanelVisible={privatePanelVisible}
        videoCallOpen={videoCallOpen}
      />
    );
  }
  if (selectedLayout) {
    const { subSections } = selectedLayout;
    sharedSessionContent = (
      <SharedWithTabs
        socket={socket}
        subSections={subSections}
        privatePanelVisible={privatePanelVisible}
        videoCallOpen={videoCallOpen}
      />
    );
  }
  return (
    <Grid
      className={classnames(
        privatePanelVisible
          ? classes.sharedSessionContainer
          : classes.fullSharedSessionContainer,
        meetingIsActive && 'active',
        videoCallOpen && 'inCall'
      )}
    >
      {sharedSessionContent}
    </Grid>
  );
};

SharedSession.propTypes = {
  classes: shape({}).isRequired,
  selectedMenuItem: number.isRequired,
  videoCallOpen: bool.isRequired,
  sections: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  selectedLayout: shape({}),
  privatePanelVisible: bool.isRequired,
  meetingIsActive: bool.isRequired
};

SharedSession.defaultProps = {
  selectedLayout: null
};

const mapStateToProps = (state) => {
  const { sections, selectedLayout } = state.project;
  const { selectedMenuItem } = state.menu;
  const { meetingIsActive } = state.meeting;
  return {
    selectedMenuItem,
    meetingIsActive,
    sections,
    selectedLayout
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SharedSession));
