import React from 'react';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const NumberColorName = ({ option, subOption, classes }) => {
  if (option) {
    return (
      <span
        className={classes.numberColorNameMenuItemContainer}
        style={{ backgroundColor: option.content.color, color: 'white' }}
      >
        {option.content.number}
      </span>
    );
  }
  if (subOption) {
    return (
      <span
        className={classes.numberColorNameSubmenuItemContainer}
        style={{ backgroundColor: subOption.content.color, color: 'white' }}
      >
        {subOption.content.number}
      </span>
    );
  }
  return null;
};

NumberColorName.propTypes = {
  option: shape({}),
  subOption: shape({}),
  classes: shape({}).isRequired
};

NumberColorName.defaultProps = {
  option: null,
  subOption: null
};

export default withStyles(styles)(NumberColorName);
