import ActionUtility from '../../../utilities/ActionUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import SessionEffect from '../effects';

export default class SessionAction {
  static LOGIN_REQUEST = 'LOGIN_REQUEST';

  static LOGIN_REQUEST_FINISHED = 'LOGIN_REQUEST_FINISHED';

  static LOGOUT = 'LOGOUT';

  static CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';

  static CHANGE_PASSWORD_REQUEST_FINISHED = 'CHANGE_PASSWORD_REQUEST_FINISHED';

  static FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

  static FORGOT_PASSWORD_REQUEST_FINISHED = 'FORGOT_PASSWORD_REQUEST_FINISHED';

  static RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';

  static RESET_PASSWORD_REQUEST_FINISHED = 'RESET_PASSWORD_REQUEST_FINISHED';

  static CUSTOMER_SESSION_REQUEST = 'CUSTOMER_SESSION_REQUEST';

  static CUSTOMER_SESSION_REQUEST_FINISHED =
    'CUSTOMER_SESSION_REQUEST_FINISHED';

  static login(email, password, cookies, log) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.LOGIN_REQUEST,
        SessionEffect.login,
        true,
        language,
        email,
        password,
        log,
        cookies
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static logout(cookies = null) {
    if (cookies) {
      sessionStorage.clear();
      cookies.remove('user');
      cookies.remove('token');
      cookies.remove('refreshToken');
      cookies.remove('expirationTime');
      cookies.remove('accessToken');
    }

    return ActionUtility.createAction(SessionAction.LOGOUT, '');
  }

  static changePassword(currentPassword, newPassword) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const accessToken = sessionStorage.getItem('accessToken') || '';

      const model = await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.CHANGE_PASSWORD_REQUEST,
        SessionEffect.changePassword,
        true,
        language,
        token,
        currentPassword,
        newPassword,
        accessToken
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static forgotPassword(email) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.FORGOT_PASSWORD_REQUEST,
        SessionEffect.forgotPassword,
        true,
        language,
        email
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static resetPassword(email, newPassword, code) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.RESET_PASSWORD_REQUEST,
        SessionEffect.resetPassword,
        true,
        language,
        email,
        newPassword,
        code
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static setCustomerSession(body, customerId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;

      const model = await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.CUSTOMER_SESSION_REQUEST,
        SessionEffect.setCustomerSession,
        true,
        language,
        body,
        customerId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static confirmAccount(email, oneTimePassword, password, cookies) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;

      const model = await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.LOGIN_REQUEST,
        SessionEffect.confirmAccount,
        true,
        language,
        email,
        oneTimePassword,
        password,
        cookies
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static refreshLogin(email, refreshToken, cookies) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;

      await ActionUtility.createThunkEffect(
        dispatch,
        SessionAction.LOGIN_REQUEST,
        SessionEffect.refreshLogin,
        true,
        language,
        email,
        refreshToken,
        cookies
      );
    };
  }

  static setUserFromSession(
    token,
    user,
    refreshToken,
    expirationTime,
    accessToken,
    cookies
  ) {
    cookies.set('user', JSON.stringify(user), { path: '/', maxAge: 31536000 });
    cookies.set('token', token, { path: '/', maxAge: 31536000 });
    cookies.set('refreshToken', refreshToken, { path: '/', maxAge: 31536000 });
    cookies.set('expirationTime', expirationTime, {
      path: '/',
      maxAge: 31536000
    });
    cookies.set('accessToken', accessToken, { path: '/', maxAge: 31536000 });

    return ActionUtility.createAction(SessionAction.LOGIN_REQUEST_FINISHED, {
      token,
      user
    });
  }

  static setUserFromCookies(
    token,
    user,
    refreshToken,
    expirationTime,
    accessToken
  ) {
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshToken', refreshToken);
    sessionStorage.setItem('expirationTime', expirationTime);
    sessionStorage.setItem('accessToken', accessToken);

    return ActionUtility.createAction(SessionAction.LOGIN_REQUEST_FINISHED, {
      token,
      user
    });
  }
}
