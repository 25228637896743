import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, bool, func, arrayOf, number } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import Chat from 'twilio-chat';
import Bubble from './bubble';
import { sendMessage } from '../../config/assets';
import { isMobile } from '../../utils';
import styles from './styles';

const newMessageSound = require('../../assets/sounds/message.mp3');

class ChatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      messages: [],
      message: '',
      messageSound: new Audio(newMessageSound)
    };
    this.messagesEnd = null;
  }

  componentDidMount = () => {
    this.createChat();
  };

  componentDidUpdate = () => {
    const {
      mobileMenuIsOpen,
      readMessages,
      pendingMessagesToRead,
      messagesRead,
      currentTab,
      privatePanelVisible
    } = this.props;
    const { messages } = this.state;
    if (
      (isMobile() && mobileMenuIsOpen && currentTab === 0) ||
      (!isMobile() && privatePanelVisible && currentTab === 0)
    ) {
      readMessages(messages);
      setTimeout(() => {
        this.scrollToBottom();
      }, 50);
    } else if (messagesRead !== messages) {
      pendingMessagesToRead();
    }
  };

  componentWillUnmount() {
    this.client.shutdown();
  }

  createChat = () => {
    const { twilioToken } = this.props;
    Chat.create(twilioToken).then(this.setupChatClient);
  };

  setupChatClient = (client) => {
    const { twilioRoom } = this.props;
    this.client = client;
    this.client
      .getChannelByUniqueName(twilioRoom)
      .then((channel) => {
        this.channel = channel;
        return this.channel.join().catch(() => {});
      })
      .then(() => {
        this.setState({ isLoading: false });
        this.channel.getMessages().then(this.messagesLoaded);
        this.channel.on('messageAdded', this.messageAdded);
      })
      .catch(this.handleError);
  };

  descriptors = (descriptors) => {
    console.log('descriptors', descriptors);
  };

  handleError = (error) => {
    console.error(error);
    this.setState({
      error: 'Could not load chat.'
    });
  };

  handleMessage = (message) => ({
    text: message.body,
    author: { id: message.author, name: message.author },
    timestamp: message.timestamp
  });

  messagesLoaded = (messagePage) => {
    this.setState(
      {
        messages: messagePage.items.map(this.handleMessage)
      },
      this.scrollToBottom
    );
  };

  messageAdded = (message) => {
    const { identity } = this.props;
    this.setState(
      (prevState) => ({
        messages: [...prevState.messages, this.handleMessage(message)]
      }),
      this.newMessage(message.author !== identity)
    );
  };

  newMessage = (playSound) => {
    const { messageSound } = this.state;
    setTimeout(() => {
      this.scrollToBottom();
    }, 50);
    if (playSound) {
      messageSound.play();
    }
  };

  sendMessage = () => {
    const { message } = this.state;
    if (message !== '') {
      this.channel.sendMessage(message);
      this.setState({
        message: ''
      });
    }
  };

  scrollToBottom = () => {
    if (this.messagesEnd !== null) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  };

  setInput = (value) => {
    this.setState({
      message: value
    });
  };

  onEnterPressed = (e) => {
    if (e.charCode === 13) {
      this.sendMessage();
    }
  };

  render() {
    const { classes, identity } = this.props;
    const { error, isLoading, messages, message } = this.state;
    if (error) {
      return <p>{error}</p>;
    }
    if (isLoading) {
      return <p>Loading chat...</p>;
    }
    return (
      <Grid
        className={classes.chatContainer}
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid
          className={classes.chatTitleContainer}
          container
          justify="flex-star"
          alignItems="center"
        >
          <ChatIcon className={classes.chatIcon} />
          <h2 className={classes.chatTitle}>Chat</h2>
        </Grid>

        <Grid
          className={classes.chatLog}
          ref={(ref) => {
            this.chatLog = ref;
          }}
        >
          {messages.map(({ text, author }) => (
            <Bubble
              author={author.name}
              text={text}
              isAuthor={author.name === identity}
            />
          ))}
          <div
            className={classes.messagesEnd}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          />
        </Grid>
        <Grid
          container
          className={classes.inputContainer}
          justify="space-around"
          alignItems="center"
        >
          <input
            type="text"
            value={message}
            onChange={(e) => this.setInput(e.target.value)}
            onKeyPress={this.onEnterPressed}
          />
          <button
            className={`${classes.sendButton} button`}
            type="button"
            onClick={this.sendMessage}
          >
            <img src={sendMessage.src} alt={sendMessage.alt} />
          </button>
        </Grid>
      </Grid>
    );
  }
}

ChatComponent.propTypes = {
  twilioToken: string.isRequired,
  twilioRoom: string.isRequired,
  identity: string.isRequired,
  classes: shape({}).isRequired,
  mobileMenuIsOpen: bool.isRequired,
  readMessages: func.isRequired,
  pendingMessagesToRead: func.isRequired,
  messagesRead: arrayOf(string).isRequired,
  currentTab: number.isRequired,
  privatePanelVisible: bool.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  return {
    language
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ChatComponent));
