const styles = (theme) => ({
  videoChatMobile: {
    height: '100%',
    background: '#FAFAFA',
    boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.15)',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    bottom: 0,
    zIndex: 98
  },
  videosContainer: {
    padding: 8,
    position: 'relative'
  },
  videosMobileContainer: {
    padding: 0,
    position: 'relative',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  hiddenVideosMobileContainer: {
    padding: 8,
    position: 'absolute',
    width: 'calc(100% - 80px)',
    justifyContent: 'space-between'
    // bottom: '-250px'
  },
  remoteMedia: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap'
    }
  },
  clientVideoContainer: {
    width: '28%',
    height: 85,
    position: 'relative',
    borderRadius: 10,
    overflow: 'hidden',
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: 145,
      height: 105,
      margin: 0,
      marginRight: 10
    }
  },
  clientVideo: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    // background: 'rgba(71, 71, 71, 0.69)',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  userVideoContainer: {
    width: 'calc(100% - 40px)',
    position: 'relative',
    height: '100%',
    backgroundColor: 'rgba(71, 71, 71, 0.69)',
    borderRadius: 10,
    overflow: 'hidden'
  },
  userVideo: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: 'rgba(71, 71, 71, 0.69)',
    position: 'relative',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    [theme.breakpoints.down('sm')]: {
      width: 145,
      height: 105,
      marginRight: 10
    }
    // borderRadius: 10,
    // overflow: 'hidden',
    // height: 0,
    // background: 'rgba(71, 71, 71, 0.69)',
    // '& video': {
    //   width: '100%',
    //   height: '100%',
    //   objectFit: 'cover'
    // }
  },
  mobileVideoContainer: {
    width: '100%',
    position: 'relative'
  },
  mobileClientVideoContainer: {
    width: '48%',
    position: 'relative',
    margin: '5px 0',
    [theme.breakpoints.down('sm')]: {
      width: 145,
      height: 105,
      margin: 0,
      marginRight: 10
    }
  },
  mobileVideo: {
    borderRadius: 10,
    overflow: 'hidden',
    height: 110,
    background: 'rgba(71, 71, 71, 0.69)',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    [theme.breakpoints.down('sm')]: {
      height: 105
    }
  },
  initials: {
    position: 'absolute',
    width: 40,
    height: 40,
    borderRadius: 40,
    background: 'rgba(71, 71, 71, 0.69)',
    backdropFilter: 'blur(10px)',
    bottom: 8,
    left: 8,
    '& p': {
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'white',
      margin: 0
    }
  },
  onlyInitials: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 10,
    background: 'rgba(71, 71, 71, 0.69)',
    backdropFilter: 'blur(10px)',
    bottom: 0,
    left: 0,
    '& p': {
      fontSize: 32,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'white',
      margin: 0
    }
  },
  initialsClient: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 22,
    height: 22,
    borderRadius: 22,
    background: 'rgba(71, 71, 71, 0.69)',
    backdropFilter: 'blur(10px)',
    bottom: 4,
    left: 4,
    '& p': {
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'white',
      margin: 0
    }
  },
  onlyInitialsClient: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 10,
    background: 'rgba(71, 71, 71, 0.69)',
    backdropFilter: 'blur(10px)',
    bottom: 0,
    left: 0,
    '& p': {
      fontSize: 32,
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'white',
      margin: 0
    }
  },
  closedVideoChat: {
    background: '#FAFAFA',
    boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.15)',
    height: 40,
    bottom: 0,
    justifyContent: 'flex-end',
    flexDirection: 'row-reverse'
  },
  openVideoChatIcon: {
    transform: 'rotate(180deg)',
    position: 'absolute',
    right: 10
  },
  hiddenVideo: {
    visibility: 'hidden'
  },
  mobileModalContainer: {
    width: '98vw',
    height: '98vh',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    background: '#fafafa',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    outline: 'none',
    padding: 32,
    overflow: 'auto',
    textAlign: 'center',
    '& h2': {
      color: '#ed6b6a',
      fontSize: 19,
      fontWeight: 'bold',
      width: '100%'
    },
    '& .failed': {
      width: 40,
      height: 40
    }
  },
  modalContainer: {
    width: 450,
    height: '50vh',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    background: '#fafafa',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    outline: 'none',
    padding: 32,
    textAlign: 'center',
    '& h2': {
      color: '#ed6b6a',
      fontSize: 19,
      fontWeight: 'bold',
      width: '100%'
    },
    '& .failed': {
      width: 75,
      height: 75
    }
  },
  modalPreviewContainer: {
    width: 624,
    height: 675,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    background: '#fafafa',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    outline: 'none',
    padding: 30,
    [theme.breakpoints.down('lg')]: {
      width: 532,
      height: 575,
      padding: '35px 25px'
    },
    [theme.breakpoints.down('sm')]: {
      width: 388,
      height: 459,
      padding: '20px 15px'
    },
    [theme.breakpoints.down('xs')]: {
      width: 296,
      height: 382,
      padding: '20px 15px'
    },
    '& h2': {
      color: '#ed6b6a',
      fontSize: 19,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'left'
    }
  },
  cameraSelect: {
    marginTop: 0,
    marginBottom: 0,
    justifyContent: 'space-between'
  },
  buttons: {
    width: '100%',
    marginTop: 10,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: 7,
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  previewCameraContainer: {
    // height: '85%'
  },
  previewCamera: {
    height: 'calc(100% - 170px)',
    width: '100%',
    background: 'rgba(71, 71, 71, 0.69)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  buttonAsLinkTop: {
    border: 'none',
    background: 'none',
    padding: '10px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttonAsLinkBottom: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      border: 'none',
      background: 'none',
      padding: '10px 0'
    }
  },
  webcamContainer: {
    marginTop: 10,
    height: 416,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      height: 336
    },
    [theme.breakpoints.down('sm')]: {
      height: 265
    },
    [theme.breakpoints.down('xs')]: {
      height: 194
    },
    '& video': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      transform: 'scale(-1, 1)',
      borderRadius: 10
    }
  },
  flipButton: {
    position: 'absolute',
    left: 10,
    bottom: 20,
    height: 40,
    width: 40,
    background: 'rgba(71, 71, 71, 0.69)',
    backdropFilter: 'blur(10px)',
    '&:focus': {
      outline: 'none'
    }
  },
  joinVideoButtonContainer: {
    width: 235,
    [theme.breakpoints.down('sm')]: {
      width: 330
    },
    [theme.breakpoints.down('xs')]: {
      width: 250
    }
  },
  modalLeaveMeetingContainer: {
    width: 420,
    height: 335,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    background: '#fafafa',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    outline: 'none',
    padding: 32,
    '& h2': {
      color: '#4a4a4a',
      fontSize: 21,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'left'
    },
    '& h3': {
      color: '#6c6c6c',
      fontSize: 21,
      fontWeight: 300,
      width: '100%',
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      width: 287,
      height: 243,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      background: '#fafafa',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: 10,
      outline: 'none',
      padding: 15,
      overflow: 'auto',
      '& h2': {
        color: '#6c6c6c',
        fontSize: 16,
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'left'
      },
      '& h3': {
        color: '#6c6c6c',
        fontSize: 16,
        fontWeight: 300,
        width: '100%',
        textAlign: 'left'
      }
    }
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  cancelButton: {
    color: '#4a4a4a',
    border: 'none',
    width: 90,
    height: 40,
    outline: 'none',
    padding: '6px 0',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#transparent'
    },
    '&:active': {
      backgroundColor: '#transparent',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: '#transparent',
      outline: 'none'
    }
  },
  deleteButton: {
    color: '#C8270C',
    width: 165,
    height: 40,
    outline: 'none',
    padding: '6px 12px',
    fontSize: 14,
    boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.5)',
    borderRadius: 60,
    border: '2px solid #C8270C',
    backgroundColor: 'transparent',
    '&:hover': {
      border: '2px solid #C8270C',
      backgroundColor: 'transparent'
    },
    '&:active': {
      border: '2px solid #C8270C',
      backgroundColor: 'transparent',
      outline: 'none'
    },
    '&:focus': {
      border: '2px solid #C8270C',
      backgroundColor: 'transparent',
      outline: 'none'
    }
  },
  speakerAndButttons: {
    height: 230,
    marginBottom: 8
  },
  hiddenMedia: {
    display: 'none'
  },
  videosReel: {
    height: 120,
    overflowX: 'auto',
    paddingLeft: 15
  },
  chatIcon: {
    fontSize: 24,
    color: '#6C6C6C'
  },
  chatBadgeIndicator: {
    top: 4,
    right: 4,
    width: 6,
    height: 6,
    borderRadius: 6,
    border: 'none',
    position: 'absolute',
    background: '#ED6B6A'
  },
  chatIconContainer: {
    position: 'relative'
  }
});

export default styles;
