import React from 'react';
import { shape, string, bool, func, arrayOf, number } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import themeStyles from '../../styles';

const ClientMobilePrivatePanel = ({
  classes,
  language,
  buyerTabs,
  currentTab,
  mobileMenuIsOpen,
  toggleMobileMenu,
  changeTab
}) => (
  <Grid
    className={
      mobileMenuIsOpen
        ? classes.mobilePrivatePanelContainer
        : classes.hiddenMobilePrivatePanelContainer
    }
  >
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.tabsContainer}
    >
      {buyerTabs.map((tab, index) => (
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          className={currentTab === index ? classes.activeTab : classes.tab}
          onClick={() => {
            changeTab(index);
          }}
        >
          <Grid
            container
            className={
              currentTab === index
                ? classes.activeTabIndicator
                : classes.noTabIndicator
            }
          />
          {tab[language]}
        </Grid>
      ))}
      <Close
        className={classes.privatePanelButton}
        onClick={toggleMobileMenu}
      />
    </Grid>
    {buyerTabs.map((tab, index) => (
      <Grid
        container
        className={
          currentTab === index
            ? classes.selectedTabContentContainer
            : classes.noSelectedTabContentContainer
        }
      >
        {tab.component}
      </Grid>
    ))}
  </Grid>
);

ClientMobilePrivatePanel.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  buyerTabs: arrayOf(shape({})).isRequired,
  currentTab: number.isRequired,
  changeTab: func.isRequired,
  mobileMenuIsOpen: bool.isRequired,
  toggleMobileMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  return {
    language
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(themeStyles)(ClientMobilePrivatePanel));
