import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, arrayOf, shape, func, bool } from 'prop-types';
import ImageMenuItem from '../ImageMenuItem';
import personalizeIcon from '../../../../assets/Icons/icon-personalize-white.svg';
import ThreeSixtyAction from '../../../../stores/threeSixty/actions';
import { getSelectedStyle } from '../../../../selectors/Menu';
import {
  socketIdSelector,
  emitingSelector,
  logSelector,
  publisherSelector
} from '../../../../selectors/Socket';
import SocketActions from '../../../../stores/sockets/actions';

class MobileStylesMenu extends Component {
  componentDidMount() {
    const { socket, publisher } = this.props;
    if (socket !== null && !publisher) {
      socket.on('SET-STYLE', (data) => {
        const { type } = data;
        switch (type) {
          case 'SET-STYLE':
            this.applyStyle(data.style);
            break;

          default:
            break;
        }
      });
    }
  }

  styleChange = (e, style) => {
    const { socket, typeOfLog, isEmiting, socketId: id } = this.props;
    this.applyStyle(style);
    if (isEmiting) {
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'SET-STYLE',
        data: {
          type: 'SET-STYLE',
          style
        }
      });
    }
  };

  applyStyle = async (style) => {
    const { dispatch } = this.props;

    await dispatch(ThreeSixtyAction.updateSpheres(style));

    await dispatch(ThreeSixtyAction.setSelectedStyle(style));

    await dispatch(ThreeSixtyAction.getScenes());
  };

  render() {
    const { stylesMenu, selectedStyle, closeMenu, urls } = this.props;
    return (
      <>
        <div className="mobile-submenu-title">STYLES</div>
        <div
          id="views-mobile-menu"
          className="mobile-submenu views-mobile-menu sub-mobile-menu d-flex justify-content-start align-items-center"
        >
          {stylesMenu.map((option, index) => {
            const { style, type, image } = option;
            return (
              <ImageMenuItem
                key={type}
                type={type}
                keyName={type}
                name={style}
                index={index}
                onClick={this.styleChange}
                img={image}
                selected={selectedStyle}
                closeMenu={closeMenu}
              />
            );
          })}
        </div>
        {urls.test !== 'null' && (
          <div className="mobile-personalize-container d-flex align-items-center">
            <div className="mobile-personalize-intro">
              Take a quick consultation with our AI Interior Designer and
              discover your own style.
            </div>
            <button
              type="button"
              className="mobile-personalize-button d-flex justify-content-center align-items-center"
              onClick={() => {
                if (urls.test !== 'null') window.open(urls.test, '_blank');
              }}
            >
              <img
                src={personalizeIcon}
                alt="Personalize"
                className="mobile-personalize-button-icon"
              />
              Personalize
            </button>
          </div>
        )}
      </>
    );
  }
}

MobileStylesMenu.propTypes = {
  stylesMenu: arrayOf(shape({})).isRequired,
  selectedStyle: string.isRequired,
  closeMenu: func.isRequired,
  urls: shape({}).isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired,
  isEmiting: bool.isRequired,
  socketId: string.isRequired,
  typeOfLog: string.isRequired,
  publisher: bool.isRequired
};

const mapStateToProps = (state) => {
  const { menu: stylesMenu, urls, threeSixty } = state.threeSixty;
  return {
    stylesMenu,
    selectedStyle: getSelectedStyle(state),
    urls,
    threeSixty,
    socketId: socketIdSelector(state),
    isEmiting: emitingSelector(state),
    typeOfLog: logSelector(state),
    publisher: publisherSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileStylesMenu);
