import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, func, bool } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import { closeIcon } from '../../config/assets';
import {
  yesDelete,
  areYouSure,
  youWontBeAble,
  noGoBack
} from '../../config/messages';

class DeleteCalcModal extends Component {
  deleteCalc = async () => {
    const { deleteCalcFunc } = this.props;
    deleteCalcFunc();
  };

  render() {
    const { classes, language, deleteCalc, hideDeleteModal } = this.props;
    return (
      <Modal open={deleteCalc} onClose={hideDeleteModal}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={`${classes.modalContainer} calc`}
        >
          <img
            src={closeIcon.src}
            alt={closeIcon.alt}
            className={classes.closeIcon}
            onClick={this.hideDeleteModal}
          />
          <h2>{areYouSure[language]}</h2>
          <p>{youWontBeAble[language]}</p>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <button
              type="button"
              className={classes.buttonAsLinkTop}
              onClick={hideDeleteModal}
            >
              {noGoBack[language]}
            </button>
            <button
              type="button"
              className={classes.deleteButtonCalc}
              onClick={this.deleteCalc}
            >
              {yesDelete[language]}
            </button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

DeleteCalcModal.propTypes = {
  classes: shape({}).isRequired,
  deleteCalc: bool.isRequired,
  language: string.isRequired,
  hideDeleteModal: func.isRequired,
  deleteCalcFunc: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  return {
    language
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DeleteCalcModal));
