export const formatNumber = (n) =>
  n
    .toString()
    .replace(/[^0-9.]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getFilteredFloorplans = (
  prices,
  searchValue,
  bedroomsFilter,
  bathroomsFilter,
  parkingFilter
) => {
  let filteredFloorplans = prices;
  if (searchValue !== '') {
    filteredFloorplans = filteredFloorplans.filter((el) => {
      const floorplanName = el.name.toLowerCase();
      return floorplanName.includes(searchValue.toLowerCase());
    });
  }
  if (bedroomsFilter !== '') {
    filteredFloorplans = filteredFloorplans.filter(
      (el) => el.bedrooms === bedroomsFilter
    );
  }
  if (bathroomsFilter !== '') {
    filteredFloorplans = filteredFloorplans.filter(
      (el) => el.bathrooms === bathroomsFilter
    );
  }
  if (parkingFilter !== '') {
    filteredFloorplans = filteredFloorplans.filter(
      (el) => el.parking === parkingFilter
    );
  }
  return filteredFloorplans;
};

export const getAreaLabel = (language, floorplan) => {
  let area = '-';
  if (language === 'en') {
    if (floorplan.sqftArea) {
      area = `${floorplan.sqftArea} sqft`;
    } else if (floorplan.mtsArea) {
      area = `${floorplan.mtsArea} m2`;
    }
  } else if (language === 'es') {
    if (floorplan.mtsArea) {
      area = `${floorplan.mtsArea} m2`;
    } else if (floorplan.sqftArea) {
      area = `${floorplan.sqftArea} sqft`;
    }
  }
  return area;
};

export const sortOptions = (prices, property) =>
  [...new Set(prices.map((el) => el[property]))].sort((a, b) => a - b);
