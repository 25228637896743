export const logo =
  'https://virtual-sales-portal-assets.s3-us-west-2.amazonaws.com/atHum-logo.svg';
export const contractedLogo =
  'https://dev-developers-assets.athum.com/0ddbd0be-bd9f-418d-a3c3-e4942517e388/20/sections/home/logoContrated.png';
export const builderId = '2793b14b-de1f-49fd-a097-bdeee72eb4f6';
export const propertyId = 14;
export const layoutName = 'Tennyson02';
export const levelMenu = {
  bottom: '81px',
  right: '23px'
};
export const takeTestUri = 'https://demowebapp.athum.com/registration';
export const registerUri = 'https://demowebapp.athum.com/registration';
export const rotationMessage =
  'Turn your phone into landscape mode for a better expirience.';
export const shopCar = {
  title: 'Furniture on the Scene:',
  show: true
};
export const lang = 'es';
export const autoPlay = false;
export const autoPlayInterval = 10000;
export const showInstructions = false;

export const mapSize = {
  width: 420,
  height: 220
};
export const mapSizeDesktop = {
  width: 633.33,
  height: 386.66
};
