import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { shape, string, bool, func } from 'prop-types';
import {
  withStyles,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import SalesMenu from '../components/Menu/SalesMenu';
import ClientMenu from '../components/Menu/ClientMenu';
import styles from '../styles/noTheme';
import { isSmallDesktop } from '../utils';

class PageWrapper extends Component {
  constructor() {
    super();
    this.state = {
      openMenu: !isSmallDesktop()
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ openMenu: !prevState.openMenu }));
  };

  render() {
    const {
      RouteComponent,
      menu,
      cookies,
      classes,
      token,
      historyComp,
      location,
      match,
      theme,
      socket,
      checkUserInSession,
      isCustomer
    } = this.props;
    const { openMenu } = this.state;
    const projectTheme = createMuiTheme({
      leftMenu: theme.leftMenu,
      rightMenu: theme.rightMenu,
      content: theme.content,
      fontFamily: theme.fontFamily,
      colors: theme.colors
    });
    const fontFamily = {
      Roboto: `'Roboto', sans-serif`,
      'Nunito Sans': `'Nunito Sans', sans-serif`,
      'Titillium Web': `'Titillium Web', sans-serif`,
      Dosis: `'Dosis', sans-serif`,
      Tinos: `'Tinos', serif`
    };
    const withMenuContainer = openMenu
      ? classes.withMenuContainer
      : classes.withCollapsedMenuContainer;
    return (
      <ThemeProvider theme={projectTheme}>
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
          className={classes.pageWrapperContainer}
          style={{ fontFamily: fontFamily[theme.fontFamily] }}
        >
          {menu && (token || isCustomer) && (
            <SalesMenu
              history={historyComp}
              cookies={cookies}
              socket={socket}
              openMenu={openMenu}
              toggleMenu={this.toggleMenu}
            />
          )}
          {menu && !token && !isCustomer && (
            <ClientMenu
              history={historyComp}
              cookies={cookies}
              socket={socket}
              openMenu={openMenu}
              toggleMenu={this.toggleMenu}
            />
          )}
          <Grid className={menu ? withMenuContainer : classes.noMenuContainer}>
            <RouteComponent
              cookies={cookies}
              history={historyComp}
              location={location}
              match={match}
              socket={socket}
              checkUserInSession={checkUserInSession}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

PageWrapper.propTypes = {
  RouteComponent: shape({}).isRequired,
  menu: bool.isRequired,
  cookies: shape({}).isRequired,
  classes: shape({}).isRequired,
  token: string.isRequired,
  historyComp: shape({}).isRequired,
  location: shape({}).isRequired,
  match: shape({}).isRequired,
  theme: shape({}).isRequired,
  socket: shape({}).isRequired,
  checkUserInSession: func.isRequired,
  isCustomer: bool.isRequired
};

const mapStateToProps = (state) => {
  const { theme } = state.project;
  return {
    theme
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(PageWrapper));
