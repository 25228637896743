import React, { Component } from 'react';
import { arrayOf, shape, number, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getParentNodeId, calculateBackgroundWidth } from '../../utils';
import styles from './styles';
import { socketEmit } from '../../services/socket';

class ListWithIcon extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: '' };
  }

  componentDidMount() {
    const { socket } = this.props;
    socket.on('LIST-WITH-ICON-ITEM', this.onSelectedEvent);
  }

  onItemSelected = (e) => {
    e.preventDefault();
    const selected = e.target.id || getParentNodeId(e.target);
    const selectedWidth = e.target.id
      ? calculateBackgroundWidth(e.target)
      : calculateBackgroundWidth(e.target.parentNode);
    const { socket, meetingId, publisher, isCustomer, sessionID } = this.props;
    if (publisher || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      this.setState({ selected });
      socketEmit(socket, type, {
        id,
        event: 'LIST-WITH-ICON-ITEM',
        data: {
          type: 'LIST-WITH-ICON-ITEM',
          selected,
          selectedWidth
        }
      });
      this.resetSelected();
    }
  };

  onSelectedEvent = (data) => {
    const { selected } = data;
    this.setState({ selected });
    this.resetSelected();
  };

  resetSelected = () => {
    setTimeout(() => {
      this.setState({ selected: '' });
    }, 2000);
  };

  render() {
    const {
      classes,
      content,
      columns,
      callOut,
      publisher,
      isCover
    } = this.props;
    const { selected } = this.state;
    let listClass = classes.listContainer;
    if (isCover) {
      listClass = classes.coverListContainer;
    } else if (callOut) {
      listClass = classes.cardListContainer;
    }
    let iconListClass = 'list-icon';
    if (isCover) {
      iconListClass = classes.coverListIcon;
    } else if (callOut) {
      iconListClass = classes.cardListIcon;
    }
    return (
      <Grid container className={listClass} direction="row">
        {content.map(({ text, icon, width = null }, index) => {
          const key = `p-${index * 1}`;
          return (
            <Grid
              container
              key={key}
              id={key}
              style={{
                width: width || `calc(${100 / columns}% - 1%)`
              }}
              className={`list-element ${publisher ? classes.pointer : ''}`}
              onClick={this.onItemSelected}
            >
              <img src={icon} alt={text} className={iconListClass} />
              <p className={selected === key ? 'selected' : ''}>{`${text} `}</p>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

ListWithIcon.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  meetingId: string.isRequired,
  publisher: string.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired,
  columns: number,
  callOut: bool,
  isCover: bool
};

ListWithIcon.defaultProps = {
  columns: 1,
  callOut: false,
  isCover: false
};

export default withStyles(styles)(ListWithIcon);
