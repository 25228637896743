import React from 'react';
import './ImageContainerCard.css';

const ImageContainerCard = () => (
  <div className="image-container-card">
    <div className="gradient element1 d-flex justify-content-center align-items-end">
      <div className="image_title_card gradient element2 d-flex justify-content-start align-items-center">
        <div className="gradient element3" />
      </div>
    </div>
  </div>
);

export default ImageContainerCard;
