import React from 'react';
import { shape, string, arrayOf, number, func } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import styles from '../../../styles';
import { backButton } from '../../../config/messages';

const SelectedLayoutHeader = ({
  classes,
  subSections,
  selectedLayout,
  language,
  selectedTab,
  unselectLayout
}) => {
  const containsTour = subSections.filter(({ type }) => type === 'tour');
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.buttonsContainer}
    >
      <Grid
        container
        alignItems="center"
        className={classes.backAndTitleContainer}
      >
        <KeyboardArrowLeftIcon
          className={classes.icon}
          onClick={() => unselectLayout(null)}
        />
        <h1 className={classes.title}>
          {containsTour.length > 0 && selectedTab + 1 === containsTour[0].order
            ? selectedLayout.title
            : backButton[language]}
        </h1>
      </Grid>
    </Grid>
  );
};

SelectedLayoutHeader.propTypes = {
  classes: shape({}).isRequired,
  subSections: arrayOf(shape({})).isRequired,
  selectedLayout: shape({}),
  language: string.isRequired,
  selectedTab: number.isRequired,
  unselectLayout: func.isRequired
};

SelectedLayoutHeader.defaultProps = {
  selectedLayout: null
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { selectedLayout } = state.project;
  return {
    selectedLayout,
    language
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SelectedLayoutHeader));
