import {
  VIRTUAL_SALES_API,
  VIRTUAL_SALES_API_KEY
} from '../../../config/endpoints';
import HttpUtility from '../../../utilities/HttpUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import SectionsModel from '../models';

export default class SessionEffect {
  static async getSections(language, token, propertyId, propertiesSections) {
    const endpoint = `${VIRTUAL_SALES_API}/v2/${language}/sales-person/projects/${propertyId}/sections`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(
      response.data,
      propertyId,
      propertiesSections
    );

    return model;
  }

  static async getSectionsByProjectIdForClient(
    language,
    propertyId,
    propertiesSections
  ) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/customers/projects/${propertyId}/sections`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(
      response.data,
      propertyId,
      propertiesSections
    );

    return model;
  }

  static async getSectionsForClient(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/v2/${language}/buyer-person/meetings/${meetingId}/sections`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }

  static async getClientProjectSections(
    language,
    propertyId,
    propertiesSections
  ) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/buyer-person/projects/${propertyId}/sections`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(
      response.data,
      propertyId,
      propertiesSections
    );

    return model;
  }

  static async getSectionsForClientNavigation(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/customers/meetings/${meetingId}/sections`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }

  static async getProjects(language, token) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/projects`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }

  static async getClientProjects(language, builderId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/builder/${builderId}/projects`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }

  static async getBuilderInfo(language, builder) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/builders/${builder}`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }

  static async getLayoutInfo(
    language,
    token,
    layoutId,
    selectedPage,
    selectedTab
  ) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/sales-person/layouts/${layoutId}`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return {
      selectedLayout: model.selectedLayout,
      selectedPage,
      selectedTab
    };
  }

  static async getPrices(language, token, projectId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/project/${projectId}/prices`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }

  static async updatePrices(language, token, projectId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/project/${projectId}/prices`;
    const response = await HttpUtility.put(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new SectionsModel(response.data);

    return model;
  }
}
