import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, func } from 'prop-types';
import { Grid, FormControl, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import SessionAction from '../stores/session/actions';
import { logo } from '../config/customization';
import styles from '../styles';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const { params } = match;
    const { mail = '' } = params;
    const parsedUrl = queryString.parse(window.location.search);
    const { continueUrl = '', oobCode = '', email = '' } = parsedUrl;
    const redirectUrl = continueUrl;
    this.state = {
      mail: mail || email,
      newPassword: '',
      code: oobCode || '',
      redirectUrl,
      passwordChanged: false
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { mail, newPassword, code } = this.state;
    const actionResponse = await dispatch(
      SessionAction.resetPassword(mail, newPassword, code)
    );
    const { isError } = actionResponse;
    if (!isError) {
      this.setState({ passwordChanged: true });
    }
  };

  continue = () => {
    const { redirectUrl } = this.state;
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      this.login();
    }
  };

  login = async () => {
    const { cookies, dispatch } = this.props;
    const { mail, password } = this.state;
    const actionResponse = await dispatch(
      SessionAction.login(mail, password, cookies)
    );
    const { isError } = actionResponse;
    if (!isError) {
      this.goToLobby();
    }
  };

  goToLobby = () => {
    const { history } = this.props;
    history.push('/');
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  render() {
    const { mail, newPassword, code, passwordChanged } = this.state;
    const { classes, error } = this.props;
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.forgotPasswordMainContainer}
      >
        <img src={logo} alt="Logo" style={{ margin: '40px 0 10% 0' }} />
        {passwordChanged ? (
          <div className={classes.forgotPasswordFormContainer}>
            <p style={{ textAlign: 'center', width: '318px' }}>
              Password has been changed
            </p>
            <CustomButton type="button" onClick={this.continue}>
              Continue
            </CustomButton>
          </div>
        ) : (
          <form
            className={classes.forgotPasswordFormContainer}
            onSubmit={this.handleSubmit}
            noValidate
            autoComplete="off"
          >
            {error ? (
              <FormHelperText className={classes.errorMessage}>
                {error}
              </FormHelperText>
            ) : (
              <FormHelperText className={classes.errorMessage} />
            )}
            <FormControl>
              <CustomLabel htmlFor="mail">Email</CustomLabel>
              <CustomInput
                id="mail"
                type="mail"
                value={mail}
                onChange={this.handleInputChange}
                autoComplete="new-password"
              />
            </FormControl>
            <FormControl>
              <CustomLabel htmlFor="code">Verification Code</CustomLabel>
              <CustomInput
                id="code"
                type="text"
                value={code}
                onChange={this.handleInputChange}
              />
            </FormControl>
            <FormControl>
              <CustomLabel htmlFor="newPassword">New Password</CustomLabel>
              <CustomInput
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={this.handleInputChange}
                autoComplete="new-password"
              />
            </FormControl>
            <CustomButton type="submit">Reset Password</CustomButton>
          </form>
        )}
      </Grid>
    );
  }
}

ResetPasswordPage.propTypes = {
  history: shape({}).isRequired,
  cookies: shape({}).isRequired,
  match: shape({}).isRequired,
  classes: shape({}).isRequired,
  error: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { message: error } = state.error;
  return {
    language,
    error
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetPasswordPage));
