export const TEST_VIRTUAL_SALES_API = 'https://dev-remote-sales-api.athum.com';
// export const PROD_VIRTUAL_SALES_API = 'https://remote-sales-api.athum.com';
export const PROD_VIRTUAL_SALES_API =
  'https://us-west2-avria-production.cloudfunctions.net/rso_api/prod/v1';
export const STAGING_VIRTUAL_SALES_API =
  'https://staging-remote-sales-api.athum.com';
const api = {
  production: PROD_VIRTUAL_SALES_API,
  development: TEST_VIRTUAL_SALES_API,
  staging: STAGING_VIRTUAL_SALES_API
};
export const VIRTUAL_SALES_API = api[process.env.REACT_APP_ENV];
// export const API_KEY_TEST = 'sP5qB7ErVj5uFDnt1rged6JNfl6F6Ms433SCg3w0';
export const API_KEY_TEST = 'IJReiO3PuB6GJBi0aiXU06Q7dDILLnXj8ZFoiduC';
export const API_KEY_PROD = 'xFE7OlnGRX9xdVaiubEUZ4M3vVoDVoVE8qf8KbzC';
export const API_KEY_STAGING = 'DtVLifqxt263mu9FoGfmWYTqsNKaq7KaVvpr1Ws4';
const apiKey = {
  production: API_KEY_PROD,
  development: API_KEY_TEST,
  staging: API_KEY_STAGING
};
export const VIRTUAL_SALES_API_KEY = apiKey[process.env.REACT_APP_ENV];
// 360
export const webAppApiProd = 'https://webapp-api.athum.co';
export const THREE_SIXTY_API_DEV = 'https://test-360-api.athum.co/';
// export const THREE_SIXTY_API_PROD = 'https://360-api.athum.com/v3/';
export const THREE_SIXTY_API_PROD =
  'https://us-west2-avria-production.cloudfunctions.net/three_sixty_api/prod/v1/';
export const THREE_SIXTY_API_STAGING = 'https://360-api.athum.com/v3/';
const api360 = {
  production: THREE_SIXTY_API_PROD,
  development: THREE_SIXTY_API_DEV,
  staging: THREE_SIXTY_API_STAGING
};
export const THREE_SIXTY_API = api360[process.env.REACT_APP_ENV];
export const THREE_SIXTY_API_PROD_KEY =
  'tHcR7YBjJG4Ty9I0IYEVU4ejEotjYksb1uFC0wbr';
export const THREE_SIXTY_API_DEV_KEY =
  'UGDB9Sr4mR2IzN532KZW07uA5tAraS0T6Ah9QPNr';
export const THREE_SIXTY_API_STAGING_KEY =
  'tHcR7YBjJG4Ty9I0IYEVU4ejEotjYksb1uFC0wbr';
const apiKey360 = {
  production: THREE_SIXTY_API_PROD_KEY,
  development: THREE_SIXTY_API_DEV_KEY,
  staging: THREE_SIXTY_API_STAGING_KEY
};
export const THREE_SIXTY_API_KEY = apiKey360[process.env.REACT_APP_ENV];
export const X_API_DEV_KEY = 'RVSTgA7lWk3hFLgka7j68S2moU5zmzA5OkT0Qmr8';
export const X_API_PROD_KEY = 'f4SkXvQiyq2kGtKvaBP3b1OHcWROXreG6Vq6wO7e';
export const X_API_STAGING_KEY = 'f4SkXvQiyq2kGtKvaBP3b1OHcWROXreG6Vq6wO7e';
const xApiKey = {
  production: X_API_PROD_KEY,
  development: X_API_DEV_KEY,
  staging: X_API_STAGING_KEY
};
export const X_API_KEY = xApiKey[process.env.REACT_APP_ENV];
export const SOCKET_TEST_API = 'https://virtualsalesapp.azurewebsites.net';
// export const SOCKET_PROD_API = 'https://prod-athum-rso-ws.azurewebsites.net/';
export const SOCKET_PROD_API = 'https://prod-rso-websocket.azurewebsites.net/';
export const SOCKET_STAGING_API =
  'https://staging-athum-rso-ws.azurewebsites.net';
const socket = {
  production: SOCKET_PROD_API,
  development: SOCKET_TEST_API,
  staging: SOCKET_STAGING_API
};
export const SOCKET = socket[process.env.REACT_APP_ENV];
export const TEST_GOOGLE_API = 'AIzaSyApQ3-oto9pwPQsH8e_gfV4au1FdNiYFGw';
export const PROD_GOOGLE_API = 'AIzaSyApQ3-oto9pwPQsH8e_gfV4au1FdNiYFGw';
export const STAGING_GOOGLE_API = 'AIzaSyApQ3-oto9pwPQsH8e_gfV4au1FdNiYFGw';
const googleApi = {
  production: PROD_GOOGLE_API,
  development: TEST_GOOGLE_API,
  staging: STAGING_GOOGLE_API
};
export const GOOGLE_API = googleApi[process.env.REACT_APP_ENV];

export const VERSION = process.env.REACT_APP_VERSION;

export const WEBAPP_API_DEV = 'https://test-webapp-api.athum.co';

export const WEBAPP_API_PROD = 'https://webapp-api.athum.co';

export const WEBAPP_API =
  process.env.REACT_APP_ENV === 'production' ? WEBAPP_API_PROD : WEBAPP_API_DEV;

export const WEBAPP_API_KEY_DEV = 'f4SkXvQiyq2kGtKvaBP3b1OHcWROXreG6Vq6wO7e';

export const WEBAPP_API_KEY_PROD = 'RVSTgA7lWk3hFLgka7j68S2moU5zmzA5OkT0Qmr8';

export const WEBAPP_API_KEY =
  process.env.REACT_APP_ENV === 'production'
    ? WEBAPP_API_KEY_PROD
    : WEBAPP_API_KEY_DEV;
