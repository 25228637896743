const styles = () => ({
  numberColorNameMenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  numberColorNameSubmenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  numberNameMenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer',
    backgroundColor: '#ededed'
  },
  numberNameSubmenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer',
    backgroundColor: '#ededed'
  },
  colorNameMenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  colorNameSubmenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  iconNameMenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  iconNameSubmenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  iconColorNameMenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  },
  iconColorNameSubmenuItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    fontSize: 10,
    marginRight: 12,
    cursor: 'pointer'
  }
});

export default styles;
