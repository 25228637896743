import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, bool, arrayOf, func, shape } from 'prop-types';
import MobileMenuOptions from './MobileMenuOptions';
import MobileMenuButton from './MobileMenuButton';
import MobileSubMenu from './MobileSubMenu';
import closeIcon from '../../../../assets/Icons/icon-close.svg';
import '../Menu.scss';
import ThreeSixtyAction from '../../../../stores/threeSixty/actions';
import SocketActions from '../../../../stores/sockets/actions';
import { isPreview } from '../../../../utils';
import {
  isPortraitSelector,
  menuOptionSelector,
  menuOptionsSelector
} from '../../../../selectors/Menu';
import { errorSelector } from '../../../../selectors/Error';
import { loadingSelector } from '../../../../selectors/Loading';
import {
  socketIdSelector,
  emitingSelector,
  logSelector,
  publisherSelector
} from '../../../../selectors/Socket';

class MobileMenu extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false
    };
  }

  componentDidMount() {
    const { socket, dispatch, publisher } = this.props;
    if (socket !== null && !publisher) {
      socket.on('THREE-MENU', (data) => {
        const { type } = data;
        console.log(type);
        switch (type) {
          case 'THREE-MENU-SELECTED':
            this.setState({
              menuOpen: data.menuOpen
            });
            dispatch(
              ThreeSixtyAction.setSelectedMenuOption(data.selectedMenuOption)
            );
            break;
          default:
            break;
        }
      });
    }
  }

  openMenu = () => {
    const { socket, typeOfLog, isEmiting, socketId: id } = this.props;
    this.setState({ menuOpen: true });
    if (isEmiting) {
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'THREE-MENU',
        data: {
          type: 'THREE-MENU-SELECTED',
          selectedMenuOption: '',
          menuOpen: true
        }
      });
    }
  };

  closeMenu = () => {
    const { dispatch, socket, typeOfLog, isEmiting, socketId: id } = this.props;
    this.setState({ menuOpen: false });
    dispatch(ThreeSixtyAction.setSelectedMenuOption(''));
    if (isEmiting) {
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'THREE-MENU',
        data: {
          type: 'THREE-MENU-SELECTED',
          selectedMenuOption: '',
          menuOpen: false
        }
      });
    }
  };

  onSelectedMenuOption = (selectedMenuOption) => {
    const {
      dispatch,
      selectedMenuOption: stateSelectedMenuOption,
      isEmiting,
      socketId: id,
      socket,
      typeOfLog
    } = this.props;

    if (
      stateSelectedMenuOption === selectedMenuOption ||
      (selectedMenuOption === '' && isEmiting)
    ) {
      dispatch(ThreeSixtyAction.setSelectedMenuOption(''));
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'THREE-MENU',
        data: {
          type: 'THREE-MENU-SELECTED',
          selectedMenuOption: '',
          menuOpen: false
        }
      });
    } else if (isEmiting) {
      dispatch(
        ThreeSixtyAction.setSelectedMenuOption(`mobile-${selectedMenuOption}`)
      );
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'THREE-MENU',
        data: {
          type: 'THREE-MENU-SELECTED',
          selectedMenuOption: `mobile-${selectedMenuOption}`,
          menuOpen: true
        }
      });
    }
  };

  render() {
    const {
      loading,
      error,
      menuOptionsFiltered,
      selectedMenuOption,
      socket
    } = this.props;
    const { menuOpen } = this.state;
    return (
      <>
        <div
          className={`mobile-menu-container ${
            (loading || error || isPreview() || !menuOpen) && 'display-none'
          }`}
        >
          <div className="close-menubutton">
            <img
              src={closeIcon}
              alt="close"
              style={{ width: '100%', height: '100%' }}
              onClick={() => {
                this.closeMenu();
              }}
            />
          </div>
          <nav className="mobile-menu d-flex justify-content-center align-items-center">
            {menuOptionsFiltered.map((option, index) => (
              <MobileMenuOptions
                key={`${index.toString()}-option`}
                i={index}
                active={`mobile-${option}` === selectedMenuOption}
                type={option}
                click={this.onSelectedMenuOption}
              />
            ))}
          </nav>
          <MobileSubMenu closeMenu={this.closeMenu} socket={socket} />
        </div>
        {!menuOpen && !loading && <MobileMenuButton openMenu={this.openMenu} />}
      </>
    );
  }
}

MobileMenu.propTypes = {
  loading: bool.isRequired,
  selectedMenuOption: string.isRequired,
  error: string.isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired,
  isEmiting: bool.isRequired,
  socketId: string.isRequired,
  typeOfLog: string.isRequired,
  publisher: bool.isRequired,
  menuOptionsFiltered: arrayOf(string)
};

MobileMenu.defaultProps = {
  menuOptionsFiltered: []
};

const mapStateToProps = (state) => ({
  menuOptionsFiltered: menuOptionsSelector(state),
  showTabletPortrait: isPortraitSelector(),
  selectedMenuOption: menuOptionSelector(state),
  error: errorSelector(state),
  loading: loadingSelector(state),
  socketId: socketIdSelector(state),
  isEmiting: emitingSelector(state),
  typeOfLog: logSelector(state),
  publisher: publisherSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
