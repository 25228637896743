import React from 'react';
import { shape, string, bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Bubble = ({ classes, author, text, isAuthor }) => (
  <Grid
    container
    alignItems="center"
    direction={!isAuthor ? 'flex-start' : 'row-reverse'}
  >
    <Grid className={!isAuthor ? classes.lightGreen : classes.gray}>
      <p className={classes.author}>{author}</p>
      <p>{text}</p>
    </Grid>
  </Grid>
);

Bubble.propTypes = {
  classes: shape({}).isRequired,
  author: string.isRequired,
  text: string.isRequired,
  isAuthor: bool.isRequired
};

export default withStyles(styles)(Bubble);
