import ActionUtility from '../../../utilities/ActionUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import MeetingEffect from '../effects';

export default class MeetingAction {
  static CREATE_MEETING_REQUEST = 'CREATE_MEETING_REQUEST';

  static CREATE_MEETING_REQUEST_FINISHED = 'CREATE_MEETING_REQUEST_FINISHED';

  static LIST_MEETINGS_REQUEST = 'LIST_MEETINGS_REQUEST';

  static LIST_MEETINGS_REQUEST_FINISHED = 'LIST_MEETINGS_REQUEST_FINISHED';

  static START_MEETING_REQUEST = 'START_MEETING_REQUEST';

  static START_MEETING_REQUEST_FINISHED = 'START_MEETING_REQUEST_FINISHED';

  static JOIN_MEETING_REQUEST = 'JOIN_MEETING_REQUEST';

  static JOIN_MEETING_REQUEST_FINISHED = 'JOIN_MEETING_REQUEST_FINISHED';

  static GET_TWILIO_TOKEN_REQUEST = 'GET_TWILIO_TOKEN_REQUEST';

  static GET_TWILIO_TOKEN_REQUEST_FINISHED =
    'GET_TWILIO_TOKEN_REQUEST_FINISHED';

  static DELETE_MEETING_REQUEST = 'DELETE_MEETING_REQUEST';

  static DELETE_MEETING_REQUEST_FINISHED = 'DELETE_MEETING_REQUEST_FINISHED';

  static FINISH_MEETING_REQUEST = 'FINISH_MEETING_REQUEST';

  static FINISH_MEETING_REQUEST_FINISHED = 'FINISH_MEETING_REQUEST_FINISHED';

  static CHECK_MEETING_STATUS_REQUEST = 'CHECK_MEETING_STATUS_REQUEST';

  static CHECK_MEETING_STATUS_REQUEST_FINISHED =
    'CHECK_MEETING_STATUS_REQUEST_FINISHED';

  static LEAVE_MEETING_REQUEST = 'LEAVE_MEETING_REQUEST';

  static LEAVE_MEETING_REQUEST_FINISHED = 'LEAVE_MEETING_REQUEST_FINISHED';

  static REFRESH_CREATED_MEETING = 'REFRESH_CREATED_MEETING';

  static DISCONNECT_MEETING = 'DISCONNECT_MEETING';

  static ONBOARDING_REQUEST = 'ONBOARDING_REQUEST';

  static ONBOARDING_REQUEST_FINISHED = 'ONBOARDING_REQUEST_FINISHED';

  static SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';

  static SEND_EMAIL_REQUEST_FINISHED = 'SEND_EMAIL_REQUEST_FINISHED';

  static GET_CUSTOMER_MEETING_REQUEST = 'GET_CUSTOMER_MEETING_REQUEST';

  static GET_CUSTOMER_MEETING_REQUEST_FINISHED =
    'GET_CUSTOMER_MEETING_REQUEST_FINISHED';

  static CLIENT_LOG_REQUEST = 'CLIENT_LOG_REQUEST';

  static CLIENT_LOG_REQUEST_FINISHED = 'CLIENT_LOG_REQUEST_FINISHED';

  static SET_PUBLISHER = 'SET_PUBLISHER';

  static SAVE_CALCULATOR_INFO_REQUEST = 'SAVE_CALCULATOR_INFO_REQUEST';

  static SAVE_CALCULATOR_INFO_REQUEST_FINISHED =
    'SAVE_CALCULATOR_INFO_REQUEST_FINISHED';

  static SET_SHOW_TABLE_FINISHED = 'SET_SHOW_TABLE_FINISHED';

  static createMeeting(body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.CREATE_MEETING_REQUEST,
        MeetingEffect.createMeeting,
        true,
        language,
        token,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static listMeetings(body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.LIST_MEETINGS_REQUEST,
        MeetingEffect.listMeetings,
        true,
        language,
        token,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static startMeeting(meetingId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.START_MEETING_REQUEST,
        MeetingEffect.startMeeting,
        true,
        language,
        token,
        meetingId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static joinMeeting(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.JOIN_MEETING_REQUEST,
        MeetingEffect.joinMeeting,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getTwilioToken(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.GET_TWILIO_TOKEN_REQUEST,
        MeetingEffect.getTwilioToken,
        true,
        language,
        token,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getBuyerTwilioToken(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.GET_TWILIO_TOKEN_REQUEST,
        MeetingEffect.getBuyerTwilioToken,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static deleteMeeting(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.DELETE_MEETING_REQUEST,
        MeetingEffect.deleteMeeting,
        true,
        language,
        token,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static finishMeeting(meetingId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.FINISH_MEETING_REQUEST,
        MeetingEffect.finishMeeting,
        true,
        language,
        token,
        meetingId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static checkMeetingStatus(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.CHECK_MEETING_STATUS_REQUEST,
        MeetingEffect.checkMeetingStatus,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static leaveMeeting(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.LEAVE_MEETING_REQUEST,
        MeetingEffect.leaveMeeting,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static refreshCreatedMeeting() {
    return ActionUtility.createAction(
      MeetingAction.REFRESH_CREATED_MEETING,
      ''
    );
  }

  static getBuyerTwilioTokenV2(meetingId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage, session: stateSession } = getState();
      const { language } = stateLanguage;
      const { token } = stateSession;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.GET_TWILIO_TOKEN_REQUEST,
        MeetingEffect.getBuyerTwilioTokenV2,
        true,
        language,
        token,
        meetingId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static meetingDisconnect() {
    return ActionUtility.createAction(MeetingAction.DISCONNECT_MEETING, '');
  }

  static onboarding(meetingId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.ONBOARDING_REQUEST,
        MeetingEffect.onboarding,
        true,
        language,
        meetingId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static sendEmail(meetingId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.SEND_EMAIL_REQUEST,
        MeetingEffect.sendEmail,
        true,
        language,
        meetingId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static sendEmailReschedule(meetingId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.SEND_EMAIL_REQUEST,
        MeetingEffect.sendEmailReschedule,
        true,
        language,
        meetingId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static getCustomerMeeting(meetingId, customerId) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.GET_CUSTOMER_MEETING_REQUEST,
        MeetingEffect.getCustomerMeeting,
        true,
        language,
        meetingId,
        customerId
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static clientLog(customerId, body) {
    return async (dispatch, getState) => {
      const { language: stateLanguage } = getState();
      const { language } = stateLanguage;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.CLIENT_LOG_REQUEST,
        MeetingEffect.clientLog,
        true,
        language,
        customerId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static setPublisher(publisher) {
    return ActionUtility.createAction(MeetingAction.SET_PUBLISHER, {
      publisher
    });
  }

  static saveMxCalculatorData() {
    return async (dispatch, getState) => {
      const {
        language: stateLanguage,
        session: stateSession,
        project,
        meeting
      } = getState();
      const { language } = stateLanguage;
      const { calculators } = project;
      const { token } = stateSession;
      const { activeMeeting } = meeting;
      const body = {
        priceUnits: calculators
      };
      const meetingId = activeMeeting.id;
      console.log('action', language, token, activeMeeting, body);
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        MeetingAction.SAVE_CALCULATOR_INFO_REQUEST,
        MeetingEffect.saveMxCalculator,
        false,
        language,
        token,
        meetingId,
        body
      );
      const isError = model instanceof HttpErrorResponseModel;
      return { model, isError };
    };
  }

  static setShowTable(showTable) {
    return ActionUtility.createAction(
      MeetingAction.SET_SHOW_TABLE_FINISHED,
      showTable
    );
  }
}
