/* eslint-disable class-methods-use-this */
import SessionAction from '../actions';
import BaseReducer from '../../../utilities/BaseReducer';

export default class SessionReducer extends BaseReducer {
  initialState = {
    loading: false,
    token: '',
    user: {},
    email: '',
    refreshToken: '',
    authTime: '',
    expirationTime: 0,
    message: '',
    sessionID: ''
  };

  [SessionAction.LOGIN_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      token: action.payload.token,
      user: action.payload.user
    };
  }

  [SessionAction.LOGOUT](state) {
    return {
      ...state,
      ...this.initialState
    };
  }

  [SessionAction.CHANGE_PASSWORD_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      message: action.payload
    };
  }

  [SessionAction.CUSTOMER_SESSION_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      sessionID: action.payload
    };
  }
}
