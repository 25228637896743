import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { shape, string, bool, func } from 'prop-types';
import { Grid, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/es';
import CustomInput from '../CustomInput';
import CustomLabel from '../CustomLabel';
import styles from './styles';
import CustomButton from '../CustomButton';
import Loader from '../Loader';
import { poweredByAtHum, background } from '../../config/assets';
import {
  welcomeMessage,
  sessionReadyMessage,
  firstRescheduleMessage,
  clientNameLabel,
  clientLastNameLabel,
  clickToGetStarted,
  agentMessage,
  yourSessionWillStart,
  sessionClosedMessage,
  yourSessionStartsOn,
  atSession,
  yourSessionFor,
  hasBeenCancelled,
  pleaseContactAgent,
  emailSentMessage
} from '../../config/messages';

const ClientWelcome = ({
  classes,
  setName,
  status,
  dateTime,
  logo,
  deletedAt,
  loading,
  twilioMeetingAvailable,
  history,
  onboarding,
  meetingId,
  language
}) => {
  const [clientFirstName, setClientFirstName] = useState('');
  const [clientLastName, setClientLastName] = useState('');
  moment.locale(language);
  const showForm = moment(dateTime).diff(moment(), 'minutes') <= 15;
  useEffect(() => {
    if (!twilioMeetingAvailable) {
      history.push('/meeting-room-full');
    }
  });
  const { salesPerson = {} } = onboarding;
  const date = moment(dateTime).format('LL');
  const time = moment(dateTime).format('LT');
  const statusMessage = {
    scheduled: `${yourSessionStartsOn[language]} ${date} ${atSession[language]} ${time}`,
    active: sessionReadyMessage[language],
    closed: sessionClosedMessage[language]
  };
  const welcomeStatusMessage = {
    scheduled: welcomeMessage[language],
    active: welcomeMessage[language],
    closed: emailSentMessage[language]
  };
  let message = statusMessage[status];
  if (loading) {
    message = yourSessionWillStart[language];
  }
  let content = null;
  if (loading) {
    content = (
      <>
        <img src={logo} alt="Logo" className={classes.logo} />
        <h1>{welcomeStatusMessage[status]}</h1>
        <h3>{message}</h3>
        <Loader load={false} />
      </>
    );
  } else if (deletedAt) {
    content = (
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <img src={logo} alt="Logo" className={classes.logo} />
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.deletedMeetingFirstContainer}
        >
          <h3 className={classes.deletedBlack}>
            {`${yourSessionFor[language]} ${date} ${atSession[language]} ${time} ${hasBeenCancelled[language]}`}
          </h3>
          <h3 className={classes.deleted}>{pleaseContactAgent[language]}</h3>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <h3 className={classes.deletedAgent}>{agentMessage[language]}</h3>
          <h3 className={classes.deleted}>{salesPerson.email || ''}</h3>
        </Grid>
      </Grid>
    );
  } else if (status !== 'closed' && !showForm) {
    content = (
      <>
        <img src={logo} alt="Logo" className={classes.logo} />
        <h1>{welcomeStatusMessage[status]}</h1>
        <h3>{message}</h3>
        <Link to={`/reschedule/${meetingId}`}>
          {firstRescheduleMessage[language]}
        </Link>
      </>
    );
  } else {
    content = (
      <>
        <img src={logo} alt="Logo" className={classes.logo} />
        <h1>{welcomeStatusMessage[status]}</h1>
        <h3>{message}</h3>
        {status !== 'closed' && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setName({
                firstName: clientFirstName,
                lastName: clientLastName
              });
            }}
            className={classes.formContainer}
          >
            <FormControl className={classes.formControlContainer}>
              <CustomLabel htmlFor="clientFirstName">
                {clientNameLabel[language]}
              </CustomLabel>
              <CustomInput
                id="clientFirstName"
                type="text"
                value={clientFirstName}
                onChange={(e) => {
                  setClientFirstName(e.target.value);
                }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlContainer}>
              <CustomLabel htmlFor="clientLastName">
                {clientLastNameLabel[language]}
              </CustomLabel>
              <CustomInput
                id="clientLastName"
                type="text"
                value={clientLastName}
                onChange={(e) => setClientLastName(e.target.value)}
              />
            </FormControl>
            <Grid container className={classes.buttonContainer}>
              <CustomButton type="submit">
                {clickToGetStarted[language]}
              </CustomButton>
            </Grid>
          </form>
        )}
      </>
    );
  }
  return (
    <Grid
      style={{
        backgroundImage: `url(${background.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: deletedAt ? '100% 75%' : '100% 55%'
      }}
      className={classes.mainContainer}
    >
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        wrap="nowrap"
        className={loading ? classes.waitContainer : classes.welcomeContainer}
      >
        {logo && (
          <>
            {content}
            <Grid>
              <img
                src={poweredByAtHum.src}
                alt={poweredByAtHum.alt}
                className={classes.athumLogo}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { twilioMeetingAvailable } = state.project;
  const { onboarding, meetingId } = state.meeting;
  const { language } = state.language;
  return {
    twilioMeetingAvailable,
    onboarding,
    meetingId,
    language
  };
};

ClientWelcome.propTypes = {
  history: shape({}).isRequired,
  setName: func.isRequired,
  classes: shape({}).isRequired,
  status: string.isRequired,
  dateTime: string.isRequired,
  logo: string.isRequired,
  deletedAt: string.isRequired,
  loading: bool.isRequired,
  twilioMeetingAvailable: bool.isRequired,
  onboarding: shape({}).isRequired,
  meetingId: string.isRequired,
  language: string.isRequired
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ClientWelcome));
