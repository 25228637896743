export default class MeetingsModel {
  constructor(data = {}, meetingId = '') {
    const {
      meeting = {},
      meetings = [],
      token: twilioToken = '',
      identity = '',
      room_name: twilioRoom = '',
      onboarding = {},
      customer = {}
    } = data;
    this.meeting = meeting;
    this.meetings = meetings;
    this.twilioToken = twilioToken;
    this.identity = identity;
    this.twilioRoom = twilioRoom;
    this.onboarding = onboarding;
    this.meetingId = meetingId;
    this.customer = customer;
  }

  meeting = {};

  meetings = [];

  twilioToken = '';

  identity = '';

  twilioRoom = '';

  onboarding = {};

  meetingId = '';

  customer = {};
}
