import React, { Component } from 'react';
import { arrayOf, string, shape, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { socketEmit } from '../../services/socket';
import Image from '../Image';
import Thumbnail from './Thumbnail';
import styles from './styles';
import { isMobile } from '../../utils';

/* eslint-disable camelcase */

class Carousel extends Component {
  constructor() {
    super();
    this.state = {
      selectedIndex: 0,
      selectedIndexThumbnailCarousel: 0
    };
  }

  componentDidMount = () => {
    this.handleSocketMessage();
  };

  componentWillUnmount = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.off('GALLERY', this.onMessage);
    }
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('GALLERY', this.onMessage);
    }
  };

  onMessage = (data) => {
    const { type, selectedIndex } = data;
    switch (type) {
      case 'GALLERY':
        this.handleGallerySelectedIndex(selectedIndex);
        break;

      default:
        break;
    }
  };

  onThumbnailClick = (selectedIndex) => {
    const { publisher } = this.props;
    if (publisher) {
      this.setState({
        selectedIndex
      });
      const minImagesIndex = isMobile() ? 2 : 3;
      if (selectedIndex > minImagesIndex) {
        const selectedIndexThumbnailCarousel = Math.round(
          selectedIndex / minImagesIndex
        );
        this.setState({
          selectedIndexThumbnailCarousel
        });
      }
      this.emit(selectedIndex);
    }
  };

  handleGallerySelectedIndex = (selectedIndex) => {
    this.setState({
      selectedIndex
    });
  };

  onNext = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndex } = this.state;
      const { content: images } = this.props;
      if (selectedIndex !== images.length - 1) {
        this.setState((prevState) => ({
          selectedIndex: prevState.selectedIndex + 1
        }));
        const minImagesIndex = isMobile() ? 2 : 3;
        if (selectedIndex + 1 > minImagesIndex) {
          const selectedIndexThumbnailCarousel = Math.round(
            (selectedIndex + 1) / minImagesIndex
          );
          this.setState({
            selectedIndexThumbnailCarousel
          });
        }
        this.emit(selectedIndex + 1);
      }
    }
  };

  onPrev = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndex } = this.state;
      if (selectedIndex !== 0) {
        this.setState((prevState) => ({
          selectedIndex: prevState.selectedIndex - 1
        }));
        const minImagesIndex = isMobile() ? 2 : 3;
        if (selectedIndex - 1 < minImagesIndex) {
          const selectedIndexThumbnailCarousel = Math.round(
            (selectedIndex - 1) / minImagesIndex
          );
          this.setState({
            selectedIndexThumbnailCarousel
          });
        }
        this.emit(selectedIndex - 1);
      }
    }
  };

  onNextThumbnail = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndexThumbnailCarousel } = this.state;
      const { content: images } = this.props;
      if (selectedIndexThumbnailCarousel !== images.length - 1) {
        this.setState((prevState) => ({
          selectedIndexThumbnailCarousel:
            prevState.selectedIndexThumbnailCarousel + 1
        }));
      }
    }
  };

  onPrevThumbnail = () => {
    const { publisher } = this.props;
    if (publisher) {
      const { selectedIndexThumbnailCarousel } = this.state;
      if (selectedIndexThumbnailCarousel !== 0) {
        this.setState((prevState) => ({
          selectedIndexThumbnailCarousel:
            prevState.selectedIndexThumbnailCarousel - 1
        }));
      }
    }
  };

  emit = (selectedIndex) => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'GALLERY',
        data: {
          type: 'GALLERY',
          selectedIndex
        }
      });
    }
  };

  render() {
    const { classes, content: images } = this.props;
    const { selectedIndex, selectedIndexThumbnailCarousel } = this.state;
    const minImagesIndex = isMobile() ? 2 : 3;
    const thumbnailWidth = isMobile() ? 110 : 154;
    let thumbnailNavigationCondition = images.length > 4;
    if (isMobile()) {
      thumbnailNavigationCondition = images.length > 3;
    }
    const thumbnailRightChevron = (
      <div
        role="button"
        tabIndex={0}
        className={classnames(classes.button, 'right')}
        onClick={this.onNextThumbnail}
      >
        <ChevronRight className={classes.chevron} />
      </div>
    );
    return (
      <div className={classes.carouselContainer}>
        {selectedIndexThumbnailCarousel !== 0 && images.length > 1 && (
          <div
            role="button"
            tabIndex={0}
            className={classnames(classes.button, 'left')}
            onClick={this.onPrevThumbnail}
          >
            <ChevronLeft className={classes.chevron} />
          </div>
        )}
        <div className={classes.selectedImageContainer}>
          {selectedIndex !== 0 && images.length > 1 && (
            <div
              className={classes.selectedImageLeftContainer}
              onClick={this.onPrev}
            >
              <ChevronLeft className={classes.chevronWhite} />
            </div>
          )}
          <Image
            content={{
              src: images[selectedIndex].src,
              alt: `${images[selectedIndex].alt} selected`,
              height: '100%'
            }}
          />
          {selectedIndex !== images.length - 1 && (
            <div
              className={classes.selectedImageRightContainer}
              onClick={this.onNext}
            >
              <ChevronRight className={classes.chevronWhite} />
            </div>
          )}
        </div>
        <div className={classes.thumbnailsContainer}>
          <div
            className={classes.thumbnailContainer}
            style={{
              width: images.length * thumbnailWidth,
              transform: `translateX(-${
                selectedIndexThumbnailCarousel * thumbnailWidth
              }px)`
            }}
          >
            {images.map((image, index) => (
              <Thumbnail
                key={`thumbnail-${index.toString()}`}
                index={index}
                url={image.src}
                name={`thumbnail-${image.alt}`}
                selected={selectedIndex === index}
                onThumbnailClick={this.onThumbnailClick}
              />
            ))}
          </div>
        </div>
        {selectedIndexThumbnailCarousel !== images.length - 1 &&
          thumbnailNavigationCondition &&
          selectedIndexThumbnailCarousel < images.length - 1 - minImagesIndex &&
          thumbnailRightChevron}
        {/* {images[selectedIndex].description && (
          <div className={classes.description}>
            <p>{images[selectedIndex].description}</p>
          </div>
        )} */}
      </div>
    );
  }
}

Carousel.propTypes = {
  content: arrayOf(string).isRequired,
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired
};

export default withStyles(styles)(Carousel);
