import React from 'react';
import { connect } from 'react-redux';
import { shape, string, func, bool } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import { closeIcon } from '../../config/assets';
import {
  yesFinish,
  noGoBack,
  leaveSession,
  sessionWillContinue
} from '../../config/messages';

const LeaveMeetingModal = ({
  classes,
  language,
  leaveMeetingModal,
  hideLeaveMeetingModal,
  logout,
  token
}) => (
  <Modal open={leaveMeetingModal} onClose={hideLeaveMeetingModal}>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.modalLeaveMeetingContainer}
    >
      <img
        src={closeIcon.src}
        alt={closeIcon.alt}
        className={classes.closeIcon}
        onClick={hideLeaveMeetingModal}
      />
      <h2>{leaveSession[language]}</h2>
      {!token && <h3>{sessionWillContinue[language]}</h3>}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.buttonsContainer}
      >
        <button
          type="button"
          className={classes.leaveMeetingCancelButton}
          onClick={hideLeaveMeetingModal}
        >
          {noGoBack[language]}
        </button>
        <button
          type="button"
          className={classes.leaveMeetingDeleteButton}
          onClick={logout}
        >
          {yesFinish[language]}
        </button>
      </Grid>
    </Grid>
  </Modal>
);

LeaveMeetingModal.propTypes = {
  classes: shape({}).isRequired,
  token: string.isRequired,
  language: string.isRequired,
  leaveMeetingModal: bool.isRequired,
  hideLeaveMeetingModal: func.isRequired,
  logout: func.isRequired
};

const mapStateToProps = (state) => {
  let { token } = state.session;
  const { language } = state.language;
  if (!token) {
    token = sessionStorage.getItem('token');
  }
  return {
    token,
    language
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(LeaveMeetingModal));
