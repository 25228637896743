import React from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, bool, func } from 'prop-types';
import { MenuList, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';

const SalesMenu = ({
  classes,
  selectedMenuItem,
  token,
  sections,
  isCustomer,
  handleSelectMenuItem,
  openMenu
}) => (
  <MenuList className={classes.menuProjectsItemsContainer}>
    {sections.map(({ icon, name, order }) => (
      <MenuItem
        key={order}
        className={`${
          order === selectedMenuItem ? classes.menuItemActive : classes.menuItem
        } sales`}
        onClick={
          token || isCustomer ? () => handleSelectMenuItem(order) : () => {}
        }
      >
        <img
          src={icon}
          alt={name}
          className={`${
            order === selectedMenuItem
              ? classes.menuIconActive
              : classes.menuIcon
          } sales`}
        />
        {openMenu && name}
      </MenuItem>
    ))}
  </MenuList>
);

SalesMenu.propTypes = {
  selectedMenuItem: number.isRequired,
  classes: shape({}).isRequired,
  token: string.isRequired,
  sections: arrayOf(shape({})).isRequired,
  isCustomer: bool.isRequired,
  handleSelectMenuItem: func.isRequired,
  openMenu: bool.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { selectedMenuItem } = state.menu;
  const { isCustomer } = state.meeting;
  const { sections } = state.project;
  return {
    selectedMenuItem,
    token,
    sections,
    isCustomer
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(SalesMenu));
