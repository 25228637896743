const styles = () => ({
  svgContainer: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  zoomButtons: {
    width: 40,
    height: 250,
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    zIndex: 10,
    background: '#FAFAFA',
    boxShadow: '0px 1px 2px rgba(138, 138, 138, 0.5)',
    padding: '5px 0',
    borderRadius: 24,
    '& img': {
      background: '#FAFAFA',
      padding: 5,
      width: '28px !important',
      height: '28px !important',
      '&.selectedTool': {
        background: '#D6D6D6',
        padding: 5,
        borderRadius: 24
      }
    }
  }
});

export default styles;
