const styles = {
  videoPlayer: {
    width: '100%'
  },
  iframe: {
    width: '100%',
    height: '100%',
    outline: 'none'
  }
};

export default styles;
