import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, string, arrayOf, shape, bool } from 'prop-types';
import ImageMenuItem from '../ImageMenuItem';
import ThreeSixtyAction from '../../../../stores/threeSixty/actions';
import { getSelectedScene } from '../../../../selectors/Menu';
import {
  socketIdSelector,
  emitingSelector,
  logSelector,
  publisherSelector
} from '../../../../selectors/Socket';
import SocketActions from '../../../../stores/sockets/actions';

class MobileViewsMenu extends Component {
  componentDidMount() {
    const { socket, publisher } = this.props;
    if (socket !== null && !publisher) {
      socket.on('CHANGE-SCENE', (data) => {
        const { type } = data;
        switch (type) {
          case 'CHANGE-SCENE':
            this.applyView(data.name);
            break;

          default:
            break;
        }
      });
    }
  }

  viewItemClick = async (e, targetName) => {
    const { socket, typeOfLog, isEmiting, socketId: id } = this.props;
    const name = targetName || e.target.name || e.target.getAttribute('name');
    this.applyView(name);
    if (isEmiting) {
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'CHANGE-SCENE',
        data: {
          type: 'CHANGE-SCENE',
          name
        }
      });
    }
  };

  applyView = async (name) => {
    const { dispatch } = this.props;

    dispatch(ThreeSixtyAction.setSelectedScene(name));

    dispatch(ThreeSixtyAction.getStyles());

    await dispatch(ThreeSixtyAction.changeSceneSphere());
  };

  render() {
    const { scenes, selectedScene, closeMenu } = this.props;
    return (
      <>
        <div className="mobile-submenu-title">SCENES</div>
        <div
          id="views-mobile-menu"
          className="mobile-submenu views-mobile-menu sub-mobile-menu d-flex justify-content-start align-items-center"
        >
          {scenes.map((scene, index) => {
            const { key, image, name } = scene;
            return (
              <ImageMenuItem
                key={key}
                keyName={key}
                name={name}
                index={index}
                onClick={this.viewItemClick}
                img={image}
                selected={selectedScene}
                closeMenu={closeMenu}
              />
            );
          })}
        </div>
      </>
    );
  }
}

MobileViewsMenu.propTypes = {
  scenes: arrayOf(shape({})).isRequired,
  closeMenu: func.isRequired,
  selectedScene: string.isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired,
  isEmiting: bool.isRequired,
  socketId: string.isRequired,
  typeOfLog: string.isRequired,
  publisher: bool.isRequired
};

const mapStateToProps = (state) => {
  const { scenes } = state.threeSixty;
  return {
    scenes,
    selectedScene: getSelectedScene(state),
    socketId: socketIdSelector(state),
    isEmiting: emitingSelector(state),
    typeOfLog: logSelector(state),
    publisher: publisherSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileViewsMenu);
