import Video, {
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  createLocalTracks
} from 'twilio-video';
import { isMobile } from '../utils';

const sessionStartFile = require('../assets/sounds/sessionstart.mp3');
const sessionDisconnectFile = require('../assets/sounds/disconnect.wav');

class TWILIO {
  constructor() {
    this.audioTracks = [];
    this.videoTracks = [];
    this.audioTrack = null;
    this.videoTrack = null;
    this.remoteAudioTrack = null;
    this.remoteVideoTrack = null;
    this.audio = true;
    this.video = false;
    this.remoteAudio = true;
    this.remoteVideo = false;
    this.activeRoom = null;
    this.error = '';
    this.connected = false;
    this.dominantSid = '';
    this.dominantContainer = null;
    this.remoteMediaContainer = null;
    this.classes = null;
    this.onError = null;
    this.previewCamera = null;
    this.previewAudio = null;
    this.startVideo = false;
    this.setLoadingStatus = null;
  }

  connect = (
    twilioToken,
    roomName,
    remoteMediaContainer,
    dominantContainer,
    classes,
    setLoadingStatus,
    onError,
    startVideo,
    cameraId
  ) => {
    this.classes = classes;
    this.remoteMediaContainer = remoteMediaContainer;
    this.dominantContainer = dominantContainer;
    this.startVideo = startVideo;
    this.onError = onError;
    this.sessionStartSound = new Audio(sessionStartFile);
    this.sessionDisconnectedSound = new Audio(sessionDisconnectFile);
    this.alreadyPlayed = false;
    this.selectedCamera = cameraId;
    this.setLoadingStatus = setLoadingStatus;
    createLocalTracks({
      audio: true,
      video: false
    }).then((localTracks) => {
      const connectOptions = {
        name: roomName,
        ...ConnectOptions,
        tracks: localTracks,
        dominantSpeaker: true
      };
      return Video.connect(twilioToken, connectOptions).then(
        this.roomJoined,
        this.connectionError
      );
    });
  };

  roomJoined = (room) => {
    this.connected = true;
    this.activeRoom = room;
    this.error = '';
    this.setLoadingStatus(false);

    this.localParticipant(room.localParticipant);
    room.participants.forEach(this.alreadyConnectedParticipants);
    room.on('participantConnected', this.remoteParticipants);
    room.on('participantDisconnected', this.participantDisconnected);
    room.on('dominantSpeakerChanged', this.dominantParticipant);
    room.on('disconnected', () => {
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
    });

    if (this.startVideo) {
      this.muteVideo();
    }
  };

  connectionError = (error) => {
    console.error(
      'ConnectionError',
      error.code,
      error.message,
      error.status,
      error.more_info
    );
    this.error = error.message;
    this.onError(this.error, error.code);
  };

  localParticipant = (participant) => {
    this.participantConnected(participant);
    const tracks = Array.from(participant.tracks.values());
    tracks.forEach((track) => {
      this.handleTrack(track);
    });
  };

  remoteParticipants = (participant) => {
    this.playActiveSound();
    this.participantConnected(participant);
  };

  alreadyConnectedParticipants = (participant) => {
    if (!this.alreadyPlayed) {
      this.alreadyPlayed = true;
      this.playActiveSound();
    }
    this.participantConnected(participant);
  };

  participantDominantCreation = (participant) => {
    this.createParticipantContainer(participant, this.dominantContainer);
  };

  participantConnected = (participant) => {
    this.createParticipantContainer(participant, this.remoteMediaContainer);
  };

  participantDisconnected = (participant) => {
    const tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
    document.getElementById(participant.sid).remove();
    if (this.dominantSid === participant.sid) {
      this.dominantSid = '';
    }
    this.sessionDisconnectedSound.play();
  };

  dominantParticipant = (participant) => {
    if (participant) {
      if (this.dominantSid !== participant.sid) {
        if (this.dominantSid !== '') {
          const oldContainer = document.getElementById(this.dominantSid);
          oldContainer.className = '';
          oldContainer.setAttribute('class', 'client-video-container');
          this.remoteMediaContainer.appendChild(oldContainer);
        }

        this.dominantSid = participant.sid;

        const container = document.getElementById(participant.sid);
        if (container) {
          container.className = '';
          container.setAttribute('class', this.classes.userVideo);
          this.dominantContainer.appendChild(container);
        }
      }
    }
  };

  createParticipantContainer = (participant, container) => {
    const participantDiv = document.createElement('div');
    participantDiv.setAttribute('id', participant.sid);
    participantDiv.setAttribute(
      'class',
      isMobile()
        ? this.classes.mobileClientVideoContainer
        : 'client-video-container'
    );

    const tracksDiv = document.createElement('div');
    tracksDiv.setAttribute('id', `video-${participant.sid}`);
    tracksDiv.setAttribute(
      'class',
      isMobile() ? this.classes.mobileVideo : 'client-video'
    );
    participantDiv.appendChild(tracksDiv);

    const labelDiv = document.createElement('div');
    labelDiv.setAttribute('id', `initials-${participant.sid}`);
    labelDiv.setAttribute('class', 'only-initials-client');
    const peerIdentityStrings = participant.identity.split(' ');
    const peerIdentityInitials =
      participant.identity &&
      `${peerIdentityStrings[0].slice(0, 1)}${peerIdentityStrings[1].slice(
        0,
        1
      )}`;
    const paragraph = document.createElement('p');
    paragraph.innerHTML = peerIdentityInitials;
    labelDiv.appendChild(paragraph);
    participantDiv.appendChild(labelDiv);

    container.appendChild(participantDiv);

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed)
        this.trackSubscribed(tracksDiv, publication.track);
    });
    participant.on('trackSubscribed', (track) => {
      if (track.kind === 'video') {
        labelDiv.className = '';
        labelDiv.setAttribute('class', 'initials-client');
      }
      this.trackSubscribed(tracksDiv, track);
    });
    participant.on('trackUnsubscribed', (track) => {
      if (track.kind === 'video') {
        labelDiv.className = '';
        labelDiv.setAttribute('class', 'only-initials-client');
      }
      this.trackUnsubscribed(track);
    });
  };

  handleTrack = (track) => {
    switch (track.kind) {
      case 'video':
        if (track instanceof LocalVideoTrack) {
          this.videoTrack = track;
        } else {
          track.on('enabled', () => {});
          track.on('stop', (stoppedTrack) => {
            this.detachTracks([stoppedTrack]);
          });
        }
        this.videoTracks.push(track);
        break;
      case 'audio':
        if (track instanceof LocalAudioTrack) {
          this.audioTrack = track;
        } else {
          track.on('disabled', () => {});
        }
        this.audioTracks.push(track);
        break;

      default:
        break;
    }
  };

  attachLocalParticipantTracks = (participant, container) => {
    const tracks = Array.from(participant.tracks.values());
    tracks.forEach((track) => {
      this.trackSubscribed(container, track);
    });
  };

  trackSubscribed = (container, track) => {
    this.handleTrack(track);
    const trackToAttach = track.attach();
    if (track.kind === 'video') {
      trackToAttach.style.transform = 'scale(-1, 1)';
    }
    container.appendChild(trackToAttach);
  };

  trackUnsubscribed = (track) => {
    track.detach().forEach((element) => element.remove());
  };

  detachTracks = (tracks) => {
    tracks.forEach((track) => {
      this.trackUnsubscribed(track);
    });
  };

  detachParticipantTracks = (participant) => {
    const tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  };

  stopLocalVideoTrack = () => {
    if (this.videoTrack) {
      this.videoTrack.stop();
      this.activeRoom.localParticipant.unpublishTrack(this.videoTrack);
      this.trackUnsubscribed(this.videoTrack);
    }
  };

  stopLocalAudioTrack = () => {
    if (this.audioTrack) {
      this.audioTrack.stop();
      this.activeRoom.localParticipant.unpublishTrack(this.audioTrack);
      this.trackUnsubscribed(this.audioTrack);
    }
  };

  muteAudio = () => {
    if (this.connected && this.activeRoom) {
      if (this.audioTrack && this.audio) {
        this.audioTrack.disable();
      } else {
        this.audioTrack.enable();
      }
      this.audio = !this.audio;
    }
  };

  muteVideo = () => {
    if (this.connected && this.activeRoom) {
      if (this.video && this.videoTrack) {
        this.unpublishVideoTrack();
        this.videoTrack = null;
        this.video = false;
      } else {
        this.createVideoTrack(true);
      }
    }
  };

  getVideoStatus = () => this.video;

  unpublishVideoTrack = () => {
    if (this.videoTrack && this.activeRoom) {
      this.videoTrack.stop();
      this.activeRoom.localParticipant.unpublishTrack(this.videoTrack);
      const idLabels = `initials-${this.activeRoom.localParticipant.sid}`;
      const labelContainer = document.getElementById(idLabels);
      labelContainer.className = '';
      labelContainer.setAttribute('class', 'only-initials-client');
      this.detachTracks([this.videoTrack]);
    }
  };

  createVideoTrack = (video) => {
    const options = {
      name: 'localCamera'
    };

    if (this.selectedCamera) {
      options.deviceId = this.selectedCamera;
    }

    if (video) {
      Video.createLocalVideoTrack(options).then((localVideoTrack) => {
        this.activeRoom.localParticipant.publishTrack(localVideoTrack);
        const idVideo = `video-${this.activeRoom.localParticipant.sid}`;
        const idLabels = `initials-${this.activeRoom.localParticipant.sid}`;
        const container = document.getElementById(idVideo);
        const labelContainer = document.getElementById(idLabels);
        labelContainer.className = '';
        labelContainer.setAttribute('class', 'initials-client');
        this.trackSubscribed(container, localVideoTrack);
        this.videoTrack = localVideoTrack;
      });
    }

    this.video = video;
  };

  createAudioTrack = () => {
    const options = {
      name: 'localAudio'
    };
    if (this.selectedMic) {
      options.deviceId = this.selectedMic;
    }
    Video.createLocalAudioTrack(options).then((localAudioTrack) => {
      this.activeRoom.localParticipant.publishTrack(localAudioTrack);
      const idVideo = `video-${this.activeRoom.localParticipant.sid}`;
      const container = document.getElementById(idVideo);
      this.trackSubscribed(container, localAudioTrack);
      this.audioTrack = localAudioTrack;
      this.audio = true;
    });
  };

  disconnect = () => {
    this.stopLocalVideoTrack();
    this.stopLocalAudioTrack();
    this.connected = false;
    this.audioTrack = null;
    this.videoTrack = null;
    this.activeRoom.disconnect();
    this.sessionDisconnectedSound.play();
  };

  getActiveRoom = () => this.activeRoom;

  playActiveSound = () => this.sessionStartSound.play();
}

export default TWILIO;
