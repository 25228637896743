import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomSecondaryButton = withStyles((theme) => ({
  root: {
    width: 220,
    height: 40,
    boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.5)',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    border: 'none',
    borderRadius: 60,
    lineHeight: 1.5,
    backgroundColor: '#fafafa',
    color: theme.colors.primary,
    outline: 'none',
    '&:hover': {
      backgroundColor: '#fafafa'
    },
    '&:active': {
      backgroundColor: '#fafafa',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: '#fafafa',
      outline: 'none'
    }
  }
}))(Button);

export default CustomSecondaryButton;
