import React from 'react';
import { shape, string } from 'prop-types';

const Draws = ({ draws, color }) => {
  const drawsArray = Object.keys(draws);
  return (
    <g>
      {drawsArray.map((key) => (
        <polyline
          key={key}
          points={draws[key].join(' ')}
          style={{ fill: 'none', stroke: color, strokeWidth: 2 }}
        />
      ))}
    </g>
  );
};

Draws.propTypes = {
  draws: shape({}).isRequired,
  color: string.isRequired
};

export default Draws;
