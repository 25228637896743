/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  FormControl,
  Select,
  withStyles,
  InputAdornment,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProjectAction from '../../stores/project/actions';
import CustomInput from '../CustomInput';
import CustomLabel from '../CustomLabel';
import {
  getUnitInfo,
  getPercentageDiscountPrice,
  getMoneyDiscountPrice,
  getPercentageDiscountDeposit,
  getDeferredMonthly,
  getDeedAmount,
  numberWithCommas,
  percentageLimit,
  limitMonths
} from '../../utils';
import {
  calculatorSelector,
  calculatorsSelector
} from '../../selectors/Calculator';
import CustomSelectInput from '../CustomSelectInput';
import styles from './styles';
import CustomTextField from '../CustomTextField';
import {
  bathroomIcon,
  bedroomIcon,
  parkingIcon,
  sqmIcon
} from '../../config/assets';

class MXCalculator extends Component {
  constructor(props) {
    super();
    const { calculator } = props;
    this.state = {
      unit: calculator.unitName,
      price: calculator.price,
      availableOn: calculator.availableOn,
      status: calculator.status,
      typeDiscount: calculator.typeDiscount,
      discount: calculator.discount,
      priceWithDiscount: calculator.priceWithDiscount,
      downPaymentPercentage: calculator.downPaymentPercentage,
      downPayment: calculator.downPayment,
      holdingFee: calculator.holdingFee,
      deferredAmountPercentage: calculator.deferredAmountPercentage,
      deferredAmountMonths: calculator.deferredAmountMonths,
      deferredAmountMonthlyPayment: calculator.deferredAmountMonthlyPayment,
      deedsAmount: calculator.deedsAmount,
      notaryFeesPercentage: calculator.notaryFeesPercentage,
      notaryFeesAmount: calculator.notaryFeesAmount,
      bathrooms: calculator.bathrooms,
      bedrooms: calculator.bedrooms,
      parking: calculator.parking,
      area: calculator.area
      /* this would be added once wer have the credit api
       ,
      credit: '',
      interestRate: '',
      deadlines: '',
      estimatedMonthlyPayment: '' */
    };
  }

  componentDidMount() {
    const { calculator, enableAddUnit } = this.props;
    if (calculator.unitName !== '') {
      enableAddUnit(true);
    }
  }

  unitSelect = (event, value) => {
    console.log('unit', value);
    const {
      units,
      dispatch,
      calculator,
      currentCalculator,
      calculators,
      enableAddUnit
    } = this.props;
    const {
      typeDiscount,
      discount,
      downPaymentPercentage,
      deferredAmountMonths,
      deferredAmountPercentage,
      notaryFeesPercentage
    } = this.state;
    const unitInfo = getUnitInfo(value, units);
    if (unitInfo !== undefined) {
      let priceWithDiscount = '';
      if (discount !== '') {
        if (typeDiscount === 'percentage') {
          priceWithDiscount = getPercentageDiscountPrice(
            discount,
            unitInfo.prices[0].price
          );
        } else if (typeDiscount === 'money') {
          priceWithDiscount = getMoneyDiscountPrice(
            discount,
            unitInfo.prices[0].price
          );
        }
      }

      const downPayment = getPercentageDiscountDeposit(
        downPaymentPercentage,
        unitInfo.prices[0].price
      );

      const deferredAmountMonthlyPayment = getDeferredMonthly(
        deferredAmountMonths,
        deferredAmountPercentage,
        unitInfo.prices[0].price
      );

      const notaryFeesAmount = getPercentageDiscountDeposit(
        notaryFeesPercentage,
        unitInfo.prices[0].price
      );

      calculator.layoutId = unitInfo.value;
      calculator.unitName = value;
      calculator.price = unitInfo.prices[0].price;
      calculator.availableOn = unitInfo.availableOn;
      calculator.status = unitInfo.status;
      calculator.priceWithDiscount = priceWithDiscount;
      calculator.downPayment = downPayment;
      calculator.deferredAmountMonthlyPayment = deferredAmountMonthlyPayment;
      calculator.notaryFeesAmount = notaryFeesAmount;
      calculator.area = unitInfo.area;
      calculator.bathrooms = unitInfo.bathrooms;
      calculator.bedrooms = unitInfo.bedrooms;
      calculator.parking = unitInfo.parking;
      dispatch(
        ProjectAction.updateCalculator(
          calculator,
          currentCalculator,
          calculators
        )
      );

      this.setState({
        unit: value,
        price: unitInfo.prices[0].price,
        availableOn: unitInfo.availableOn,
        status: unitInfo.status,
        area: unitInfo.area,
        bathrooms: unitInfo.bathrooms,
        bedrooms: unitInfo.bedrooms,
        parking: unitInfo.parking,
        priceWithDiscount,
        downPayment,
        deferredAmountMonthlyPayment,
        notaryFeesAmount
      });

      enableAddUnit(true);
    }
  };

  discountSelect = (event) => {
    const { target } = event;
    const { value } = target;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    calculator.typeDiscount = value;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({
      typeDiscount: value,
      priceWithDiscount: '',
      discount: ''
    });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    let positiveValue = Math.abs(value.replace(/,/g, ''));
    if (Number.isNaN(positiveValue)) {
      positiveValue = '';
    }
    calculator[id] = positiveValue;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({ [id]: positiveValue });
  };

  handleAvailableOn = (event) => {
    const { target } = event;
    const { id, value } = target;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    calculator[id] = value;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({ [id]: value });
  };

  handleDiscount = (event) => {
    const { target } = event;
    const { id, value } = target;
    const { typeDiscount, price } = this.state;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    let priceWithDiscount = '';
    let discountValue = value.replace(/,/g, '');
    if (typeDiscount === 'percentage') {
      discountValue = percentageLimit(parseInt(discountValue, 10));
      priceWithDiscount = getPercentageDiscountPrice(
        parseInt(discountValue, 10),
        price
      );
    } else if (typeDiscount === 'money') {
      discountValue = Math.abs(discountValue);
      if (Number.isNaN(discountValue)) {
        discountValue = '';
      }
      priceWithDiscount = getMoneyDiscountPrice(
        parseInt(discountValue, 10),
        price
      );
    }
    calculator[id] = discountValue;
    calculator.priceWithDiscount = priceWithDiscount;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({
      [id]: discountValue,
      priceWithDiscount
    });
  };

  handleDeposit = (event) => {
    const { target } = event;
    const { id, value } = target;
    const { price } = this.state;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    const deposit = percentageLimit(parseInt(value, 10));
    const downPayment = getPercentageDiscountDeposit(deposit, price);
    calculator[id] = deposit;
    calculator.downPayment = downPayment;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );

    this.setState({ [id]: deposit, downPayment });
  };

  handleNotarial = (event) => {
    const { target } = event;
    const { id, value } = target;
    const { price } = this.state;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    const notarial = percentageLimit(parseInt(value, 10));
    const notaryFeesAmount = getPercentageDiscountDeposit(notarial, price);
    calculator[id] = notarial;
    calculator.notaryFeesAmount = notaryFeesAmount;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({ [id]: notarial, notaryFeesAmount });
  };

  handleMonths = (event) => {
    const { target } = event;
    const { id, value } = target;
    const {
      price,
      deferredAmountPercentage,
      holdingFee,
      downPayment
    } = this.state;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    const months = limitMonths(parseInt(value, 10));
    const deedsAmount = getDeedAmount(
      deferredAmountPercentage,
      price,
      holdingFee,
      downPayment
    );
    const deferredAmountMonthlyPayment = getDeferredMonthly(
      months,
      deferredAmountPercentage,
      price
    );
    calculator[id] = months;
    calculator.deferredAmountMonthlyPayment = parseInt(
      deferredAmountMonthlyPayment,
      10
    );
    calculator.deedsAmount = deedsAmount;
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({ [id]: months, deferredAmountMonthlyPayment, deedsAmount });
  };

  handleDiferred = (event) => {
    const { target } = event;
    const { id, value } = target;
    const { price, deferredAmountMonths } = this.state;
    const { calculator, currentCalculator, calculators, dispatch } = this.props;
    const deferred = percentageLimit(parseInt(value, 10));
    calculator[id] = deferred;
    const deferredAmountMonthlyPayment = getDeferredMonthly(
      deferredAmountMonths,
      deferred,
      price
    );
    dispatch(
      ProjectAction.updateCalculator(calculator, currentCalculator, calculators)
    );
    this.setState({ [id]: deferred, deferredAmountMonthlyPayment });
  };

  render() {
    const { units, classes, notaryFees } = this.props;
    const {
      unit,
      price,
      availableOn,
      status,
      typeDiscount,
      discount,
      priceWithDiscount,
      downPaymentPercentage,
      downPayment,
      holdingFee,
      deferredAmountPercentage,
      deferredAmountMonths,
      deferredAmountMonthlyPayment,
      deedsAmount,
      notaryFeesPercentage,
      notaryFeesAmount,
      area,
      bathrooms,
      bedrooms,
      parking
    } = this.state;
    return (
      <div className={classes.formContainer}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="input-container"
          >
            <FormControl className={`${classes.xLargeInput} first`}>
              <CustomLabel htmlFor="unit">Unidad</CustomLabel>
              <Autocomplete
                id="unit"
                options={units.map((option) => option.name)}
                defaultValue={unit}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                  />
                )}
                onChange={this.unitSelect}
              />
            </FormControl>
            <FormControl className={classes.xLargeInput}>
              <CustomLabel htmlFor="price">Precio</CustomLabel>
              <CustomInput
                id="price"
                type="text"
                placeholder="0"
                value={numberWithCommas(price)}
                onChange={this.handleInputChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                disabled
              />
            </FormControl>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="bottom-border input-container"
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              className={`${classes.xLargeInput} first icons`}
            >
              <CustomLabel htmlFor="description">Descripción</CustomLabel>
              <Grid
                container
                id="description"
                justify="flex-start"
                alignItems="flex-start"
              >
                <img
                  src={bedroomIcon.src}
                  alt={bedroomIcon.src}
                  className={classes.icons}
                />
                <Grid className={classes.iconText}>{bedrooms}</Grid>
                <img
                  src={bathroomIcon.src}
                  alt={bathroomIcon.src}
                  className={classes.icons}
                />
                <Grid className={classes.iconText}>{bathrooms}</Grid>
                <img
                  src={parkingIcon.src}
                  alt={parkingIcon.src}
                  className={classes.icons}
                />
                <Grid className={classes.iconText}>{parking}</Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              className={`${classes.xLargeInput} icons`}
            >
              <CustomLabel htmlFor="area">Area</CustomLabel>
              <Grid
                id="area"
                container
                justify="flex-start"
                alignItems="center"
              >
                <img
                  src={sqmIcon.src}
                  alt={sqmIcon.src}
                  className={classes.icons}
                />
                <Grid className={classes.iconText}>{area}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="bottom-border input-container"
          >
            <FormControl className={`${classes.xLargeInput} first`}>
              <CustomLabel htmlFor="availableOn">Disponible en</CustomLabel>
              <CustomInput
                id="availableOn"
                type="text"
                value={availableOn}
                onChange={this.handleAvailableOn}
                disabled
              />
            </FormControl>
            <FormControl className={classes.xLargeInput}>
              <CustomLabel htmlFor="status">Fase</CustomLabel>
              <CustomInput
                id="status"
                type="text"
                value={status}
                onChange={this.handleInputChange}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="bottom-border input-container"
          >
            <FormControl className={`${classes.smallInput} first`}>
              <CustomLabel htmlFor="typeDiscount">Descuento</CustomLabel>
              <Select
                id="typeDiscount"
                value={typeDiscount}
                onChange={this.discountSelect}
                input={<CustomSelectInput />}
                className={classes.select}
              >
                <MenuItem className={classes.options} value="money">
                  $
                </MenuItem>
                <MenuItem className={classes.options} value="percentage">
                  %
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={`${classes.largeInput} second`}>
              <CustomInput
                id="discount"
                type="text"
                placeholder="0"
                value={numberWithCommas(discount)}
                onChange={this.handleDiscount}
                className={classes.inputWithoutLabel}
              />
            </FormControl>
            <FormControl className={classes.xLargeInput}>
              <CustomLabel htmlFor="priceWithDiscount">
                Precio con descuento
              </CustomLabel>
              <CustomInput
                id="priceWithDiscount"
                type="text"
                placeholder="0"
                value={numberWithCommas(priceWithDiscount)}
                InputProps={{
                  readOnly: true
                }}
                disabled
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="bottom-border input-container"
          >
            <FormControl className={`${classes.smallInput} first`}>
              <CustomLabel htmlFor="downPaymentPercentage">
                Enganche
              </CustomLabel>
              <CustomInput
                id="downPaymentPercentage"
                type="text"
                placeholder="0"
                value={numberWithCommas(downPaymentPercentage)}
                onChange={this.handleDeposit}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <FormControl className={`${classes.largeInput} second`}>
              <CustomInput
                id="deposit"
                type="text"
                placeholder="0"
                value={numberWithCommas(downPayment)}
                InputProps={{
                  readOnly: true
                }}
                disabled
                className={classes.inputWithoutLabel}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <FormControl className={classes.xLargeInput}>
              <CustomLabel htmlFor="holdingFee">Monto de apartado</CustomLabel>
              <CustomInput
                id="holdingFee"
                type="text"
                placeholder="0"
                value={numberWithCommas(holdingFee)}
                onChange={this.handleInputChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="bottom-border input-container"
          >
            <FormControl className={`${classes.smallInput} first`}>
              <CustomLabel htmlFor="deferredAmountPercentage">
                Diferido
              </CustomLabel>
              <CustomInput
                id="deferredAmountPercentage"
                type="text"
                placeholder="0"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                value={deferredAmountPercentage}
                onChange={this.handleDiferred}
              />
            </FormControl>
            <FormControl className={`${classes.largeInput} second`}>
              <CustomLabel htmlFor="deferredAmountMonths">Meses</CustomLabel>
              <CustomInput
                id="deferredAmountMonths"
                type="text"
                placeholder="0"
                value={deferredAmountMonths}
                onChange={this.handleMonths}
              />
            </FormControl>
            <FormControl className={classes.xLargeInput}>
              <CustomLabel htmlFor="deferredAmountMonthlyPayment">
                Mensualidad
              </CustomLabel>
              <CustomInput
                id="deferredAmountMonthlyPayment"
                type="text"
                placeholder="0"
                value={numberWithCommas(deferredAmountMonthlyPayment)}
                onChange={this.handleInputChange}
                disabled
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className="bottom-border input-container"
          >
            <FormControl className={`${classes.xLargeInput} first`}>
              <CustomLabel htmlFor="deedsAmount">
                Saldo al momento de la entrega
              </CustomLabel>
              <CustomInput
                id="deedsAmount"
                type="text"
                placeholder="0"
                value={numberWithCommas(deedsAmount)}
                onChange={this.handleInputChange}
                disabled
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {notaryFees && (
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className="input-container"
            >
              <FormControl className={`${classes.xLargeInput} first`}>
                <CustomLabel htmlFor="notaryFeesPercentage">
                  Gastos Notariales (aprox.)
                </CustomLabel>
                <CustomInput
                  id="notaryFeesPercentage"
                  type="text"
                  placeholder="0"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  value={numberWithCommas(notaryFeesPercentage)}
                  onChange={this.handleNotarial}
                />
              </FormControl>
              <FormControl className={classes.xLargeInput}>
                <CustomInput
                  id="notaryFeesAmount"
                  type="text"
                  placeholder="0"
                  value={numberWithCommas(notaryFeesAmount)}
                  className={classes.inputWithoutLabel}
                  disabled
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  calculator: calculatorSelector(state, ownProps),
  calculators: calculatorsSelector(state)
});

MXCalculator.propTypes = {
  units: arrayOf(shape({})).isRequired,
  classes: shape({}).isRequired,
  calculator: shape({}).isRequired,
  dispatch: func.isRequired,
  currentCalculator: number.isRequired,
  calculators: arrayOf(shape({})).isRequired,
  enableAddUnit: func.isRequired,
  notaryFees: bool.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MXCalculator)
);
