import { shape } from 'prop-types';
import React from 'react';
import MobileMenu from './Mobile';

const Menu = ({ socket }) => <MobileMenu socket={socket} />;

Menu.propTypes = {
  socket: shape({}).isRequired
};

export default Menu;
