import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, bool, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LanguageAction from '../stores/language/actions';
import ProjectAction from '../stores/project/actions';
import MeetingAction from '../stores/meeting/actions';
import MenuAction from '../stores/menu/actions';
import TwilioAction from '../stores/twilio/actions';
import ClientWelcome from '../components/ClientWelcome';
import styles from '../styles';
import PrivatePanel from '../components/PrivatePanel';
import SharedSession from '../components/SharedSession';
import FullPageLoader from '../components/Loader/FullPageLoader';
import {
  isSupported,
  getBrowser,
  typeOfDevice,
  getOperatingSystem
} from '../utils';
import Onboarding from '../components/Onboarding';
import { socketEmit } from '../services/socket';

const sessionStartFile = require('../assets/sounds/sessionstart.mp3');

class MeetingRoomPage extends Component {
  constructor() {
    super();
    this.state = {
      videoCallOpen: true,
      body: {},
      startOnboarding: false,
      privatePanelVisible: true,
      userAgent: '',
      device: '',
      latitude: '',
      longitude: '',
      so: ''
    };
  }

  async componentDidMount() {
    const { match, history, socket, dispatch } = this.props;
    if (!isSupported()) {
      history.push('/error/415');
    } else {
      const { params } = match;
      const { meetingId } = params;
      this.checkMeetingStatus();
      const actionResponse = await dispatch(
        MeetingAction.onboarding(meetingId)
      );
      const { isError } = actionResponse;
      if (!isError) {
        const { model } = actionResponse;
        this.getPropertyLanguage(model.onboarding.id);
      }
      dispatch(TwilioAction.createTwilioObject());
      window.addEventListener('offline', this.handleOffline);
      this.getLocation();
      this.setState({
        userAgent: getBrowser(),
        device: typeOfDevice(),
        so: getOperatingSystem()
      });
    }

    socket.on('REMOTE-SALES-CONNECTED', this.onMessage);
    socket.on('UPDATE-REMOTE-BUYER', this.onMessage);
  }

  componentWillUnmount = () => {
    const { socket } = this.props;
    socket.off('REMOTE-SALES-CONNECTED', this.onMessage);
  };

  getLocation = () => {
    navigator.geolocation.getCurrentPosition(this.getLatLon);
  };

  getLatLon = (position) => {
    const { latitude, longitude } = position.coords;
    this.setState({ latitude, longitude });
  };

  onMessage = (data) => {
    const { type } = data;
    switch (type) {
      case 'REMOTE-SALES-CONNECTED':
        this.playAudio();
        break;
      case 'UPDATE-REMOTE-BUYER':
        this.updateBuyer(data);
        break;
      default:
        break;
    }
  };

  updateBuyer = ({
    selectedMenuItem,
    selectedTab,
    selectedPage,
    selectedLayout
  }) => {
    const { dispatch } = this.props;
    dispatch(MenuAction.setSelectedMenuItem(selectedMenuItem));
    dispatch(
      ProjectAction.selectLayout(selectedLayout, selectedPage, selectedTab)
    );
  };

  playAudio = () => {
    const audio = new Audio(sessionStartFile);
    audio.play();
  };

  handleOffline = () => {
    const { dispatch } = this.props;
    dispatch(MeetingAction.meetingDisconnect());
  };

  checkMeetingStatus = async () => {
    const { match, dispatch } = this.props;
    const { params } = match;
    const { meetingId } = params;
    const actionResponse = await dispatch(
      MeetingAction.checkMeetingStatus(meetingId)
    );
    const { isError } = actionResponse;
    if (!isError) {
      this.getPropertySections(meetingId);
    }
  };

  joinMeeting = async () => {
    const { match, dispatch } = this.props;
    const { params } = match;
    const { meetingId } = params;
    const { body, userAgent, device, latitude, longitude, so } = this.state;
    const obj = {
      ...body,
      userAgent,
      device,
      latitude,
      longitude,
      so
    };
    const actionResponse = await dispatch(MeetingAction.joinMeeting(meetingId));
    const { isError } = actionResponse;
    if (!isError) {
      const { model } = actionResponse;
      const { meeting } = model;
      const { status } = meeting;
      if (status === 'active') {
        dispatch(MeetingAction.getBuyerTwilioTokenV2(meetingId, obj));
        this.subscribeToChannel(meetingId);
        this.getPropertySections(meetingId);
      } else {
        this.checkMeetingStatusChange();
      }
    }
    this.setState({ startOnboarding: false });
  };

  setName = (body) => {
    this.setState({ body, startOnboarding: true });
  };

  getPropertySections = (meetingId) => {
    const { dispatch, socket } = this.props;
    dispatch(ProjectAction.getSectionsForClient(meetingId));

    socketEmit(socket, 'message-meeting', {
      id: meetingId,
      event: 'REMOTE-BUYER-CONNECTED',
      data: {
        type: 'REMOTE-BUYER-CONNECTED'
      }
    });
  };

  getPropertyLanguage = (projectId) => {
    const { dispatch } = this.props;
    dispatch(LanguageAction.getPropertyLanguage(projectId));
  };

  subscribeToChannel = (meetingId) => {
    const { socket } = this.props;
    socketEmit(socket, 'subscribe-meeting', { id: meetingId });
  };

  checkMeetingStatusChange = () => {
    setTimeout(() => {
      const { body } = this.state;
      this.joinMeeting(body);
    }, 5000);
  };

  toggleVideoCall = () => {
    this.setState((prevState) => ({ videoCallOpen: !prevState.videoCallOpen }));
  };

  togglePrivatePanel = () => {
    this.setState((prevState) => ({
      privatePanelVisible: !prevState.privatePanelVisible
    }));
  };

  render() {
    const {
      meetingIsActive,
      classes,
      socket,
      meetingInfo,
      loading,
      general,
      onboarding,
      history
    } = this.props;
    const { videoCallOpen, startOnboarding, privatePanelVisible } = this.state;
    const { status = '', dateTime = '', deletedAt = '' } = meetingInfo;
    const { logoUri } = general;
    if (!meetingIsActive || status !== 'active') {
      return startOnboarding ? (
        <Onboarding onboarding={onboarding} joinMeeting={this.joinMeeting} />
      ) : (
        <ClientWelcome
          setName={this.setName}
          status={status}
          dateTime={dateTime}
          logo={logoUri}
          deletedAt={deletedAt}
          loading={loading}
          history={history}
        />
      );
    }
    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.mainContainer}
      >
        <FullPageLoader loading={loading} />
        <SharedSession
          socket={socket}
          videoCallOpen={videoCallOpen}
          privatePanelVisible={privatePanelVisible}
        />
        <PrivatePanel
          privatePanelVisible={privatePanelVisible}
          togglePrivatePanel={this.togglePrivatePanel}
          videoCallOpen={videoCallOpen && meetingIsActive}
          socket={socket}
          openScheduleMeetingForm={this.openScheduleMeetingForm}
          handleCopyText={this.handleCopyText}
          startMeeting={this.startMeeting}
          showDeleteModal={this.showDeleteModal}
          toggleVideoCall={this.toggleVideoCall}
        />
      </Grid>
    );
  }
}

MeetingRoomPage.propTypes = {
  match: shape({}).isRequired,
  socket: shape({}).isRequired,
  meetingInfo: shape({}).isRequired,
  classes: shape({}).isRequired,
  loading: bool.isRequired,
  general: shape({}).isRequired,
  onboarding: shape({}).isRequired,
  history: shape({}).isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

const mapStateToProps = (state) => {
  const { meetingInfo, onboarding, meetingIsActive } = state.meeting;
  const { language } = state.language;
  const { general } = state.project;
  const { loading } = state.loading;
  return {
    meetingIsActive,
    meetingInfo,
    language,
    loading,
    general,
    onboarding
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MeetingRoomPage));
