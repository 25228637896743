import React, { Component } from 'react';
import { arrayOf, shape, number, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { getParentNodeId } from '../../utils';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: '' };
  }

  componentDidMount() {
    const { socket } = this.props;
    socket.on('LIST-ITEM', this.onSelectedEvent);
  }

  onItemSelected = (e) => {
    e.preventDefault();
    const selected = e.target.id || getParentNodeId(e.target);
    const { socket, meetingId, publisher } = this.props;
    if (publisher) {
      this.setState({ selected });
      socket.emit('message-meeting', {
        id: meetingId,
        event: 'LIST-ITEM',
        data: {
          type: 'LIST-ITEM',
          selected
        }
      });
      this.resetSelected();
    }
  };

  onSelectedEvent = (data) => {
    const { selected } = data;
    this.setState({ selected });
    this.resetSelected();
  };

  resetSelected = () => {
    setTimeout(() => {
      this.setState({ selected: '' });
    }, 2000);
  };

  render() {
    const {
      classes,
      content,
      columns,
      ordered,
      publisher,
      isCover
    } = this.props;
    const { selected } = this.state;
    let listClass = classes.listContainer;
    if (isCover) {
      listClass = classes.coverListContainer;
    }
    if (!ordered) {
      return (
        <ul className={listClass}>
          {content.map(({ text }, index) => (
            <li
              key={`p-${index * 1}`}
              id={`p-${index * 1}`}
              style={{
                width: `calc(${100 / columns}% - 6%)`,
                float: 'left',
                marginRight: '5%'
              }}
              className={`${selected === `p-${index * 1}` ? 'selected' : ''} ${
                publisher ? classes.pointer : ''
              }`}
              onClick={this.onItemSelected}
            >
              {`${text} `}
            </li>
          ))}
        </ul>
      );
    }
    return (
      <ol className={classes.orderedListContainer}>
        {content.map(({ text }, index) => (
          <li
            key={`p-${index * 1}`}
            id={`p-${index * 1}`}
            style={{
              width: `calc(${100 / columns}% - 6%)`,
              float: 'left',
              marginRight: '5%'
            }}
            className={`${selected === `p-${index * 1}` ? 'selected' : ''} ${
              publisher ? classes.pointer : ''
            }`}
            onClick={this.onItemSelected}
          >
            {`${text} `}
          </li>
        ))}
      </ol>
    );
  }
}

List.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: string.isRequired,
  columns: number,
  color: string,
  ordered: bool,
  isCover: bool
};

List.defaultProps = {
  columns: 1,
  color: '#4a4a4a',
  ordered: false,
  isCover: false
};

export default withStyles(styles)(List);
