import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, func } from 'prop-types';
import { Grid, FormControl, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SessionAction from '../stores/session/actions';
import { athumLogoLogin } from '../config/assets';
import styles from '../styles';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';

class ForgotPasswordPage extends Component {
  constructor() {
    super();
    this.state = {
      mail: '',
      showEmailMessage: false
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { mail } = this.state;
    const actionResponse = await dispatch(SessionAction.forgotPassword(mail));
    const { isError } = actionResponse;
    if (!isError) {
      this.showEmailMessage();
    }
  };

  goToLobby = () => {
    const { history } = this.props;
    history.push('/');
  };

  showEmailMessage = () => {
    this.setState({ showEmailMessage: true });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  render() {
    const { mail, showEmailMessage } = this.state;
    const { classes, error } = this.props;
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.forgotPasswordMainContainer}
      >
        <img
          src={athumLogoLogin.src}
          alt={athumLogoLogin.alt}
          style={{ margin: '40px 0 10% 0' }}
        />
        {showEmailMessage ? (
          <div className={classes.forgotPasswordFormContainer}>
            <p style={{ textAlign: 'center', width: '318px' }}>
              Please check your inbox for the email we just sent you with
              instructions for resetting your password
            </p>
            <CustomButton type="button" onClick={this.goToLobby}>
              Continue
            </CustomButton>
          </div>
        ) : (
          <form
            className={classes.forgotPasswordFormContainer}
            onSubmit={this.handleSubmit}
            noValidate
            autoComplete="off"
          >
            {error ? (
              <FormHelperText className={classes.errorMessage}>
                {error}
              </FormHelperText>
            ) : (
              <FormHelperText className={classes.errorMessage} />
            )}
            <FormControl>
              <CustomLabel htmlFor="mail">Email</CustomLabel>
              <CustomInput
                id="mail"
                type="mail"
                value={mail}
                onChange={this.handleInputChange}
              />
            </FormControl>
            <CustomButton type="submit">Verify Email</CustomButton>
          </form>
        )}
      </Grid>
    );
  }
}

ForgotPasswordPage.propTypes = {
  history: shape({}).isRequired,
  classes: shape({}).isRequired,
  error: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { message: error } = state.error;
  return {
    language,
    error
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotPasswordPage));
