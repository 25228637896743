import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, func, oneOfType, bool } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import MeetingAction from '../../stores/meeting/actions';
import { closeIcon } from '../../config/assets';
import {
  deleteMeeting,
  deleteButton,
  cancelButton
} from '../../config/messages';

class DeleteMeetingModal extends Component {
  deleteMeeting = async () => {
    const {
      dispatch,
      token,
      meetingToBeDeleted,
      hideDeleteModal,
      getMeetingList
    } = this.props;
    if (token) {
      const actionResponse = await dispatch(
        MeetingAction.deleteMeeting(meetingToBeDeleted)
      );
      const { isError } = actionResponse;
      if (!isError) {
        getMeetingList();
      }
    }
    hideDeleteModal();
  };

  render() {
    const {
      classes,
      language,
      meetingToBeDeleted,
      hideDeleteModal
    } = this.props;
    return (
      <Modal open={meetingToBeDeleted} onClose={hideDeleteModal}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.modalContainer}
        >
          <img
            src={closeIcon.src}
            alt={closeIcon.alt}
            className={classes.closeIcon}
            onClick={this.hideDeleteModal}
          />
          <h2>{deleteMeeting[language]}</h2>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <button
              type="button"
              className={classes.cancelButton}
              onClick={hideDeleteModal}
            >
              {cancelButton[language]}
            </button>
            <button
              type="button"
              className={classes.deleteButton}
              onClick={this.deleteMeeting}
            >
              {deleteButton[language]}
            </button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

DeleteMeetingModal.propTypes = {
  classes: shape({}).isRequired,
  token: string.isRequired,
  language: string.isRequired,
  dispatch: func.isRequired,
  meetingToBeDeleted: oneOfType([bool, string]).isRequired,
  hideDeleteModal: func.isRequired,
  getMeetingList: func.isRequired
};

const mapStateToProps = (state) => {
  let { token } = state.session;
  const { language } = state.language;
  if (!token) {
    token = sessionStorage.getItem('token');
  }
  return {
    token,
    language
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeleteMeetingModal));
