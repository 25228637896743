const styles = (theme) => ({
  liveTourContainer: {
    width: '100%',
    height: '100%'
  },
  videoContainer: {
    padding: '10px 48px',
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  },
  sharingButtonContiner: {
    width: '100%'
  },
  sharingButton: {
    width: 258
  },
  webcamContainer: {
    padding: '10px 0px',
    height: 'calc(100% - 200px)',
    width: '100%',
    position: 'relative',
    '& video': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      transform: 'scale(-1, 1)',
      borderRadius: 10
    }
  },
  webcamContainerWatcher: {
    padding: '10px 0px',
    height: '100%',
    width: '100%',
    position: 'relative',
    '& video': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      transform: 'scale(-1, 1)',
      borderRadius: 10
    }
  },
  parragraph: {
    margin: 0,
    padding: '10px 48px 16px 48px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 300
  },
  flipButton: {
    position: 'absolute',
    left: 10,
    bottom: 20,
    height: 40,
    width: 40,
    background: 'rgba(71, 71, 71, 0.69)',
    backdropFilter: 'blur(10px)',
    '&:focus': {
      outline: 'none'
    }
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 1,
    '&.white-background': {
      backgroundColor: 'white'
    },
    '&.scene': {
      backgroundColor: 'transparent'
    },
    ' &.hide': {
      transition: 'opacity 1s ease-out',
      opacity: 0,
      height: 0,
      width: 0,
      overflow: 'hidden'
    },
    '& .loader-image-container': {
      backgroundColor: 'white',
      borderRadius: '50%'
    },
    '&.display-none': {
      display: 'none'
    }
  },
  textContainer: {
    textAlign: 'center',
    '& h1': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 21,
      color: '#6C6C6C'
    }
  }
});

export default styles;
