import React from 'react';
import { shape, func, bool } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import styles from '../../styles';

const UpdatePricesModal = ({
  classes,
  pricesUpdateConfirmationModal,
  pricesUpdated,
  closePriceEdition,
  updatePrices,
  closePricesUpdateConfirmationModal
}) => (
  <Modal
    open={pricesUpdateConfirmationModal}
    onClose={pricesUpdated ? closePriceEdition : () => {}}
  >
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.pricesModalContainer}
    >
      {pricesUpdated ? (
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
          className={classes.pricesModalContent}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.successUpdateIconContainer}
          >
            <Check className={classes.successUpdateIcon} />
          </Grid>
          <h1 className={classes.priceModalTitle}>
            Prices have been successfully updated
          </h1>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <button
              type="button"
              className={classes.modalConfirmationButton}
              onClick={closePriceEdition}
            >
              Go back to home
            </button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
          className={classes.pricesModalContent}
        >
          <h1 className={classes.priceModalTitle}>
            Are you sure you want to update the prices?
          </h1>
          <p className={classes.priceModalParagraph}>
            The updated prices will be shown across all your organization
            accounts
          </p>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.buttonsContainer}
          >
            <button
              type="button"
              className={classes.modalCancelButton}
              onClick={closePricesUpdateConfirmationModal}
            >
              No, go back
            </button>
            <button
              type="button"
              className={classes.modalConfirmationButton}
              onClick={updatePrices}
            >
              Yes, update prices
            </button>
          </Grid>
        </Grid>
      )}
    </Grid>
  </Modal>
);

UpdatePricesModal.propTypes = {
  classes: shape({}).isRequired,
  pricesUpdateConfirmationModal: bool.isRequired,
  pricesUpdated: bool.isRequired,
  closePriceEdition: func.isRequired,
  updatePrices: func.isRequired,
  closePricesUpdateConfirmationModal: func.isRequired
};

export default withStyles(styles)(UpdatePricesModal);
