import React, { Component } from 'react';
import { shape, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NeerByPlaces from '../NeerByPlaces';
import { getMarkers } from '../../utils';
import styles from './styles';
import mapStyles from './mapStyles.json';
import { socketEmit } from '../../services/socket';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectMarker: null,
      currentShownMarkers: [],
      map: null,
      selected: '',
      places: []
    };
    this.Map = null;
  }

  componentDidMount() {
    this.initMap();
    this.onMessageReceived();
  }

  onMessageReceived = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('MAP-BUTTON', this.handleMarkers);
    }
  };

  initMap = () => {
    const { content } = this.props;
    const { project, categories } = content;
    const { latitude, longitude, markerIcon: icon } = project;
    const places = categories;

    const { maps } = window.google;
    const lat = Number(latitude);
    const lng = Number(longitude);
    const options = {
      center: { lat, lng },
      zoom: 15,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM
      },
      styles: mapStyles
    };

    const map = new maps.Map(this.Map, options);

    const marker = new maps.Marker({
      position: { lat, lng },
      map,
      animation: maps.Animation.DROP,
      icon: {
        url: icon,
        scaledSize: new maps.Size(50, 50)
      }
    });
    this.setState({
      projectMarker: marker,
      map,
      places
    });
  };

  loadJS = (src) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  };

  handleMarkers = (data) => {
    const { selected } = data;
    this.addMarkers(selected);
  };

  addMarkers = (selected) => {
    const {
      socket,
      publisher,
      activeMeeting,
      isCustomer,
      sessionID,
      content
    } = this.props;
    const { project } = content;
    const { latitude, longitude } = project;
    const { map, selected: stateSelected, places } = this.state;
    const markers = getMarkers(places, selected);
    const { maps } = window.google;
    let wantToSelect = selected;
    this.removeMarkers();
    const currentShownMarkers = [];
    const bounds = new maps.LatLngBounds();
    if (wantToSelect !== stateSelected) {
      const defaultLatLng = new maps.LatLng(latitude, longitude);
      bounds.extend(defaultLatLng);
      for (let i = 0; i < markers.length; i += 1) {
        const item = markers[i];
        const { location, name } = item;
        const myLatLng = new maps.LatLng(location.lat, location.lng);
        const marker = new maps.Marker({
          position: myLatLng,
          map,
          animation: maps.Animation.DROP,
          title: name
        });
        currentShownMarkers.push(marker);
        bounds.extend(myLatLng);
      }
      map.fitBounds(bounds);
    } else {
      wantToSelect = '';
    }

    this.setState({
      currentShownMarkers,
      selected: wantToSelect,
      places
    });

    if (publisher || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'MAP-BUTTON',
        data: {
          type: 'MAP-BUTTON',
          selected
        }
      });
    }
  };

  removeMarkers = () => {
    const { currentShownMarkers } = this.state;

    for (let i = 0; i < currentShownMarkers.length; i += 1) {
      currentShownMarkers[i].setMap(null);
    }
  };

  render() {
    const { classes } = this.props;
    const { selected, places } = this.state;
    return (
      <Grid container direction="column" className={classes.mapContainer}>
        <Grid ref={(ref) => (this.Map = ref)} className={classes.map} />
        <NeerByPlaces
          places={places}
          selected={selected}
          addMarkers={this.addMarkers}
        />
      </Grid>
    );
  }
}

Map.propTypes = {
  classes: shape({}).isRequired,
  content: shape({}).isRequired,
  socket: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  publisher: bool.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired
};

const mapStateToProps = (state) => {
  const { sessionID } = state.session;
  const { activeMeeting, isCustomer } = state.meeting;
  return {
    activeMeeting,
    isCustomer,
    sessionID
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Map));
