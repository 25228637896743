import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppRouter from './routers/AppRouter';
import configureStore from './stores/rootStore';

const jsx = () => (
  <CookiesProvider>
    <Provider store={configureStore()}>
      <CssBaseline />
      <AppRouter />
    </Provider>
  </CookiesProvider>
);

export default jsx;
