const styles = (theme) => {
  const {
    leftMenu = {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69',
      svgInactiveColor: '#6c6c6c',
      svgActiveColor: '#ec6b69',
      alertColor: '#ec6b69',
      svg: {
        inactive: 'brightness(0) invert(42.3%)',
        active:
          'invert(65%) sepia(82%) saturate(2200%) hue-rotate(316deg) brightness(109%) contrast(70%)'
      }
    }
  } = theme;
  return {
    avatarContainer: {
      paddingLeft: 13,
      paddingRight: 13,
      '& h5': {
        marginBottom: 0,
        marginLeft: 5,
        fontSize: 14
      }
    },
    avatarBadgeWithName: {
      width: '90%'
    },
    avatar: {
      width: 31,
      height: 31,
      '& img': {
        filter: leftMenu.svg.inactive
      }
    },
    dropdownIcon: {
      filter: leftMenu.svg.inactive,
      height: 15
    },
    menuIcon: {
      fontSize: 20
    }
  };
};

export default styles;
