import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const CallButtons = ({ buttons, classes, direction }) => (
  <Grid
    container
    justify={direction === 'column' ? 'flex-end' : 'flex-start'}
    alignItems={direction === 'column' ? 'flex-end' : 'center'}
    direction={direction}
    className={
      direction === 'column'
        ? classes.callButtonsContainer
        : classes.sessionButtonsContainer
    }
  >
    {buttons.map(({ icon, offIcon, action, isActive }, index) => (
      <img
        key={`${index.toString()}`}
        src={isActive ? icon.src : offIcon.src}
        alt={isActive ? icon.alt : offIcon.alt}
        onClick={action}
        className={classes.callButtonIcon}
      />
    ))}
  </Grid>
);

CallButtons.propTypes = {
  classes: shape({}).isRequired,
  buttons: arrayOf(shape({})).isRequired,
  direction: string
};

CallButtons.defaultProps = {
  direction: 'column'
};

export default withStyles(styles)(CallButtons);
