const styles = () => ({
  callOutContainer: {
    position: 'absolute',
    background: '#FAFAFA',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    width: 160,
    height: 190,
    zIndex: 3,
    top: 8,
    left: 8,
    overflow: 'hidden'
  },

  overlayContainer: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%'
  }
});

export default styles;
