import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, bool, func, number, arrayOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import styles from '../styles';
import LanguageActions from '../stores/language/actions';
import ProjectAction from '../stores/project/actions';
import MeetingAction from '../stores/meeting/actions';
import TwilioAction from '../stores/twilio/actions';
import PrivatePanel from '../components/PrivatePanel';
import SharedSession from '../components/SharedSession';
import CreateMeetingModal from '../components/Modals/CreateMeetingModal';
import DeleteMeetingModal from '../components/Modals/DeleteMeetingModal';
import FullPageLoader from '../components/Loader/FullPageLoader';
import { socketEmit } from '../services/socket';

class LobbyPage extends Component {
  constructor() {
    super();
    this.state = {
      scheduleMeetingForm: false,
      videoCallOpen: true,
      meetingToBeDeleted: false,
      privatePanelVisible: true
    };
  }

  componentDidMount() {
    const { dispatch, token, socket } = this.props;
    const userFromSession = JSON.parse(sessionStorage.getItem('user')) || {};
    if (JSON.stringify(userFromSession) === '{}') {
      this.goToLogin();
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('offline', this.handleOffline);
    if (token) {
      this.getMeetingsList();
      this.getPropertyToShow();
      dispatch(TwilioAction.createTwilioObject());
      dispatch(MeetingAction.setPublisher(token !== ''));
      socket.on('REMOTE-BUYER-CONNECTED', this.onMessage);
    }
  }

  onMessage = (data) => {
    const { type } = data;
    switch (type) {
      case 'REMOTE-BUYER-CONNECTED':
        this.sendUpdateRemoteBuyer();
        break;
      default:
        break;
    }
  };

  sendUpdateRemoteBuyer = () => {
    const {
      selectedMenuItem,
      selectedTab,
      selectedPage,
      selectedLayout,
      activeMeeting,
      socket
    } = this.props;
    const { id: meetingId } = activeMeeting;
    socketEmit(socket, 'message-meeting', {
      id: meetingId,
      event: 'UPDATE-REMOTE-BUYER',
      data: {
        type: 'UPDATE-REMOTE-BUYER',
        selectedMenuItem,
        selectedTab,
        selectedPage,
        selectedLayout
      }
    });
  };

  handleOffline = () => {
    const { dispatch } = this.props;
    dispatch(MeetingAction.meetingDisconnect());
  };

  goToLogin = () => {
    const { history } = this.props;
    history.push('/login');
  };

  openScheduleMeetingForm = () => {
    this.setState({ scheduleMeetingForm: true });
  };

  closeScheduleMeetingForm = () => {
    this.setState({ scheduleMeetingForm: false });
  };

  getMeetingsList = async () => {
    const { dispatch, token, checkUserInSession } = this.props;
    if (token) {
      const body = {
        status: ['scheduled', 'active']
      };
      const actionResponse = await dispatch(MeetingAction.listMeetings(body));
      const { isError } = actionResponse;
      if (isError) {
        checkUserInSession();
      }
    }
  };

  getPrices = (token, projectId) => {
    const { dispatch } = this.props;
    if (token) {
      dispatch(ProjectAction.getPrices(projectId));
    }
  };

  getPropertyToShow = async () => {
    const { token, dispatch } = this.props;
    if (token) {
      const actionResponse = await dispatch(ProjectAction.getProjects());
      const { isError } = actionResponse;
      if (!isError) {
        const { model } = actionResponse;
        this.getPropertySections(token, model.selectedProject);
        this.getPrices(token, model.selectedProject);
        this.getPropertyLanguage(model.selectedProject);
      }
    }
  };

  getPropertySections = (token, projectId) => {
    const { dispatch } = this.props;
    if (token) {
      dispatch(ProjectAction.getSections(projectId));
    }
  };

  getPropertyLanguage = (projectId) => {
    const { dispatch } = this.props;
    dispatch(LanguageActions.getPropertyLanguage(projectId));
  };

  startMeeting = async (meetingId) => {
    const { token, propertiesSections, projects, dispatch } = this.props;
    if (token) {
      const body = {
        status: 'active'
      };
      const actionResponse = await dispatch(
        MeetingAction.startMeeting(meetingId, body)
      );
      const { isError } = actionResponse;
      if (!isError) {
        const { model } = actionResponse;
        dispatch(MeetingAction.getTwilioToken(meetingId));
        this.subscribeToChannel(meetingId);
        const actionResponse2 = await dispatch(
          ProjectAction.getSections(
            model.meeting.projectId,
            propertiesSections,
            projects
          )
        );
        const { isError: isError2 } = actionResponse2;
        if (!isError2) {
          dispatch(ProjectAction.getPrices(model.meeting.projectId));
        }
      }
    }
  };

  showDeleteModal = (meetingId) => {
    this.setState({ meetingToBeDeleted: meetingId });
  };

  hideDeleteModal = () => {
    this.setState({ meetingToBeDeleted: false });
  };

  subscribeToChannel = (meetingId) => {
    const { socket } = this.props;
    socketEmit(socket, 'subscribe-meeting', { id: meetingId });
  };

  handleCopyText = (meetingId) => {
    const location = window.location.origin;
    copy(`${location}/meeting/${meetingId}`);
  };

  toggleVideoCall = () => {
    this.setState((prevState) => ({ videoCallOpen: !prevState.videoCallOpen }));
  };

  togglePrivatePanel = () => {
    this.setState((prevState) => ({
      privatePanelVisible: !prevState.privatePanelVisible
    }));
  };

  render() {
    const {
      classes,
      meetingIsActive,
      socket,
      loading,
      history,
      cookies,
      loadingTheme
    } = this.props;
    const {
      scheduleMeetingForm,
      videoCallOpen,
      meetingToBeDeleted,
      privatePanelVisible
    } = this.state;
    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.mainContainer}
      >
        <CreateMeetingModal
          scheduleMeetingForm={scheduleMeetingForm}
          closeScheduleMeetingForm={this.closeScheduleMeetingForm}
        />
        <DeleteMeetingModal
          meetingToBeDeleted={meetingToBeDeleted}
          hideDeleteModal={this.hideDeleteModal}
          getMeetingList={this.getMeetingsList}
        />
        <FullPageLoader loading={loading} />
        {!loadingTheme && (
          <>
            <SharedSession
              socket={socket}
              videoCallOpen={videoCallOpen && meetingIsActive}
              privatePanelVisible={privatePanelVisible}
            />
            <PrivatePanel
              privatePanelVisible={privatePanelVisible}
              togglePrivatePanel={this.togglePrivatePanel}
              videoCallOpen={videoCallOpen && meetingIsActive}
              socket={socket}
              openScheduleMeetingForm={this.openScheduleMeetingForm}
              handleCopyText={this.handleCopyText}
              startMeeting={this.startMeeting}
              showDeleteModal={this.showDeleteModal}
              toggleVideoCall={this.toggleVideoCall}
              history={history}
              cookies={cookies}
            />
          </>
        )}
      </Grid>
    );
  }
}

LobbyPage.propTypes = {
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  token: string.isRequired,
  activeMeeting: shape({}).isRequired,
  socket: shape({}).isRequired,
  loading: bool.isRequired,
  checkUserInSession: func.isRequired,
  selectedMenuItem: number.isRequired,
  selectedTab: number.isRequired,
  selectedPage: number.isRequired,
  propertiesSections: shape({}).isRequired,
  selectedLayout: shape({}),
  cookies: shape({}).isRequired,
  projects: arrayOf(shape({})).isRequired,
  loadingTheme: bool.isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

LobbyPage.defaultProps = {
  selectedLayout: null
};

const mapStateToProps = (state) => {
  let { token } = state.session;
  const { activeMeeting, meetingIsActive } = state.meeting;
  if (!token) {
    token = sessionStorage.getItem('token');
  }
  const { selectedMenuItem } = state.menu;
  const {
    selectedTab,
    selectedPage,
    selectedLayout,
    propertiesSections,
    projects,
    loadingTheme
  } = state.project;
  const { loading } = state.loading;
  return {
    token,
    activeMeeting,
    loading,
    selectedMenuItem,
    selectedTab,
    selectedPage,
    selectedLayout,
    propertiesSections,
    projects,
    loadingTheme,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LobbyPage));
