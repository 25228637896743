import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomSelectInput = withStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 20
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 12
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#ededed',
      border: 'none',
      fontSize: 14,
      padding: '11px 12px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      color: '#4A4A4A',
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: `${theme.content.activeFontColor} 0 0 0 1px`
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })
)(InputBase);

export default CustomSelectInput;
