import React, { Component } from 'react';
import { shape, string, arrayOf, number, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProjectAction from '../../../stores/project/actions';
import MenuAction from '../../../stores/menu/actions';
import CustomTabs from '../../CustomTabs';
import CustomTab from '../../CustomTab';
import ContentTypeContainer from '../../ContentTypeContainer';
import SelectedLayoutHeader from './SelectedLayoutHeader';
import BackToInteractiveMap from './BackToInteractiveMap';
import styles from '../../../styles';
import { socketEmit } from '../../../services/socket';

class SharedWithTabs extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { selectedLayout, selectedTab } = props;
    this.state = {
      selectedTab: selectedLayout ? 0 : selectedTab
    };
    this.headerContainer = null;
  }

  componentDidMount = () => {
    this.mounted = true;
    this.handleSocketMessage();
  };

  componentDidUpdate = (prevProps) => {
    const {
      subSections,
      selectedLayout,
      selectedTab,
      selectedPage
    } = this.props;
    const {
      subSections: prevSubSections,
      selectedLayout: prevSelectedLayout,
      selectedTab: prevSelectedTab
    } = prevProps;
    if (
      JSON.stringify(subSections) !== JSON.stringify(prevSubSections) ||
      selectedTab !== prevSelectedTab
    ) {
      this.handleChange(
        null,
        selectedLayout ? 0 : selectedTab,
        (prevSelectedLayout && !selectedLayout) ||
          (!prevSelectedLayout && !selectedLayout)
          ? selectedPage
          : 1
      );
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('MENU', (data) => {
        const { type, selectedMenuItem } = data;
        switch (type) {
          case 'MENU':
            this.handleSelectMenuItem(selectedMenuItem);
            break;
          default:
            break;
        }
      });
      socket.on('SUBMENU', (data) => {
        const { type, selectedTab, selectedPage } = data;
        switch (type) {
          case 'SUBMENU':
            this.handleChange(null, selectedTab, selectedPage);
            break;

          default:
            break;
        }
      });
      socket.on('ACTIVE-FLOORPLAN', (data) => {
        const { type, selectedLayout } = data;
        switch (type) {
          case 'ACTIVE-FLOORPLAN':
            this.unselectLayout(selectedLayout);
            break;
          default:
            break;
        }
      });
    }
  };

  unselectLayout = (selectedLayout) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      selectedPage,
      selectedTab,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    dispatch(
      ProjectAction.selectLayout(selectedLayout, selectedPage, selectedTab)
    );
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout
        }
      });
    }
  };

  handleChange = (event, selectedTab, selectedPage) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      selectedLayout,
      sessionID,
      isCustomer,
      dispatch
    } = this.props;
    if (this.mounted) {
      this.setState({ selectedTab });
    }
    if (!selectedLayout) {
      dispatch(ProjectAction.selectTab(selectedTab, selectedPage));
    }
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'SUBMENU',
        data: {
          type: 'SUBMENU',
          selectedTab,
          selectedPage
        }
      });
    }
  };

  handleSelectMenuItem = (selectedMenuItem) => {
    const {
      socket,
      activeMeeting,
      meetingIsActive,
      token,
      dispatch
    } = this.props;
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'MENU',
        data: {
          type: 'MENU',
          selectedMenuItem
        }
      });
    }
    dispatch(MenuAction.setSelectedMenuItem(selectedMenuItem));
  };

  selectLayout = (selectedLayout, selectedTab, currentPage) => {
    const {
      socket,
      token,
      activeMeeting,
      meetingIsActive,
      dispatch
    } = this.props;
    dispatch(
      ProjectAction.selectLayout(selectedLayout, currentPage, selectedTab)
    );
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout,
          selectedTab,
          currentPage
        }
      });
    }
  };

  goToInteractiveMap = (selectedMenuItem, selectedTab, selectedPage) => {
    const { dispatch } = this.props;
    this.handleSelectMenuItem(selectedMenuItem);
    this.selectLayout(null, selectedTab, selectedPage);
    dispatch(MenuAction.storeInteractiveMapLocation({}));
    dispatch(ProjectAction.storeInteractiveMapLocation({}));
  };

  render() {
    const {
      classes,
      socket,
      token,
      activeMeeting,
      subSections,
      selectedLayout,
      customer,
      privatePanelVisible,
      videoCallOpen,
      publisher,
      selectedPage,
      selectedMenuItem
    } = this.props;
    const { id = '' } = activeMeeting;
    const { selectedTab } = this.state;
    const clientNavigation = JSON.stringify(customer) !== '{}';
    console.log('selectedLayout', selectedLayout);
    return (
      <Grid container className={classes.sharedSessionContentContainer}>
        {subSections.length > 1 && (
          <CustomTabs
            value={selectedTab}
            onChange={token || clientNavigation ? this.handleChange : () => {}}
            indicatorColor="primary"
            textColor="primary"
            variant={subSections.length < 3 ? 'standard' : 'scrollable'}
            scrollButtons={subSections.length < 3 ? 'off' : 'auto'}
            className={classes.sharedSessionTabsContainer}
          >
            {subSections.map(({ name }) => (
              <CustomTab
                key={`tab-${name}`}
                label={name}
                wrapped={subSections.length >= 3}
              />
            ))}
          </CustomTabs>
        )}
        <Grid
          container
          justify="center"
          alignItems="center"
          className={
            subSections.length > 1
              ? classes.sharedSessionTabContentContainer
              : classes.noTabsContainer
          }
          ref={(ref) => {
            this.headerContainer = ref;
          }}
        >
          <BackToInteractiveMap goToInteractiveMap={this.goToInteractiveMap} />
          {selectedLayout && (
            <SelectedLayoutHeader
              subSections={subSections}
              selectedTab={selectedTab}
              unselectLayout={this.unselectLayout}
            />
          )}
          {subSections.map(({ content, type, name, columns = 1 }, index) => {
            if (selectedTab === index) {
              return (
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  key={`section-${name}`}
                  className={
                    selectedLayout
                      ? classes.layoutSubSectionContainer
                      : classes.subSectionContainer
                  }
                >
                  <ContentTypeContainer
                    content={content}
                    type={type}
                    socket={socket}
                    publisher={publisher}
                    meetingId={id}
                    columns={columns}
                    selectedTab={selectedTab}
                    selectedPage={selectedPage}
                    selectedMenuItem={selectedMenuItem}
                    privatePanelVisible={privatePanelVisible}
                    videoCallOpen={videoCallOpen}
                    tabs={subSections.length > 1}
                  />
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </Grid>
    );
  }
}

SharedWithTabs.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  token: string.isRequired,
  subSections: arrayOf(shape({})).isRequired,
  selectedLayout: shape({}),
  selectedPage: number,
  selectedTab: number,
  customer: shape({}).isRequired,
  privatePanelVisible: bool.isRequired,
  videoCallOpen: bool.isRequired,
  publisher: bool.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired,
  selectedMenuItem: number,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

SharedWithTabs.defaultProps = {
  selectedLayout: null,
  selectedPage: 1,
  selectedTab: 0,
  selectedMenuItem: 1
};

const mapStateToProps = (state) => {
  const { token, sessionID } = state.session;
  const {
    activeMeeting,
    customer,
    publisher,
    isCustomer,
    meetingIsActive
  } = state.meeting;
  const { selectedMenuItem } = state.menu;
  const { selectedLayout, selectedPage, selectedTab } = state.project;
  return {
    selectedMenuItem,
    activeMeeting,
    token,
    selectedLayout,
    selectedPage,
    selectedTab,
    customer,
    publisher,
    isCustomer,
    sessionID,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SharedWithTabs));
