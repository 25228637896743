import React from 'react';
import { shape, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Pagination from './Pagination';
import Watcher from './Watcher';
import styles from './styles';

const Calculator = ({ classes, publisher, socket, content }) => (
  <Grid container className={classes.calculator}>
    {!publisher ? (
      <Watcher socket={socket} />
    ) : (
      <Pagination
        socket={socket}
        content={content}
        sections={content.sections}
      />
    )}
  </Grid>
);

Calculator.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  content: shape({}).isRequired
};

export default withStyles(styles)(Calculator);
