const styles = (theme) => ({
  paragraph: {
    margin: 0,
    fontSize: 16,
    fontWeight: 300,
    padding: '10px 48px 16px 48px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 36px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 24px'
    }
  },
  cardParagraph: {
    margin: 0,
    fontSize: 12,
    fontWeight: 300,
    padding: '5px 10px 0 10px',
    color: '#4a4a4a'
  },
  link: {
    color: '#56B0C0',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

export default styles;
