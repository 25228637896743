import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, func } from 'prop-types';
import { Grid, FormControl, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SessionAction from '../stores/session/actions';
import ProjectAction from '../stores/project/actions';
import { athumLogoLogin } from '../config/assets';
import styles from '../styles';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';
import PasswordInput from '../components/PasswordInput';
import {
  isSupported,
  typeOfDevice,
  getBrowser,
  getOperatingSystem
} from '../utils';

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      mail: '',
      password: '',
      showPassword: false,
      userAgent: '',
      device: '',
      latitude: '',
      longitude: '',
      so: ''
    };
  }

  componentDidMount() {
    const { match, history, dispatch } = this.props;
    if (!isSupported()) {
      history.push('/error/415');
    } else {
      const { params } = match;
      const { builder = null } = params;
      if (builder) {
        dispatch(ProjectAction.getBuilderInfo(builder));
      }
      this.getLocation();
      this.setState({
        userAgent: getBrowser(),
        device: typeOfDevice(),
        so: getOperatingSystem()
      });
    }
  }

  getLocation = () => {
    navigator.geolocation.getCurrentPosition(this.getLatLon);
  };

  getLatLon = (position) => {
    const { latitude, longitude } = position.coords;
    this.setState({ latitude, longitude });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { cookies, dispatch } = this.props;
    const {
      mail,
      password,
      userAgent,
      device,
      latitude,
      longitude,
      so
    } = this.state;
    const log = {
      userAgent,
      device,
      latitude,
      longitude,
      so
    };
    const actionResponse = await dispatch(
      SessionAction.login(mail, password, cookies, log)
    );
    const { isError } = actionResponse;
    if (!isError) {
      this.goToLobby();
    }
  };

  goToLobby = () => {
    const { history } = this.props;
    history.push('/');
  };

  goToForgotPassword = () => {
    const { history } = this.props;
    history.push('/forgot-password');
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  };

  render() {
    const { mail, password, showPassword } = this.state;
    const { classes, error, match, builderInfo } = this.props;
    const { params } = match;
    const { builder = null } = params;
    const { logo = '', name = '' } = builderInfo;
    return (
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.mainContainerLogin}
      >
        {builder && logo ? (
          <img src={logo} alt={name} />
        ) : (
          <img src={athumLogoLogin.src} alt={athumLogoLogin.alt} />
        )}
        <form
          className={classes.formContainer}
          onSubmit={this.handleSubmit}
          noValidate
          autoComplete="off"
        >
          {error ? (
            <FormHelperText className={classes.errorMessage}>
              {error}
            </FormHelperText>
          ) : (
            <FormHelperText className={classes.errorMessage} />
          )}
          <FormControl className={classes.formControlLogin}>
            <CustomLabel htmlFor="mail">Email</CustomLabel>
            <CustomInput
              id="mail"
              type="mail"
              value={mail}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <FormControl className={classes.formControlLogin}>
            <CustomLabel htmlFor="mail">Password</CustomLabel>
            <PasswordInput
              handleClickShowPassword={this.handleClickShowPassword}
              showPassword={showPassword}
              password={password}
              handleInputChange={this.handleInputChange}
            />
          </FormControl>
          <Grid container className={classes.buttonContainerLogin}>
            <CustomButton type="submit">Login</CustomButton>
          </Grid>
        </form>
        <button
          type="button"
          onClick={this.goToForgotPassword}
          className={classes.forgotPasswordButton}
        >
          Forgot password?
        </button>
        {builder && (
          <img
            src={athumLogoLogin.src}
            alt={athumLogoLogin.alt}
            className={classes.athumLogoLogin}
          />
        )}
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  history: shape({}).isRequired,
  match: shape({}).isRequired,
  cookies: shape({}).isRequired,
  classes: shape({}).isRequired,
  error: string.isRequired,
  builderInfo: shape({}).isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { message: error } = state.error;
  const { builderInfo } = state.project;
  return {
    language,
    error,
    builderInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginPage));
