const styles = (theme) => ({
  privatePanelContainer: {
    width: '100%',
    height: 'auto',
    maxHeight: '97%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    color: '#6c6c6c',
    paddingBottom: 16,
    overflow: 'hidden',
    '& h2': {
      width: '100%',
      margin: 0,
      textAlign: 'left',
      fontSize: 16,
      fontWeight: 'bold',
      paddingTop: 5,
      paddingLeft: 16,
      paddingBottom: 13
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#fafafa',
      color: '#6c6c6c',
      overflow: 'hidden',
      '& h2': {
        width: '100%',
        margin: 0,
        textAlign: 'left',
        fontSize: 16,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingLeft: 16,
        paddingBottom: 13
      }
    }
  },
  noMeetingsContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 17,
    '& h4': {
      fontSize: 21,
      fontWeight: 300,
      textAlign: 'center',
      width: 300
    }
  },
  nextMeetingContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 17,
    '& h4': {
      fontSize: 21,
      fontWeight: 300,
      textAlign: 'center',
      width: 300
    }
  },
  buttonIcon: {
    marginRight: 7
  },
  meetingsListContainer: {
    width: '100%',
    height: 'auto',
    maxHeight: 400,
    overflow: 'auto',
    marginBottom: 17,
    '& h3': {
      width: '100%',
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'left',
      paddingLeft: 14,
      marginTop: 24
    }
  },
  dayMeetingsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20,
    '& p': {
      color: '#ed6b6a',
      width: '100%',
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'left',
      paddingLeft: 14
    }
  },
  dayMeetingsDetail: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: 50,
    padding: 14
  },
  meetingIconsContainer: {
    width: 120,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    '& img': {
      cursor: 'pointer'
    }
  }
});

export default styles;
