import React from 'react';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const IconName = ({ option, subOption, classes }) => {
  if (option) {
    return (
      <span className={classes.iconNameMenuItemContainer}>
        <img src={option.content.icon} alt={option.name} />
      </span>
    );
  }
  if (subOption) {
    return (
      <span className={classes.iconNameSubmenuItemContainer}>
        <img src={subOption.content.icon} alt={subOption.name} />
      </span>
    );
  }
  return null;
};

IconName.propTypes = {
  option: shape({}),
  subOption: shape({}),
  classes: shape({}).isRequired
};

IconName.defaultProps = {
  option: null,
  subOption: null
};

export default withStyles(styles)(IconName);
