import React from 'react';
import { shape, number, string, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Page from '../../Pagination/Page';
import styles from './styles';

const CallOut = ({
  callOut,
  socket,
  publisher,
  meetingId,
  currentPage,
  selectedTab,
  callOutAction,
  classes
}) => (
  <div
    className={classes.callOutContainer}
    onClick={(e) => {
      e.stopPropagation();
      callOutAction(callOut);
    }}
  >
    <Page
      content={callOut.page}
      socket={socket}
      publisher={publisher}
      meetingId={meetingId}
      currentPage={currentPage}
      selectedTab={selectedTab}
      callOut
    />
  </div>
);

CallOut.propTypes = {
  callOut: shape({}).isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  currentPage: number.isRequired,
  selectedTab: number.isRequired,
  callOutAction: func.isRequired,
  classes: shape({}).isRequired
};

export default withStyles(styles)(CallOut);
