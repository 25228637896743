import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, shape, bool, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import ProjectAction from '../../stores/project/actions';
import MenuAction from '../../stores/menu/actions';
import { socketEmit } from '../../services/socket';

class Link extends Component {
  handleSelectMenuItem = (selectedMenuItem) => {
    const {
      socket,
      meetingId,
      publisher,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'MENU',
        data: {
          type: 'MENU',
          selectedMenuItem
        }
      });
    }
    dispatch(MenuAction.setSelectedMenuItem(selectedMenuItem));
  };

  selectLayout = (selectedLayout, selectedTab, currentPage) => {
    const {
      socket,
      meetingId,
      publisher,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    dispatch(
      ProjectAction.selectLayout(selectedLayout, currentPage, selectedTab)
    );
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout,
          selectedTab,
          currentPage
        }
      });
    }
  };

  selectLayoutByLayoutId = async (
    layoutId,
    section,
    selectedTab = 0,
    selectedPage = 1
  ) => {
    const { socket, activeMeeting, dispatch, meetingIsActive } = this.props;
    const actionResponse = await dispatch(
      ProjectAction.getLayoutInfo(layoutId, selectedPage, selectedTab)
    );
    const { isError } = actionResponse;
    if (!isError) {
      if (meetingIsActive) {
        socketEmit(socket, 'message-meeting', {
          id: activeMeeting.id,
          event: 'ACTIVE-FLOORPLAN',
          data: {
            type: 'ACTIVE-FLOORPLAN',
            layoutId,
            selectedTab,
            selectedPage
          }
        });
      }
      this.handleSelectMenuItem(Number(section));
    }
  };

  linkAction = (section, subSection, page, layoutId) => {
    if (layoutId) {
      this.selectLayoutByLayoutId(layoutId, section, subSection, page);
    } else {
      if (section) {
        this.handleSelectMenuItem(section);
      }
      if (subSection || page) {
        this.selectLayout(null, subSection, page);
      }
    }
  };

  render() {
    const { classes, content, callOut } = this.props;
    return (
      <p className={callOut ? classes.cardParagraph : classes.paragraph}>
        {content.map(({ text, section, subSection, page, layoutId }, index) => (
          <span
            key={`p-${index * 1}`}
            className={classes.link}
            onClick={() => this.linkAction(section, subSection, page, layoutId)}
          >
            {`${text}${index !== content.length - 1 ? ', ' : ''}`}
          </span>
        ))}
      </p>
    );
  }
}

Link.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: bool.isRequired,
  activeMeeting: shape({}).isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired,
  callOut: bool,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

Link.defaultProps = {
  callOut: false
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { activeMeeting, isCustomer, meetingIsActive } = state.meeting;
  const { token, sessionID } = state.session;
  return {
    language,
    activeMeeting,
    token,
    isCustomer,
    sessionID,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Link));
