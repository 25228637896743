import { createSelector } from 'reselect';
import MxCalculatorModel from '../../models/MXCalculatorModel';

export class CalculatorSelector {
  static getUnits(state) {
    const { units } = state.content;
    console.log('units', units);
    return units.map((unit) => ({
      name: `${unit.unitName}`,
      value: unit.id,
      prices: unit.prices,
      availableOn: unit.availableOn || '',
      status: unit.displayStatus,
      area: unit.area,
      bathrooms: unit.bathrooms,
      bedrooms: unit.bedrooms,
      parking: unit.parking
    }));
  }

  static getType(state) {
    return state.content.type;
  }

  static getRegion(state) {
    return state.content.region;
  }

  static getCalculators(state) {
    return state.project.calculators;
  }

  static getCalculator(state, ownProps) {
    const { calculators } = state.project;
    const { currentCalculator } = ownProps;
    return new MxCalculatorModel(calculators[currentCalculator]);
  }

  static getCurrentCalculator(state) {
    const { currentCalculator } = state.project;
    return currentCalculator;
  }

  static getDeferredPercentage(state) {
    const { calculators } = state.project;
    return calculators[0].deferredAmountPercentage;
  }

  static getDeferredMonths(state) {
    const { calculators } = state.project;
    return calculators[0].deferredAmountMonths;
  }

  static getShowTable(state) {
    const { showTable } = state.meeting;
    return showTable;
  }

  static showAvailableOn(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.availableOn !== '');
  }

  static showStatus(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.status !== '');
  }

  static showPrice(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.price !== '');
  }

  static showDiscount(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.discount !== '');
  }

  static showPriceWithDiscount(state) {
    const { calculators } = state.project;
    return calculators.some(
      (calculator) => calculator.priceWithDiscount !== ''
    );
  }

  static showDownPaymentPercentage(state) {
    const { calculators } = state.project;
    return calculators.some(
      (calculator) => calculator.downPaymentPercentage !== ''
    );
  }

  static showDownPayment(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.downPayment !== 0);
  }

  static showDeferredAmountMonthlyPayment(state) {
    const { calculators } = state.project;
    return calculators.some(
      (calculator) => calculator.deferredAmountMonthlyPayment !== ''
    );
  }

  static showDeedAmount(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.deedsAmount !== '');
  }

  static showNotaryFeesAmount(state) {
    const { calculators } = state.project;
    return calculators.some((calculator) => calculator.notaryFeesAmount !== 0);
  }

  static canShare(state) {
    const { calculators } = state.project;
    if (calculators.length > 0) {
      return (
        calculators[0].unitName !== '' && calculators[0].unitName !== undefined
      );
    }
    return false;
  }
}

export const unitsSelector = createSelector(
  [CalculatorSelector.getUnits],
  (units) => units
);

export const typeSelector = createSelector(
  [CalculatorSelector.getType],
  (type) => type
);

export const regionSelector = createSelector(
  [CalculatorSelector.getRegion],
  (region) => region
);

export const calculatorSelector = createSelector(
  [CalculatorSelector.getCalculator],
  (calculator) => calculator
);
export const currentCalculatorSelector = createSelector(
  [CalculatorSelector.getCurrentCalculator],
  (calculator) => calculator
);

export const calculatorsSelector = createSelector(
  [CalculatorSelector.getCalculators],
  (calculators) => calculators
);

export const deferredPercentageSelector = createSelector(
  [CalculatorSelector.getDeferredPercentage],
  (percentage) => percentage
);

export const deferredMonthsSelector = createSelector(
  [CalculatorSelector.getDeferredMonths],
  (months) => months
);

export const showTableSelector = createSelector(
  [CalculatorSelector.getShowTable],
  (showTable) => showTable
);

export const showAvailableOnSelector = createSelector(
  [CalculatorSelector.showAvailableOn],
  (showAvailableOn) => showAvailableOn
);

export const showStatusSelector = createSelector(
  [CalculatorSelector.showStatus],
  (showStatus) => showStatus
);

export const showPriceSelector = createSelector(
  [CalculatorSelector.showPrice],
  (showPrice) => showPrice
);

export const showDiscountSelector = createSelector(
  [CalculatorSelector.showDiscount],
  (showDiscount) => showDiscount
);

export const showPriceWithDiscountSelector = createSelector(
  [CalculatorSelector.showPriceWithDiscount],
  (showPriceWithDiscount) => showPriceWithDiscount
);

export const showDownPaymentPercentageSelector = createSelector(
  [CalculatorSelector.showDownPaymentPercentage],
  (showDownPaymentPercentage) => showDownPaymentPercentage
);

export const showDownPaymentSelector = createSelector(
  [CalculatorSelector.showDownPayment],
  (showDownPayment) => showDownPayment
);

export const showDeferredAmountMonthlyPaymentSelector = createSelector(
  [CalculatorSelector.showDeferredAmountMonthlyPayment],
  (showDeferredAmountMonthlyPayment) => showDeferredAmountMonthlyPayment
);

export const showDeedAmountSelector = createSelector(
  [CalculatorSelector.showDeedAmount],
  (showDeedAmount) => showDeedAmount
);

export const showNotaryFeesAmountSelector = createSelector(
  [CalculatorSelector.showNotaryFeesAmount],
  (showNotaryFeesAmount) => showNotaryFeesAmount
);

export const canShareSelector = createSelector(
  [CalculatorSelector.canShare],
  (canShare) => canShare
);
