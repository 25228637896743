import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, bool, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProjectAction from '../../stores/project/actions';
import MenuAction from '../../stores/menu/actions';
import { settingsIcon } from '../../config/assets';
import themeStyles from '../../styles';
import { socketEmit } from '../../services/socket';
import MobileClientMenu from './MobileClientMenu';
import ClientSectionsMenu from './ClientSectionsMenu';

class ClientMenu extends Component {
  componentDidMount() {
    this.handleSocketMessage();
  }

  componentWillUnmount() {
    const { socket } = this.props;
    socket.off('MENU', () => {});
  }

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('MENU', (data) => {
        const { type, selectedMenuItem } = data;
        switch (type) {
          case 'MENU':
            this.handleSelectMenuItem(selectedMenuItem);
            break;
          default:
            break;
        }
      });
      socket.on('ACTIVE-FLOORPLAN', (data) => {
        const { type, selectedLayout, selectedPage, selectedTab } = data;
        switch (type) {
          case 'ACTIVE-FLOORPLAN':
            this.selectLayout(selectedLayout, selectedPage, selectedTab);
            break;
          default:
            break;
        }
      });
      socket.on('MENU-PROPERTY', (data) => {
        const { type, selectedProperty } = data;
        switch (type) {
          case 'MENU-PROPERTY':
            this.getSectionsForProject(selectedProperty);
            break;
          default:
            break;
        }
      });
    }
  };

  handleSelectMenuItem = (selectedMenuItem) => {
    const {
      socket,
      activeMeeting,
      meetingIsActive,
      publisher,
      token,
      history,
      dispatch
    } = this.props;
    if (publisher && meetingIsActive) {
      socketEmit(socket, 'message-meeting', {
        id: activeMeeting.id,
        event: 'MENU',
        data: {
          type: 'MENU',
          selectedMenuItem
        }
      });
    }
    dispatch(MenuAction.setSelectedMenuItem(selectedMenuItem));
    if (token) {
      if (selectedMenuItem === 'Settings') {
        history.push('/settings');
      } else {
        history.push('/');
      }
      this.selectLayout(null);
    }
  };

  selectLayout = (selectedLayout, selectedPage = 1, selectedTab = 0) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      dispatch
    } = this.props;
    dispatch(ProjectAction.selectLayout(selectedLayout));
    if (publisher && meetingIsActive) {
      socketEmit(socket, 'message-meeting', {
        id: activeMeeting.id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout,
          selectedPage,
          selectedTab
        }
      });
    }
  };

  getSectionsForProject = (id) => {
    const { dispatch } = this.props;
    dispatch(ProjectAction.getClientProjectSections(id));
    dispatch(ProjectAction.selectTab(0, 1));
    dispatch(MenuAction.setSelectedMenuItem(1));
  };

  render() {
    const {
      classes,
      selectedMenuItem,
      sections,
      onboarding,
      openMenu,
      toggleMenu
    } = this.props;
    let activeMenuItem = sections.filter(
      ({ order }) => selectedMenuItem === order
    );
    if (selectedMenuItem === sections.length + 1) {
      activeMenuItem = [
        { order: sections.length + 1, name: 'Settings', icon: settingsIcon.src }
      ];
    }
    return (
      <>
        {activeMenuItem.length === 1 && (
          <MobileClientMenu handleSelectMenuItem={this.handleSelectMenuItem} />
        )}
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={
            openMenu ? classes.menuContainer : classes.collapsedMenuContainer
          }
        >
          <ClientSectionsMenu openMenu={openMenu} toggleMenu={toggleMenu} />
          {openMenu && JSON.stringify(onboarding) !== '{}' && (
            <img
              src={onboarding.athumLogo}
              alt="At hum"
              className={classes.athumLogo}
            />
          )}
        </Grid>
      </>
    );
  }
}

ClientMenu.propTypes = {
  selectedMenuItem: number.isRequired,
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  socket: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  token: string.isRequired,
  sections: arrayOf(shape({})).isRequired,
  publisher: bool.isRequired,
  onboarding: shape({}).isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired,
  openMenu: bool.isRequired,
  toggleMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { selectedMenuItem } = state.menu;
  const {
    activeMeeting,
    publisher,
    onboarding,
    meetingIsActive
  } = state.meeting;
  const { sections } = state.project;
  return {
    selectedMenuItem,
    activeMeeting,
    token,
    sections,
    publisher,
    onboarding,
    meetingIsActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(themeStyles)(ClientMenu));
