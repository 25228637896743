/* eslint-disable import/no-cycle */

import queryString from 'query-string';
import { GOOGLE_API } from '../config/endpoints';
import Color from '../class/Color';
import Solver from '../class/Solver';

/* eslint-disable no-param-reassign */

const getParentNodeId = (elem) => elem.parentNode.id;

const calculateBackgroundWidth = (element) => {
  let calculatedWidth = 0;
  const childrens = Array.from(element.childNodes);
  childrens.forEach((child) => {
    const { width } = child.getBoundingClientRect();
    calculatedWidth += width;
  });
  return calculatedWidth;
};

const deleteWhiteSpaces = (s) => {
  if (typeof s !== 'string') return '';
  return s.replace(/ +/g, '');
};

const sendMessage = (msg) => {
  window.parent.postMessage(msg, '*');
};

const truncate = (maxSize, string) =>
  string.length <= maxSize ? string : `${string.substr(0, maxSize - 3)}...`;

const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const isMobileDevice = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const isTablet = () =>
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    navigator.userAgent.toLowerCase()
  ) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const typeOfDevice = () => {
  let device = 'Desktop';

  if (isTablet()) {
    device = 'Tablet';
  }

  if (isMobileDevice()) {
    device = 'Mobile';
  }

  return device;
};

const isPortrait = () => window.innerHeight > window.innerWidth;

const isPreview = () => {
  const { location } = window;
  const parsed = queryString.parse(location.search);
  return !!parsed.preview;
};

const isSurveyCompleted = () => {
  const { location } = window;
  const parsed = queryString.parse(location.search);
  return !!parsed.surveyCompleted;
};

const getLevelData = (levels, currentLevel) => {
  const level = levels.filter((item) =>
    item.levelNumber === currentLevel ? item : null
  );
  return level && level[0];
};

const getLevelScenes = (currentLevel, style) => {
  const currentStyle = currentLevel.styles.filter(
    (levelStyle) => levelStyle.key === style
  );
  return currentStyle.length > 0
    ? currentStyle[currentStyle.length - 1].scenes
    : [];
};

const getScene = (scenes, sceneKey) => {
  const currentScene = scenes.filter((scene) => scene.key === sceneKey);
  return currentScene.length > 0 ? currentScene[currentScene.length - 1] : null;
};

const getUse = (roomKey, scene) => {
  const use = scene.filter((item) =>
    item.key.toLowerCase() === roomKey.toLowerCase() ? item : null
  );
  return use && use[0];
};

const get360Style = (style, menu) => {
  const menuStyle = menu.filter((item) =>
    item.name.toLowerCase() === style.toLowerCase() ||
    item.key.toLowerCase() === style.toLowerCase()
      ? item
      : null
  );
  return menuStyle && menuStyle[0];
};

const get360Use = (roomKey, scene) => {
  const use = scene.filter((item) =>
    item.key.toLowerCase() === roomKey.toLowerCase() ? item : null
  );
  return use && use[0];
};

const get360Uses = (uses) => {
  if (uses.length > 1) {
    return uses.map((use) => {
      const { key, name, image } = use;
      return {
        key,
        name,
        image
      };
    });
  }
  return [];
};

const getCurrentRoomUse = (use) => use.key;

const get360DataStyle = (currentStyle, styles) => {
  const style = styles.filter((item) =>
    item.key.toLowerCase() === currentStyle ? item : null
  );
  return style && style[0];
};

const get360Scene = (currentScene, scenes) => {
  const scene = scenes.filter((item) => {
    const currentItemName = item.name || '';
    const currentItemKey = item.key || '';
    return currentItemName.toLowerCase().includes(currentScene.toLowerCase()) ||
      currentScene.toLowerCase().includes(currentItemName.toLowerCase()) ||
      currentItemKey.toLowerCase().includes(currentScene.toLowerCase()) ||
      currentScene.toLowerCase().includes(currentItemKey.toLowerCase())
      ? item
      : null;
  });
  return scene && scene[0];
};

const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

const hasGyroscope = () => {
  if (typeof DeviceOrientationEvent !== 'undefined') {
    if (
      typeof DeviceOrientationEvent.requestPermission === 'function' ||
      (isMobileDevice() && window.DeviceOrientationEvent)
    ) {
      return true;
    }
  }
  return false;
};

const getRoomToRequest = (roomUse, uses, defaultUse) => {
  let room = null;
  if (roomUse !== '' && roomUse !== null && roomUse !== undefined) {
    room = roomUse;
  } else {
    room = defaultUse;
  }
  const exist = uses.find((use) => use.key === room);

  return exist ? room : defaultUse;
};

const getProcessed360Data = (data, level, style, room, roomUse) => {
  const levelData = getLevelData(data.levels, level);
  if (levelData) {
    const { menu } = data;
    const styleToRequest = style === 'default' ? data.defaultStyle : style;
    const menuStyle = get360Style(styleToRequest, menu);

    const jsonStyle = get360DataStyle(
      menuStyle.key.toLowerCase(),
      levelData.styles
    );
    if (jsonStyle) {
      const roomToRequest = room === 'default' ? levelData.defaultScene : room;
      const jsonScene = get360Scene(roomToRequest, jsonStyle.scenes);
      if (jsonScene) {
        const roomUseToRequest = getRoomToRequest(
          roomUse,
          jsonScene.uses,
          jsonScene.defaultUse
        );
        const use = get360Use(roomUseToRequest, jsonScene.uses);
        const uses = get360Uses(jsonScene.uses);
        const currentRoomUse = getCurrentRoomUse(use);
        if (use) {
          const { startScenePosition, key: sceneKey, hotspots } = jsonScene;
          return {
            use,
            uses,
            currentRoomUse,
            levelData,
            jsonStyle,
            startScenePosition,
            menuStyle,
            sceneKey,
            hotspots
          };
        }
      }
    }
  }
  return null;
};

const getViewerDependingOnPreview = (preview, viewer) => {
  if (preview) {
    viewer.scene.children[0].children.forEach((child) => {
      const element = child;
      element.visible = false;
    });
  }
  return viewer;
};

const isEmpty = (obj) => {
  if (obj === null) {
    return true;
  }

  const keys = Object.keys(obj);

  if (keys.length === 0) {
    return true;
  }

  return false;
};

const isMobile = () => window.innerWidth <= 960;

const isSmallDesktop = () => window.innerWidth <= 1200;

const getMarkers = (categories, type) => {
  const markers = [];
  categories.forEach(({ places, name: catName, markerIcon: icon }) => {
    if (catName === type) {
      places.forEach(({ name, latitude, longitude }) => {
        const location = {
          lat: latitude,
          lng: longitude
        };
        markers.push({
          category: name,
          name,
          location,
          icon
        });
      });
    }
  });
  return markers;
};

const getGoogleApiUri = () => {
  const api = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API}&callback=initMap`;
  return api;
};

const getVideoType = (src) => {
  if (src.includes('vimeo')) {
    return 'vimeo';
  }

  if (src.includes('youtube')) {
    return 'youtube';
  }

  if (src.includes('mp4')) {
    return 'mp4';
  }

  return '';
};

const getOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'iOS';
  }

  if (navigator.appVersion.indexOf('Win') !== -1) {
    return 'Windows';
  }

  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }

  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return 'Linux';
  }

  return 'Unknown';
};

const getBrowser = () => {
  const { userAgent } = navigator;

  const isFirefoxIOS = userAgent.indexOf('FxiOS') > -1;

  if (isFirefoxIOS) {
    return 'Firefox';
  }

  const isFirefox = userAgent.indexOf('Firefox') > -1;

  if (isFirefox) {
    return 'Firefox';
  }

  const isChrome = userAgent.indexOf('Chrome') > -1;

  if (isChrome) {
    return 'Chrome';
  }

  if (userAgent.indexOf('Edge') > -1) {
    return 'Edge';
  }

  if (userAgent.indexOf('MSIE ') > 0) {
    return 'Explorer';
  }

  const isChromeIOS = userAgent.indexOf('CriOS') > -1;

  if (isChromeIOS) {
    return 'Chrome';
  }

  const isSafari = userAgent.indexOf('Safari') > -1;

  if (isSafari) {
    return 'Safari';
  }

  return 'Unknown';
};

const isSupported = () => {
  const operatingStystem = getOperatingSystem();
  const browser = getBrowser();

  if (!navigator.mediaDevices) {
    return false;
  }

  if (operatingStystem === 'iOS' && browser !== 'Safari') {
    return false;
  }

  if (operatingStystem === 'Windows' && browser === 'Explorer') {
    return false;
  }

  if (operatingStystem === 'Unknown') {
    return false;
  }

  return true;
};

const promisifiedOldGUM = (constraints, successCallback, errorCallback) => {
  // First get ahold of getUserMedia, if present
  const getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia;

  // Some browsers just don't implement it - return a rejected promise with an error
  // to keep a consistent interface
  if (!getUserMedia) {
    return Promise.reject(
      new Error('getUserMedia is not implemented in this browser')
    );
  }

  // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
  return new Promise((successCallback, errorCallback) => {
    getUserMedia.call(navigator, constraints, successCallback, errorCallback);
  });
};

const defineNavigator = () => {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = promisifiedOldGUM;
  }
};

export const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

const getActiveInactive = (
  inactiveColor,
  activeColor,
  backgroundColor,
  iconColor = null
) => {
  const inactiveFont = hexToRgb(inactiveColor);
  if (inactiveFont.length !== 3) {
    console.error('Invalid format!');
  }
  const inactiveFontColor = new Color(
    inactiveFont[0],
    inactiveFont[1],
    inactiveFont[2]
  );
  const inactiveFontColorSolver = new Solver(inactiveFontColor);
  const svgInactiveColor = inactiveFontColorSolver.solve();

  const activeFont = hexToRgb(activeColor);
  if (activeFont.length !== 3) {
    console.error('Invalid format!');
  }
  const activeFontColor = new Color(
    activeFont[0],
    activeFont[1],
    activeFont[2]
  );
  const activeFontColorSolver = new Solver(activeFontColor);
  const svgActiveColor = activeFontColorSolver.solve();

  if (backgroundColor) {
    const background = hexToRgb(backgroundColor);
    if (background.length !== 3) {
      console.error('Invalid format!');
    }
    const svgBackgroundColor = new Color(
      background[0],
      background[1],
      background[2]
    );
    const backgroundColorSolver = new Solver(svgBackgroundColor);
    const newBackgroundColor = backgroundColorSolver.solve();
    if (iconColor) {
      const icon = hexToRgb(iconColor);
      let processedIconColor = null;
      let iconColorSolver = null;
      let newIconColor = null;
      if (icon !== null) {
        if (icon !== null && icon.length !== 3) {
          console.error('Invalid format!');
        }
        processedIconColor = new Color(icon[0], icon[1], icon[2]);
        iconColorSolver = new Solver(processedIconColor);
        newIconColor = iconColorSolver.solve();
      } else if (iconColor.toLowerCase().includes('invert')) {
        newIconColor = {
          filter: iconColor
        };
      }

      return {
        background: newBackgroundColor.filter,
        active: svgActiveColor.filter,
        inactive: svgInactiveColor.filter,
        iconColor: newIconColor.filter
      };
    }
    return {
      background: newBackgroundColor.filter,
      active: svgActiveColor.filter,
      inactive: svgInactiveColor.filter
    };
  }

  return {
    active: svgActiveColor.filter,
    inactive: svgInactiveColor.filter
  };
};

const returnProjectTheme = (currentProject = null) => {
  let theme = {
    leftMenu: {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69',
      alertColor: '#ec6b69',
      svg: {
        inactive: 'brightness(0) invert(42.3%)',
        active:
          'invert(65%) sepia(82%) saturate(2200%) hue-rotate(316deg) brightness(109%) contrast(70%)'
      }
    },
    rightMenu: {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69',
      alertColor: '#ec6b69',
      inactiveTabColor: '#6c6c6c',
      tabsBackgroundColor: '#ededed',
      svg: {
        inactive: 'brightness(0) invert(42.3%)',
        active:
          'invert(65%) sepia(82%) saturate(2200%) hue-rotate(316deg) brightness(109%) contrast(70%)'
      }
    },
    content: {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69',
      coverBackgroundColor: '#fafafa',
      coverInactiveFontColor: '#6c6c6c',
      coverActiveFontColor: '#ec6b69',
      tabsBackgroundColor: '#fafafa',
      inactiveTabColor: '#6c6c6c',
      activeTabColor: '#ec6b69',
      highlightColor: '#ec6b69',
      alertColor: '#ec6b69',
      iconColor:
        'invert(83%) sepia(4%) saturate(0%) hue-rotate(39deg) brightness(115%) contrast(108%)',
      svg: {
        inactive: 'brightness(0) invert(42.3%)',
        active:
          'invert(65%) sepia(82%) saturate(2200%) hue-rotate(316deg) brightness(109%) contrast(70%)',
        background: 'brightness(0) invert(42.3%)'
      }
    },
    colors: {
      prmary: '#ec6b69',
      complementary: '#ec6b69',
      accent: '#ec6b69'
    },
    fontFamily: 'Roboto'
  };
  if (currentProject !== null && currentProject.theme !== undefined) {
    theme = currentProject.theme;
  }

  const leftMenuSvgColors = getActiveInactive(
    theme.leftMenu.inactiveFontColor,
    theme.leftMenu.activeFontColor
  );
  const rightMenuSvgColors = getActiveInactive(
    theme.rightMenu.inactiveFontColor,
    theme.rightMenu.inactiveFontColor
  );
  const contentSvgColors = getActiveInactive(
    theme.content.inactiveFontColor,
    theme.content.inactiveFontColor,
    theme.content.backgroundColor,
    theme.content.iconColor
  );

  theme.leftMenu.svg = leftMenuSvgColors;
  theme.rightMenu.svg = rightMenuSvgColors;
  theme.content.svg = contentSvgColors;
  theme.content.iconColor = contentSvgColors.iconColor;

  return theme;
};

const convertToRGB = (hex, alpha = '0') => {
  if (hex.length !== 6) {
    console.error('Only six-digit hex colors are allowed.');
  }

  const aRgbHex = hex.match(/.{1,2}/g);
  return `rgba(${parseInt(aRgbHex[1], 16)},${parseInt(
    aRgbHex[2],
    16
  )},${parseInt(aRgbHex[3], 16)}, ${alpha})`;
};

const getUnitInfo = (value, units) => {
  const selectedUnit = units.find((unit) => unit.name === value);
  return selectedUnit;
};

const getPercentageDiscountPrice = (value, price) => {
  const perToSubstract = price * (value / 100);
  const perDiscountPrice = price - perToSubstract;
  if (Number.isNaN(perDiscountPrice)) {
    return '';
  }
  return perDiscountPrice.toFixed(2);
};

const getMoneyDiscountPrice = (value, price) => {
  if (Number.isNaN(value)) {
    return '';
  }
  const moneyDiscount = price - value;

  if (Math.sign(moneyDiscount) === -1) {
    return 0;
  }

  if (Number.isNaN(moneyDiscount)) {
    return '';
  }
  return moneyDiscount.toFixed(2);
};

const getPercentageDiscountDeposit = (value, price) => price * (value / 100);

const getDeferredMonthly = (value, deferredAmountPercentage, price) => {
  const deferred = (price * (deferredAmountPercentage / 100)) / value;
  if (Number.isNaN(deferred) || deferred === Infinity) {
    return '';
  }

  return deferred.toFixed();
};

const getDeedAmount = (value, price, depositAmount, deposit) => {
  const amount = (value / 100) * price;
  const deed = price - depositAmount - deposit - amount;
  return deed.toFixed(2);
};

const percentageLimit = (percentage) => {
  if (Number.isNaN(percentage)) {
    return '';
  }
  return percentage > 100 ? '100' : percentage;
};

const limitMonths = (months) => {
  if (Number.isNaN(months)) {
    return '';
  }
  return Math.abs(months);
};

const emptyForDash = (string) => (string === '' ? '-' : string);

const millisToMinutesAndSeconds = (ms) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = ((ms % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export {
  deleteWhiteSpaces,
  sendMessage,
  truncate,
  numberWithCommas,
  isMobileDevice,
  isTablet,
  isPreview,
  typeOfDevice,
  isSurveyCompleted,
  getUse,
  getScene,
  getLevelData,
  getLevelScenes,
  get360DataStyle,
  get360Scene,
  get360Style,
  titleCase,
  hasGyroscope,
  isPortrait,
  get360Use,
  get360Uses,
  getRoomToRequest,
  getCurrentRoomUse,
  getProcessed360Data,
  getViewerDependingOnPreview,
  isEmpty,
  isMobile,
  isSmallDesktop,
  getMarkers,
  getGoogleApiUri,
  getVideoType,
  getOperatingSystem,
  getBrowser,
  isSupported,
  defineNavigator,
  getParentNodeId,
  calculateBackgroundWidth,
  returnProjectTheme,
  convertToRGB,
  getUnitInfo,
  getPercentageDiscountPrice,
  getMoneyDiscountPrice,
  getPercentageDiscountDeposit,
  getDeferredMonthly,
  getDeedAmount,
  percentageLimit,
  limitMonths,
  emptyForDash,
  millisToMinutesAndSeconds
};
