const styles = () => ({
  svgContainer: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  annotationButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    zIndex: 10,
    background: '#FAFAFA',
    boxShadow: '0px 1px 2px rgba(138, 138, 138, 0.5)',
    padding: '5px 0',
    borderRadius: 24
  },
  annotationButtons: {
    width: 40,
    height: 500,
    maxHeight: '98%',
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    zIndex: 10,
    background: '#FAFAFA',
    boxShadow: '0px 1px 2px rgba(138, 138, 138, 0.5)',
    borderRadius: 24,
    '& img': {
      background: '#FAFAFA',
      padding: 5,
      width: '28px !important',
      height: '28px !important',
      '&.selectedTool': {
        background: '#D6D6D6',
        padding: 5,
        borderRadius: 24
      }
    }
  },
  iconStyle: {
    color: '#4a4a4a',
    fontSize: 20,
    cursor: 'pointer'
  },
  colorButton: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    boxShadow: '0px 1px 2px rgba(138, 138, 138, 0.5)',
    borderRadius: 20
  },
  checkIcon: {
    color: 'white',
    fontSize: 20
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1
  },
  selectedImageLeftContainer: {
    position: 'absolute',
    zIndex: 2,
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    width: 50,
    background: 'rgba(0, 0, 0, 0.3)'
  },
  selectedImageRightContainer: {
    position: 'absolute',
    zIndex: 2,
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    width: 50,
    background: 'rgba(0, 0, 0, 0.3)'
  }
});

export default styles;
