import { createSelector } from 'reselect';

export class ViewStyleSelector {
  static getMeetingId(state) {
    return state.meeting.activeMeeting.id;
  }
}

export const meetingIdSelector = createSelector(
  [ViewStyleSelector.getMeetingId],
  (meetingId) => meetingId
);
