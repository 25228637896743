const styles = (theme) => ({
  xLargeInput: {
    width: 233,
    height: 80,
    '&.first': {
      marginRight: '12px'
    },
    '&.second': {
      marginRight: '12px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '&.first': {
        marginRight: 0
      }
    },
    '&.icons': {
      height: 44,
      marginBottom: 12,
      [theme.breakpoints.down('xs')]: {
        width: '50%'
      }
    }
  },
  largeInput: {
    width: 142,
    height: 80,
    '&.first': {
      marginRight: '12px'
    },
    '&.second': {
      marginRight: '12px'
    }
  },
  smallInput: {
    width: 80,
    height: 80,
    '&.first': {
      marginRight: '12px'
    },
    '&.second': {
      marginRight: '12px'
    }
  },
  select: {
    marginTop: 24,
    '& ul option': {
      paddingLeft: 24
    }
  },
  inputWithoutLabel: {
    marginTop: 24
  },
  calculatorContainer: { height: '100%', width: '100%' },
  header: { height: 48 },
  calculator: {
    height: '100%',
    width: '100%',
    '& .title-table': {
      fontSize: 16,
      fontWeight: 'bold'
    },
    '& .bottom-border': {
      width: '99%',
      borderBottom: '0.2px solid #EDEDED'
    }
  },
  calculatorTable: {
    overflow: 'auto'
  },
  title: {
    fontSize: 16,
    paddingLeft: 11
  },
  footer: {
    paddingLeft: 18,
    height: 56,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    borderTop: '0.2px solid #EDEDED',
    background: '#ffffff'
  },
  smallButton: {
    borderRadius: '50%',
    background: '#FFFFFF',
    color: theme.content.activeFontColor,
    textTransform: 'none',
    height: 40,
    width: 40,
    minWidth: 40,
    marginRight: 10,
    '& .plus': {
      filter: theme.leftMenu.svg.active
    },
    '&:focus': {
      outline: 'none'
    },
    '& .grayed': {
      color: 'rgba(138, 138, 138, 0.5)',
      background: 'transparent'
    }
  },
  delete: {
    height: 20,
    width: 20,
    minWidth: 20,
    '& img': {
      height: 20,
      width: 20
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mediumButton: {
    borderRadius: 20,
    background: '#FAFAFA',
    textTransform: 'none',
    height: 40,
    marginRight: 10,
    '&:focus': {
      outline: 'none'
    }
  },
  largeButton: {
    borderRadius: 60,
    background: '#FFFFFF',
    color: theme.content.activeFontColor,
    textTransform: 'none',
    height: 40,
    width: 183.86,
    minWidth: 183.86,
    marginLeft: 15,
    marginRight: 5,
    '&:focus': {
      outline: 'none'
    },
    '&.filled': {
      background: theme.leftMenu.backgroundColor,
      color: '#FFFFFF',
      marginLeft: 10,
      '& img': {
        width: 15,
        height: 12,
        filter: 'brightness(0) invert(1)',
        marginLeft: 5
      }
    }
  },
  tableHeaderContainer: {
    marginBottom: 10
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 1,
    '&.white-background': {
      backgroundColor: 'white'
    },
    '&.scene': {
      backgroundColor: 'transparent'
    },
    ' &.hide': {
      transition: 'opacity 1s ease-out',
      opacity: 0,
      height: 0,
      width: 0,
      overflow: 'hidden'
    },
    '& .loader-image-container': {
      backgroundColor: 'white',
      borderRadius: '50%'
    },
    '&.display-none': {
      display: 'none'
    }
  },
  formContainer: {
    overflowY: 'auto',
    height: 'calc(100% - 104px)',
    width: '100%',
    '& .input-container': {
      padding: '13px 12px'
    },
    '& .bottom-border': {
      width: '99%',
      borderBottom: '0.2px solid #EDEDED',
      marginBottom: 13
    }
  },
  textContainer: {
    textAlign: 'center',
    '& h1': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 21,
      color: '#6C6C6C'
    }
  },
  options: {
    padding: '10px 12px'
  },
  tableContainer: {
    width: '100%',
    height: '90%',
    overflow: 'auto',
    '& table': {
      width: '100%',
      overflow: 'auto',
      '& thead': {
        color: '#fff',
        backgroundColor: '#6C6C6C'
      }
    }
  },
  boldRow: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#353535'
  },
  icons: {
    height: 16,
    width: 16
  },
  iconText: {
    margin: '0 5px'
  }
});

export default styles;
