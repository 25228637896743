import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTab = withStyles((theme) => {
  return {
    root: {
      color: theme.content.inactiveTabColor,
      textTransform: 'none',
      '&:focus': {
        outline: 'none'
      },
      '&.Mui-selected': {
        color: theme.content.activeTabColor
      },
      minWidth: 100
    }
  };
})(Tab);

export default CustomTab;
