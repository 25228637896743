const styles = (theme) => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 99,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    background: '#ffffff',
    overflow: 'auto',
    padding: 10
  },
  welcomeContainer: {
    minHeight: '100%',
    '& h1': {
      color: '#6c6c6c',
      margin: 0,
      fontSize: 24,
      fontWeight: 300,
      width: 400,
      textAlign: 'center',
      marginBottom: 34,
      marginTop: 34,
      [theme.breakpoints.down('sm')]: {
        fontSize: 21,
        width: 300,
        marginBottom: 12,
        marginTop: 12
      }
    },
    '& h3': {
      color: '#4a4a4a',
      margin: 0,
      fontSize: 18,
      fontWeight: 300,
      width: 300,
      marginBottom: 24,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        marginBottom: 12,
        padding: '0 40px'
      }
    },
    '& p': {
      color: '#6c6c6c',
      margin: 0,
      fontSize: 16,
      fontWeight: 300,
      width: '100%',
      textAlign: 'center'
    }
  },
  waitContainer: {
    height: '100%',
    '& h1': {
      color: '#6c6c6c',
      margin: 0,
      fontSize: 24,
      fontWeight: 300,
      width: 300,
      textAlign: 'center',
      marginBottom: 34,
      marginTop: 34,
      [theme.breakpoints.down('sm')]: {
        width: 300,
        fontSize: 21
      }
    },
    '& h3': {
      color: '#4a4a4a',
      margin: 0,
      fontSize: 18,
      fontWeight: 300,
      width: 300,
      marginBottom: 24,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        marginBottom: 12,
        padding: '0 40px'
      }
    },
    '& p': {
      color: '#6c6c6c',
      margin: 0,
      fontSize: 16,
      fontWeight: 300,
      width: '100%',
      textAlign: 'center'
    }
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 89,
    backgroundColor: 'rgba(255, 255, 255, 0.69)'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: 300
    }
  },
  formControlContainer: {
    width: '100%'
  },
  buttonContainer: {
    marginTop: 36,
    marginBottom: 36,
    [theme.breakpoints.up('sm')]: {
      width: 300
    }
  },
  logo: {
    height: 120,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: 80
    }
  },
  athumLogo: {
    width: 100,
    maxHeight: 100
  },
  deletedMeetingFirstContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  deleted: {
    padding: '0 30px !important'
  },
  deletedBlack: {
    fontWeight: 'bold !important',
    margin: '0 !important',
    padding: '0 30px !important'
  },
  deletedAgent: {
    fontWeight: 'bold !important',
    color: '#56B0C0 !important',
    margin: '0 !important',
    padding: '0 30px !important'
  }
});

export default styles;
