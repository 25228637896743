import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { shape, string, bool, func } from 'prop-types';
import { withCookies } from 'react-cookie';
import { createBrowserHistory } from 'history';
import socketIOClient from 'socket.io-client';
import { SOCKET } from '../config/endpoints';
import strings from '../language';
import LanguageActions from '../stores/language/actions';
import NotFoundPage from '../pages/NotFoundPage';
import PageWrapper from '../pages/PageWrapper';
import { defineNavigator } from '../utils';
import getRoutes from './routes';
import checkUserInSession from './checkUserInSession';

const history = createBrowserHistory();

class AppRouter extends Component {
  constructor() {
    super();
    this.io = socketIOClient(SOCKET);
  }

  componentDidMount() {
    const language = strings.getLanguage();
    defineNavigator();
    const { dispatch, cookies } = this.props;
    if (language !== 'es' && language !== 'en') {
      strings.setLanguage('es');
      dispatch(LanguageActions.setLanguage('es'));
    } else {
      strings.setLanguage(language);
      dispatch(LanguageActions.setLanguage(language));
    }

    checkUserInSession(cookies, dispatch);
  }

  setLanguage = (language) => {
    const { dispatch } = this.props;
    strings.setLanguage(language);
    dispatch(LanguageActions.setLanguage(language));
  };

  render() {
    const {
      cookies,
      language,
      error,
      token,
      isCustomer,
      dispatch
    } = this.props;
    const routes = getRoutes(error);
    if (!language) {
      return null;
    }
    return (
      <Router history={history}>
        <Switch>
          {routes.map(({ RouteComponent, path, menu }) => (
            <Route
              key={path}
              path={path}
              exact
              component={({ history: historyComp, location, match }) => (
                <PageWrapper
                  RouteComponent={RouteComponent}
                  menu={menu}
                  cookies={cookies}
                  token={token}
                  historyComp={historyComp}
                  location={location}
                  match={match}
                  socket={this.io}
                  checkUserInSession={() => {
                    checkUserInSession(cookies, dispatch);
                  }}
                  isCustomer={isCustomer}
                />
              )}
            />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    );
  }
}

AppRouter.propTypes = {
  cookies: shape({}).isRequired,
  language: string.isRequired,
  error: string.isRequired,
  token: string.isRequired,
  isCustomer: bool.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { language } = state.language;
  const { isCustomer } = state.meeting;
  const { message: error } = state.error;
  return {
    language,
    error,
    token,
    isCustomer
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(AppRouter)
);
