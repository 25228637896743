import React, { useRef, useEffect, useState } from 'react';
import { string, shape, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Player from '@vimeo/player';
import styles from './styles';

const VimeoPlayer = ({
  src,
  playing,
  muted,
  onPlay,
  onPause,
  onMute,
  onUnmute,
  classes
}) => {
  const vimeo = useRef(null);
  const [player, setPlayer] = useState();
  useEffect(() => {
    const id = src.substring(src.lastIndexOf('/') + 1);
    const vimeoPlayer = new Player(vimeo.current, {
      id,
      width: '100%',
      height: '100%'
    });

    vimeoPlayer.on('play', () => {
      onPlay();
    });
    vimeoPlayer.on('pause', () => {
      onPause();
    });
    vimeoPlayer.on('volumechange', (event) => {
      if (event.volume > 0) {
        onUnmute();
      } else {
        onMute();
      }
    });
    setPlayer(vimeoPlayer);
  }, [setPlayer, onPlay, onPause, src, onMute, onUnmute]);
  if (playing && vimeo && player) {
    player.play();
  } else if (!playing && vimeo && player) {
    player.pause();
  }
  if (muted && vimeo && player) {
    player.setMuted(true);
  } else if (!muted && vimeo && player) {
    player.setMuted(false);
  }
  return (
    <iframe
      ref={vimeo}
      title="vimeo-video"
      width="100%"
      src={src}
      className={classes.iframe}
    />
  );
};

VimeoPlayer.propTypes = {
  src: string.isRequired,
  playing: bool.isRequired,
  muted: bool.isRequired,
  onPlay: func.isRequired,
  onPause: func.isRequired,
  onMute: func.isRequired,
  onUnmute: func.isRequired,
  classes: shape({}).isRequired
};

export default withStyles(styles)(VimeoPlayer);
