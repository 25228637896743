import React from 'react';
import { shape, string, bool, func, arrayOf, number } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PrivatePanelSession from './Session';
import SalesMobilePrivatePanel from './SalesMobilePrivatePanel';
import ClientMobilePrivatePanel from './ClientMobilePrivatePanel';
import themeStyles from '../../styles';

const MobilePrivatePanel = ({
  classes,
  twilioToken,
  identity,
  twilioRoom,
  videoCallOpen,
  socket,
  toggleVideoCall,
  token,
  history,
  meetingIsActive,
  tabs,
  buyerTabs,
  pendingMessages,
  currentTab,
  mobileMenuIsOpen,
  toggleMobileMenu,
  changeTab,
  cookies
}) => (
  <>
    {meetingIsActive && (
      <Grid
        container
        className={
          videoCallOpen
            ? classes.mobileSessionPanelOpen
            : classes.mobileSessionPanel
        }
      >
        <PrivatePanelSession
          twilioToken={twilioToken}
          meeting={twilioRoom}
          identity={identity}
          videoCallOpen={videoCallOpen}
          toggleVideoCall={toggleVideoCall}
          socket={socket}
          currentTab={currentTab}
          toggleMobileMenu={toggleMobileMenu}
          pendingMessages={pendingMessages}
        />
      </Grid>
    )}
    {token ? (
      <SalesMobilePrivatePanel
        history={history}
        cookies={cookies}
        tabs={tabs}
        pendingMessages={pendingMessages}
        mobileMenuIsOpen={mobileMenuIsOpen}
        toggleMobileMenu={toggleMobileMenu}
        currentTab={currentTab}
        changeTab={changeTab}
      />
    ) : (
      <ClientMobilePrivatePanel
        buyerTabs={buyerTabs}
        mobileMenuIsOpen={mobileMenuIsOpen}
        toggleMobileMenu={toggleMobileMenu}
        currentTab={currentTab}
        changeTab={changeTab}
      />
    )}
  </>
);

MobilePrivatePanel.propTypes = {
  classes: shape({}).isRequired,
  twilioToken: string.isRequired,
  identity: string.isRequired,
  twilioRoom: string.isRequired,
  videoCallOpen: bool.isRequired,
  socket: shape({}).isRequired,
  toggleVideoCall: func.isRequired,
  token: string.isRequired,
  cookies: shape({}).isRequired,
  history: shape({}).isRequired,
  meetingIsActive: bool.isRequired,
  tabs: arrayOf(shape({})).isRequired,
  buyerTabs: arrayOf(shape({})).isRequired,
  pendingMessages: bool.isRequired,
  currentTab: number.isRequired,
  changeTab: func.isRequired,
  mobileMenuIsOpen: bool.isRequired,
  toggleMobileMenu: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const {
    meetings,
    twilioToken,
    identity,
    twilioRoom,
    meetingIsActive
  } = state.meeting;
  return {
    meetings,
    twilioToken,
    identity,
    twilioRoom,
    token,
    meetingIsActive
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(themeStyles)(MobilePrivatePanel));
