import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, func } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { dropdownIcon, settingsIcon } from '../../config/assets';
import themeStyles from '../../styles';

class ClientMenu extends Component {
  constructor() {
    super();
    this.state = {
      showMobileMenu: false
    };
  }

  showMobileMenu = () => {
    this.setState((prevState) => ({
      showMobileMenu: !prevState.showMobileMenu
    }));
  };

  goToSettings = () => {
    const { history } = this.props;
    history.push('/settings');
  };

  render() {
    const {
      classes,
      selectedMenuItem,
      token,
      sections,
      customer,
      handleSelectMenuItem
    } = this.props;
    const { showMobileMenu } = this.state;
    let activeMenuItem = sections.filter(
      ({ order }) => selectedMenuItem === order
    );
    if (selectedMenuItem === sections.length + 1) {
      activeMenuItem = [
        { order: sections.length + 1, name: 'Settings', icon: settingsIcon.src }
      ];
    }
    const mobileMenuItemClick = (e, id) => {
      e.stopPropagation();
      handleSelectMenuItem(id);
      this.showMobileMenu();
    };
    const clientNavigation = JSON.stringify(customer) !== '{}';
    return (
      <>
        <Grid
          container
          alignItems="center"
          className={classes.mobileMenuContainer}
        >
          <MenuItem
            key={activeMenuItem[0].order}
            className={classes.mobileMenuItemActive}
            onClick={token || clientNavigation ? this.showMobileMenu : () => {}}
          >
            <img
              src={activeMenuItem[0].icon}
              alt={activeMenuItem[0].name}
              className={classes.menuIcon}
            />
            {activeMenuItem[0].name}
            <img
              src={dropdownIcon.src}
              alt={dropdownIcon.alt}
              className={classes.dropdownIcon}
            />
          </MenuItem>
        </Grid>
        {showMobileMenu && (
          <Grid
            className={classes.mobileMenuBack}
            onClick={this.showMobileMenu}
          >
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              direction="column"
              wrap="nowrap"
              className={classes.mobileMenu}
            >
              {sections.map(({ order, icon, name }) => (
                <MenuItem
                  key={order}
                  className={classes.menuItem}
                  onClick={
                    token || clientNavigation
                      ? (e) => mobileMenuItemClick(e, order)
                      : () => {}
                  }
                >
                  <img src={icon} alt={name} className={classes.menuIcon} />
                  {name}
                </MenuItem>
              ))}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

ClientMenu.propTypes = {
  selectedMenuItem: number.isRequired,
  classes: shape({}).isRequired,
  history: shape({}).isRequired,
  token: string.isRequired,
  sections: arrayOf(shape({})).isRequired,
  customer: shape({}).isRequired,
  handleSelectMenuItem: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { selectedMenuItem } = state.menu;
  const { customer } = state.meeting;
  const { sections } = state.project;
  return {
    selectedMenuItem,
    token,
    sections,
    customer
  };
};

export default connect(mapStateToProps)(withStyles(themeStyles)(ClientMenu));
