import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, bool, func, number } from 'prop-types';
import copy from 'copy-to-clipboard';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ChatIcon from '@material-ui/icons/Chat';
import MeetingAction from '../../stores/meeting/actions';
import TwilioAction from '../../stores/twilio/actions';
import styles from './styles';
import CallButtons from '../CallButtons';
import ErrorModal from '../Modals/ErrorModal';
import PreviewModal from '../Modals/PreviewModal';
import LeaveMeetingModal from '../Modals/LeaveMeetingModal';
import {
  logoutIcon,
  micOnIcon,
  videoOnIcon,
  micOffIcon,
  videoOffIcon,
  linkIcon
} from '../../config/assets';
import { isMobile } from '../../utils';
import { socketEmit } from '../../services/socket';
import './styles.scss';

class VideoChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: true,
      error: '',
      errorType: 'generic',
      errorCode: null,
      preview: false,
      selectedMic: 'no-mic',
      microphones: [],
      settings: false,
      leaveMeetingModal: false,
      loading: true
    };

    this.localVideo = null;
    this.activeVideo = null;
    this.remoteMedia = null;
    this.yourname = null;
  }

  componentDidMount = () => {
    this.startPreview();
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount() {
    const { socket } = this.props;
    socket.off('FINISH-SESSION', this.onFinishSession);
  }

  startConnection = (video, cameraId) => {
    this.connect(video, cameraId);
    this.handleSocketMessage();
  };

  startPreview = () => {
    this.setState({
      preview: true
    });
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('FINISH-SESSION', this.onFinishSession);
    }
  };

  onFinishSession = (data) => {
    const { type } = data;
    switch (type) {
      case 'FINISH-SESSION':
        this.disconnect();
        this.finishMeeting();
        break;
      default:
        break;
    }
  };

  setVideoStart = (video, cameraId) => {
    this.setState({
      preview: false
    });
    this.startConnection(video, cameraId);
  };

  connect = (video, cameraId) => {
    const {
      twilio,
      twilioToken,
      roomName,
      classes,
      socket,
      activeMeeting,
      dispatch
    } = this.props;
    this.setState({
      error: ''
    });
    if (twilio) {
      dispatch(TwilioAction.toggleVideo(video));
      twilio.connect(
        twilioToken,
        roomName,
        this.remoteMedia,
        this.activeVideo,
        classes,
        this.setLoadingStatus,
        this.onError,
        video,
        cameraId,
        socket,
        activeMeeting.id
      );
    }
  };

  onError = (error, code = null) => {
    console.error(error);
    const { history } = this.props;
    if (error.includes('not Found')) {
      history.push('/error/404');
    } else {
      const type = error.includes('Permission denied')
        ? 'permission'
        : 'generic';
      this.setState({
        error,
        errorType: type,
        errorCode: code,
        preview: false
      });
    }
  };

  muteAudio = () => {
    const { twilio } = this.props;
    const { audio } = this.state;
    if (twilio) {
      twilio.muteAudio();
      this.setState({
        audio: !audio
      });
    }
  };

  muteVideo = () => {
    const { twilio, video, dispatch } = this.props;
    if (twilio) {
      twilio.muteVideo();
      dispatch(TwilioAction.toggleVideo(!video));
    }
  };

  closeModal = () => {
    this.setState({
      error: ''
    });
  };

  finishMeeting = async () => {
    const { token, activeMeeting, twilioRoom, socket, dispatch } = this.props;
    if (token) {
      const body = {
        status: 'closed',
        twilioRoomId: twilioRoom
      };
      const { id: meetingId } = activeMeeting;
      socketEmit(socket, 'message-meeting', {
        id: meetingId,
        event: 'FINISH-SESSION',
        data: {
          type: 'FINISH-SESSION'
        }
      });
      const actionResponse = await dispatch(
        MeetingAction.finishMeeting(meetingId, body)
      );
      const { isError } = actionResponse;
      if (!isError) {
        this.getMeetingsList();
      }
    } else {
      const { id: meetingId } = activeMeeting;
      dispatch(MeetingAction.leaveMeeting(meetingId));
    }
  };

  copyLink = () => {
    const { activeMeeting } = this.props;
    const location = window.location.origin;
    copy(`${location}/meeting/${activeMeeting.id}`);
  };

  disconnect = () => {
    const { twilio } = this.props;
    if (twilio) {
      twilio.disconnect();
    }
  };

  getMeetingsList = () => {
    const { dispatch, token } = this.props;
    if (token) {
      const body = {
        status: ['scheduled', 'active']
      };
      dispatch(MeetingAction.listMeetings(body));
    }
  };

  applyChanges = () => {};

  showLeaveMeetingModal = () => {
    this.setState({ leaveMeetingModal: true });
  };

  hideLeaveMeetingModal = () => {
    this.setState({ leaveMeetingModal: false });
  };

  logout = () => {
    this.disconnect();
    this.finishMeeting();
  };

  setLoadingStatus = (loading) => {
    this.setState({ loading });
  };

  render() {
    const {
      classes,
      videoCallOpen,
      toggleVideoCall,
      video,
      language,
      token,
      socket,
      activeMeeting,
      currentTab,
      toggleMobileMenu,
      pendingMessages
    } = this.props;
    const { id: meetingId } = activeMeeting;
    const {
      error,
      errorType,
      errorCode,
      audio,
      preview,
      settings,
      previewAudio,
      selectedMic,
      microphones,
      leaveMeetingModal,
      loading
    } = this.state;
    const buttons = [
      {
        icon: videoOnIcon,
        offIcon: videoOffIcon,
        action: this.muteVideo,
        isActive: video
      },
      {
        icon: micOnIcon,
        offIcon: micOffIcon,
        action: this.muteAudio,
        isActive: audio
      },
      {
        icon: logoutIcon,
        offIcon: logoutIcon,
        action: this.showLeaveMeetingModal,
        isActive: true
      }
    ];
    const sessionButtons = [
      {
        icon: linkIcon,
        offIcon: linkIcon,
        action: this.copyLink,
        isActive: true
      }
    ];
    const mobileContainer = videoCallOpen
      ? classes.videoChatMobile
      : classes.closedVideoChat;

    const mobileVideosContainer = classes.videosMobileContainer;
    return (
      <>
        <LeaveMeetingModal
          leaveMeetingModal={leaveMeetingModal}
          hideLeaveMeetingModal={this.hideLeaveMeetingModal}
          logout={this.logout}
        />
        <Grid container className={`${isMobile() ? mobileContainer : ''}`}>
          {isMobile() ? (
            <Grid
              container
              alignItems="flex-start"
              className={
                isMobile() ? mobileVideosContainer : classes.videosContainer
              }
            >
              <Grid
                container
                className={classes.videosReel}
                style={!videoCallOpen ? { display: 'none' } : {}}
                wrap="nowrap"
              >
                <Grid
                  ref={(ref) => {
                    this.activeVideo = ref;
                  }}
                  className={classes.remoteMedia}
                />
                <Grid
                  ref={(ref) => {
                    this.remoteMedia = ref;
                  }}
                  className={classnames(
                    classes.remoteMedia,
                    'remoteMedia',
                    currentTab !== 0 && classes.hiddenMedia
                  )}
                />
              </Grid>
              {!loading && (
                <Grid
                  container
                  justify="space-between"
                  style={videoCallOpen ? { paddingBottom: 8 } : {}}
                >
                  <CallButtons
                    buttons={buttons}
                    videoCallOpen={videoCallOpen}
                    direction="row"
                  />
                  {!token && (
                    <Grid className={classes.chatIconContainer}>
                      {pendingMessages && (
                        <Grid
                          container
                          className={classes.chatBadgeIndicator}
                        />
                      )}
                      <ChatIcon
                        onClick={toggleMobileMenu}
                        className={classes.chatIcon}
                      />
                    </Grid>
                  )}
                  {videoCallOpen ? (
                    <ArrowDropDown onClick={toggleVideoCall} />
                  ) : (
                    <ArrowDropUp onClick={toggleVideoCall} />
                  )}
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              alignItems="flex-start"
              className={
                isMobile() ? mobileVideosContainer : classes.videosContainer
              }
            >
              <Grid container>
                {!loading && (
                  <CallButtons
                    buttons={sessionButtons}
                    videoCallOpen={videoCallOpen}
                    direction="row"
                  />
                )}
              </Grid>
              <Grid
                container
                justify="flex-end"
                alignItems="flex-end"
                className={classes.speakerAndButttons}
              >
                <Grid
                  className={
                    isMobile()
                      ? classes.mobileVideoContainer
                      : classes.userVideoContainer
                  }
                >
                  <Grid
                    ref={(ref) => {
                      this.activeVideo = ref;
                    }}
                    style={{ width: '100%' }}
                    className={classes.remoteMedia}
                  />
                </Grid>
                {!loading && (
                  <CallButtons
                    buttons={buttons}
                    videoCallOpen={videoCallOpen}
                    direction="column"
                  />
                )}
              </Grid>
              <Grid
                ref={(ref) => {
                  this.remoteMedia = ref;
                }}
                style={{ width: '100%', height: 'auto' }}
                className={classnames(
                  classes.remoteMedia,
                  'remoteMedia',
                  currentTab !== 0 && classes.hiddenMedia
                )}
              />
            </Grid>
          )}

          <ErrorModal
            error={error}
            type={errorType}
            code={errorCode}
            closeModal={this.closeModal}
            connect={this.startPreview}
            lang={language}
          />
          {preview && (
            <PreviewModal
              preview={preview}
              microphones={microphones}
              selectedMic={selectedMic}
              handleSelectMic={this.handleSelectMic}
              setVideoStart={this.setVideoStart}
              audioTrack={previewAudio}
              settings={settings}
              applyChanges={this.applyChanges}
              onError={this.onError}
              publisher={!!token}
              meetingId={meetingId}
              socket={socket}
              language={language}
            />
          )}
        </Grid>
      </>
    );
  }
}

VideoChat.propTypes = {
  roomName: string.isRequired,
  twilioToken: string.isRequired,
  classes: shape({}).isRequired,
  videoCallOpen: bool.isRequired,
  toggleVideoCall: func.isRequired,
  language: string.isRequired,
  token: string.isRequired,
  activeMeeting: shape({}).isRequired,
  twilioRoom: string.isRequired,
  socket: shape({}).isRequired,
  twilio: shape({}).isRequired,
  history: shape({}).isRequired,
  video: bool.isRequired,
  currentTab: number.isRequired,
  toggleMobileMenu: func.isRequired,
  publisher: bool.isRequired,
  pendingMessages: bool,
  dispatch: func.isRequired
};

VideoChat.defaultProps = {
  pendingMessages: false
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { language } = state.language;
  const { activeMeeting, twilioRoom, publisher } = state.meeting;
  const { loading } = state.loading;
  const { twilio, video } = state.twilio;
  return {
    token,
    language,
    activeMeeting,
    twilioRoom,
    loading,
    twilio,
    video,
    publisher
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VideoChat));
