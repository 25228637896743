import { hexToRgb } from '../../utils';

const styles = (theme) => {
  const {
    content = {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69',
      coverBackgroundColor: '#fafafa',
      coverInactiveFontColor: '#6c6c6c',
      coverActiveFontColor: '#ec6b69',
      tabsBackgroundColor: '#fafafa',
      inactiveTabColor: '#6c6c6c',
      activeTabColor: '#ec6b69',
      highlightColor: '#ec6b69',
      alertColor: '#ec6b69',
      iconColor:
        'invert(83%) sepia(4%) saturate(0%) hue-rotate(39deg) brightness(115%) contrast(108%)',
      svg: {
        inactive: 'brightness(0) invert(42.3%)',
        active:
          'invert(65%) sepia(82%) saturate(2200%) hue-rotate(316deg) brightness(109%) contrast(70%)',
        background: 'brightness(0) invert(42.3%)',
        coverIconColor: 'brightness(0) invert(42.3%)'
      }
    }
  } = theme;
  console.log('theme', theme);
  const activeColorRGB = hexToRgb(theme.content.activeFontColor);
  const coverHghlightRGB = hexToRgb(
    theme.leftMenu.activeFontColor || theme.leftMenu.activeFontColor
  );
  return {
    listContainer: {
      margin: 0,
      padding: '10px 48px 16px 48px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 36px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 24px'
      },
      '& p': {
        margin: 0,
        fontSize: 16,
        fontWeight: 300,
        color: theme.content.inactiveFontColor
      },
      '& .list-element': {
        margin: '5px 0',
        '& .selected': {
          backgroundColor: `rgba(${activeColorRGB[0]}, ${activeColorRGB[1]}, ${activeColorRGB[2]}, 0.2)`,
          color: theme.content.activeFontColor
        },
        '& .list-icon': {
          width: 20,
          height: 20,
          marginRight: 10,
          filter: content.iconColor
        }
      }
    },
    coverListContainer: {
      margin: 0,
      padding: '10px 48px 16px 48px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 36px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 24px'
      },
      '& p': {
        margin: 0,
        fontSize: 16,
        fontWeight: 300,
        color: theme.leftMenu.inactiveFontColor
      },
      '& .list-element': {
        margin: '5px 0',
        '& .selected': {
          backgroundColor: `rgba(${coverHghlightRGB[0]}, ${coverHghlightRGB[1]}, ${coverHghlightRGB[2]}, 0.2)`,
          color: theme.leftMenu.backgroundColor
        },
        '& .list-icon': {
          width: 20,
          height: 20,
          marginRight: 10,
          filter: content.svg.background
        }
      }
    },
    cardListContainer: {
      margin: 0,
      padding: '5px 10px',
      '& p': {
        margin: 0,
        fontSize: 12,
        fontWeight: 300,
        color: theme.content.inactiveFontColor
      },
      '& .list-element': {
        margin: '5px 0',
        '& .selected': {
          backgroundColor: `rgba(${activeColorRGB[0]}, ${activeColorRGB[1]}, ${activeColorRGB[2]}, 0.2)`,
          color: theme.content.inactiveFontColor
        }
      }
    },
    coverListIcon: {
      width: 20,
      height: 20,
      marginRight: 10,
      filter: theme.leftMenu.svg.inactive
    },
    cardListIcon: {
      width: 12,
      height: 12,
      marginRight: 5,
      filter: content.iconColor
    },
    pointer: {
      cursor: 'pointer'
    }
  };
};

export default styles;
