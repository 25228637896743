import React from 'react';
import { shape, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Loader from './index';
import styles from './styles';

const FullPageLoader = ({ classes, loading }) => {
  if (loading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.fullPageLoaderContainer}
      >
        <Loader load={false} />
      </Grid>
    );
  }
  return null;
};

FullPageLoader.propTypes = {
  classes: shape({}).isRequired,
  loading: bool.isRequired
};

export default withStyles(styles)(FullPageLoader);
