import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CopyLinkButton = withStyles((theme) => ({
  root: {
    width: 550,
    height: 50,
    boxShadow: '0px 2px 4px rgba(138, 138, 138, 0.5)',
    textTransform: 'none',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 12px',
    border: 'none',
    borderRadius: 3.5,
    lineHeight: 1.5,
    backgroundColor: '#ededed',
    color: '#6c6c6c',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#ededed'
    },
    '&:active': {
      backgroundColor: '#ededed',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: '#ededed',
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 10
    }
  }
}))(Button);

export default CopyLinkButton;
