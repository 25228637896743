import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, func } from 'prop-types';
import { Grid, FormControl, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SessionAction from '../stores/session/actions';
import { athumLogoLogin } from '../config/assets';
import styles from '../styles';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { params } = match;
    const { mail = '', oneTimePassword = '' } = params;
    this.state = {
      mail,
      oneTimePassword,
      password: '',
      confirmPassword: ''
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { cookies, dispatch } = this.props;
    const { mail, password, oneTimePassword } = this.state;
    const actionResponse = await dispatch(
      SessionAction.confirmAccount(mail, oneTimePassword, password, cookies)
    );
    const { isError } = actionResponse;
    if (!isError) {
      this.goToLobby();
    }
  };

  goToLobby = () => {
    const { history } = this.props;
    history.push('/');
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  render() {
    const { mail, password, oneTimePassword, confirmPassword } = this.state;
    const { classes, error, match, builderInfo } = this.props;
    const { params } = match;
    const { builder = null } = params;
    const { logo = '', name = '' } = builderInfo;
    return (
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
        className={classes.mainContainerLogin}
      >
        {builder && logo ? (
          <img src={logo} alt={name} />
        ) : (
          <img src={athumLogoLogin.src} alt={athumLogoLogin.alt} />
        )}
        <form
          className={classes.formContainer}
          onSubmit={this.handleSubmit}
          noValidate
          autoComplete="off"
        >
          {error ? (
            <FormHelperText className={classes.errorMessage}>
              {error}
            </FormHelperText>
          ) : (
            <FormHelperText className={classes.errorMessage} />
          )}
          <FormControl>
            <CustomLabel htmlFor="mail">Email</CustomLabel>
            <CustomInput
              id="mail"
              type="mail"
              value={mail}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <FormControl>
            <CustomLabel htmlFor="oneTimePassword">
              One Time Password
            </CustomLabel>
            <CustomInput
              id="oneTimePassword"
              type="password"
              value={oneTimePassword}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <FormControl>
            <CustomLabel htmlFor="password">Password</CustomLabel>
            <CustomInput
              id="password"
              type="password"
              value={password}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <FormControl>
            <CustomLabel htmlFor="confirmPassword">
              Confirm Password
            </CustomLabel>
            <CustomInput
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <CustomButton type="submit">Login</CustomButton>
        </form>
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  history: shape({}).isRequired,
  match: shape({}).isRequired,
  cookies: shape({}).isRequired,
  classes: shape({}).isRequired,
  error: string.isRequired,
  builderInfo: shape({}).isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { message: error } = state.error;
  const { builderInfo } = state.project;
  return {
    language,
    error,
    builderInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginPage));
