import LoginPage from '../pages/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import LobbyPage from '../pages/LobbyPage';
import SettingsPage from '../pages/SettingsPage';
import MeetingRoomPage from '../pages/MeetingRoomPage';
import ClientPage from '../pages/ClientPage';
import OneTimePasswordLoginPage from '../pages/OneTimePasswordLoginPage';
import ClientErrorPage from '../pages/ClientErrorPage';
import ErrorPage from '../pages/ErrorPage';
import MeetingRoomFull from '../pages/MeetingRoomFull';
import ReschedulePage from '../pages/ReschedulePage';

const getRoutes = (error) => [
  {
    RouteComponent: LoginPage,
    path: '/login',
    menu: false
  },
  {
    RouteComponent: LoginPage,
    path: '/login/:builder',
    menu: false
  },
  {
    RouteComponent: LobbyPage,
    path: '/',
    menu: true
  },
  {
    RouteComponent: SettingsPage,
    path: '/settings',
    menu: true
  },
  {
    RouteComponent: ForgotPasswordPage,
    path: '/forgot-password',
    menu: false
  },
  {
    RouteComponent: ResetPasswordPage,
    path: '/reset-password',
    menu: false
  },
  {
    RouteComponent: ResetPasswordPage,
    path: '/reset-password/:mail',
    menu: false
  },
  {
    RouteComponent: MeetingRoomPage,
    path: '/meeting/:meetingId',
    menu: true
  },
  {
    RouteComponent: ClientPage,
    path: '/meeting/:meetingId/:customerId',
    menu: !error
  },
  {
    RouteComponent: ClientErrorPage,
    path: '/client/error',
    menu: false
  },
  {
    RouteComponent: OneTimePasswordLoginPage,
    path: '/confirm-account',
    menu: false
  },
  {
    RouteComponent: OneTimePasswordLoginPage,
    path: '/confirm-account/:mail/:oneTimePassword',
    menu: false
  },
  {
    RouteComponent: ErrorPage,
    path: '/error/:error',
    menu: false
  },
  {
    RouteComponent: MeetingRoomFull,
    path: '/meeting-room-full',
    menu: false
  },
  {
    RouteComponent: ReschedulePage,
    path: '/reschedule/:meetingId',
    menu: false
  },
  {
    RouteComponent: ReschedulePage,
    path: '/reschedule',
    menu: false
  }
];

export default getRoutes;
