import React from 'react';
import { shape, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Watcher from './Watcher';
import Broadcaster from './Broadcaster';
import styles from './styles';

const LiveTour = ({ classes, publisher, meetingId, socket }) => (
  <Grid container className={classes.liveTourContainer}>
    {!publisher ? (
      <Watcher socket={socket} meetingId={meetingId} />
    ) : (
      <Broadcaster socket={socket} meetingId={meetingId} />
    )}
  </Grid>
);

LiveTour.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: bool.isRequired
};

export default withStyles(styles)(LiveTour);
