import { TextField } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomTextField = withStyles((theme) =>
  createStyles({
    root: {
      marginTop: 24,
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#4A4A4A',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#ededed',
        border: 'none',
        fontSize: 14,
        width: '100%',
        height: 40,
        transition: theme.transitions.create(['box-shadow']),
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.content.activeFontColor}`,
          borderWidth: 1
        },
        '&:focus': {
          boxShadow: `${theme.content.activeFontColor} 0 0 0 1px`
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          height: 32
        },
        '& .MuiAutocomplete-input': {
          padding: '1.5px 4px',
          '&:focus': {
            boxShadow: `${theme.content.activeFontColor} 0 0 0 1px`
          }
        }
      }
    }
  })
)(TextField);

export default CustomTextField;
