import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, string, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControl, FormHelperText } from '@material-ui/core';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';
import styles from '../styles';
import SessionAction from '../stores/session/actions';
import FullPageLoader from '../components/Loader/FullPageLoader';
import {
  changePassword,
  currentPasswordLabel,
  newPasswordLabel
} from '../config/messages';

class SettingsPage extends Component {
  constructor() {
    super();
    this.state = {
      currentPassword: '',
      newPassword: ''
    };
  }

  componentDidMount() {
    const userFromSession = JSON.parse(sessionStorage.getItem('user')) || {};
    if (JSON.stringify(userFromSession) === '{}') {
      this.goToLogin();
    }
    // this.initiateChangePassword();
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  goToLogin = () => {
    const { history } = this.props;
    history.push('/login');
  };

  // initiateChangePassword = () => {
  //   const { sessionActions: actionsFromSession } = this.props;
  //   actionsFromSession.initiateChangePassword();
  // };

  changePassword = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { currentPassword, newPassword } = this.state;
    dispatch(SessionAction.changePassword(currentPassword, newPassword));
  };

  render() {
    const { classes, loading, error, message, language } = this.props;
    const { currentPassword, newPassword } = this.state;
    const errorMessage = error ? (
      <FormHelperText className={classes.errorMessage}>{error}</FormHelperText>
    ) : (
      <FormHelperText className={classes.errorMessage} />
    );
    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.settingsMainContainer}
      >
        <FullPageLoader loading={loading} />
        <form
          className={classes.settingsFormContainer}
          onSubmit={this.changePassword}
          noValidate
          autoComplete="off"
        >
          {message ? (
            <FormHelperText className={classes.correctMessage}>
              {message}
            </FormHelperText>
          ) : (
            errorMessage
          )}
          <FormControl className={classes.settingsFormControl}>
            <CustomLabel htmlFor="currentPassword">
              {currentPasswordLabel[language]}
            </CustomLabel>
            <CustomInput
              id="currentPassword"
              type="password"
              value={currentPassword}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <FormControl className={classes.settingsFormControl}>
            <CustomLabel htmlFor="newPassword">
              {newPasswordLabel[language]}
            </CustomLabel>
            <CustomInput
              id="newPassword"
              type="password"
              value={newPassword}
              onChange={this.handleInputChange}
            />
          </FormControl>
          <Grid container className={classes.settingsButtonContainer}>
            <CustomButton type="submit">
              {changePassword[language]}
            </CustomButton>
          </Grid>
        </form>
      </Grid>
    );
  }
}

SettingsPage.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  loading: bool.isRequired,
  error: string.isRequired,
  history: shape({}).isRequired,
  message: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { token, message } = state.session;
  const { loading } = state.loading;
  const { message: error } = state.error;
  const { language } = state.language;
  return {
    token,
    language,
    loading,
    error,
    message
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SettingsPage));
