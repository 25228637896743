import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, bool, arrayOf, shape, func } from 'prop-types';
import ImageMenuItem from '../ImageMenuItem';
import ThreeSixtyAction from '../../../../stores/threeSixty/actions';
import { getCurrentRoomUse, getUses } from '../../../../selectors/Menu';
import {
  socketIdSelector,
  emitingSelector,
  logSelector,
  publisherSelector
} from '../../../../selectors/Socket';
import SocketActions from '../../../../stores/sockets/actions';

class MobileChangeRoomsMenu extends Component {
  componentDidMount() {
    const { socket, publisher } = this.props;
    if (socket !== null && !publisher) {
      socket.on('CHANGE-ROOM', (data) => {
        const { type } = data;
        switch (type) {
          case 'CHANGE-ROOM':
            this.applyRoom(data.roomType);
            break;

          default:
            break;
        }
      });
    }
  }

  changeRoomType = async (e, roomName) => {
    const { socket, typeOfLog, isEmiting, socketId: id } = this.props;
    const roomType = roomName === 'default' ? null : roomName;
    this.applyRoom(roomType);
    if (isEmiting) {
      SocketActions.socketEmit(socket, typeOfLog, {
        id,
        event: 'CHANGE-ROOM',
        data: {
          type: 'CHANGE-ROOM',
          roomType
        }
      });
    }
  };

  applyRoom = async (roomType) => {
    const { dispatch } = this.props;
    await dispatch(ThreeSixtyAction.setSelectedUse(roomType));

    await dispatch(ThreeSixtyAction.changeSphereUse());
  };

  render() {
    const { uses, currentRoomUse, closeMenu } = this.props;
    return (
      <>
        <div className="mobile-submenu-title">CHANGE ROOMS</div>
        <div
          id="views-mobile-menu"
          className="mobile-submenu views-mobile-menu sub-mobile-menu d-flex justify-content-start align-items-center"
        >
          {uses.map((room, index) => (
            <ImageMenuItem
              key={room.key}
              keyName={room.key}
              name={room.name}
              index={index}
              onClick={this.changeRoomType}
              img={room.image}
              selected={currentRoomUse}
              closeMenu={closeMenu}
            />
          ))}
        </div>
      </>
    );
  }
}

MobileChangeRoomsMenu.propTypes = {
  closeMenu: func.isRequired,
  uses: arrayOf(shape({})).isRequired,
  currentRoomUse: string.isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired,
  isEmiting: bool.isRequired,
  socketId: string.isRequired,
  typeOfLog: string.isRequired,
  publisher: bool.isRequired
};

const mapStateToProps = (state) => ({
  uses: getUses(state),
  currentRoomUse: getCurrentRoomUse(state),
  socketId: socketIdSelector(state),
  isEmiting: emitingSelector(state),
  typeOfLog: logSelector(state),
  publisher: publisherSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileChangeRoomsMenu);
