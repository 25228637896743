import React from 'react';
import { shape, string, bool, func, arrayOf, number } from 'prop-types';
import { connect } from 'react-redux';
import SalesDesktopPrivatePanel from './SalesDesktopPrivatePanel';
import ClientDesktopPrivatePanel from './ClientDesktopPrivatePanel';

const DesktopPrivatePanel = ({
  privatePanelVisible,
  togglePrivatePanel,
  videoCallOpen,
  socket,
  toggleVideoCall,
  token,
  meetingIsActive,
  tabs,
  buyerTabs,
  pendingMessages,
  currentTab,
  changeTab
}) => (
  <>
    {token ? (
      <SalesDesktopPrivatePanel
        videoCallOpen={videoCallOpen && meetingIsActive}
        socket={socket}
        toggleVideoCall={toggleVideoCall}
        togglePrivatePanel={togglePrivatePanel}
        privatePanelVisible={privatePanelVisible}
        tabs={tabs}
        pendingMessages={pendingMessages}
        currentTab={currentTab}
        changeTab={changeTab}
      />
    ) : (
      <ClientDesktopPrivatePanel
        videoCallOpen={videoCallOpen && meetingIsActive}
        socket={socket}
        toggleVideoCall={toggleVideoCall}
        togglePrivatePanel={togglePrivatePanel}
        privatePanelVisible={privatePanelVisible}
        buyerTabs={buyerTabs}
        pendingMessages={pendingMessages}
        currentTab={currentTab}
        changeTab={changeTab}
      />
    )}
  </>
);

DesktopPrivatePanel.propTypes = {
  privatePanelVisible: bool.isRequired,
  togglePrivatePanel: func.isRequired,
  videoCallOpen: bool.isRequired,
  socket: shape({}).isRequired,
  toggleVideoCall: func.isRequired,
  token: string.isRequired,
  meetingIsActive: bool.isRequired,
  tabs: arrayOf(shape({})).isRequired,
  buyerTabs: arrayOf(shape({})).isRequired,
  pendingMessages: bool.isRequired,
  currentTab: number.isRequired,
  changeTab: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { meetingIsActive } = state.meeting;
  return {
    token,
    meetingIsActive
  };
};

export default connect(mapStateToProps, null)(DesktopPrivatePanel);
