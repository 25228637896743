import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { shape, arrayOf, string, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import themeStyles from '../../../styles';
import { getFilteredFloorplans, getAreaLabel } from './utils';
import EditablePrice from './EditablePrice';
import FloorplanSpecs from './FloorplanSpecs';

const FilteredFloorplans = ({
  classes,
  prices,
  language,
  canUpdatePrices,
  state,
  toggleFloorplanDetails,
  undo,
  handlePriceInputChange
}) => {
  const {
    searchValue,
    bedrooms: bedroomsFilter,
    bathrooms: bathroomsFilter,
    parking: parkingFilter,
    floorplans,
    isEditing
  } = state;
  const filteredFloorplans = getFilteredFloorplans(
    prices,
    searchValue,
    bedroomsFilter,
    bathroomsFilter,
    parkingFilter
  );
  return (
    <Grid
      className={classes.layoutsDetailsContainer}
      style={{ paddingBottom: canUpdatePrices ? 65 : 0 }}
    >
      {filteredFloorplans.map((floorplan, index) => {
        const area = getAreaLabel(language, floorplan);
        return (
          <Grid container direction="column" key={floorplan.id}>
            {floorplan.units.map((unit, index2) => {
              const identifier = `${floorplan.id}-${unit.name}`;
              let price = '$ -';
              let rawPrice = '';
              let rawCurrency = '';
              const options = { style: 'currency', currency: 'USD' };
              const numberFormat = new Intl.NumberFormat('en-US', options);
              unit.prices.forEach((el) => {
                price = `${numberFormat.format(el.price)} ${el.currency}`;
                rawPrice = el.price;
                rawCurrency = el.currency;
              });
              return (
                <Fragment key={identifier}>
                  <Grid
                    container
                    className={
                      index === 0 && index2 === 0
                        ? classes.firstLayoutDetailsContainer
                        : classes.layoutDetailsContainer
                    }
                  >
                    <Grid container justify="space-between" alignItems="center">
                      <Grid
                        container
                        className={classes.layoutName}
                        onClick={() => {
                          toggleFloorplanDetails(identifier);
                        }}
                      >
                        {floorplans[identifier] ? (
                          <ArrowDropDown className={classes.pricePanelIcon} />
                        ) : (
                          <ArrowRight className={classes.pricePanelIcon} />
                        )}
                        <Grid>{floorplan.name}</Grid>
                      </Grid>
                      {isEditing ? (
                        <EditablePrice
                          rawCurrency={rawCurrency}
                          rawPrice={rawPrice}
                          state={state}
                          unit={unit}
                          undo={undo}
                          handlePriceInputChange={handlePriceInputChange}
                        />
                      ) : (
                        <Grid className={classes.layoutPrice}>{price}</Grid>
                      )}
                    </Grid>
                    <Grid container className={classes.unitName}>
                      <Grid>{unit.name}</Grid>
                    </Grid>
                    {floorplans[identifier] ? (
                      <FloorplanSpecs floorplan={floorplan} area={area} />
                    ) : null}
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

FilteredFloorplans.propTypes = {
  classes: shape({}).isRequired,
  prices: arrayOf(shape({})).isRequired,
  language: string.isRequired,
  canUpdatePrices: bool.isRequired,
  searchValue: string,
  bedrooms: string,
  bathrooms: string,
  parking: string,
  floorplans: shape({}),
  isEditing: bool,
  state: shape({}).isRequired,
  toggleFloorplanDetails: func.isRequired,
  undo: func.isRequired,
  handlePriceInputChange: func.isRequired
};

FilteredFloorplans.defaultProps = {
  searchValue: '',
  bedrooms: '',
  bathrooms: '',
  parking: '',
  floorplans: {},
  isEditing: false
};

const mapStateToProps = (state) => {
  const { prices } = state.project;
  const { language } = state.language;
  const { user = { roles: [] } } = state.session;
  const { roles = [] } = user;
  const canUpdatePrices =
    roles.filter((role) =>
      role.modules.filter(
        ({ module, permissions }) =>
          module === 'Prices' && permissions.includes('update')
      )
    ).length > 0;
  return {
    prices,
    language,
    canUpdatePrices
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(themeStyles)(FilteredFloorplans));
