import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, shape, bool, number, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem } from '@material-ui/core';
import ProjectAction from '../../stores/project/actions';
import CustomPriceSelectInput from '../CustomPriceSelectInput';
import CustomInputBedroom from '../CustomInputBedroom';
import CustomInputBathroom from '../CustomInputBathroom';
import CustomInputBathroomOffice from '../CustomInputBathroomOffice';
import CustomInputParking from '../CustomInputParking';
import styles from './styles';
import {
  bathroomIcon,
  bedroomIcon,
  parkingIcon,
  sqmIcon,
  buildingIcon
} from '../../config/assets';
import { filterFloorplansBy } from '../../config/messages';
import { socketEmit } from '../../services/socket';

class Layouts extends Component {
  constructor() {
    super();
    this.state = {
      bedrooms: null,
      bathrooms: null,
      parking: null
    };
  }

  componentDidMount = () => {
    this.handleSocketMessage();
  };

  selectLayout = (selectedLayout) => {
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      currentPage,
      selectedTab,
      isCustomer,
      sessionID,
      dispatch
    } = this.props;
    dispatch(
      ProjectAction.selectLayout(selectedLayout, currentPage, selectedTab)
    );
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id,
        event: 'ACTIVE-FLOORPLAN',
        data: {
          type: 'ACTIVE-FLOORPLAN',
          selectedLayout
        }
      });
    }
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('ACTIVE-FLOORPLAN', (data) => {
        const { type, selectedLayout } = data;
        switch (type) {
          case 'ACTIVE-FLOORPLAN':
            this.selectLayout(selectedLayout);
            break;
          default:
            break;
        }
      });
      socket.on('LAYOUT-FILTERS', (data) => {
        const { type, bedrooms, bathrooms, parking } = data;
        switch (type) {
          case 'LAYOUT-FILTERS':
            this.handleFilters(bedrooms, bathrooms, parking);
            break;
          default:
            break;
        }
      });
    }
  };

  handleFilters = (bedrooms, bathrooms, parking) => {
    this.setState({ bedrooms, bathrooms, parking });
  };

  handleChange = (event, id) => {
    const { target } = event;
    const { value } = target;
    this.setState({ [id]: value });
    const {
      socket,
      publisher,
      activeMeeting,
      meetingIsActive,
      isCustomer,
      sessionID
    } = this.props;
    const { bedrooms, bathrooms, parking } = this.state;
    if ((publisher && meetingIsActive) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const socketId = isCustomer ? sessionID : activeMeeting.id;
      socketEmit(socket, type, {
        id: socketId,
        event: 'LAYOUT-FILTERS',
        data: {
          type: 'LAYOUT-FILTERS',
          bedrooms: id === 'bedrooms' ? value : bedrooms,
          bathrooms: id === 'bathrooms' ? value : bathrooms,
          parking: id === 'parking' ? value : parking
        }
      });
    }
  };

  render() {
    const { classes, content, language } = this.props;
    const {
      bedrooms: bedroomsFilter,
      bathrooms: bathroomsFilter,
      parking: parkingFilter
    } = this.state;
    const bedroomOptions = [...new Set(content.map((el) => el.bedrooms))].sort(
      (a, b) => a - b
    );
    const bathroomOptions = [
      ...new Set(content.map((el) => el.bathrooms))
    ].sort((a, b) => a - b);
    const parkingOptions = [...new Set(content.map((el) => el.parking))].sort(
      (a, b) => a - b
    );
    let filteredFloorplans = content;
    if (bedroomsFilter !== null) {
      filteredFloorplans = filteredFloorplans.filter(
        (el) => el.bedrooms === bedroomsFilter
      );
    }
    if (bathroomsFilter !== null) {
      filteredFloorplans = filteredFloorplans.filter(
        (el) => el.bathrooms === bathroomsFilter
      );
    }
    if (parkingFilter !== null) {
      filteredFloorplans = filteredFloorplans.filter(
        (el) => el.parking === parkingFilter
      );
    }
    const bathroomIconSelect =
      content.length > 0 ? content[0].bathroomIcon : bathroomIcon.src;
    const bathroomSelect =
      content.length > 0 && content[0].bedrooms > 0 ? (
        <CustomInputBathroom />
      ) : (
        <CustomInputBathroomOffice />
      );
    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={classes.floorplansContainer}
      >
        <Grid container>
          {(bedroomOptions.length > 1 ||
            bathroomOptions.length > 1 ||
            parkingOptions.length > 1) && (
            <h3>{filterFloorplansBy[language]}</h3>
          )}
          {bedroomOptions.length > 1 && (
            <Select
              id="bedrooms"
              value={bedroomsFilter}
              onChange={(event) => this.handleChange(event, 'bedrooms')}
              input={
                bedroomsFilter === null ? (
                  <CustomInputBedroom />
                ) : (
                  <CustomPriceSelectInput />
                )
              }
              className={classes.filterSelect}
            >
              <MenuItem value={null}>
                <img src={bedroomIcon.src} alt={bedroomIcon.alt} />
              </MenuItem>
              {bedroomOptions.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          )}
          {bathroomOptions.length > 1 && (
            <Select
              id="bathrooms"
              value={bathroomsFilter}
              onChange={(event) => this.handleChange(event, 'bathrooms')}
              input={
                bathroomsFilter === null ? (
                  bathroomSelect
                ) : (
                  <CustomPriceSelectInput />
                )
              }
              className={classes.filterSelect}
            >
              <MenuItem value={null}>
                <img src={bathroomIconSelect} alt="Bathroom" />
              </MenuItem>
              {bathroomOptions.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          )}
          {parkingOptions.length > 1 && (
            <Select
              id="parking"
              value={parkingFilter}
              onChange={(event) => this.handleChange(event, 'parking')}
              input={
                parkingFilter === null ? (
                  <CustomInputParking />
                ) : (
                  <CustomPriceSelectInput />
                )
              }
              className={classes.filterSelect}
            >
              <MenuItem value={null}>
                <img src={parkingIcon.src} alt={parkingIcon.alt} />
              </MenuItem>
              {parkingOptions.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        {filteredFloorplans.map((layout) => {
          const {
            layoutId,
            title,
            layoutName,
            bedrooms,
            bathrooms,
            area,
            areaMetric,
            imageUri: minimap,
            bathroomIcon: bathroomUniqueIcon,
            parking,
            subSections,
            building = null,
            studio,
            studioIcon,
            threeSixty,
            threeSixtyIcon,
            video,
            videoIcon
          } = layout;
          const doClick = subSections.length > 0;
          let badge = null;
          if (video) {
            badge = (
              <Grid className={classes.layoutRibbon}>
                <Grid
                  container
                  className={classes.videoLayoutRibbonContent}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  Video
                  <img
                    src={videoIcon}
                    alt="Video"
                    className={classes.badgeIcon}
                  />
                </Grid>
              </Grid>
            );
          }
          if (threeSixty) {
            badge = (
              <Grid className={classes.layoutRibbon}>
                <Grid
                  container
                  className={classes.threeSixtyLayoutRibbonContent}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  360°
                  <img
                    src={threeSixtyIcon}
                    alt="Three Sixty"
                    className={classes.badgeIcon}
                  />
                </Grid>
              </Grid>
            );
          }
          return (
            <Grid
              key={layoutId}
              container
              justify="space-around"
              alignItems="flex-start"
              direction="column"
              className={
                doClick
                  ? classes.floorplanContainer
                  : classes.floorplanContainerNoAction
              }
              onClick={doClick ? () => this.selectLayout(layout) : () => {}}
            >
              {badge}
              <img src={minimap} alt={layoutName} className={classes.minimap} />
              <p className={classes.layoutTitle}>{title}</p>
              <Grid container justify="flex-start" alignItems="center">
                {studio ? (
                  <Grid className={classes.floorplanSpecification}>
                    <img
                      src={studioIcon}
                      alt="studio"
                      className={classes.specificationIcon}
                    />
                    {studio}
                  </Grid>
                ) : (
                  bedrooms > 0 && (
                    <Grid className={classes.floorplanSpecification}>
                      <img
                        src={bedroomIcon.src}
                        alt={bedroomIcon.alt}
                        className={classes.specificationIcon}
                      />
                      {bedrooms}
                    </Grid>
                  )
                )}
                <Grid className={classes.floorplanSpecification}>
                  <img
                    src={bathroomUniqueIcon}
                    alt="bathroom"
                    className={classes.specificationIcon}
                  />
                  {bathrooms}
                </Grid>
                {parking > 0 && (
                  <Grid className={classes.floorplanSpecification}>
                    <img
                      src={parkingIcon.src}
                      alt={parkingIcon.alt}
                      className={classes.specificationIcon}
                    />
                    {parking}
                  </Grid>
                )}
                <Grid className={classes.floorplanSpecification}>
                  <img
                    src={sqmIcon.src}
                    alt={sqmIcon.alt}
                    className={classes.specificationIcon}
                  />
                  {`${area} ${areaMetric}`}
                </Grid>
                {building && (
                  <Grid className={classes.floorplanSpecification}>
                    <img
                      src={buildingIcon.src}
                      alt={buildingIcon.alt}
                      className={classes.specificationIcon}
                    />
                    {building.length > 65
                      ? `${building.substring(0, 62)}...`
                      : building}
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

Layouts.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  publisher: bool.isRequired,
  currentPage: number.isRequired,
  selectedTab: number.isRequired,
  language: string.isRequired,
  sessionID: string.isRequired,
  isCustomer: bool.isRequired,
  dispatch: func.isRequired,
  meetingIsActive: bool.isRequired
};

const mapStateToProps = (state) => {
  const { sessionID } = state.session;
  const { activeMeeting, isCustomer, meetingIsActive } = state.meeting;
  const { language } = state.language;
  return { activeMeeting, language, sessionID, isCustomer, meetingIsActive };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Layouts));
