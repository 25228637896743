import React, { Component } from 'react';
import { shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles';
import Loader from './Loader';
import { socketEmit } from '../../services/socket';

class Watcher extends Component {
  constructor(props) {
    super(props);
    this.video = null;
    this.state = {
      config: {
        iceServers: [
          {
            urls: 'stun:stun.services.mozilla.com'
          },
          {
            urls: 'stun:stun.l.google.com:19302'
          }
        ]
      },
      peerConnection: null,
      start: false,
      id: uuidv4()
    };
  }

  componentDidMount() {
    this.handleSocketMessage();
  }

  componentWillUnmount() {
    const { socket } = this.props;
    socket.off('OFFER', this.onMessage);
    socket.off('CONNECT', this.onMessage);
    socket.off('BROADCASTER', this.onMessage);
    socket.off('CANDIDATE', this.onMessage);
    socket.off('DISCONNECTPEER', this.onMessage);
    if (this.video !== null) {
      if (this.video.srcObject) {
        const stream = this.video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        this.video.srcObject = null;
      }
    }
  }

  handleSocketMessage = () => {
    const { socket } = this.props;
    socket.on('OFFER', this.onMessage);
    socket.on('CONNECT', this.onMessage);
    socket.on('CANDIDATE', this.onMessage);
    socket.on('DISCONNECTPEER', this.onMessage);
    socket.on('BROADCASTER', this.onMessage);
  };

  onMessage = (data) => {
    const { type, id, description, candidate } = data;
    switch (type) {
      case 'OFFER':
        this.handleOffer(id, description);
        break;
      case 'CONNECT':
        this.handleConnect(id);
        break;
      case 'CANDIDATE':
        this.handleCandidate(id, candidate);
        break;
      case 'DISCONNECTPEER':
        this.handleDisconnectPeer(id, candidate);
        break;
      case 'BROADCASTER':
        this.handleBroadCaster(id, candidate);
        break;
      default:
        break;
    }
  };

  handleOffer = (id, description) => {
    const { socket, meetingId } = this.props;
    const { config, id: stateId } = this.state;
    let { peerConnection } = this.state;
    if (id === stateId) {
      peerConnection = new RTCPeerConnection(config);
      peerConnection
        .setRemoteDescription(description)
        .then(() => peerConnection.createAnswer())
        .then((sdp) => peerConnection.setLocalDescription(sdp))
        .then(() => {
          socketEmit(socket, 'message-meeting', {
            id: meetingId,
            event: 'ANSWER',
            data: {
              type: 'ANSWER',
              id,
              description: peerConnection.localDescription
            }
          });
        });
      peerConnection.ontrack = (event) => {
        // eslint-disable-next-line prefer-destructuring
        this.video.srcObject = event.streams[0];
      };
      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          socketEmit(socket, 'message-meeting', {
            id: meetingId,
            event: 'CANDIDATE',
            data: {
              type: 'CANDIDATE',
              id,
              candidate: event.candidate
            }
          });
        }
      };
      this.setState({ peerConnection });
    }
  };

  handleCandidate = (id, candidate) => {
    const { peerConnection, id: stateId } = this.state;
    if (id === stateId) {
      peerConnection
        .addIceCandidate(new RTCIceCandidate(candidate))
        .catch((e) => console.error(e));
    }
  };

  handleConnect = () => {
    const { socket, meetingId } = this.props;
    const { id } = this.state;
    this.setState({ start: true });
    socketEmit(socket, 'message-meeting', {
      id: meetingId,
      event: 'WATCHER',
      data: {
        type: 'WATCHER',
        id
      }
    });
  };

  handleBroadCaster = () => {
    const { socket, meetingId } = this.props;
    const { id } = this.state;
    this.setState({ start: true });
    socketEmit(socket, 'message-meeting', {
      id: meetingId,
      event: 'WATCHER',
      data: {
        type: 'WATCHER',
        id
      }
    });
  };

  handleDisconnectPeer = () => {
    const { peerConnection } = this.state;
    peerConnection.close();
  };

  render() {
    const { classes } = this.props;
    const { start } = this.state;
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.videoContainer}
      >
        {start && (
          <Grid item className={classes.webcamContainerWatcher}>
            <video
              ref={(ref) => {
                this.video = ref;
              }}
              playsInline
              autoPlay
              muted
            />
          </Grid>
        )}
        {!start && <Loader />}
      </Grid>
    );
  }
}

Watcher.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired
};

export default withStyles(styles)(Watcher);
