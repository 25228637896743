import React, { Component } from 'react';
import { shape, arrayOf, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getParentNodeId, calculateBackgroundWidth } from '../../utils';
import { socketEmit } from '../../services/socket';
import styles from './styles';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: '', selectedWidth: 0 };
  }

  componentDidMount() {
    const { socket } = this.props;
    socket.on('TABLE-ITEM-SELECTED', this.onSelectedEvent);
  }

  onSelectedEvent = (data) => {
    const { selected } = data;
    this.setState({ selected });
    this.resetSelected();
  };

  onItemSelected = (e) => {
    e.preventDefault();
    const selected = e.target.id || getParentNodeId(e.target);
    const selectedWidth = e.target.id
      ? calculateBackgroundWidth(e.target)
      : calculateBackgroundWidth(e.target.parentNode);
    const { socket, meetingId, publisher, isCustomer, sessionID } = this.props;
    if (publisher || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      this.setState({ selected, selectedWidth });
      socketEmit(socket, type, {
        id,
        event: 'TABLE-ITEM-SELECTED',
        data: {
          type: 'TABLE-ITEM-SELECTED',
          selected,
          selectedWidth
        }
      });
      this.resetSelected();
    }
  };

  resetSelected = () => {
    setTimeout(() => {
      this.setState({ selected: '', selectedWidth: 0 });
    }, 2000);
  };

  render() {
    const { classes, content, height, publisher } = this.props;
    const { selected } = this.state;
    return (
      <Grid className={classes.tableContainer} style={{ height }}>
        <table className={classes.table}>
          {content.map((el, indexRow) => (
            <tr className={classes.tableRow}>
              {el.columns.map((col, index) => (
                <td
                  id={`td-${indexRow * 1}-${index * 1}`}
                  key={`td-${index * 1}`}
                  className={`${classes.tableColumn} ${
                    selected === `td-${indexRow * 1}-${index * 1}`
                      ? 'selected'
                      : ''
                  } ${publisher ? classes.pointer : ''}`}
                  onClick={this.onItemSelected}
                >
                  {col.rows.map(({ color, fontWeight, text }, index2) => (
                    <p
                      className={classes.columnRow}
                      key={`p-${index2 * 1}`}
                      style={{ color, fontWeight }}
                    >
                      {text}
                    </p>
                  ))}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </Grid>
    );
  }
}

Table.propTypes = {
  classes: shape({}).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: string.isRequired,
  content: arrayOf(shape({})).isRequired,
  height: string.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired
};

export default withStyles(styles)(Table);
