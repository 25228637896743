import { hexToRgb } from '../../utils';

const styles = (theme) => {
  const primaryColorRGB = hexToRgb(theme.colors.primary);
  const complementaryColorRGB = hexToRgb(theme.colors.complementary);
  return {
    chatContainer: {
      width: '100%',
      height: 'calc(100% - 400px)',
      background: theme.rightMenu.backgroundColor,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: '100%'
      }
    },
    chatLog: {
      width: '100%',
      height: '80%',
      overflow: 'auto',
      color: theme.rightMenu.inactiveFontColor
    },
    author: {
      color: theme.rightMenu.inactiveFontColor,
      fontWeight: 'bold',
      fontSize: 14
    },
    inputContainer: {
      width: '100%',
      height: 40,
      position: 'absolute',
      bottom: 0,
      zIndex: 99,
      backgroundColor: theme.rightMenu.backgroundColor,
      borderTop: '0.7px solid rgba(237, 237, 237, 0.3)',
      [theme.breakpoints.down('sm')]: {
        bottom: 40
      },
      '& input': {
        width: '80%',
        height: 30,
        borderRadius: 25,
        border: 'none',
        backgroundColor: theme.rightMenu.inactiveTabColor,
        color: theme.rightMenu.inactiveFontColor,
        padding: 10,
        '&:focus': {
          outline: 'none'
        }
      },
      '& button': {
        width: '10%',
        '&:focus': {
          outline: 'none'
        }
      }
    },
    messageEnd: {
      float: 'left',
      clear: 'both',
      padding: 10,
      margin: 10
    },
    lightGreen: {
      width: '80%',
      padding: 10,
      margin: 10,
      background: `rgba(${primaryColorRGB[0]}, ${primaryColorRGB[1]}, ${primaryColorRGB[2]}, 0.1)`,
      borderRadius: '10px'
    },
    gray: {
      width: '80%',
      padding: 10,
      margin: 10,
      background: `rgba(${complementaryColorRGB[0]}, ${complementaryColorRGB[1]}, ${complementaryColorRGB[2]}, 0.1)`,
      borderRadius: '10px'
    },
    bubble: { width: '100%' },
    bubbleLeft: {
      justifyContent: 'flex-start'
    },
    bubbleRight: {
      justifyContent: 'flex-end'
    },
    sendButton: {
      '&.button': {
        width: 30,
        height: 30,
        background: theme.colors.primary,
        border: 'none',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:focus': {
          outline: 0
        },
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }
      }
    },
    chatTitleContainer: {
      height: '10%'
    },
    chatIcon: {
      color: theme.rightMenu.inactiveFontColor,
      fontSize: 14,
      margin: '0 12px'
    },
    chatTitle: {
      color: theme.rightMenu.inactiveFontColor,
      fontSize: 14,
      fonttWeight: 'bold',
      margin: 0
    }
  };
};

export default styles;
