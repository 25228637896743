const styles = (theme) => ({
  carouselContainer: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  selectedImageContainer: {
    width: '100%',
    height: '80%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      // width: '100%',
      height: '100%'
    }
  },
  selectedImageFullContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      // width: '100%',
      height: '100%'
    }
  },
  thumbnailsContainer: {
    width: 'auto',
    padding: 15,
    height: '20%',
    background: 'white'
  },
  thumbnailContainer: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    background: 'white'
  },
  thumbnail: {
    height: '100%',
    width: 144,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    marginRight: 10,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: 100
    },
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      '&:focus': {
        outline: 'none'
      }
    },
    '& .selected': {
      border: `3px solid ${theme.content.activeFontColor}`,
      borderRadius: 4
    },
    '&:last-child': {
      marginRight: 0
    },
    '&:focus': {
      outline: 0
    }
  },
  button: {
    height: 28,
    width: 28,
    background: '#ffffff',
    backdropFilter: 'blur(10px)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 99,
    bottom: 'calc((20% - 30px) / 2)',
    cursor: 'pointer',
    '&.left': {
      left: 5
    },
    '&.right': {
      right: 5
    },
    '&:focus': {
      outline: 0
    }
  },
  chevron: {
    color: '#6c6c6c'
  },
  loaded: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s linear 0s, opacity 300ms'
  },
  loading: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s linear 300ms, opacity 300ms'
  }
});

export default styles;
