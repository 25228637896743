const styles = (theme) => ({
  logoContainer: {
    padding: '10px 48px 16px 48px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 36px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 24px'
    }
  },
  builderLogo: {
    height: 35
  },
  athumLogo: {
    height: 35
  }
});

export default styles;
