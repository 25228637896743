import React from 'react';
import { shape, string, func, number } from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CustomButton from '../CustomButton';
import { isMobile } from '../../utils';
import {
  fullMessage,
  firstFullMessage,
  buttonText,
  typeMessage
} from '../../config/messages';
import { iconFailed } from '../../config/assets';
import styles from '../../styles';

const ErrorModal = ({
  classes,
  error,
  closeModal,
  connect,
  lang,
  type,
  code
}) => {
  console.error(error);
  const selectedMessage = typeMessage[type];
  const fullRoom = error.includes('Room contains too many Participants');
  const errorMessage = fullRoom
    ? fullMessage[lang]
    : selectedMessage.message[lang];
  const headerMessage = fullRoom
    ? firstFullMessage[lang]
    : selectedMessage.firstMessage[lang];
  return (
    <Modal
      open={error !== ''}
      onClose={() => {
        closeModal();
      }}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        className={
          isMobile()
            ? classes.errorMobileModalContainer
            : classes.errorModalContainer
        }
      >
        <img className="failed" src={iconFailed.src} alt={iconFailed.src} />
        <h2>{headerMessage}</h2>
        <p>{errorMessage}</p>
        {!fullRoom && (
          <CustomButton
            onClick={() => {
              connect();
            }}
          >
            {buttonText[lang]}
          </CustomButton>
        )}
        {code && <p>{code}</p>}
      </Grid>
    </Modal>
  );
};

ErrorModal.propTypes = {
  classes: shape({}).isRequired,
  error: string.isRequired,
  closeModal: func.isRequired,
  connect: func.isRequired,
  lang: string.isRequired,
  type: string.isRequired,
  code: number.isRequired
};

export default withStyles(styles)(ErrorModal);
