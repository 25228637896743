import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24
      },
      '&.MuiInputBase-adornedStart': {
        backgroundColor: '#EDEDED',
        borderRadius: 4,
        '& .MuiInputAdornment-positionStart': {
          marginLeft: 8
        },
        '&.Mui-disabled': {
          backgroundColor: '#FAFAFA'
        }
      },
      '&.MuiInputBase-adornedEnd': {
        backgroundColor: '#EDEDED',
        borderRadius: 4,
        '& .MuiInputAdornment-positionEnd': {
          marginRight: 8
        },
        '&.Mui-disabled': {
          backgroundColor: '#FAFAFA'
        }
      },
      '&:-webkit-autofill': {
        backgroundColor: '#EDEDED'
      },
      '&:-webkit-autofill:hover': {
        backgroundColor: '#EDEDED'
      },

      '&:-webkit-autofill:focus': {
        backgroundColor: '#EDEDED'
      },
      '&:-webkit-autofill:active': {
        backgroundColor: '#EDEDED'
      }
    },
    input: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      color: '#4A4A4A',
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#EDEDED',
      border: 'none',
      fontSize: 14,
      width: 318,
      padding: 12,
      transition: theme.transitions.create(['box-shadow']),
      '&:focus': {
        boxShadow: `${theme.rightMenu.activeFontColor} 0 0 0 1px`
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '&#clientNameFM': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 412
        }
      },
      '&#clientEmailFM': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 412
        }
      },
      '&.MuiInputBase-inputAdornedStart': {
        paddingLeft: 0
      },
      '&.Mui-disabled': {
        backgroundColor: '#FAFAFA',
        color: '#4A4A4A'
      }
    }
  })
)(InputBase);

export default CustomInput;
