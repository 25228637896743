import React, { Component } from 'react';
import { arrayOf, shape, bool, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Carousel from '../Carousel';
import styles from './styles';
import { socketEmit } from '../../services/socket';

class Finishes extends Component {
  constructor() {
    super();
    this.state = {
      selectedFinish: null
    };
  }

  componentDidMount = () => {
    this.handleSocketMessage();
  };

  selectFinish = (selectedFinish) => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    this.setState({ selectedFinish });
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'ACTIVE-FINISH',
        data: {
          type: 'ACTIVE-FINISH',
          selectedFinish
        }
      });
    }
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('ACTIVE-FINISH', (data) => {
        const { type, selectedFinish } = data;
        switch (type) {
          case 'ACTIVE-FINISH':
            this.selectFinish(selectedFinish);
            break;
          default:
            break;
        }
      });
    }
  };

  render() {
    const { classes, content, socket, publisher, meetingId } = this.props;
    const { selectedFinish } = this.state;
    if (selectedFinish) {
      return (
        <Grid container>
          <KeyboardArrowLeftIcon
            className={classes.icon}
            onClick={() => this.selectFinish(null)}
          />
          <Carousel
            content={selectedFinish.gallery}
            socket={socket}
            publisher={publisher}
            meetingId={meetingId}
          />
        </Grid>
      );
    }
    return (
      <Grid
        container
        justify="space-around"
        direction="row"
        className={classes.finishesContainer}
      >
        {content.map((finish) => {
          const { gallery, image, name } = finish;
          const doClick = gallery.length > 0;
          return (
            <Grid
              key={name}
              container
              justify="space-between"
              direction="column"
              className={
                doClick
                  ? classes.finishContainer
                  : classes.finishContainerNoAction
              }
              onClick={doClick ? () => this.selectFinish(finish) : () => {}}
            >
              <img src={image} alt={name} className={classes.finishImage} />
              <p>{name}</p>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

Finishes.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  meetingId: string.isRequired,
  publisher: bool.isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired
};

export default withStyles(styles)(Finishes);
