import React from 'react';
import { Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles((theme) => {
  const {
    leftMenu = {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69'
    }
  } = theme;
  return {
    paper: {
      border: '0.7px solid rgba(237, 237, 237, 0.3)',
      width: '180px',
      left: '0px !important',
      top: '40px !important',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: 0,
      backgroundColor: leftMenu.backgroundColor,
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      [theme.breakpoints.down('sm')]: {
        right: '0px',
        margin: 'auto',
        width: '100%'
      }
    }
  };
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    disableAutoFocusItem
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

export default StyledMenu;
