const styles = (theme) => ({
  container: {
    position: 'relative',
    width: '100%'
  },
  description: {
    padding: '25px 20px 25px',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'rgba(40, 40, 40, 0.8)',
    color: '#fafafa'
  },
  loaded: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s linear 0s, opacity 300ms',
    width: '100%'
  },
  loading: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s linear 300ms, opacity 300ms'
  },
  '100%': {
    height: '100%'
  },
  '90%': {
    height: '90%'
  },
  '80%': {
    height: '80%',
    [theme.breakpoints.down('lg')]: {
      height: '70%'
    },
    [theme.breakpoints.down('md')]: {
      height: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      height: '80%'
    }
  },
  '70%': {
    height: '70%'
  },
  '60%': {
    height: '60%',
    [theme.breakpoints.down('lg')]: {
      height: '50%'
    },
    [theme.breakpoints.down('md')]: {
      height: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      height: '60%'
    }
  },
  '50%': {
    height: '50%'
  },
  '40%': {
    height: '40%'
  },
  '30%': {
    height: '30%'
  }
});

export default styles;
