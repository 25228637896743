import React from 'react';
import { shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const WebView = ({ classes, url, layout }) => (
  <iframe
    src={url}
    title={layout}
    className={classes.iframeContainer}
    frameBorder="0"
    allowFullscreen
    allow="xr-spatial-tracking"
    sandbox="allow-forms allow-modals	allow-orientation-lock allow-pointer-lock	allow-popups allow-popups-to-escape-sandbox	allow-presentation	allow-same-origin	allow-scripts"
  />
);

WebView.propTypes = {
  classes: shape({}).isRequired,
  url: string.isRequired,
  layout: string.isRequired
};
export default withStyles(styles)(WebView);
