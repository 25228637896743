/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { connect } from 'react-redux';
import ThreeSixtyAction from '../../stores/threeSixty/actions';
import Menu from './Menu/Menu';
import CurrentViewStyle from './CurrentViewStyle';
import Viewer from './Viewer';
import ErrorModal from './ErrorModal';
import './Loader.scss';

class ThreeSixty extends Component {
  componentDidMount() {
    this.loadContent();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(ThreeSixtyAction.reset());
  }

  async loadContent() {
    const { builderInfo, dispatch } = this.props;

    await dispatch(ThreeSixtyAction.setBuilder({ ...builderInfo.params }));

    await dispatch(ThreeSixtyAction.getScenes());

    await dispatch(ThreeSixtyAction.getStyles());

    await dispatch(ThreeSixtyAction.getScenesByStyles());

    // await dispatch(SessionAction.log([]));
  }

  render() {
    const { levels, socket } = this.props;
    return (
      <>
        <div className="w-100 h-100" style={{ position: 'absolute' }}>
          <Menu socket={socket} />
          {levels.length > 0 && <Viewer socket={socket} />}
          <CurrentViewStyle />
          <ErrorModal />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    currentLevel,
    selectedStyleName,
    selectedScene,
    selectedFinish,
    mode,
    levels
  } = state.threeSixty;
  return {
    currentLevel,
    selectedStyleName,
    selectedScene,
    selectedFinish,
    mode,
    levels
  };
};

ThreeSixty.propTypes = {
  builderInfo: shape({}).isRequired,
  levels: arrayOf(shape({})).isRequired,
  dispatch: func.isRequired,
  socket: shape({}).isRequired
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreeSixty);
