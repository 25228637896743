import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Loader from '../Loader';
import styles from './styles';

const Content = ({ classes, text, img }) => {
  const isGif = img.includes('gif');
  const [loaded, setLoaded] = useState(false);
  return (
    <Grid container justify="center" alignItems="center">
      <Loader load={isGif ? !loaded : loaded}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.contentContainer}
        >
          <p>{text}</p>
          <img
            onLoad={() => setLoaded(true)}
            onError={() => setLoaded(false)}
            src={img}
            alt="content"
          />
        </Grid>
      </Loader>
    </Grid>
  );
};

Content.propTypes = {
  classes: shape({}).isRequired,
  text: string.isRequired,
  img: string.isRequired
};

export default withStyles(styles)(Content);
