/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect } from 'react';
import { string, bool, func, shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const MP4Player = ({
  src,
  playing,
  muted,
  onPlay,
  onPause,
  onMute,
  onUnmute,
  classes
}) => {
  const video = useRef(null);
  useEffect(() => {
    video.current.addEventListener('play', () => {
      onPlay();
    });
    video.current.addEventListener('pause', () => {
      onPause();
    });
    video.current.addEventListener('volumechange', (event) => {
      if (event.target.muted) {
        onMute();
      } else {
        onUnmute();
      }
    });
  });
  if (!playing && video.current !== null) {
    video.current.pause();
  } else if (playing && video.current !== null) {
    video.current.play();
  }
  return (
    <video
      ref={video}
      src={src}
      className={classes.iframe}
      controls
      muted={muted}
    />
  );
};

MP4Player.propTypes = {
  src: string.isRequired,
  playing: bool.isRequired,
  muted: bool.isRequired,
  onPlay: func.isRequired,
  onPause: func.isRequired,
  onMute: func.isRequired,
  onUnmute: func.isRequired,
  classes: shape({}).isRequired
};

export default withStyles(styles)(MP4Player);
