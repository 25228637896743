import { combineReducers } from 'redux';
import LanguageReducer from './language/reducers';
import SessionReducer from './session/reducers';
import ErrorReducer from './error/reducers';
import LoadingReducer from './loading/reducers';
import ProjectReducer from './project/reducers';
import MeetingReducer from './meeting/reducers';
import MenuReducer from './menu/reducers';
import TwilioReducer from './twilio/reducers';
import ThreeSixtyReducer from './threeSixty/reducers';
import PanoramaReducer from './panorama/reducers';

const rootReducer = combineReducers({
  language: new LanguageReducer().reducer,
  session: new SessionReducer().reducer,
  error: new ErrorReducer().reducer,
  loading: new LoadingReducer().reducer,
  project: new ProjectReducer().reducer,
  meeting: new MeetingReducer().reducer,
  menu: new MenuReducer().reducer,
  twilio: new TwilioReducer().reducer,
  panorama: new PanoramaReducer().reducer,
  threeSixty: new ThreeSixtyReducer().reducer
});

export default rootReducer;
