import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, shape, arrayOf, number, func } from 'prop-types';
import { Grid, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { switchIcon } from '../../config/assets';
import styles from '../../styles';
import { switchProperties } from '../../config/messages';
import StyledMenu from './StyledMenu';
import StyledMenuItem from './StyledMenuItem';

class SalesMenu extends Component {
  constructor() {
    super();
    this.state = {
      anchorProjectEl: null
    };
  }

  handleProjectClick = (event) => {
    this.setState({ anchorProjectEl: event.currentTarget });
  };

  handleProjectClose = () => {
    this.setState({ anchorProjectEl: null });
  };

  render() {
    const {
      classes,
      language,
      projects,
      selectedProject: currentProject,
      handlePropertySelected
    } = this.props;
    const { anchorProjectEl } = this.state;
    const selectedProject = projects.find(
      (project) => project.id === currentProject
    );
    return (
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.projectsMobileMenu}
      >
        <Grid
          onClick={(e) => {
            e.stopPropagation();
            this.handleProjectClick(e);
          }}
          container
          className={classes.switchContainer}
        >
          <Grid
            container
            className="switch-properties-container"
            alignItems="center"
            justify="flex-start"
          >
            <img
              src={switchIcon.src}
              alt={switchIcon.alt}
              className={classes.switchIcon}
            />
            {switchProperties[language]}
          </Grid>
          {selectedProject && (
            <Grid
              container
              alignItems="center"
              justify="flex-start"
              className="switch-properties-current"
            >
              <img
                src={selectedProject.logoContracted}
                alt={`logo-${selectedProject.id}`}
                className={classes.projectLogo}
              />
              {selectedProject.name}
            </Grid>
          )}
        </Grid>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorProjectEl}
          open={Boolean(anchorProjectEl)}
          onClose={(e) => {
            e.stopPropagation();
            this.handleProjectClose();
          }}
        >
          {projects.map((project) => (
            <StyledMenuItem
              key={`project-${project.id}`}
              className={classes.menuProjectItem}
              onClick={() => {
                handlePropertySelected(project.id);
                this.handleProjectClose();
              }}
            >
              <img
                src={project.logoContracted}
                alt={`logo-${project.id}`}
                className={classes.projectLogo}
              />

              <ListItemText
                className={classes.projectName}
                primary={project.name}
              />
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Grid>
    );
  }
}

SalesMenu.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  projects: arrayOf(shape({})).isRequired,
  selectedProject: number.isRequired,
  handlePropertySelected: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { projects, selectedProject } = state.project;
  return {
    language,
    projects,
    selectedProject
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SalesMenu));
