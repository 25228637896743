import React from 'react';
import { shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import loader from '../../assets/home-white.gif';
import styles from './styles';
import { background } from '../../config/assets';
import { waitingAgentCalculator } from '../../config/messages';

const Loader = ({ classes, language }) => (
  <div
    style={{
      backgroundImage: `url(${background.src})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 80%'
    }}
    className={`${classes.loader} white-background`}
  >
    <div className={classes.textContainer}>
      <h1>{waitingAgentCalculator[language]}</h1>
    </div>
    <div className="loader-image-container">
      <img src={loader} alt="athum loader" />
    </div>
  </div>
);

Loader.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;

  return {
    language
  };
};
export default connect(mapStateToProps, null)(withStyles(styles)(Loader));
