import React, { useState } from 'react';
import { string, bool, number, func, shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../Loader';
import styles from './styles';

const Thumbnail = ({
  url,
  name,
  selected,
  index,
  onThumbnailClick,
  classes
}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div
      role="button"
      tabIndex={0}
      className={classes.thumbnail}
      onClick={() => {
        onThumbnailClick(index);
      }}
    >
      <Loader load={loaded}>
        <img
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(false)}
          className={`${loaded ? classes.loaded : classes.loading} ${
            selected ? 'selected' : ''
          }`}
          src={url}
          alt={name}
        />
      </Loader>
    </div>
  );
};

Thumbnail.propTypes = {
  url: string.isRequired,
  name: string.isRequired,
  selected: bool.isRequired,
  index: number.isRequired,
  onThumbnailClick: func.isRequired,
  classes: shape({}).isRequired
};

export default withStyles(styles)(Thumbnail);
