/* eslint-disable class-methods-use-this */
import TwilioAction from '../actions';
import BaseReducer from '../../../utilities/BaseReducer';
import TWILIO from '../../../class/Twilio';

export default class TwilioReducer extends BaseReducer {
  initialState = {
    twilio: null,
    video: false
  };

  [TwilioAction.CREATE_TWILIO_OBJECT](state) {
    return {
      ...state,
      twilio: new TWILIO()
    };
  }

  [TwilioAction.TOGGLE_VIDEO](state, action) {
    return {
      ...state,
      video: action.payload.video
    };
  }
}
