import React from 'react';
import { shape, arrayOf, func, string } from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import themeStyles from '../../../styles';
import CustomButton from '../../CustomButton';
import { plusIcon } from '../../../config/assets';
import { newSession, noSessionsRegistered } from '../../../config/messages';
import ActiveMeetings from './ActiveMeetings';
import ScheduledMeetings from './ScheduledMeetings';

const PrivatePanelMeetings = ({
  meetings,
  classes,
  openScheduleMeetingForm,
  handleCopyText,
  startMeeting,
  deleteMeeting,
  toggleMobileMenu,
  language,
  theme
}) => {
  moment.locale(language);
  const createSessionButton = (
    <Grid container className={classes.buttonContainer}>
      <CustomButton onClick={openScheduleMeetingForm}>
        <img
          src={plusIcon.src}
          alt={plusIcon.alt}
          className={classes.buttonIcon}
        />
        {newSession[language]}
      </CustomButton>
    </Grid>
  );
  return (
    <Grid className={classes.meetingsPrivatePanelContainer}>
      <ActiveMeetings
        meetings={meetings}
        openScheduleMeetingForm={openScheduleMeetingForm}
        handleCopyText={handleCopyText}
        startMeeting={startMeeting}
        toggleMobileMenu={toggleMobileMenu}
        language={language}
      />
      <ScheduledMeetings
        meetings={meetings}
        openScheduleMeetingForm={openScheduleMeetingForm}
        handleCopyText={handleCopyText}
        startMeeting={startMeeting}
        toggleMobileMenu={toggleMobileMenu}
        language={language}
        deleteMeeting={deleteMeeting}
        theme={theme}
      />
      {meetings.length === 0 && (
        <Grid className={classes.noMeetingsContainer}>
          <h4>{noSessionsRegistered[language]}</h4>
          {createSessionButton}
        </Grid>
      )}
    </Grid>
  );
};

PrivatePanelMeetings.propTypes = {
  meetings: arrayOf(shape({})).isRequired,
  classes: shape({}).isRequired,
  openScheduleMeetingForm: func.isRequired,
  handleCopyText: func.isRequired,
  startMeeting: func.isRequired,
  deleteMeeting: func.isRequired,
  toggleMobileMenu: func.isRequired,
  language: string.isRequired,
  theme: shape({}).isRequired
};

export default withStyles(themeStyles)(PrivatePanelMeetings);
