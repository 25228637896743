import React, { useRef, useEffect, useState } from 'react';
import { string, shape, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const YoutubePlayer = ({
  src,
  playing,
  muted,
  onPlay,
  onPause,
  onMute,
  onUnmute,
  classes,
  publisher
}) => {
  const youtube = useRef(null);
  const [player, setPlayer] = useState(null);
  let mutedStatus = muted;
  useEffect(() => {
    const onPlayerReady = (event) => {
      if (muted) {
        event.target.mute();
      }
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.PLAYING) {
        onPlay();
      } else if (event.data === window.YT.PlayerState.PAUSED) {
        onPause();
      }
    };
    const loadVideo = () => {
      const id = src.substring(src.lastIndexOf('/') + 1);
      const ytPlayer = new window.YT.Player('youtube-player', {
        videoId: id,
        playerVars: { autoplay: 0 },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange
        }
      });
      ytPlayer.addEventListener();
      setPlayer(ytPlayer);
    };

    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      loadVideo();
    }
  }, [setPlayer, onPlay, onPause, src, muted]);

  if (playing && youtube.current !== null && player !== null) {
    if (typeof player.playVideo === 'function') {
      player.playVideo();
    }
  } else if (!playing && youtube.current !== null && player !== null) {
    if (typeof player.pauseVideo === 'function') {
      player.pauseVideo();
    }
  }
  if (muted && youtube.current !== null && player !== null) {
    if (typeof player.mute === 'function') {
      player.mute();
    }
  } else if (!muted && youtube.current !== null && player !== null) {
    if (typeof player.unMute === 'function') {
      player.unMute();
    }
  }
  setInterval(() => {
    if (publisher && youtube.current !== null && player !== null) {
      if (player.isMuted() && !mutedStatus) {
        onMute();
        mutedStatus = !mutedStatus;
      } else if (!player.isMuted() && mutedStatus) {
        onUnmute();
        mutedStatus = !mutedStatus;
      }
    }
  }, 3000);
  return <div id="youtube-player" ref={youtube} className={classes.iframe} />;
};

YoutubePlayer.propTypes = {
  src: string.isRequired,
  playing: bool.isRequired,
  muted: bool.isRequired,
  onPlay: func.isRequired,
  onPause: func.isRequired,
  onMute: func.isRequired,
  onUnmute: func.isRequired,
  classes: shape({}).isRequired,
  publisher: bool.isRequired
};

export default withStyles(styles)(YoutubePlayer);
