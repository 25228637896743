import { hexToRgb } from '../../utils';

const styles = (theme) => {
  const primaryColorRGB = hexToRgb(theme.colors.primary || theme.colors.prmary);
  return {
    tableContainer: {
      overflowX: 'auto',
      padding: '10px 48px 16px 60px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 58px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    table: {
      width: '100%'
    },
    tableRow: {
      borderBottom: '1px solid #ededed'
    },
    tableColumn: {
      verticalAlign: 'top',
      '&.selected': {
        backgroundColor: `rgba(${primaryColorRGB[0]}, ${primaryColorRGB[1]}, ${primaryColorRGB[2]}, 0.2)`,
        color: theme.content.inactiveFontColor
      }
    },
    columnRow: {
      margin: 0,
      padding: 6
    },
    pointer: {
      cursor: 'pointer'
    }
  };
};

export default styles;
