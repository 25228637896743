import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shape, func, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CustomButton from './buttons';
import Content from './content';
import Circle from './Circle';
import { athumLogoLogin, background } from '../../config/assets';
import {
  buttonNextOnboarding,
  buttonStartOnboarding,
  buttonSkip
} from '../../config/messages';
import styles from './styles';

class Onboarding extends Component {
  constructor() {
    super();
    this.state = {
      selectedIndex: 0,
      numberOfItems: 0,
      projectLogoContracted: '',
      athumLogo: '',
      content: null
    };
  }

  componentDidMount = () => {
    this.arrangeContent();
  };

  arrangeContent = () => {
    const { onboarding } = this.props;
    const { pages } = onboarding;
    const { content } = pages[0];
    this.setState({
      projectLogoContracted: onboarding.projectLogoContracted,
      athumLogo: onboarding.athumLogo,
      content: this.prepareContent(content[0]),
      numberOfItems: content[0].content.length - 1
    });
  };

  prepareContent = (info) => {
    const content = [];
    info.content.forEach((element, index) => {
      content.push(
        <Content
          key={`content${index.toString()}`}
          text={element.content[0].content[0].text}
          img={element.content[1].content.src}
        />
      );
    });
    return content;
  };

  handleGallerySelectedIndex = (selectedIndex) => {
    this.setState({
      selectedIndex
    });
  };

  onNext = () => {
    this.setState((prevState) => ({
      selectedIndex: prevState.selectedIndex + 1
    }));
  };

  changeIndex = (selectedIndex) => {
    this.setState({ selectedIndex });
  };

  onPrev = () => {
    const { selectedIndex } = this.state;
    if (selectedIndex !== 0) {
      this.setState((prevState) => ({
        selectedIndex: prevState.selectedIndex - 1
      }));
    }
  };

  render() {
    const { classes, joinMeeting, language } = this.props;
    const {
      numberOfItems,
      selectedIndex,
      content,
      projectLogoContracted,
      athumLogo
    } = this.state;
    return (
      <>
        {content && (
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            wrap="nowrap"
            className={classes.container}
          >
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="flex-start"
              wrap="nowrap"
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{
                  backgroundImage: `url(${background.src})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 90%'
                }}
                className={classes.header}
              >
                <img src={projectLogoContracted} alt="project-icon" />
                <img src={athumLogo} alt={athumLogoLogin.alt} />
              </Grid>
              {content[selectedIndex]}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.buttonsContainer}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.buttons}
                >
                  <Grid
                    container
                    alignItems="center"
                    className={classes.skipButton}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        joinMeeting();
                      }}
                      className={classes.buttonAsLink}
                    >
                      {buttonSkip[language]}
                    </button>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    style={{ width: 34 }}
                  >
                    {content.map((item, index) => (
                      <Circle
                        selected={index <= selectedIndex}
                        goToIndex={() => this.changeIndex(index)}
                      />
                    ))}
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.nextButton}
                  >
                    {selectedIndex !== numberOfItems && (
                      <CustomButton
                        onClick={() => {
                          this.onNext();
                        }}
                      >
                        {buttonNextOnboarding[language]}
                      </CustomButton>
                    )}
                    {selectedIndex === numberOfItems && (
                      <CustomButton
                        onClick={() => {
                          joinMeeting();
                        }}
                      >
                        {buttonStartOnboarding[language]}
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

Onboarding.propTypes = {
  onboarding: shape({}).isRequired,
  classes: shape({}).isRequired,
  joinMeeting: func.isRequired,
  language: string.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  return {
    language
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Onboarding));
