import React from 'react';
import { shape, string, number, func } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import styles from '../../../styles';
import { backButton } from '../../../config/messages';

const BackToInteractiveMap = ({
  classes,
  interactiveMapSection,
  interactiveMapSubsectionPage,
  language,
  goToInteractiveMap
}) => {
  const interactiveMapIsPrevious =
    JSON.stringify(interactiveMapSection) !== '{}' &&
    JSON.stringify(interactiveMapSubsectionPage) !== '{}';
  const goBackToInteractiveMap = () =>
    goToInteractiveMap(
      interactiveMapSection.selectedMenuItem,
      interactiveMapSubsectionPage.selectedTab,
      interactiveMapSubsectionPage.selectedPage
    );
  if (interactiveMapIsPrevious) {
    return (
      <Grid
        container
        alignItems="center"
        onClick={goBackToInteractiveMap}
        className={classes.backButton}
      >
        <KeyboardArrowLeftIcon className={classes.backIcon} />
        {backButton[language]}
      </Grid>
    );
  }
  return null;
};

BackToInteractiveMap.propTypes = {
  classes: shape({}).isRequired,
  selectedPage: number,
  selectedTab: number,
  interactiveMapSection: shape({}).isRequired,
  interactiveMapSubsectionPage: shape({}).isRequired,
  language: string.isRequired,
  selectedMenuItem: number,
  goToInteractiveMap: func.isRequired
};

BackToInteractiveMap.defaultProps = {
  selectedPage: 1,
  selectedTab: 0,
  selectedMenuItem: 1
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const {
    selectedMenuItem,
    interactiveMapLocation: interactiveMapSection
  } = state.menu;
  const {
    selectedLayout,
    selectedPage,
    selectedTab,
    interactiveMapLocation: interactiveMapSubsectionPage
  } = state.project;
  return {
    selectedMenuItem,
    selectedLayout,
    selectedPage,
    selectedTab,
    interactiveMapSection,
    interactiveMapSubsectionPage,
    language
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(BackToInteractiveMap));
