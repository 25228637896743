import React, { useState, useRef } from 'react';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Loader from '../Loader';
import styles from './styles';

const Image = ({ classes, content }) => {
  const {
    src,
    alt,
    height = '100%',
    description = '',
    objectFit = 'cover'
  } = content;
  const [loaded, setLoaded] = useState(false);
  const refContainer = useRef(null);
  const possibleHeights = {
    '100%': '100%',
    '90%': '90%',
    '80%': '80%',
    '70%': '70%',
    '60%': '60%',
    '50%': '50%',
    '40%': '40%',
    '30%': '30%'
  };
  return (
    <Grid
      className={classnames(
        classes.container,
        classes[possibleHeights[height]]
      )}
    >
      <Loader load={loaded}>
        <img
          ref={refContainer}
          className={`${loaded ? classes.loaded : classes.loading}`}
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(false)}
          src={src}
          alt={alt}
          style={{ height: '100%', objectFit }}
        />
        {description !== '' && loaded && (
          <Grid
            style={{
              width: refContainer.current && refContainer.current.clientWidth
            }}
            className={classes.description}
          >
            <p>{description}</p>
          </Grid>
        )}
      </Loader>
    </Grid>
  );
};

Image.propTypes = {
  classes: shape({}).isRequired,
  content: shape({}).isRequired
};

export default withStyles(styles)(Image);
