import React from 'react';
import { bool, shape, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const Circle = ({ selected, classes, goToIndex }) => (
  <Grid
    className={classes.indexCircles}
    style={{
      backgroundColor: `${selected ? '#56B0C0' : '#EDEDED'}`
    }}
    onClick={goToIndex}
  />
);

Circle.propTypes = {
  classes: shape({}).isRequired,
  selected: bool.isRequired,
  goToIndex: func.isRequired
};

export default withStyles(styles)(Circle);
