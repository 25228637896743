import ActionUtility from '../../../utilities/ActionUtility';
import PanoramaErrorModel from '../../../models/PanoramaErrorModel';
import PanoramaEffect from '../effects';
import ThreeSixtyAction from '../../threeSixty/actions';

export default class PanoramaAction {
  static PANORAMA_INFO_REQUEST = 'PANORAMA_INFO_REQUEST';

  static PANORAMA_INFO_REQUEST_FINISHED = 'PANORAMA_INFO_REQUEST_FINISHED';

  static CONTAINER_REQUEST_FINISHED = 'CONTAINER_REQUEST_FINISHED';

  static PANORAMA_REQUEST = 'PANORAMA_REQUEST';

  static PANORAMA_REQUEST_FINISHED = 'PANORAMA_REQUEST_FINISHED';

  static PANORAMA_RESET_REQUEST_FINISHED = 'PANORAMA_RESET_REQUEST_FINISHED';

  static setContainer(container) {
    return ActionUtility.createAction(
      PanoramaAction.CONTAINER_REQUEST_FINISHED,
      container
    );
  }

  static createPanoramaInfo() {
    return async (dispatch, getState) => {
      const { threeSixty, panorama } = getState();
      const { container } = panorama;
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        PanoramaAction.PANORAMA_INFO_REQUEST,
        PanoramaEffect.createPanorama,
        false,
        container,
        threeSixty.levels,
        threeSixty.currentLevel,
        threeSixty.defaultStyle,
        threeSixty.selectedStyle,
        threeSixty.selectedScene,
        threeSixty.currentRoomUse,
        threeSixty.selectedFinish,
        threeSixty.levelScenes
      );
      const isError = model instanceof PanoramaErrorModel;
      return { model, isError };
    };
  }

  static setPanorama(socket) {
    return async (dispatch, getState) => {
      const { panorama, meeting, session, threeSixty } = getState();
      const { panoramaInfo, panorama: threeSixtyPano } = panorama;
      const { activeMeeting, isCustomer, publisher } = meeting;
      const { sessionID } = session;
      const { menu } = threeSixty;
      const isEmisor = publisher || isCustomer;
      const socketId = isCustomer ? sessionID : activeMeeting.id;
      const typeOfLog = isCustomer ? 'customer-log' : 'message-meeting';
      const model = await ActionUtility.createThunkEffect(
        dispatch,
        PanoramaAction.PANORAMA_REQUEST,
        PanoramaEffect.createThreeSixty,
        false,
        threeSixtyPano,
        panoramaInfo,
        async (sceneName, level, use) => {
          if (sceneName !== undefined) {
            await dispatch(ThreeSixtyAction.setSelectedScene(sceneName));
          }
          if (level !== undefined) {
            await dispatch(ThreeSixtyAction.setCurrentLevel(level));
            await dispatch(ThreeSixtyAction.getScenes());
            await dispatch(ThreeSixtyAction.setSelectedScene(sceneName));
          }
          if (use !== undefined) {
            await dispatch(ThreeSixtyAction.setSelectedUse(use));
          }

          await dispatch(ThreeSixtyAction.getStyles());
        },
        async (expand) => {
          if (expand !== undefined) {
            await dispatch(ThreeSixtyAction.expandMenu(expand));
            await dispatch(ThreeSixtyAction.setSelectedMenuOption(''));
          }
        },
        async (style) => {
          if (style !== undefined) {
            const selectedStyle = menu.find((item) => item.type === style);
            await dispatch(
              ThreeSixtyAction.setSelectedNameStyle(selectedStyle.style)
            );
          }
        },
        socketId,
        socket,
        typeOfLog,
        isEmisor
      );

      const isError = model instanceof PanoramaErrorModel;

      return { model, isError };
    };
  }

  static reset() {
    return ActionUtility.createAction(
      PanoramaAction.PANORAMA_RESET_REQUEST_FINISHED,
      ''
    );
  }
}
