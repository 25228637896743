import {
  VIRTUAL_SALES_API,
  VIRTUAL_SALES_API_KEY
} from '../../../config/endpoints';
import HttpUtility from '../../../utilities/HttpUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import LoginModel from '../models';

export default class SessionEffect {
  static async login(language, email, password, log, cookies = null) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/login`;

    const loginObj = { email, password, ...log };
    const response = await HttpUtility.post(endpoint, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...loginObj
      })
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    if (cookies) {
      const {
        token,
        user,
        refreshToken,
        expirationTime,
        accessToken
      } = response.data;
      cookies.set('user', JSON.stringify(user), {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('token', token, { path: '/', maxAge: 31536000 });
      cookies.set('refreshToken', refreshToken, {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('expirationTime', expirationTime, {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('accessToken', accessToken, { path: '/', maxAge: 31536000 });
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem('expirationTime', expirationTime);
      sessionStorage.setItem('accessToken', accessToken);
    }
    const model = new LoginModel(response.data);
    return model;
  }

  static async changePassword(
    language,
    token,
    currentPassword,
    newPassword,
    accessToken
  ) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/change-password`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        current_password: currentPassword,
        new_password: newPassword,
        access_token: accessToken
      })
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data.result.message;
  }

  static async forgotPassword(language, email) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/forgot-password`;
    const response = await HttpUtility.post(endpoint, {
      body: JSON.stringify({
        email
      })
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response;
  }

  static async resetPassword(language, email, newPassword, code) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/reset-password`;
    const response = await HttpUtility.put(endpoint, {
      body: JSON.stringify({
        email,
        newPassword,
        code
      })
    });

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response;
  }

  static async setCustomerSession(language, body, customerId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/customers/${customerId}/sessions`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data.session.session_id;
  }

  static async confirmAccount(
    language,
    email,
    oneTimePassword,
    password,
    cookies
  ) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/confirm-account`;
    const response = await HttpUtility.post(endpoint, {
      header: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        new_password: password,
        temporary_password: oneTimePassword
      })
    });

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    if (cookies) {
      const {
        token,
        user,
        refreshToken,
        expirationTime,
        accessToken
      } = response.data;
      cookies.set('user', JSON.stringify(user), {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('token', token, { path: '/', maxAge: 31536000 });
      cookies.set('refreshToken', refreshToken, {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('expirationTime', expirationTime, {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('accessToken', accessToken, { path: '/', maxAge: 31536000 });
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem('expirationTime', expirationTime);
      sessionStorage.setItem('accessToken', accessToken);
    }

    const model = new LoginModel(response.data);
    return model;
  }

  static async refreshLogin(language, email, refreshToken, cookies) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/refresh-token`;
    const response = await HttpUtility.post(endpoint, {
      header: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        refreshToken
      })
    });

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    if (cookies) {
      const {
        token,
        user,
        refreshToken: newRefreshToken,
        expirationTime,
        accessToken
      } = response.data;
      cookies.set('user', JSON.stringify(user), {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('token', token, { path: '/', maxAge: 31536000 });
      cookies.set('refreshToken', newRefreshToken, {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('expirationTime', expirationTime, {
        path: '/',
        maxAge: 31536000
      });
      cookies.set('accessToken', accessToken, { path: '/', maxAge: 31536000 });
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('refreshToken', newRefreshToken);
      sessionStorage.setItem('expirationTime', expirationTime);
      sessionStorage.setItem('accessToken', accessToken);
    }
    const model = new LoginModel(response.data);
    return model;
  }
}
