import React from 'react';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styles from './styles';

const Logos = ({ classes, content }) => {
  const { logoUri, logoAthum } = content;
  return (
    <Grid
      className={classes.logoContainer}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <img
        src={logoUri}
        alt="developoer logo"
        className={classes.builderLogo}
      />
      <img src={logoAthum} alt="athum log" className={classes.athumLogo} />
    </Grid>
  );
};

Logos.propTypes = {
  classes: shape({}).isRequired,
  content: shape({}).isRequired
};

export default withStyles(styles)(Logos);
