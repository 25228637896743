import { createSelector } from 'reselect';

export class SocketSelector {
  static getID(state) {
    const { meeting, session } = state;
    const { isCustomer, activeMeeting } = meeting;
    const { sessionID } = session;
    return isCustomer ? sessionID : activeMeeting.id;
  }

  static getIsEmiting(state) {
    const { meeting } = state;
    const { publisher, isCustomer } = meeting;
    return publisher || isCustomer;
  }

  static getLogType(state) {
    const { meeting } = state;
    const { isCustomer } = meeting;
    return isCustomer ? 'customer-log' : 'message-meeting';
  }

  static getPublisher(state) {
    const { meeting } = state;
    const { publisher } = meeting;
    return publisher;
  }
}

export const socketIdSelector = createSelector(
  [SocketSelector.getID],
  (id) => id
);

export const emitingSelector = createSelector(
  [SocketSelector.getIsEmiting],
  (isEmiting) => isEmiting
);

export const logSelector = createSelector(
  [SocketSelector.getLogType],
  (type) => type
);

export const publisherSelector = createSelector(
  [SocketSelector.getPublisher],
  (publisher) => publisher
);
