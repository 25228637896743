const styles = (theme) => {
  const {
    content = {
      backgroundColor: '#fafafa',
      inactiveFontColor: '#6c6c6c',
      activeFontColor: '#ec6b69',
      alertColor: '#ec6b69'
    }
  } = theme;
  return {
    carouselContainer: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'relative'
    },
    selectedImageContainer: {
      width: '100%',
      height: '80%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& img': {
        // width: '100%',
        height: '100%'
      }
    },
    selectedImageLeftContainer: {
      position: 'absolute',
      zIndex: 2,
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: 0,
      width: 50,
      background: 'rgba(0, 0, 0, 0.3)'
    },
    selectedImageRightContainer: {
      position: 'absolute',
      zIndex: 2,
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      right: 0,
      width: 50,
      background: 'rgba(0, 0, 0, 0.3)'
    },
    thumbnailsContainer: {
      width: 'auto',
      padding: 15,
      height: '20%'
    },
    thumbnailContainer: {
      height: '100%',
      overflow: 'hidden',
      display: 'flex'
    },
    thumbnail: {
      height: '100%',
      width: 144,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      marginRight: 10,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: 100
      },
      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        '&:focus': {
          outline: 'none'
        }
      },
      '& .selected': {
        border: `3px solid ${theme.content.activeFontColor}`,
        borderRadius: 4
      },
      '&:last-child': {
        marginRight: 0
      },
      '&:focus': {
        outline: 0
      }
    },
    button: {
      height: 28,
      width: 28,
      background: content.backgroundColor,
      backdropFilter: 'blur(10px)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 99,
      bottom: 'calc((20% - 30px) / 2)',
      cursor: 'pointer',
      '&.left': {
        left: 5
      },
      '&.right': {
        right: 5
      },
      '&:focus': {
        outline: 0
      }
    },
    chevron: {
      color: content.inactiveFontColor
    },
    chevronWhite: {
      color: 'white'
    },
    loaded: {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s linear 0s, opacity 300ms'
    },
    loading: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s linear 300ms, opacity 300ms'
    }
  };
};

export default styles;
