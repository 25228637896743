import React from 'react';
import { shape, bool, arrayOf, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import themeStyles from '../../../styles';

const EditablePricesMenu = ({
  classes,
  canUpdatePrices,
  isEditing,
  items,
  closePriceEdition,
  showPricesUpdateConfirmationModal,
  editPrices
}) => {
  if (canUpdatePrices) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.pricesEditionContainer}
      >
        {isEditing ? (
          <Grid container justify="space-around" alignItems="center">
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.pricesCloseButton}
              onClick={closePriceEdition}
            >
              <Clear className={classes.closePricesEdition} />
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.pricesUpdateButton}
              onClick={
                items.length > 0
                  ? showPricesUpdateConfirmationModal
                  : closePriceEdition
              }
            >
              <Check className={classes.savePrices} />
              Save changes
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pricesEditionButton}
            onClick={editPrices}
          >
            <Create className={classes.editPrices} />
            Edit Prices
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
};

EditablePricesMenu.propTypes = {
  classes: shape({}).isRequired,
  canUpdatePrices: bool.isRequired,
  isEditing: bool.isRequired,
  items: arrayOf(shape({})).isRequired,
  closePriceEdition: func.isRequired,
  showPricesUpdateConfirmationModal: func.isRequired,
  editPrices: func.isRequired
};

export default withStyles(themeStyles)(EditablePricesMenu);
