import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, shape, bool, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ContentTypeContainer from '../ContentTypeContainer';
import WebView from '../WebView';
import ThreeSixty from '../ThreeSixty';
import styles from './styles';
import { socketEmit } from '../../services/socket';
import ThreeSixtyAction from '../../stores/threeSixty/actions';

class Tour extends Component {
  constructor() {
    super();
    this.state = {
      showExternalTour: false,
      show360Tour: false,
      externalLink: null
    };
  }

  componentDidMount = async () => {
    const { dispatch, selectedLayout } = this.props;
    this.handleSocketMessage();

    await dispatch(ThreeSixtyAction.setBuilder({ ...selectedLayout }));
  };

  showTour = (externalLink, layoutName) => {
    const { socket, publisher, meetingId, isCustomer, sessionID } = this.props;
    if (externalLink) {
      this.setState({ showExternalTour: true, externalLink });
    } else {
      this.setState({ show360Tour: true });
    }
    if ((publisher && meetingId) || isCustomer) {
      const type = isCustomer ? 'customer-log' : 'message-meeting';
      const id = isCustomer ? sessionID : meetingId;
      socketEmit(socket, type, {
        id,
        event: 'START-THREE-SIXTY',
        data: {
          type: 'START-THREE-SIXTY',
          externalLink,
          layoutName
        }
      });
    }
  };

  handleSocketMessage = () => {
    const { socket, publisher } = this.props;
    if (!publisher) {
      socket.on('START-THREE-SIXTY', (data) => {
        const { type, externalLink, layoutName } = data;
        switch (type) {
          case 'START-THREE-SIXTY':
            this.showTour(externalLink, layoutName);
            break;
          default:
            break;
        }
      });
    }
  };

  render() {
    const {
      classes,
      content,
      socket,
      publisher,
      meetingId,
      selectedLayout
    } = this.props;
    const { showExternalTour, show360Tour, externalLink } = this.state;
    const { layoutName, builderId, projectId } = selectedLayout;
    return (
      <Grid container className={classes.pageContainer}>
        {!showExternalTour && !show360Tour && (
          <Grid className={classes.pageContentContainer}>
            {content.map(
              (
                {
                  name,
                  type,
                  content: pageContent,
                  order,
                  columns = 1,
                  height
                },
                index
              ) => {
                const typeContent = pageContent;
                if (type === 'virtualTour') {
                  typeContent.showTour = this.showTour;
                  typeContent.layoutName = layoutName;
                }
                return (
                  <ContentTypeContainer
                    key={`${name}-${order}-${index * 1}`}
                    content={typeContent}
                    type={type}
                    socket={socket}
                    publisher={publisher}
                    meetingId={meetingId}
                    columns={columns}
                    height={height}
                  />
                );
              }
            )}
          </Grid>
        )}
        {showExternalTour && <WebView url={externalLink} layout={layoutName} />}
        {show360Tour && (
          <ThreeSixty
            socket={socket}
            meetingId={meetingId}
            builderInfo={{
              params: {
                layoutName,
                builderId,
                projectId
              }
            }}
          />
        )}
      </Grid>
    );
  }
}

Tour.propTypes = {
  classes: shape({}).isRequired,
  content: arrayOf(shape({})).isRequired,
  socket: shape({}).isRequired,
  publisher: bool.isRequired,
  meetingId: string.isRequired,
  selectedLayout: shape({}).isRequired,
  isCustomer: bool.isRequired,
  sessionID: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { sessionID } = state.session;
  const { isCustomer } = state.meeting;
  const { selectedLayout } = state.project;
  return {
    selectedLayout,
    isCustomer,
    sessionID
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Tour));
