import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { string, shape, bool, func, number, arrayOf } from 'prop-types';
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_NONE } from 'react-svg-pan-zoom';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { ReactSvgPanZoomLoader } from 'react-svg-pan-zoom-loader';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Clear from '@material-ui/icons/Clear';
import Replay from '@material-ui/icons/Replay';
import Check from '@material-ui/icons/Check';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import styles from './styles';
import Draws from './Draws';
import { isMobileDevice, isMobile } from '../../utils';
import {
  cursorIcon,
  panIcon,
  zoomInIcon,
  zoomOutExtents,
  zoomOutIcon
} from '../../config/assets';

class DrawableSVG extends PureComponent {
  constructor(props) {
    super(props);
    const { theme } = props;
    this.state = {
      tool: TOOL_NONE,
      value: INITIAL_VALUE,
      annotation: false,
      isDrawing: false,
      draws: {},
      currentTime: moment().format('x'),
      color: theme.colors.primary
    };
    this.viewer = null;
  }

  componentDidMount() {
    this.handleSocketMessage();
  }

  componentDidUpdate(prevProps) {
    const { content: prevContent } = prevProps;
    const { content } = this.props;
    const { svgImage } = content;
    const { svgImage: prevSvgImage } = prevContent;
    if (svgImage !== prevSvgImage && prevSvgImage) {
      this.resetState();
    }
  }

  componentWillUnmount() {
    const { socket } = this.props;
    socket.off('ZOOM', () => {});
    socket.off('PAN', () => {});
    socket.off('SVG-ACTION', () => {});
  }

  resetState = () => {
    this.setState({ draws: {}, annotation: false, isDrawing: false });
  };

  handleSocketMessage = () => {
    const { socket, token } = this.props;
    if (token === '') {
      socket.on('ZOOM', (data) => {
        console.log('ZOOM', data);
        const { type, zoom } = data;
        switch (type) {
          case 'ZOOM':
            this.onZoom(zoom);
            break;
          default:
            break;
        }
      });
      socket.on('PAN', (data) => {
        console.log('PAN', data);
        const { type, pan } = data;
        switch (type) {
          case 'PAN':
            this.onZoom(pan);
            break;
          default:
            break;
        }
      });
      socket.on('SVG-ACTION', (data) => {
        const { type } = data;
        switch (type) {
          case 'SVG-DRAW':
            {
              const { draws, width, height } = data;
              this.setState({
                draws,
                salesWidth: width,
                salesHeight: height
              });
            }
            break;
          case 'SVG-CLEAR-DRAW':
            {
              const { draws } = data;
              this.setState({ draws });
            }
            break;
          case 'SVG-COLOR':
            {
              const { color } = data;
              this.setState({ color });
            }
            break;
          default:
            break;
        }
      });
    }
  };

  onMouseDown = () => {
    const { draws, annotation } = this.state;
    if (annotation) {
      draws[`draw-${Object.keys(draws).length}`] = [];
      this.setState({ isDrawing: true, draws });
    }
  };

  onMouseMove = (e) => {
    const { x, y } = e;
    const { annotation, isDrawing, draws } = this.state;
    if (annotation && isDrawing) {
      const drawsArray = Object.keys(draws);
      draws[drawsArray[drawsArray.length - 1]].push(`${x},${y}`);
      this.setState({ draws, coords: { x, y } });
    }
  };

  onMouseUp = () => {
    const { annotation, draws } = this.state;
    const { width, height } = this.viewer.props;
    if (annotation) {
      this.setState({ isDrawing: false });
      const { token, activeMeeting, meetingIsActive, socket } = this.props;
      if (token && meetingIsActive) {
        socket.emit('message-meeting', {
          id: activeMeeting.id,
          event: 'SVG-ACTION',
          data: {
            type: 'SVG-DRAW',
            draws,
            width,
            height
          }
        });
      }
    }
  };

  onTouchStart = () => {
    const { draws, annotation } = this.state;
    if (annotation) {
      draws[`draw-${Object.keys(draws).length}`] = [];
      this.setState({ isDrawing: true, draws });
    }
  };

  onTouchMove = (e, marginTop, marginLeft) => {
    const { tabs, hasPagination, isGallery } = this.props;
    let verticalAdjustment = 0;
    if (isMobile()) {
      verticalAdjustment -= 48;
    }
    if (tabs) {
      verticalAdjustment -= 50;
    }
    if (hasPagination) {
      verticalAdjustment -= 40;
    }
    if (isGallery) {
      verticalAdjustment -= 48;
    }
    const x = isMobile()
      ? e.originalEvent.touches[0].clientX
      : e.originalEvent.touches[0].clientX - 245;
    const y = e.originalEvent.touches[0].clientY + verticalAdjustment;
    const { annotation, isDrawing, draws } = this.state;
    if (annotation && isDrawing) {
      const drawsArray = Object.keys(draws);
      draws[drawsArray[drawsArray.length - 1]].push(
        `${x - marginLeft},${y - marginTop}`
      );
      this.setState({ draws, coords: { x, y } });
    }
  };

  onTouchEnd = () => {
    const { annotation, draws } = this.state;
    const { width, height } = this.viewer.props;
    if (annotation) {
      this.setState({ isDrawing: false });
      const { token, activeMeeting, meetingIsActive, socket } = this.props;
      if (token && meetingIsActive) {
        socket.emit('message-meeting', {
          id: activeMeeting.id,
          event: 'SVG-ACTION',
          data: {
            type: 'SVG-DRAW',
            draws,
            width,
            height
          }
        });
      }
    }
  };

  toggleAnnotation = async () => {
    const { setToolsOpen } = this.props;
    await this.setState((prevState) => {
      setToolsOpen(!prevState.annotation);
      return {
        annotation: !prevState.annotation,
        isDrawing: false
      };
    });
  };

  clearAnnotations = () => {
    this.setState({
      draws: {}
    });
    const { token, activeMeeting, meetingIsActive, socket } = this.props;
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-CLEAR-DRAW',
          draws: {}
        }
      });
    }
  };

  deleteLastAnnotation = () => {
    const { draws } = this.state;
    delete draws[`draw-${Object.keys(draws).length - 1}`];
    this.setState({
      draws,
      coords: {}
    });
    const { token, activeMeeting, meetingIsActive, socket } = this.props;
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-CLEAR-DRAW',
          draws
        }
      });
    }
  };

  changeColor = (color) => {
    this.setState({ color });
    const { token, activeMeeting, meetingIsActive, socket } = this.props;
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'SVG-ACTION',
        data: {
          type: 'SVG-COLOR',
          color
        }
      });
    }
  };

  onZoom = (e) => {
    const { token, activeMeeting, meetingIsActive, socket } = this.props;
    this.setState({ value: e });
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'ZOOM',
        data: {
          type: 'ZOOM',
          zoom: e
        }
      });
    }
  };

  onPan = (e) => {
    const { token, activeMeeting, meetingIsActive, socket } = this.props;
    this.setState({ value: e });
    if (token && meetingIsActive) {
      socket.emit('message-meeting', {
        id: activeMeeting.id,
        event: 'PAN',
        data: {
          type: 'PAN',
          pan: e
        }
      });
    }
  };

  changeTool(nextTool) {
    this.setState({ tool: nextTool });
  }

  changeValue(nextValue) {
    this.setState({ value: nextValue });
  }

  render() {
    const {
      content,
      classes,
      token,
      theme,
      isCover,
      onPrev,
      onNext,
      selectedIndex,
      images
    } = this.props;
    const {
      tool,
      value,
      annotation,
      draws,
      coords,
      currentTime,
      color
    } = this.state;
    const {
      svgImage,
      defaultHeight,
      objectFit = 'contain',
      height: svgDefaultHeight
    } = content;
    const hasDraws = JSON.stringify(draws) !== '{}';
    const svgContainerHeight = defaultHeight
      ? `calc(100% * ${annotation || hasDraws ? 1 : defaultHeight})`
      : svgDefaultHeight;
    const { colors } = theme;
    const { primary, complementary, accent } = colors;
    const rightChevron = (
      <div className={classes.selectedImageRightContainer} onClick={onNext}>
        <ChevronRight className={classes.chevronWhite} />
      </div>
    );
    return (
      <Grid
        className={classes.svgContainer}
        style={{
          height: svgContainerHeight
        }}
      >
        {token && (
          <>
            {!annotation ? (
              <Grid
                className={classes.annotationButton}
                onClick={this.toggleAnnotation}
                container
                justify="center"
                alignItems="center"
              >
                <Create className={classes.iconStyle} />
              </Grid>
            ) : (
              <Grid
                className={classes.annotationButtons}
                container
                direction="column"
                justify="space-around"
                alignItems="center"
              >
                <Clear
                  className={classes.iconStyle}
                  onClick={() => {
                    this.toggleAnnotation();
                    this.clearAnnotations();
                  }}
                />
                <Replay
                  className={classes.iconStyle}
                  onClick={this.deleteLastAnnotation}
                />
                <Delete
                  className={classes.iconStyle}
                  onClick={this.clearAnnotations}
                />
                <Grid
                  className={classes.colorButton}
                  container
                  justify="center"
                  alignItems="center"
                  style={{ backgroundColor: primary }}
                  onClick={() => this.changeColor(primary)}
                >
                  {color === primary && <Check className={classes.checkIcon} />}
                </Grid>
                <Grid
                  className={classes.colorButton}
                  container
                  justify="center"
                  alignItems="center"
                  style={{ backgroundColor: complementary }}
                  onClick={() => this.changeColor(complementary)}
                >
                  {color === complementary && (
                    <Check className={classes.checkIcon} />
                  )}
                </Grid>
                {accent !== primary && accent !== complementary && (
                  <Grid
                    className={classes.colorButton}
                    container
                    justify="center"
                    alignItems="center"
                    style={{ backgroundColor: accent }}
                    onClick={() => this.changeColor(accent)}
                  >
                    {color === accent && (
                      <Check className={classes.checkIcon} />
                    )}
                  </Grid>
                )}
                <img
                  src={cursorIcon.src}
                  alt={cursorIcon.alt}
                  className={tool === 'none' ? 'selectedTool' : ''}
                  onClick={() => this.changeTool('none')}
                />
                <img
                  src={panIcon.src}
                  alt={panIcon.alt}
                  className={tool === 'pan' ? 'selectedTool' : ''}
                  onClick={() => this.changeTool('pan')}
                />
                <img
                  src={zoomInIcon.src}
                  alt={zoomInIcon.alt}
                  className={tool === 'zoom-in' ? 'selectedTool' : ''}
                  onClick={() => this.changeTool('zoom-in')}
                />
                <img
                  src={zoomOutIcon.src}
                  alt={zoomOutIcon.alt}
                  className={tool === 'zoom-out' ? 'selectedTool' : ''}
                  onClick={() => this.changeTool('zoom-out')}
                />
                <img
                  src={zoomOutExtents.src}
                  alt={zoomOutExtents.alt}
                  onClick={() => {
                    this.viewer.reset();
                  }}
                />
              </Grid>
            )}
          </>
        )}
        {!annotation && token && (
          <>
            {images.length > 0 && selectedIndex !== 0 && images.length > 1 && (
              <div
                className={classes.selectedImageLeftContainer}
                onClick={onPrev}
              >
                <ChevronLeft className={classes.chevronWhite} />
              </div>
            )}
            <img
              src={svgImage}
              className={classes.image}
              style={{ objectFit }}
              alt=""
            />
            {images.length > 0 &&
              selectedIndex !== images.length - 1 &&
              rightChevron}
          </>
        )}
        <AutoSizer>
          {({ width, height }) => {
            const size = width > height ? height : width;
            const { salesWidth, salesHeight } = this.state;
            const drawsArray = Object.keys(draws);
            const transformedDraws = {};
            const marginTop = height > width ? (height - width) / 2 : 0;
            const marginLeft = width > height ? (width - height) / 2 : 0;
            drawsArray.forEach((drawKey) => {
              transformedDraws[drawKey] = [];
              draws[drawKey].forEach((point) => {
                const coord = point.split(',');
                const x = (coord[0] * size) / salesWidth;
                const y = (coord[1] * size) / salesHeight;
                transformedDraws[drawKey].push(`${x},${y}`);
              });
            });
            return width === 0 || height === 0 ? null : (
              <ReactSvgPanZoomLoader
                src={`${svgImage}?time=${currentTime}`}
                render={(svgContent) => (
                  <ReactSVGPanZoom
                    width={size}
                    height={size}
                    ref={(viewer) => {
                      this.viewer = viewer;
                    }}
                    tool={tool}
                    onChangeTool={(nextTool) => this.changeTool(nextTool)}
                    value={value}
                    onZoom={this.onZoom}
                    onPan={this.onPan}
                    onChangeValue={(nextValue) => this.changeValue(nextValue)}
                    onMouseDown={isMobileDevice() ? () => {} : this.onMouseDown}
                    onMouseUp={isMobileDevice() ? () => {} : this.onMouseUp}
                    onMouseMove={isMobileDevice() ? () => {} : this.onMouseMove}
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                    onTouchMove={(e) => {
                      this.onTouchMove(e, marginTop, marginLeft);
                    }}
                    miniatureProps={{ position: 'none' }}
                    toolbarProps={{ position: 'none' }}
                    detectAutoPan={false}
                    detectWheel={false}
                    preventPanOutside
                    background={
                      isCover
                        ? theme.content.coverBackgroundColor
                        : theme.content.backgroundColor
                    }
                    SVGBackground={
                      isCover
                        ? theme.content.coverBackgroundColor
                        : theme.content.backgroundColor
                    }
                    style={{
                      marginTop,
                      marginLeft
                    }}
                  >
                    <svg width={size} height={size}>
                      {svgContent}
                      <Draws
                        draws={salesWidth ? transformedDraws : draws}
                        coords={coords}
                        color={color}
                      />
                      {!annotation && token && (
                        <rect
                          width={size}
                          height={size}
                          style={{
                            fill: isCover
                              ? theme.content.coverBackgroundColor
                              : theme.content.backgroundColor,
                            strokeWidth: 3,
                            stroke: isCover
                              ? theme.content.coverBackgroundColor
                              : theme.content.backgroundColor
                          }}
                        />
                      )}
                    </svg>
                  </ReactSVGPanZoom>
                )}
              />
            );
          }}
        </AutoSizer>
      </Grid>
    );
  }
}

DrawableSVG.propTypes = {
  classes: shape({}).isRequired,
  activeMeeting: shape({}).isRequired,
  socket: shape({}).isRequired,
  token: string.isRequired,
  content: shape({}).isRequired,
  tabs: bool.isRequired,
  hasPagination: bool.isRequired,
  isGallery: bool,
  theme: shape({}).isRequired,
  isCover: bool,
  meetingIsActive: bool.isRequired,
  onPrev: func.isRequired,
  onNext: func.isRequired,
  selectedIndex: number.isRequired,
  images: arrayOf(shape({})),
  setToolsOpen: func.isRequired
};

DrawableSVG.defaultProps = {
  isGallery: false,
  isCover: false,
  images: []
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { activeMeeting, meetingIsActive } = state.meeting;
  const { theme } = state.project;
  return {
    activeMeeting,
    meetingIsActive,
    token,
    theme
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(DrawableSVG));
