import React from 'react';
import { shape, arrayOf, func, string } from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import themeStyles from '../../../styles';
import CustomSecondaryButton from '../../CustomSecondaryButton';
import CustomButton from '../../CustomButton';
import {
  plusIcon,
  playIcon,
  linkIcon,
  deleteIcon
} from '../../../config/assets';
import {
  nextSession,
  withSession,
  newSession,
  startSession,
  nextSessions,
  atSession
} from '../../../config/messages';
import { orderMeetingsByDate } from './utils';
import { isMobile } from '../../../utils';

const ScheduledMeetings = ({
  meetings,
  classes,
  openScheduleMeetingForm,
  handleCopyText,
  startMeeting,
  deleteMeeting,
  toggleMobileMenu,
  language,
  theme
}) => {
  let inactiveIconStyle = {};
  let activeIconStyle = {};
  if (JSON.stringify(theme) !== '{}') {
    inactiveIconStyle = {
      filter: theme.rightMenu.svg.inactive
    };
    activeIconStyle = {
      filter: theme.rightMenu.svg.active
    };
  }
  moment.locale(language);
  const activeMeetings = meetings.filter(
    (meeting) => meeting.status === 'active'
  );
  const scheduledMeetings = meetings.filter(
    (meeting) => meeting.status === 'scheduled'
  );
  const createSessionButton = (
    <Grid container className={classes.buttonContainer}>
      <CustomButton onClick={openScheduleMeetingForm}>
        <img
          src={plusIcon.src}
          alt={plusIcon.alt}
          className={classes.buttonIcon}
        />
        {newSession[language]}
      </CustomButton>
    </Grid>
  );
  if (scheduledMeetings.length > 0) {
    const orderedMeetings = orderMeetingsByDate(scheduledMeetings);
    const uniqueDays = new Set(
      scheduledMeetings.map((meeting) => moment(meeting.dateTime).format('LL'))
    );
    const meetingDays = [...uniqueDays];
    const nextSessionClient = (
      <span className={classes.nextSessionClient}>
        {`${orderedMeetings[0].buyer.firstName} ${orderedMeetings[0].buyer.lastName}`}
      </span>
    );
    return (
      <>
        {activeMeetings.length === 0 && (
          <Grid className={classes.nextMeetingContainer}>
            <h4>
              {`${nextSession[language]} ${moment(
                orderedMeetings[0].dateTime
              ).format('LL')} ${atSession[language]} ${moment(
                orderedMeetings[0].dateTime
              ).format('LT')} ${withSession[language]} `}
              {nextSessionClient}
            </h4>
            <CustomSecondaryButton
              onClick={() => {
                startMeeting(orderedMeetings[0].id);
                if (isMobile()) {
                  toggleMobileMenu();
                }
              }}
            >
              <PlayCircleOutline className={classes.playIcon} />
              <span className={classes.startSessionButtonText}>
                {startSession[language]}
              </span>
            </CustomSecondaryButton>
          </Grid>
        )}
        <Grid className={classes.meetingsListContainer}>
          <h3>{nextSessions[language]}</h3>
          {meetingDays.map((meetingDay) => {
            const filteredMeetings = orderedMeetings.filter(
              (meeting) => moment(meeting.dateTime).format('LL') === meetingDay
            );
            return (
              <Grid key={meetingDay} className={classes.dayMeetingsContainer}>
                <p>{meetingDay}</p>
                {filteredMeetings.map((meeting) => (
                  <Grid key={meeting.id} className={classes.dayMeetingsDetail}>
                    {`${moment(meeting.dateTime).format('LT')} - ${
                      meeting.buyer.firstName
                    } ${meeting.buyer.lastName}`}
                    <Grid className={classes.meetingIconsContainer}>
                      <img
                        src={playIcon.src}
                        alt={playIcon.alt}
                        onClick={() => {
                          startMeeting(meeting.id);
                          if (isMobile()) {
                            toggleMobileMenu();
                          }
                        }}
                        style={inactiveIconStyle}
                      />
                      <img
                        src={linkIcon.src}
                        alt={linkIcon.alt}
                        onClick={() => handleCopyText(meeting.id)}
                        style={inactiveIconStyle}
                      />
                      <img
                        src={deleteIcon.src}
                        alt={deleteIcon.alt}
                        onClick={() => deleteMeeting(meeting.id)}
                        style={activeIconStyle}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            );
          })}
        </Grid>
        {createSessionButton}
      </>
    );
  }
  return null;
};

ScheduledMeetings.propTypes = {
  meetings: arrayOf(shape({})).isRequired,
  classes: shape({}).isRequired,
  openScheduleMeetingForm: func.isRequired,
  handleCopyText: func.isRequired,
  startMeeting: func.isRequired,
  deleteMeeting: func.isRequired,
  toggleMobileMenu: func.isRequired,
  language: string.isRequired,
  theme: shape({}).isRequired
};

export default withStyles(themeStyles)(ScheduledMeetings);
