import React from 'react';
import { shape, string, bool, func, number } from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VideoChat from '../../VideoChat';
import styles from './styles';

const PrivatePanelSession = ({
  classes,
  twilioToken,
  meeting,
  identity,
  videoCallOpen,
  toggleVideoCall,
  socket,
  currentTab,
  toggleMobileMenu,
  pendingMessages
}) => (
  <Grid className={classes.privatePanelContainer}>
    {twilioToken && identity && (
      <VideoChat
        twilioToken={twilioToken}
        roomName={meeting}
        identity={identity}
        videoCallOpen={videoCallOpen}
        toggleVideoCall={toggleVideoCall}
        socket={socket}
        currentTab={currentTab}
        toggleMobileMenu={toggleMobileMenu}
        pendingMessages={pendingMessages}
      />
    )}
  </Grid>
);

PrivatePanelSession.propTypes = {
  classes: shape({}).isRequired,
  twilioToken: string.isRequired,
  meeting: string.isRequired,
  identity: string.isRequired,
  videoCallOpen: bool.isRequired,
  toggleVideoCall: func.isRequired,
  socket: shape({}).isRequired,
  currentTab: number.isRequired,
  toggleMobileMenu: func.isRequired,
  pendingMessages: bool
};

PrivatePanelSession.defaultProps = {
  pendingMessages: false
};

export default withStyles(styles)(PrivatePanelSession);
