const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 99,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    background: '#ffffff',
    overflow: 'auto'
  },
  header: {
    height: 140,
    padding: '0 40px',
    '& img': {
      height: 47,
      [theme.breakpoints.down('sm')]: {
        height: 37
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: 100
    }
  },
  buttonAsLink: {
    border: 'none',
    background: 'none',
    padding: '10px 0',
    '&:focus': {
      outline: 'none'
    }
  },
  buttons: {
    margin: '20px 0',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    }
  },
  contentContainer: {
    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      width: '75%',
      fontSize: 14,
      textAlign: 'center',
      height: 90,
      [theme.breakpoints.up('sm')]: {
        width: 468,
        fontSize: 18,
        height: 90
      }
    },
    '& img': {
      height: 334,
      width: 334,
      borderRadius: '5%',
      objectFit: 'contain',
      [theme.breakpoints.down('xs')]: {
        width: '85%',
        height: '50%'
      }
    }
  },
  buttonsContainer: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '10px 24px'
    }
  },
  indexCircles: {
    height: 6,
    width: 6,
    minHeight: 6,
    borderRadius: '50%',
    cursor: 'pointer'
  },
  skipButton: {
    width: 100,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  nextButton: {
    width: 100
  }
});

export default styles;
