const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    margin: '0 auto',
    maxWidth: 100,
    maxHeight: 100,
    color: theme.rightMenu.activeFontColor
  },
  fullPageLoaderContainer: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 89,
    backgroundColor: 'rgba(255, 255, 255, 0.69)'
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(71, 71, 71, 0.8)',
    zIndex: 1000,
    '& .loader-image-container': {
      backgroundColor: 'white',
      borderRadius: '50%'
    }
  }
});

export default styles;
