import React from 'react';
import { shape, func, bool, string } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import CustomInput from '../CustomInput';

const PasswordInput = ({
  classes,
  handleClickShowPassword,
  showPassword,
  password,
  handleInputChange
}) => {
  const endAdornment = (
    <InputAdornment position="end" className={classes.endAdornmentLogin}>
      <IconButton
        aria-label="toggle password visibility"
        onMouseDown={handleClickShowPassword}
      >
        {showPassword ? (
          <Visibility style={{ fontSize: 20 }} />
        ) : (
          <VisibilityOff style={{ fontSize: 20 }} />
        )}
      </IconButton>
    </InputAdornment>
  );
  return (
    <CustomInput
      id="password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={handleInputChange}
      endAdornment={endAdornment}
    />
  );
};

PasswordInput.propTypes = {
  classes: shape({}).isRequired,
  handleClickShowPassword: func.isRequired,
  showPassword: bool.isRequired,
  password: string,
  handleInputChange: func.isRequired
};

PasswordInput.defaultProps = {
  password: ''
};

export default withStyles(styles)(PasswordInput);
