import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { shape, string, bool, func, number } from 'prop-types';
import { Grid, Modal, FormControl, createMuiTheme } from '@material-ui/core';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import copy from 'copy-to-clipboard';
import styles from '../../styles';
import MeetingAction from '../../stores/meeting/actions';
import CustomInput from '../CustomInput';
import CustomLabel from '../CustomLabel';
import CustomButton from '../CustomButton';
import CopyLinkButton from '../CopyLinkButton';
import CustomDatePicker from '../CustomDatePicker';
import CustomTimePicker from '../CustomTimePicker';
import Loader from '../Loader';
import {
  newSession,
  clientNameLabel,
  emailLabel,
  sessionCreated,
  goBackHome,
  phoneNumber,
  date,
  time,
  clientLastNameLabel,
  emailError
} from '../../config/messages';
import {
  plusIcon,
  closeIcon,
  correctIcon,
  linkIcon
} from '../../config/assets';

class CreateMeetingModal extends Component {
  constructor() {
    super();
    this.state = {
      clientFirstName: '',
      clientLastName: '',
      clientEmail: '',
      clientPhone: '',
      selectedDate: new Date()
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    if (target.type === 'email') {
      target.setCustomValidity('');
    }
    this.setState({ [id]: value });
  };

  handleDateChange = (selectedDate) => {
    this.setState({ selectedDate });
  };

  refreshCreatedMeeting = () => {
    const { dispatch } = this.props;
    dispatch(MeetingAction.refreshCreatedMeeting());
  };

  scheduleMeeting = async (event) => {
    event.preventDefault();
    const { selectedProject, dispatch } = this.props;
    const {
      selectedDate,
      clientFirstName,
      clientLastName,
      clientEmail
    } = this.state;
    const body = {
      projectId: selectedProject,
      buyerFirstName: clientFirstName,
      buyerLastName: clientLastName,
      buyerEmail: clientEmail,
      dateTime: moment(selectedDate).format()
    };
    const actionResponse = await dispatch(MeetingAction.createMeeting(body));
    const { isError } = actionResponse;
    if (!isError) {
      this.getMeetingsList();
      this.clearForm();
    }
  };

  clearForm = () => {
    this.setState({
      clientFirstName: '',
      clientLastName: '',
      clientEmail: '',
      clientPhone: '',
      selectedDate: new Date()
    });
  };

  getMeetingsList = () => {
    const { dispatch, token } = this.props;
    if (token) {
      const body = {
        status: ['scheduled', 'active']
      };
      dispatch(MeetingAction.listMeetings(body));
    }
  };

  handleCopyText = () => {
    const { createdMeeting } = this.props;
    const { id } = createdMeeting;
    const location = window.location.origin;
    copy(`${location}/meeting/${id}`);
  };

  handleInvalidEmail = (e) => {
    const { language } = this.props;
    e.target.setCustomValidity(emailError[language]);
  };

  onInvalid = (e) => {
    const { language } = this.props;
    if (e.target.type === 'email') {
      e.target.setCustomValidity(emailError[language]);
    }
  };

  render() {
    const {
      classes,
      scheduleMeetingForm,
      closeScheduleMeetingForm,
      createdMeeting,
      loading,
      language,
      theme
    } = this.props;
    const {
      clientFirstName,
      clientLastName,
      clientEmail,
      selectedDate,
      clientPhone
    } = this.state;
    const materialTheme = createMuiTheme({
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: theme.rightMenu.activeFontColor
          }
        },
        MuiPickersDay: {
          day: {
            color: theme.rightMenu.activeFontColor
          },
          daySelected: {
            backgroundColor: theme.rightMenu.activeFontColor
          },
          dayDisabled: {
            color: '#adadad'
          },
          current: {
            color: theme.rightMenu.activeFontColor
          }
        },
        MuiPickersModal: {
          dialogAction: {
            color: theme.rightMenu.activeFontColor
          }
        },
        MuiButton: {
          textPrimary: {
            color: theme.rightMenu.activeFontColor
          }
        },
        MuiPickersClock: {
          pin: {
            backgroundColor: theme.rightMenu.activeFontColor
          }
        },
        MuiPickersClockPointer: {
          pointer: {
            backgroundColor: theme.rightMenu.activeFontColor
          },
          noPoint: {
            backgroundColor: theme.rightMenu.activeFontColor
          },
          thumb: {
            border: `14px solid ${theme.rightMenu.activeFontColor}`
          }
        },
        MuiInput: {
          underline: {
            '&:after': {
              content: 'none'
            },
            '&:before': {
              content: 'none'
            }
          }
        }
      }
    });
    const location = window.location.origin;
    if (JSON.stringify(createdMeeting) !== '{}') {
      const { id } = createdMeeting;
      return (
        <Modal
          open={scheduleMeetingForm}
          onClose={() => {
            closeScheduleMeetingForm();
            this.refreshCreatedMeeting();
          }}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.createMeetingModalContainer}
          >
            <h2>{newSession[language]}</h2>
            <img
              src={closeIcon.src}
              alt={closeIcon.alt}
              className={classes.closeIcon}
              onClick={() => {
                closeScheduleMeetingForm();
                this.refreshCreatedMeeting();
              }}
            />
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
              className={classes.createdMeetingContainer}
            >
              <img src={correctIcon.src} alt={correctIcon.alt} />
              <h3>{sessionCreated[language]}</h3>
              <CopyLinkButton onClick={this.handleCopyText}>
                {`${location}/meeting/${id}`}
                <img src={linkIcon.src} alt={linkIcon.alt} />
              </CopyLinkButton>
              <Grid
                container
                className={classes.createMeetingModalButtonContainer}
              >
                <CustomButton
                  onClick={() => {
                    closeScheduleMeetingForm();
                    this.refreshCreatedMeeting();
                  }}
                >
                  {goBackHome[language]}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      );
    }
    return (
      <Modal
        open={scheduleMeetingForm}
        onClose={() => {
          closeScheduleMeetingForm();
          this.refreshCreatedMeeting();
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.createMeetingModalContainer}
        >
          {loading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.loaderContainer}
            >
              <Loader load={false} />
            </Grid>
          )}
          <h2>{newSession[language]}</h2>
          <img
            src={closeIcon.src}
            alt={closeIcon.alt}
            className={classes.closeIcon}
            onClick={() => {
              closeScheduleMeetingForm();
              this.refreshCreatedMeeting();
            }}
          />
          <form
            onSubmit={this.scheduleMeeting}
            className={classes.createMeetingModalFormContainer}
            onInvalid={this.onInvalid}
          >
            <FormControl className={classes.createMeetingModalFormControl}>
              <CustomLabel htmlFor="clientFirstName">
                {clientNameLabel[language]}
              </CustomLabel>
              <CustomInput
                id="clientFirstName"
                type="text"
                value={clientFirstName}
                onChange={this.handleInputChange}
              />
            </FormControl>
            <FormControl className={classes.createMeetingModalFormControl}>
              <CustomLabel htmlFor="clientLastName">
                {clientLastNameLabel[language]}
              </CustomLabel>
              <CustomInput
                id="clientLastName"
                type="text"
                value={clientLastName}
                onChange={this.handleInputChange}
              />
            </FormControl>
            <FormControl className={classes.createMeetingModalFormControl}>
              <CustomLabel htmlFor="clientEmail">
                {emailLabel[language]}
              </CustomLabel>
              <CustomInput
                id="clientEmail"
                type="email"
                value={clientEmail}
                onChange={this.handleInputChange}
              />
            </FormControl>
            <FormControl className={classes.createMeetingModalFormControl}>
              <CustomLabel htmlFor="clientPhone">
                {phoneNumber[language]}
              </CustomLabel>
              <CustomInput
                id="clientPhone"
                type="text"
                value={clientPhone}
                onChange={this.handleInputChange}
              />
            </FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <FormControl className={classes.createMeetingModalFormControl}>
                <CustomLabel htmlFor="date">{date[language]}</CustomLabel>
                <ThemeProvider theme={materialTheme}>
                  <CustomDatePicker
                    id="date"
                    value={selectedDate}
                    onChange={this.handleDateChange}
                    format="MMMM/DD/YYYY"
                  />
                </ThemeProvider>
              </FormControl>
              <FormControl className={classes.createMeetingModalFormControl}>
                <CustomLabel htmlFor="time">{time[language]}</CustomLabel>
                <ThemeProvider theme={materialTheme}>
                  <CustomTimePicker
                    value={selectedDate}
                    onChange={this.handleDateChange}
                  />
                </ThemeProvider>
              </FormControl>
            </MuiPickersUtilsProvider>
            <Grid
              container
              className={classes.createMeetingModalButtonContainer}
            >
              <CustomButton type="submit">
                <img
                  src={plusIcon.src}
                  alt={plusIcon.alt}
                  className={classes.buttonIcon}
                />
                {newSession[language]}
              </CustomButton>
            </Grid>
          </form>
        </Grid>
      </Modal>
    );
  }
}

CreateMeetingModal.propTypes = {
  classes: shape({}).isRequired,
  token: string.isRequired,
  language: string.isRequired,
  scheduleMeetingForm: bool.isRequired,
  closeScheduleMeetingForm: func.isRequired,
  createdMeeting: shape({}).isRequired,
  loading: bool.isRequired,
  selectedProject: number.isRequired,
  theme: shape({}).isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state.session;
  const { language } = state.language;
  const { createdMeeting } = state.meeting;
  const { loading } = state.loading;
  const { selectedProject, theme } = state.project;
  return {
    token,
    language,
    createdMeeting,
    loading,
    selectedProject,
    theme
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateMeetingModal));
