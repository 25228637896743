export default class LoginModel {
  constructor(data = {}) {
    this.token = data.token;
    this.user = data.user;
  }

  token = '';

  user = {};
}
