import React from 'react';
import { shape, string, bool, func, arrayOf, number } from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PrivatePanelSession from './Session';
import themeStyles from '../../styles';

const SalesDesktopPrivatePanel = ({
  classes,
  language,
  privatePanelVisible,
  togglePrivatePanel,
  twilioToken,
  identity,
  twilioRoom,
  videoCallOpen,
  socket,
  toggleVideoCall,
  meetingIsActive,
  tabs,
  pendingMessages,
  currentTab,
  changeTab
}) => (
  <>
    {!privatePanelVisible && (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.showPrivatePanelButton}
        onClick={togglePrivatePanel}
      >
        {pendingMessages && (
          <Grid container className={classes.chatBadgeIndicator} />
        )}
        <ChevronLeft className={classes.privatePanelButton} />
      </Grid>
    )}
    <Grid
      className={
        !privatePanelVisible
          ? classes.hiddenPrivatePanelContainer
          : classes.privatePanelContainer
      }
    >
      <Grid
        container
        justify="space-around"
        alignItems="center"
        className={classes.tabsContainer}
      >
        {tabs.map((tab, index) => (
          <Grid
            key={`tab-${index.toString()}`}
            container
            justify="center"
            alignItems="flex-start"
            className={currentTab === index ? classes.activeTab : classes.tab}
            onClick={() => {
              changeTab(index);
            }}
          >
            <Grid
              container
              className={
                currentTab === index
                  ? classes.activeTabIndicator
                  : classes.noTabIndicator
              }
            />
            {tab.badge && (
              <Grid container className={classes.chatBadgeIndicator} />
            )}
            {tab[language]}
          </Grid>
        ))}
        <ChevronRight
          className={classes.privatePanelButton}
          onClick={togglePrivatePanel}
        />
      </Grid>
      <Grid container className={classes.tabContentContainer}>
        {meetingIsActive && (
          <PrivatePanelSession
            twilioToken={twilioToken}
            meeting={twilioRoom}
            identity={identity}
            videoCallOpen={videoCallOpen}
            toggleVideoCall={toggleVideoCall}
            socket={socket}
            currentTab={currentTab}
          />
        )}
        {tabs.map((tab, index) => (
          <Grid
            key={`tab-${index.toString()}`}
            container
            className={
              currentTab !== index
                ? classes.noSelectedTabContentContainer
                : classes.selectedTabContentContainer
            }
          >
            {tab.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </>
);

SalesDesktopPrivatePanel.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  privatePanelVisible: bool.isRequired,
  togglePrivatePanel: func.isRequired,
  twilioToken: string.isRequired,
  identity: string.isRequired,
  twilioRoom: string.isRequired,
  videoCallOpen: bool.isRequired,
  socket: shape({}).isRequired,
  toggleVideoCall: func.isRequired,
  meetingIsActive: bool.isRequired,
  tabs: arrayOf(shape({})).isRequired,
  pendingMessages: bool.isRequired,
  currentTab: number.isRequired,
  changeTab: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { twilioToken, identity, twilioRoom, meetingIsActive } = state.meeting;
  return {
    language,
    twilioToken,
    identity,
    twilioRoom,
    meetingIsActive
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(themeStyles)(SalesDesktopPrivatePanel));
