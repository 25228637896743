import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { shape, string, bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControl } from '@material-ui/core';
import MeetingAction from '../stores/meeting/actions';
import CustomInput from '../components/CustomInput';
import CustomLabel from '../components/CustomLabel';
import CustomButton from '../components/CustomButton';
import { background, poweredByAtHum } from '../config/assets';
import {
  rescheduleMessage,
  firstRescheduleMessage,
  emailSentMessage,
  emailSentMessageSecond
} from '../config/messages';
import styles from '../styles';

const ReschedulePage = ({
  classes,
  language,
  meetingId,
  emailSent,
  logoUri,
  match,
  dispatch
}) => {
  const { params } = match;
  const { meetingId: meetingIdFromUrl = null } = params;
  const [clientName, setClientName] = useState('');
  const [email, setEmail] = useState('');
  if (meetingIdFromUrl && logoUri === undefined) {
    return <Redirect to={`/meeting/${meetingIdFromUrl}`} />;
  }
  if (logoUri === undefined) {
    return <Redirect to="/" />;
  }
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      style={{
        backgroundImage: `url(${background.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 35%'
      }}
      className={classes.rescheduleContainer}
    >
      <img src={logoUri} alt="logo" />
      <h2>
        {!emailSent
          ? firstRescheduleMessage[language]
          : emailSentMessage[language]}
      </h2>
      <p>
        {!emailSent
          ? rescheduleMessage[language]
          : emailSentMessageSecond[language]}
      </p>
      {!emailSent && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(
              MeetingAction.sendEmailReschedule(meetingId, {
                name: clientName,
                email
              })
            );
          }}
          className={classes.rescheduleFormContainer}
        >
          <FormControl className={classes.formControlContainer}>
            <CustomLabel htmlFor="clientName">Name</CustomLabel>
            <CustomInput
              id="clientName"
              type="text"
              value={clientName}
              onChange={(event) => {
                const { target } = event;
                const { value } = target;
                setClientName(value);
              }}
            />
          </FormControl>
          <FormControl className={classes.formControlContainer}>
            <CustomLabel htmlFor="clientEmail">Email</CustomLabel>
            <CustomInput
              id="clientEmail"
              type="email"
              value={email}
              onChange={(event) => {
                const { target } = event;
                const { value } = target;
                setEmail(value);
              }}
            />
          </FormControl>
          <Grid container className={classes.rescheduleButtonContainer}>
            <CustomButton type="submit">Send to agent</CustomButton>
          </Grid>
        </form>
      )}
      <Grid>
        <img
          src={poweredByAtHum.src}
          alt={poweredByAtHum.alt}
          className={classes.rescheduleAthumLogo}
        />
      </Grid>
    </Grid>
  );
};

ReschedulePage.propTypes = {
  classes: shape({}).isRequired,
  language: string.isRequired,
  match: shape({}).isRequired,
  meetingId: string.isRequired,
  emailSent: bool.isRequired,
  logoUri: string.isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = (state) => {
  const { language } = state.language;
  const { meetingId, emailSent } = state.meeting;
  const { general } = state.project;
  const { logoUri } = general;
  return {
    language,
    meetingId,
    emailSent,
    logoUri
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReschedulePage));
