import React from 'react';
import { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import loader from '../../assets/home-white.gif';
import styles from './styles';

const LogoLoader = ({ classes }) => (
  <Grid
    className={classes.loaderContainer}
    container
    justify="center"
    alignItems="center"
  >
    <Grid item className="loader-image-container">
      <img src={loader} alt="athum loader" />
    </Grid>
  </Grid>
);

LogoLoader.propTypes = {
  classes: shape({}).isRequired
};

export default withStyles(styles)(LogoLoader);
