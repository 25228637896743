import {
  VIRTUAL_SALES_API,
  VIRTUAL_SALES_API_KEY
} from '../../../config/endpoints';
import HttpUtility from '../../../utilities/HttpUtility';
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel';
import MeetingsModel from '../models';
import MeetingModel from '../models/MeetingModel';

export default class MeetingEffect {
  static async createMeeting(language, token, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async listMeetings(language, token, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/list`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async startMeeting(language, token, meetingId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}`;
    const response = await HttpUtility.put(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async joinMeeting(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async getTwilioToken(language, token, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/v2/${language}/sales-person/meetings/${meetingId}/start`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async getBuyerTwilioToken(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/buyer-person/meetings/${meetingId}/start`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async deleteMeeting(language, token, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}`;
    const response = await HttpUtility.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async finishMeeting(language, token, meetingId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}`;
    const response = await HttpUtility.put(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async checkMeetingStatus(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async leaveMeeting(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async getBuyerTwilioTokenV2(language, token, meetingId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/v2/${language}/participants/meetings/${meetingId}/start`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async onboarding(language, meetingId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/buyer-person/meetings/${meetingId}/onboarding`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data, meetingId);

    return model;
  }

  static async sendEmail(language, token, meetingId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/buyer-person/meetings/${meetingId}/send-email`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async sendEmailReschedule(language, token, meetingId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/buyer-person/meetings/${meetingId}/reschedule/send-email`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async getCustomerMeeting(language, meetingId, customerId) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/customers/${customerId}/meetings/${meetingId}`;
    const response = await HttpUtility.get(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY
      }
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async clientLog(language, customerId, body) {
    const endpoint = `${VIRTUAL_SALES_API}/${language}/customers/${customerId}/sessions`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        'x-api-key': VIRTUAL_SALES_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingsModel(response.data);

    return model;
  }

  static async saveMxCalculator(language, token, meetingId, body) {
    console.log('effect', language, token, meetingId, body);
    const endpoint = `${VIRTUAL_SALES_API}/${language}/meetings/${meetingId}/price-calculator/mx/sale`;
    const response = await HttpUtility.post(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    const model = new MeetingModel(response.data);

    return model;
  }
}
