const styles = {
  tooltip: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 60,
    margin: 'auto',
    height: 36,
    background: 'rgba(74, 74, 74, 0.7)',
    backdropFilter: 'blur(4px)',
    borderRadius: 5,
    transition: 'opacity 1s ease-in-out',
    '&.added': { opacity: 1 },
    '&.hide': {
      opacity: 0
    },
    '& .icon': {
      width: 18,
      height: 18,
      marginRight: 8,
      filter: 'brightness(0) invert(1)'
    },
    '& .tooltip-message': {
      color: '#FFFFFF',
      fontSize: 14
    }
  }
};

export default styles;
